import { gql } from "@apollo/client";
import {
  IonItem,
  IonLabel,
  IonCard,
  IonButton,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonAlert,
  IonToggle,
  IonPopover,
} from "@ionic/react";
import { t } from "i18next";
import { add, caretDown, caretUp, cloudDownload, informationCircle, } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIngredients } from "../dashboard-app/pages/Ingredients";
import { NumberInput } from "./NumberInput";
import { ComponentIdSelect, ComponentType } from "./ComponentIdSelect";
import { IngredientPicker } from "./IngredientPicker";
import { fixObjectForHasuraMany2ManyInsert } from "../lib/fixObjectForHasuraMany2ManyInsert";
import { referenceVolumePerHa } from "./ReferenceSprayingArea";
import { LPerUnit, useInsertReplenishment } from "./Replenishment";
import { ReplenishmentPicker } from "./ReplenishmentPicker";


export interface Dosage {
  amount_per_ha: number;
  ingredient: any
}


const useReplenishmentState = () => {
  // Use this to manipulate temporary replenishment
  const [dosages, setDosages] = useState<Dosage[]>([])
  const addDosage = (newDosage: Dosage) => {
    setDosages([...dosages, newDosage])
  }
  const changeDosage = (index: number, field: string, newValue: any) => setDosages(dosages.map((d: any, i: number) => i === index ? {...d, [field]: newValue} : d))
  const removeDosage = (index: number) => setDosages(dosages.filter((_: any, i: number) => i !== index))
  
  const totalVolumePerHa = referenceVolumePerHa
  const waterVolumePerHa = referenceVolumePerHa - dosages.reduce((prev: number, next: Dosage) => prev + (next.amount_per_ha * LPerUnit(next.ingredient.unit)), 0)

  const [insertReplenishment] = useInsertReplenishment()
  const save = (data: any) => {
    insertReplenishment({variables: {object:
      fixObjectForHasuraMany2ManyInsert(data)
    }})
  }

  return {
    dosages, addDosage, removeDosage, changeDosage, waterVolumePerHa, totalVolumePerHa, save, setDosages
  }
}


export const SavingsCalculator: React.FC<any> = ({savings, setSavings, ...props}: any) => {
  // For simulation
  const [data, setData] = useState({
    spotWidth: 0.185,
    spotLength: 0.142,
    plantsPerHa: 90000,
  })
  const [event, setEvent] = useState<any>(null)
  const spotArea = data.spotWidth * data.spotLength
  useEffect(() => {
    setSavings(1. - (spotArea * data.plantsPerHa / 10000.))
  }, [data])
  const {t} = useTranslation()

  return <>
    <IonButton size="small" onClick={setEvent} {...props}>
      {(savings * 100).toFixed(1)} % {t("Savings")}
      <IonIcon slot={"end"} icon={event ? caretUp : caretDown}/>
    </IonButton>

    <IonPopover event={event} isOpen={!!event} onDidDismiss={() => setEvent(null)}>

      <IonItem lines="none">
        <IonLabel position="stacked">{t("Spot width (cm)")}</IonLabel>
        <NumberInput value={data.spotWidth * 100} onChange={(newVal: number) => setData({...data, spotWidth: newVal / 100.})} isFloat decimals={1} />
      </IonItem>

      <IonItem lines="none">
        <IonLabel position="stacked">{t("Spot length (cm)")}</IonLabel>
        <NumberInput value={data.spotLength * 100} onChange={(newVal: number) => setData({...data, spotLength: newVal / 100.})} isFloat decimals={1} />
      </IonItem>

      <IonItem lines="none">
        <IonLabel position="stacked">{t("Number of plants per ha")}</IonLabel>
        <NumberInput value={data.plantsPerHa} onChange={(newVal: number) => setData({...data, plantsPerHa: newVal})}/>
      </IonItem>
    </IonPopover>
  
  </>
}


export const AreaConfigurator: React.FC<any> = ({previousAmount, setPreviousAmount, areaToBeTreated, setAreaToBeTreated, totalVolumePerHa, savings, maxVolume = 280., ...props}: any) => {
  const [event, setEvent] = useState<any>(null)
  const [enforceMaxVolume, setEnforceMaxVolume] = useState(true)
  const {t} = useTranslation()
  const maxFieldSizeHa = (maxVolume + previousAmount) / totalVolumePerHa / (1. - savings)
  useEffect(() => {
    // Enforce maxFieldSizeHa
    if (enforceMaxVolume && areaToBeTreated > maxFieldSizeHa) {
      setAreaToBeTreated(Math.floor(maxFieldSizeHa * 100) / 100.)
    }
  }, [maxFieldSizeHa, areaToBeTreated, enforceMaxVolume])

  return <>
    <IonButton size="small" onClick={setEvent} {...props}>
      {t("Fill up for {{ acreageHa}} ha", {acreageHa: areaToBeTreated.toFixed(2)})}
      {previousAmount > 0 && <>{" "}{t("with pre-fill amount of {{previousAmountL}} L", {previousAmountL: previousAmount.toFixed(2)})}</>}
      <IonIcon slot={"end"} icon={event ? caretUp : caretDown}/>
    </IonButton>
  
    <IonPopover event={event} isOpen={!!event} onDidDismiss={() => setEvent(null)}>

      <IonItem lines="none">
        <IonLabel position="stacked">{t("Acreage")} (ha)</IonLabel>
        <NumberInput decimals={2} value={areaToBeTreated} onChange={setAreaToBeTreated} isFloat max={enforceMaxVolume ? maxFieldSizeHa : Infinity} />
      </IonItem>

      <IonItem lines="none">
        <IonLabel position="stacked">{t("Enforce max volume of {{maxVolumeL}} L", {maxVolumeL: maxVolume.toFixed(1)})}</IonLabel>
        <IonToggle checked={enforceMaxVolume} onIonChange={({detail: {checked}}: any) => setEnforceMaxVolume(checked)}/>
      </IonItem>

      <IonItem lines="none">
        <IonLabel position="stacked">{t("Remaining amount before refilling (L)")}</IonLabel>
        <NumberInput decimals={2} value={previousAmount} onChange={setPreviousAmount} isFloat />
      </IonItem>

    </IonPopover>
 
  </>
}


export const SprayerConfigurator: React.FC<any> = ({fieldSizeHa = 10}: any) => {
  const [savings, setSavings] = useState(0)
  const [areaToBeTreated, setAreaToBeTreated] = useState(fieldSizeHa)
  const replenishmentState = useReplenishmentState()
  const [previousAmount, setPreviousAmount] = useState(0.)
  
  return <IonCard>
    <SavingsCalculator savings={savings} setSavings={setSavings}/>
    <AreaConfigurator totalVolumePerHa={replenishmentState?.totalVolumePerHa} areaToBeTreated={areaToBeTreated} setAreaToBeTreated={setAreaToBeTreated} savings={savings}
      previousAmount={previousAmount} setPreviousAmount={setPreviousAmount}/>
    <div style={{borderTop: "1px solid lightgray"}}>
      <ReplenishmentCreator areaToBeTreated={areaToBeTreated} savings={savings} replenishmentState={replenishmentState} previousAmount={previousAmount} />
    </div>
  </IonCard>
}


export const IngredientsSelect: React.FC<any> = ({ingredients, ingredientId, setIngredientId}: any) => {

  return <IonSelect value={ingredientId} onIonChange={(e: any) => setIngredientId(e.detail.value)}>
    {ingredients?.map((ingredient: any) => <IonSelectOption value={ingredient.id} key={ingredient.id}>
      {ingredient.name_DE}
    </IonSelectOption>)}
  </IonSelect>
}


export const ReplenishmentCreator: React.FC<any> = ({areaToBeTreated, savings, previousAmount, replenishmentState}: any) => {
  const {data} = useIngredients()
  const {dosages, setDosages, addDosage, removeDosage, changeDosage, totalVolumePerHa, waterVolumePerHa, save} = replenishmentState
  const areaTreatableWithPreviousAmount = previousAmount / totalVolumePerHa / (1. - savings)
  const areaTreatableWithRefill = areaToBeTreated - areaTreatableWithPreviousAmount
  const refillVolume = totalVolumePerHa * (1. - savings) * areaTreatableWithRefill
  const waterVolume = waterVolumePerHa * (1. - savings) * areaTreatableWithRefill
  const [taurusId, setTaurusId] = useState<null|number>(null)

  return <>
    {areaTreatableWithPreviousAmount > 0 && totalVolumePerHa > 0 && <IonItem>
      <IonIcon icon={informationCircle} slot="start"/>
      {t("{{ areaHa }} ha (from {{ totalAreaHa }} ha in total) can be treated with remaining {{ remainingAmountL }} L in sprayer tank", {
        areaHa: areaTreatableWithPreviousAmount.toFixed(2),
        totalAreaHa: areaToBeTreated.toFixed(2),
        remainingAmountL: previousAmount.toFixed(2),
      })}
    </IonItem>}

    <div style={{display: "flex", alignItems: "end", justifyContent: "space-between", paddingLeft: 10, paddingTop: 20, gap: 10}}>
      <div style={{width: 250, margin: 10}}>
        <strong>
          {t("Name")}
        </strong>
      </div>
      <div style={{flexGrow: 1, textAlign: "end"}}>
        <strong>{t("Comparison amount")}</strong><br/>
        {t("for broadcast treatment")}
      </div>
      <div style={{width: 140, flexGrow: 0, flexShrink: 0, textAlign: "end", paddingRight: 10}}>
        <strong>Farming GT</strong><br/>
        {t("for {{fieldSize}} ha area and {{savingsPercentage}} % savings", {fieldSize: areaTreatableWithRefill.toFixed(2), savingsPercentage: (100 * savings).toFixed(1)})}
      </div>
    </div>

    {dosages.map(({ingredient, amount_per_ha}: any, i: number) => {
      return <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", gap: 10}} key={i}>
        <DosageInputRow ingredients={data?.ingredients} ingredient={ingredient} changeDosage={changeDosage} totalVolumePerHa={totalVolumePerHa}
          i={i} areaTreatableWithRefill={areaTreatableWithRefill} savings={savings} removeDosage={removeDosage} previousAmount={previousAmount}
          initialAmount={amount_per_ha}
        />
      </div>
    })}

    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", gap: 10}}>
      <div style={{paddingLeft: 10}}>
        {t("Refill volume")}
      </div>
      <div style={{display: "flex", flexGrow: 1, flexDirection: "column", fontSize: "larger", textAlign: "end"}}>
        {referenceVolumePerHa.toFixed(2)}
        <div style={{fontSize: "10px"}}>
          L / ha*
        </div>
      </div>
      <div style={{width: 140, flexGrow: 0, flexShrink: 0, textAlign: "end", paddingRight: 10, fontSize: "larger"}}>
        {refillVolume.toFixed(2)} L
      </div>
    </div>

    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", gap: 10}}>
      <div style={{paddingLeft: 10}}>
        {t("Water volume")}
      </div>
      <div style={{display: "flex", flexGrow: 1, flexDirection: "column", fontSize: "larger", textAlign: "end"}}>
        {waterVolumePerHa.toFixed(2)}
        <div style={{fontSize: "10px"}}>
          L / ha*
        </div>
      </div>
      <div style={{width: 140, flexGrow: 0, flexShrink: 0, textAlign: "end", paddingRight: 10, fontSize: "larger"}}>
        {waterVolume.toFixed(2)} L
      </div>
    </div>

    <IngredientPicker onSelect={(ingredient: any) => addDosage({ingredient, amount_per_ha: 0.1})} expand="block" fill="clear">
      <IonIcon icon={add} slot="start"/>
      {t("Add ingredient")}
    </IngredientPicker>

    <ReplenishmentPicker expand="full" fill="clear" title={t("Select template")}
      onSelect={(replenishment: any) => setDosages(replenishment.ingredients.map(({ingredient, amount}: any) => ({
        // Convert replenishment data to dosages
        ingredient, amount_per_ha: amount / replenishment.volume_after_filling_L * referenceVolumePerHa
      })))}
    >
      <IonIcon icon={cloudDownload} slot="start"/>
      {t("Load from history")}
    </ReplenishmentPicker>

    <IonItem>
      <IonLabel>{t("Robot")}</IonLabel>
      <ComponentIdSelect componentType={ComponentType.Taurus} componentId={taurusId}
        setComponentId={(newValue: number) => setTaurusId(newValue)}/>
    </IonItem>

    {taurusId && totalVolumePerHa > 0 && <IonButton onClick={() => save({
        planned_ha: areaTreatableWithRefill, planned_herbicide_savings: savings, planned_volume_L: refillVolume,
        volume_after_filling_L: refillVolume, simulation_name: "configurator",
        ingredients: dosages.map(({amount_per_ha, ingredient}: any) => ({amount: amount_per_ha * areaTreatableWithRefill * (1. - savings), ingredient })),
        taurus_id: taurusId,
      })} expand="block">
      <IonIcon icon={save} slot="start"/>
      {t("Save for {{robotName}}", {robotName: `GT${taurusId}`})}
    </IonButton>}

  </>
}


export const DosageInputRow: React.FC<any> = ({initialAmount, ingredient, changeDosage, removeDosage, i, savings, areaTreatableWithRefill, }: any) => {
  const [amountPerHa, setAmountPerHa] = useState(initialAmount)
  const [showRemoveAlert, setShowRemoveAlert] = useState(false)

  useEffect(() => {
    if (ingredient?.default_amount_per_ha && ingredient.default_amount_per_ha != amountPerHa) {
      setAmountPerHa(ingredient.default_amount_per_ha)
      changeDosage(i, "amount_per_ha", ingredient.default_amount_per_ha)
    }
  }, [ingredient])

  const onChange = (newValue: number) => {
    if (ingredient?.default_amount_per_ha) {
      return  // Skip if amount is set by default (cannot be edited)
    }
    if (newValue == amountPerHa) {
      return  // Skip if nothing changed
    }
    if (newValue == 0 && amountPerHa != 0) {
      setShowRemoveAlert(true)  // Ask user if element should be removed
    }
    setAmountPerHa(newValue)
  }

  useEffect(() => {
    changeDosage(i, "amount_per_ha", amountPerHa)  // Update
  }, [amountPerHa])

  return <>
    <IonAlert
      isOpen={showRemoveAlert}
      message={t("The amount for {{ingredientName}} was set to 0", {ingredientName: ingredient?.name_DE})}
      buttons={[
        {
          text: t("Remove {{ingredientName}}", {ingredientName: ingredient?.name_DE}),
          handler: () => removeDosage(i),
        },
        {
          text: t("Cancel"),
          handler: () => setShowRemoveAlert(false),
        },
      ]}
      onDidDismiss={() => setShowRemoveAlert(false)}
    ></IonAlert>
    
    <IngredientPicker onSelect={(ingredient: any) => changeDosage(i, "ingredient", ingredient)}
      title={t("Select ingredient")} fill={ingredient ? "clear" : "fill"} style={{display: "block", flexShrink: 1}}>
      <IonLabel style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 130}}>
        {ingredient?.name_DE || t("Select")}
      </IonLabel>
      <IonIcon icon={caretDown} slot="end"/>
    </IngredientPicker>

    <div style={{display: "flex", flexGrow: 1, flexDirection: "column", fontSize: "larger", textAlign: "end"}}>
      <NumberInput value={amountPerHa} decimals={2} step={0.1} isFloat onChange={onChange} disabled={ingredient?.default_amount_per_ha || !ingredient}/>
      <div style={{fontSize: "10px"}}>
        {ingredient?.unit} / ha*
      </div>
    </div>
    <div style={{width: 140, flexGrow: 0, flexShrink: 0, textAlign: "end", paddingRight: 10, fontSize: "larger"}}>
      {(amountPerHa * areaTreatableWithRefill * (1. - savings) ).toFixed(2) /* Subtract previous amount depending on ratio */  } {ingredient?.unit}
    </div>
  </>
}