import { memo } from "react";
import { COLORMAP_SEABORN_ROCKET, TEXT_COLOR_SEABORN_ROCKET } from "../utils/colormap";

export interface OverallProgress {
    opening_duration_idx: number;
    pressure_idx:         number;
}

export interface CurrentStep {
    current: number;
    total:   number;
}


export const CharacteristicTable : React.FC<{
    pressures_bar : number[],
    opening_durations_ms : number[],
    spot_masses_mg : Array<(number|null)[]> | null,
    overall_progress : OverallProgress | null,
    current_step : CurrentStep | null
}> = memo((props) => {

    const td_width = `${100 / ((props.pressures_bar.length || 0) + 1)}%`;

    return <table style={{tableLayout: "fixed", width: "100%", maxWidth: '600px'}}><tbody>
        <tr>
            <td style={{width: td_width}}></td>
            {props.pressures_bar.map(pressure_bar=> {
                return <td style={{width: td_width, fontWeight: "bold", textAlign: 'right'}}>{pressure_bar.toFixed(2)} bar</td>
            })}
        </tr>
        {props.opening_durations_ms.map((opening_duration_ms, i)=><tr key={i}>
            <td style={{fontWeight: 'bold'}}>{opening_duration_ms} ms</td>
            {props.pressures_bar.map((pressure_bar, j)=>{
                let text;
                let backgroundColor = 'white';
                let color = 'rgb(38.25, 38.25, 38.25)';
                if (!props.spot_masses_mg) {
                    text = '-';
                } else {
                    let spot_mass_mg = props.spot_masses_mg[i][j];
                    if (spot_mass_mg === null) {
                        text = "-"
                    } else {
                        text = `${spot_mass_mg.toFixed(1)} mg`;
                        const MIN_MASS_MG = 75;
                        const MAX_MASS_MG = 185;
                        let x = (spot_mass_mg - MIN_MASS_MG) / (MAX_MASS_MG - MIN_MASS_MG);
                        x = Math.min(Math.max(x, 0), 1);
                        backgroundColor = COLORMAP_SEABORN_ROCKET(x);
                        color = TEXT_COLOR_SEABORN_ROCKET(backgroundColor);
                    }
                }

                let is_currently_being_calibrated = (
                    text === '-' &&
                    (props.overall_progress?.opening_duration_idx === i) &&
                    (props.overall_progress?.pressure_idx === j)
                );

                return <td style={{textAlign: 'right', color, backgroundColor, position: 'relative'}} key={j}>
                    {text}
                    {is_currently_being_calibrated && <div style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: `${(100 * props.current_step!.current / props.current_step!.total)}%`,
                        height: '100%',
                        backgroundColor: 'rgba(100,100,100, 0.5)',
                        display: 'block',
                        transition: 'width linear 1s'
                    }} />}
                </td>
            })}
        </tr>)}
    </tbody></table>
})