import { IonList, IonButton, IonItem, IonLabel, IonIcon, IonCard, IonChip, useIonPicker, IonListHeader, IonBadge, IonItemDivider, } from '@ionic/react';
import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { checkmark, chevronDown, close, cog, pencil, } from 'ionicons/icons';
import history, { goBackOrGoto } from '../history';
import useWindowDimensions from '../useWindowDimensions';
import Loading from './Loading';
import { useTranslation } from 'react-i18next';


export const HardwareVersionPicker: React.FC<any> = ({talpa}: any) => {

  const [present] = useIonPicker();
   const { loading, error, data } = useQuery(gql`query{
      talpa_hardware_versions(where: {archived: {_eq: false}})  {
        id
        description
      }
  }`);
  const [updateTalpa] = useMutation(gql`
    mutation UpdateTalpa($id: Int!, $_set: talpas_set_input = {}) {
      update_talpas_by_pk(pk_columns: {id: $id}, _set: $_set) {
        id hardware_version {
          id
        }
      }
    }
  `, {})
 
  const openPicker = async () => {
    present({
      columns: [
        {
          name: 'hardware_version',
          options: data.talpa_hardware_versions.map((v: any) => ({
              text: v.description,
              value: v.id,
              selected: v.id === talpa.hardware_version.id,
          })),
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Confirm',
          handler: (value) => {
            updateTalpa({variables: {id: talpa.id, _set: {hardware_version_id: value.hardware_version.value}}})
          },
        },
      ],
    });
  };
  if (!data) return <Loading/>
  return <IonButton fill="clear" onClick={() => openPicker()}><IonIcon slot="icon-only" icon={pencil}/></IonButton>
}

export interface TalpaCalibration {
  created_at : string;
  data: Tlp2Calibration | SingleSideCalibration;
}

export interface Tlp2Calibration {
  left:  SingleSideCalibration;
  right: SingleSideCalibration;
}

export interface SingleSideCalibration {
  calib_offset_x_m:     number;
  calib_offset_y_m:     number;
  calib_offset_yaw_rad: number;
}

function withPlusSign(f : string) : string {
  if (parseFloat(f)>0.0) {
    return `+${f}`
  }
  return f
}

const CalibrationWidget : React.FC<{calib: SingleSideCalibration | undefined}> = ({calib})=> {
  if (calib === undefined) {
    return <span style={{display: 'inline-block', whiteSpace: 'nowrap', color: '#d00', fontWeight: 'bold'}}>missing</span>
  }
  return <span style={{display: 'inline-block', whiteSpace: 'nowrap'}}>
    x: {withPlusSign((calib.calib_offset_x_m*100).toFixed(1))} cm | y: {withPlusSign((calib.calib_offset_y_m*100).toFixed(1))} cm | θ: {withPlusSign((calib.calib_offset_yaw_rad*180/Math.PI).toFixed(1))} °
  </span>
}

export const TalpaItem: React.FC<any> = ({talpa}: any) => {
  const taurus_id = talpa?.talpa_configs && talpa?.talpa_configs[0]?.robot_config?.taurus_configs[0]?.taurus?.id 
  const {isLargeScreen} = useWindowDimensions()
  const { t } = useTranslation();
  const onClick = (e:any, robotConfigId: number) => {
      e.stopPropagation()
      history.push(`/robot/${robotConfigId}`)
  }
  const [expanded, setExpanded] = useState(false)

  const headerItem = <IonItem onClick={() => setExpanded(!expanded)}>
    <IonLabel>TLP {talpa?.id}</IonLabel>

    {taurus_id && <IonButton onClick={(e: any) => onClick(e, talpa?.talpa_configs[0]?.robot_config?.id)} color="secondary">GT{taurus_id}</IonButton>}

    {talpa?.description && <i>{talpa?.description}</i>}
    <IonBadge>{talpa.hardware_version.description}</IonBadge>
    
    {isLargeScreen && <>
      <IonChip
        outline={talpa?.side_of_row === "left"}
      >
        {talpa?.side_of_row}
      </IonChip>

      <IonChip
        outline={talpa?.controller_swivel === "miControl E40"}
      >
        {talpa?.controller_swivel}
      </IonChip>
    </>}

    <IonIcon icon={chevronDown} slot="end"/>
  </IonItem>

  if (!expanded) {
      return headerItem
  }
  else {
    return <IonCard style={{margin: "15px"}}>
        {headerItem}
        <IonList>
          <IonItem>
            <IonLabel>{t("Arm hardware version")}</IonLabel>
            <HardwareVersionPicker talpa={talpa} />
            {talpa.hardware_version.description}
          </IonItem>

          <IonItemDivider>{t("Controllers")}</IonItemDivider>
          {talpa.can_id_swivel > 0 && <IonItem>
            <IonLabel>{t("Swivel")} </IonLabel>{talpa.controller_swivel} (ID: {talpa.can_id_swivel}, 0x{talpa.can_id_swivel.toString(16)})
          </IonItem>}
          {talpa.can_id_chopper > 0 && <IonItem>
            <IonLabel>{t("Chopper")} </IonLabel>{talpa.controller_chopper} (ID: {talpa.can_id_chopper}, 0x{talpa.can_id_chopper.toString(16)})
          </IonItem>}
          {talpa.can_id_lifter > 0 && <IonItem>
            <IonLabel>{t("Lifter")} </IonLabel>{"miControl E40"} (ID: {talpa.can_id_lifter}, 0x{talpa.can_id_lifter.toString(16)})
          </IonItem>}
          
          {/* New talpas */}
          {talpa.can_id_swivel_left > 0 && <IonItem>
            <IonLabel>{t("Left swivel")} </IonLabel>{talpa.controller_swivel} (ID: {talpa.can_id_swivel_left}, 0x{talpa.can_id_swivel_left.toString(16)})
          </IonItem>}
          {talpa.can_id_swivel_right > 0 && <IonItem>
            <IonLabel>{t("Right swivel")} </IonLabel>{talpa.controller_swivel} (ID: {talpa.can_id_swivel_right}, 0x{talpa.can_id_swivel_right.toString(16)})
          </IonItem>}
          {talpa.lifter_motor_address && <IonItem>
            <IonLabel>{t("Lifter motor")} </IonLabel>Linak (ID: {talpa.lifter_motor_address}, 0x{talpa.lifter_motor_address.toString(16)})
          </IonItem>}
          <IonItem>
            <IonLabel>{t("Last calibrations")} </IonLabel>
            <ul style={{listStyleType: 'none', padding: 0}}>
            {talpa.calibrations.map((tc : TalpaCalibration, idx : number) =>{
              let created_at = new Date(tc.created_at);
              let created_at_fmt = `${created_at.toLocaleDateString()}, ${created_at.toLocaleTimeString()}`
              if ((tc.data as any).left !== undefined) {
                let tc2 = tc.data as Tlp2Calibration;
                return <li style={{fontWeight: (idx===0)?'bold':'normal', borderTop: (idx===0)?'none':'1px solid #ccc', marginBottom: '.5em', fontFamily: 'monospace'}}>
                  <table style={{width: '100%'}}>
                    <tr>
                      <td colSpan={2}>{created_at_fmt}</td>
                    </tr>
                    <tr><td>left:</td><td><CalibrationWidget calib={tc2.left} /></td></tr>
                    <tr><td>right:</td><td><CalibrationWidget calib={tc2.right} /></td></tr>
                  </table>
                </li>
              } else {
                let tc1 = tc.data as SingleSideCalibration;
                return <li style={{fontWeight: (idx===0)?'bold':'normal', borderTop: (idx===0)?'none':'1px solid #ccc', marginBottom: '.5em', fontFamily: 'monospace'}}>
                  <div>{created_at_fmt}</div>
                  <CalibrationWidget calib={tc1} />
                </li>
              }
            })}
            </ul>
          </IonItem>

        </IonList>
    </IonCard>
  }
}