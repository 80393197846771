import { IonProgressBar, IonSelect, IonSelectOption } from "@ionic/react";
import { useComponentIds } from "../hooks/useComponentIds";
import { GeneratorSelect } from "../dashboard-app/pages/Generators";


export enum ComponentType {
  Taurus = "taurus",
  Talpa = "talpa",
  Aquila = "aquila",
  Generator = "generator",
  Sprayer = "sprayer",
}

export interface ComponentProps {
  componentType: ComponentType,
  showProgressBar?: boolean,
  componentId?: string | number | null, // TODO: why can this be string, number, null or undefined? number | undefined should suffice, or maybe even just number and assert that it cannot be undefined at the call site.
  setComponentId: (newValue : number) => void,
  selectChildren?: React.ReactFragment | null,
  prefix?: string | null // TODO: should probably just be string, or at least not have both null and undefined in it
}

export const ComponentIdSelect = ({ ...props }: ComponentProps) => {
  if (props.componentType === ComponentType.Generator) {
    return <GeneratorSelect {...props} />
  }
  return <ComponentIdSelectWrapped {...props} prefix={props.componentType === ComponentType.Taurus ? "GT" : null} />
}


export const ComponentIdSelectWrapped = ({ componentType, componentId, setComponentId, showProgressBar = true, selectChildren = null, prefix }: ComponentProps) => {
  const componentIds = useComponentIds(componentType)

  if (showProgressBar && !componentIds) {
    return <IonProgressBar type="indeterminate" />
  }

  return <IonSelect value={componentId} onIonChange={({ detail: { value } }: any) => setComponentId(value)}>
    {selectChildren}
    {componentIds?.map((id: number) => <IonSelectOption key={id} value={id}>{prefix || componentType.replaceAll("_", " ") + " "}{id}</IonSelectOption>)}
  </IonSelect>
}


export const ComponentTypeSelect = ({ ...props }: any) => {
  return <IonSelect style={{ textTransform: "capitalize" }} {...props}>
    {Object.values(ComponentType).map((t: string) => <IonSelectOption key={t} value={t}>{t.replaceAll("_", " ")}</IonSelectOption>)}
  </IonSelect>
}