import { RobotConfig, SensorType, SprayerConfig, TalpaConfig, TreatmentDirection } from "../hooks/useRobot";
import i18n from 'i18next';

export function validateConfig(current_config : RobotConfig) : string[] {
  let warnings : Array<string> = new Array();
  if (current_config) {
    // aquilas in range?
    for (let aquila_config of current_config.aquila_configs) {
      // are there placeholders left?
      if (aquila_config.aquila_id === null) {
        warnings.push(i18n.t("Configuration contains an Aquila placeholder at position {{position}}\u00A0cm.", {position: aquila_config.offset_y}));
      }
    }

    // make sure all cameras have the same type
    if (new Set(current_config.aquila_configs.map((ac:any)=>ac?.aquila?.camera_type)).size > 1) {
      warnings.push(i18n.t("Not all cameras are of the same model."))
    }

    //make sure all talpas have the same swivel controller
    if (new Set(current_config.talpa_configs.map((tc:any)=>tc?.talpa?.controller_swivel).filter((t:any)=>t!==undefined)).size > 1) {
      warnings.push(i18n.t("Not all Talpas have the same swivel controller type."))
    }    

    // make sure all talpas have the same chopper controller
    if (new Set(current_config.talpa_configs.map((tc:any)=>tc?.talpa?.controller_chopper).filter((t:any)=>t!==undefined)).size > 1) {
      warnings.push(i18n.t("Not all Talpas have the same chopper controller type."))
    }
    
    // make sure treatment_direction is identical everywhere
    let treatment_directions = new Set(current_config.talpa_configs.map((tc:TalpaConfig)=>tc.treatment_direction))
    if (treatment_directions.size != 1) {
      warnings.push(i18n.t("Not all Talpas have the same hoeing direction."))
    }
    let treatment_direction = Array.from(treatment_directions)[0];

    for (let talpa_config of current_config.talpa_configs) {
      // are there placeholders left?
      if (talpa_config.talpa_id === null) {
        warnings.push(i18n.t("Configuration contains a Talpa placeholder at position {{position}}\u00A0cm.", {position: talpa_config.offset_y}));
      }

      // talpa within reach of a row?
      let min_dist_to_row = Infinity;
      for (let row_config of current_config.row_configs) {
        let abs_dist = Math.abs(row_config.offset_y - talpa_config.offset_y);
        if (abs_dist < min_dist_to_row) {
          min_dist_to_row = abs_dist;
        }
      }
      
      if (talpa_config.talpa.hardware_version.id < 7) {
        if (talpa_config.treatment_direction !== TreatmentDirection.INWARD) {
          warnings.push(i18n.t("Old Talpas don't support outward treatment (TLP {talpa_id}).", {talpa_id: talpa_config.talpa_id}));
        }
        if (min_dist_to_row < 13) {
          warnings.push(i18n.t("Talpa {{position}} is closer than 13\u00A0cm to a row.", {position: talpa_config.offset_y || i18n.t("placeholder")}))
  
        }
        if (min_dist_to_row > 13) {
          warnings.push(i18n.t("Talpa {{talpaId}} is further than 13\u00A0cm from each row.", {talpaId: talpa_config.talpa_id || i18n.t("placeholder")}))
  
        }
      } else if ([7,8,9].includes(talpa_config.talpa.hardware_version.id)) {
        if (min_dist_to_row != 0 && talpa_config.treatment_direction == TreatmentDirection.INWARD) {
          warnings.push(i18n.t('Talpa {{talpaId}} is not exactly above a crop row.', {talpaId: talpa_config.talpa_id || i18n.t("placeholder")}))
        }
      }

      // talpa connected to aquila and within reach?
      let dist_to_aquila = null;
      for (let aquila_config of current_config.aquila_configs) {
        if (aquila_config.aquila_id !== talpa_config.connected_aquila_id) continue;
        dist_to_aquila = Math.abs(talpa_config.offset_y - aquila_config.offset_y)
      }
      if (dist_to_aquila === null) {
        warnings.push(i18n.t("Talpa {{talpaId}} is not associated with an Aquila.", {talpaId: talpa_config.talpa_id || i18n.t("placeholder")}))
      }
    }

    return warnings
  } else {
    return []
  }

  // possible additional validators:
  // - aquila-to-aquila min distance (width of device?)
  // - talpa-to-talpa min distance (width of device?)
  // - correct value range for row_config.offset_y? is this different from [-90cm;90cm]
  // TODO: account for extension bars (left/right)
}