import { IonContent, IonPage, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCard, IonCardContent, IonItem } from '@ionic/react';
import React, {useState} from 'react';
import { useParams } from 'react-router';
import { useQuery, useMutation, gql } from '@apollo/client';
import Toolbar from '../../components/Toolbar'
import RobotButton from '../../components/RobotButton'
import XavierForm from '../../components/XavierForm'
import EventsCard from '../../components/EventsCard'
import EditButton from '../../components/EditButton'
import ReactMarkdown from 'react-markdown'
import ApolloStatus from '../../components/ApolloStatus'

const Xavier: React.FC = () => {
  const { id, name } = useParams<{ id: string; name: string}>();
  const { data, loading, error, refetch } = useQuery(gql`query{xavier(id:"${id}"){
      id hostname description aquila {id} robot {id} partNumber serialNumber eth0Mac operational
  }}`);
  const [edit, setEdit] = useState(false)

  return (
    <IonPage>
      <Toolbar name={data?.xavier?.hostname ? data.xavier.hostname : "Xavier"}/>
      <IonContent fullscreen>
        <ApolloStatus loadings={[loading]} errors={[error]} refetch={refetch}/>
        {data && <>
            {!edit && <IonCard>
                <IonCardHeader>
                    <IonCardSubtitle>Configuration</IonCardSubtitle>
                    <IonCardTitle>{data?.xavier?.hostname || "Unnamed Xavier"}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <ReactMarkdown>{data?.xavier?.description || "No description."}</ReactMarkdown>
                    <IonItem>part number: {data?.xavier?.partNumber || "No part number"}</IonItem>
                    <IonItem>serial number: {data?.xavier?.serialNumber || "No serial number"}</IonItem>
                    <IonItem>eth0 mac: {data?.xavier?.eth0Mac || "No eth0 MAC address"}</IonItem>
                    {data?.xavier?.operational !== undefined && <IonItem>{data.xavier.operational ? "Operational" : "Dysfunctional"}</IonItem>}
                    <EditButton onClick={() => setEdit(true)}/>
                </IonCardContent>
            </IonCard>}

            {data?.xavier && edit && <XavierForm xavier={data.xavier} onFinish={() => {refetch(); setEdit(false)}}/>}
        </>}
      </IonContent>
    </IonPage>
  );
};

export default Xavier;
