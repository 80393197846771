import { IonItem, IonLabel, IonList, IonContent, IonPage, IonButton, IonCardContent, IonCard, IonCardHeader, IonCardTitle } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import Loading from '../../components/Loading'
import Error from '../../components/Error'
import Toolbar from '../../components/Toolbar'
import Refresher from '../../components/Refresher'
import getUsername from '../../lib/getUsername'
import getRoles from '../../utils/getRoles';
import NotificationSettings from '../../components/NotificationSettings';

const AboutMe: React.FC = () => {
  const { loading, error, data, refetch } = useQuery(gql`
    query SelfUserQuery ($username:String) {
      me : users(where: {name : {_eq: $username}}) {
        id
        name
      }
    }`,
    {
      "variables": {
        // get username from jwt
        "username": getUsername()
      }
    }
  );

  let roles = [...getRoles()].filter(r=>r!=='keycloak-authenticated');


  return (
    <IonPage>
      <Toolbar name={data ? `User: ${data.me[0].name}` : "About me"}/>
      <IonContent fullscreen>
         {loading && <Loading/>}
         {error && <Error error={error}/>}
         {data &&
            <IonList>
              <IonItem><IonLabel>email: </IonLabel><span>{data.me[0].name}</span></IonItem>
              <IonButton href="https://sso.farming-revolution.com/auth/realms/FarmingRevolution/account/#/security/signingin">Change Password</IonButton>
            </IonList>          
         }

         {/* show user their extra roles (annotator/fr-staff/...) */}
         {roles.length > 0 && <IonCard>
          <IonCardContent>
         <div>
            special privileges:
            {roles.map((r, idx)=><pre key={idx}>{r}</pre>)}
          </div>
          </IonCardContent>
         </IonCard>}

         {/* notification settings */}
         {data && <NotificationSettings userId={data.me[0].id}/>}

      <Refresher refetch={refetch}/>
      </IonContent>
    </IonPage>
  );
};

export default AboutMe;
