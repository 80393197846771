import { IonBadge, IonButton, IonCardContent, IonIcon, IonItem, IonLabel, IonList, IonSelect, IonSelectOption, } from '@ionic/react';
import { ban, checkboxOutline, squareOutline } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import { useCallService, usePublisher, useTopic } from '../hooks/rosHooks';
import { NumberInput } from './NumberInput';
import { useTranslation } from 'react-i18next';
import { RosPath } from './KonvaRos';
import { useSetView, } from './ViewConfig';
import { useArea } from '../hooks/useArea';
const moment = require('moment');


// Must be same as in field_editor.py
const ARGUMENTS_PER_OPERATION: any = {
  "lengthen": {
    "edge": "start",
    "offset": 0.,
  },
  "shorten": {
    "edge": "start",
    "offset": 0.,
  },
  "shift_left": {
    "offset": 0.,
  },
  "shift_right": {
    "offset": 0.,
  },
  "reverse": {},
  "archive": {},
  "restore": {},
}


export const PathInstanceItem: React.FC<any> = ({pathInstance, selected, onClick, showAllPaths}: any) => {
  const {t, i18n} = useTranslation()
  const {color, name} = useArea(pathInstance.path_def.area_id)
  if (!showAllPaths && !name) {
    return null  // Do not show paths if the area is archived (i.e. name is undefined)
  }
  if (!showAllPaths && pathInstance.archived) {
    return null  // Do not show paths if the area is archived (i.e. name is undefined)
  }
  return <IonItem onClick={pathInstance.archived ? undefined : onClick}>
    <IonIcon icon={pathInstance.archived ? ban : (selected ? checkboxOutline : squareOutline)} slot="start" color={selected ? "secondary" : undefined}/>

    {name && <IonBadge style={{background: color}}>
      {name}
    </IonBadge>}
    <IonBadge color={pathInstance.path_def.number % 2 === 0 ? "dark": undefined}>
      {t(`${pathInstance.path_def.path_type}`)}
      {pathInstance.path_def.path_number > 0 && pathInstance.path_def.path_number}
    </IonBadge>
    {pathInstance.archived && <IonBadge color="medium" slot="end">{t("archived")}</IonBadge>}
    <IonBadge slot="end" color={pathInstance.is_latest ? "secondary" : undefined}>
      {pathInstance.stamp && <>{moment(pathInstance.stamp).lang(i18n.language).fromNow()}</>}
      {pathInstance.is_latest && showAllPaths && <>{" "}({t("current")})</>}
    </IonBadge>
  </IonItem>
}


export const pathInstanceKey = ({path_def: {area_id, path_type, path_number}, stamp}: any) => `${area_id}:${path_type}${path_number}-${stamp}`


export const PathEditor: React.FC<any> = ({backButton}) => {
  const [selectedKeys, setSelectedKeys] = useState<String[]>([])
  const [operationName, setOperationName] = useState("lengthen")
  const [operationOptions, setOperationArgs] = useState<any>({})
  const [showAllPaths, setShowAllPaths] = useState(false)
  const selectPaths = usePublisher("/select_paths", "weeding_field_manager/PathInstances")
  const { t } = useTranslation()
  const pathInstances = useTopic("/path_instances", "weeding_field_manager/PathInstances")?.path_instances || []
  useEffect(() => {
    setOperationArgs({...ARGUMENTS_PER_OPERATION[operationName]})
  }, [operationName])

  const extendedPathInstances = pathInstances?.map((p: any) => {
    const key = pathInstanceKey(p)
    return {
      key, ...p
    }
  }).filter(({is_latest}: any) => showAllPaths || is_latest)
  const allKeys = extendedPathInstances.map(({key}: any) => key)

  useEffect(() => {
    // Ensures that selected keys match the filters
    const filteredSelectedKeys = selectedKeys.filter((key: String) => allKeys.includes(key))
    if (filteredSelectedKeys.length != selectedKeys.length) {
      setSelectedKeys(filteredSelectedKeys)
    }
  }, [showAllPaths, allKeys])

  const toggleSelection = (key: String) => {
    setSelectedKeys((prev: String[]) => {
      if (selectedKeys.includes(key)) {
        return prev.filter((k: String) => k !== key)  // Remove key
      }
      else {
        return [...prev, key]  // Add key
      }
    })
  }
  useEffect(() => {
    selectPaths({path_instances: pathInstances.filter((pathInstance: any) => selectedKeys.includes(pathInstanceKey(pathInstance))) || []})
  }, [selectedKeys])
  const callService = useCallService()
  const apply = () => {
    callService("/edit_paths", "weeding_field_manager/EditPaths", {
      path_instances: pathInstances.filter((pathInstance: any) => selectedKeys.includes(pathInstanceKey(pathInstance))),
      operation_name: operationName,
      operation_options_json: JSON.stringify(operationOptions),
    })
  }
  return <IonCardContent>
    <IonList style={{maxHeight: 400, overflowY: "auto"}}>
      {extendedPathInstances?.length === 0 && <IonItem>{t("No paths fitting filter")}</IonItem>}
      {extendedPathInstances?.map((pathInstance: any) => <PathInstanceItem pathInstance={pathInstance} key={pathInstance.key}
        showAllPaths={showAllPaths}
        selected={selectedKeys.includes(pathInstance.key)}
        onClick={() => toggleSelection(pathInstance.key)}
      />)}
    </IonList>
    <div style={{display: "flex", justifyContent: "space-between"}}>

      <IonItem>
        <IonSelect value={showAllPaths} onIonChange={({detail: {value}}: any) => setShowAllPaths(value)}>
          <IonSelectOption value={true}>{t("Show all paths")}</IonSelectOption>
          <IonSelectOption value={false}>{t("Show current paths only")}</IonSelectOption>
        </IonSelect>
      </IonItem>

      {selectedKeys.length > 0 && <>
        <IonItem>
          <IonSelect value={operationName} onIonChange={(e: any) => setOperationName(e.detail.value)} style={{textTransform: "capitalize"}}>
            {Object?.keys(ARGUMENTS_PER_OPERATION)?.map((operationName: string) => <IonSelectOption key={operationName} value={operationName}>{t(`${operationName.replace("_", " ")}`)}</IonSelectOption>)}
          </IonSelect>
        </IonItem>

        {operationOptions.edge !== undefined && <>
          <IonItem style={{flexGrow: 1}}>
            <IonLabel position="floating">Position</IonLabel>
            <IonSelect value={operationOptions.edge} onIonChange={(e: any) => setOperationArgs({...operationOptions, edge: e.detail.value})} style={{textTransform: "capitalize"}}>
              {["start", "end", "both sides"].map((edge: string) => <IonSelectOption key={edge} value={edge}>{t(`${edge}`)}</IonSelectOption>)}
            </IonSelect>
          </IonItem>
        </>}

        {operationOptions.offset !== undefined && <IonItem style={{flexGrow: 1}}>
          <IonLabel position="floating">Offset (m)</IonLabel>
          <NumberInput value={operationOptions.offset} min={0.} decimals={2} isFloat onChange={(offset: number) => setOperationArgs({...operationOptions, offset})}/>
        </IonItem>}
      </>}
    </div>
    <div>
      {backButton}
      {selectedKeys?.length > 0 && <IonButton color="secondary" onClick={apply}>{t(`${operationName.replace("_", " ")}`)}</IonButton>}
    </div>
  </IonCardContent>
}


export const SelectedPaths: React.FC<any> = ({}) => {
  const selectedPaths = useTopic("/selected_paths", "weeding_field_manager/Paths")?.paths
  return <>{selectedPaths?.map((path: any) => <RosPath strokeWidth={0.3} key={`${path.type}${path.number}${path.area_id}${path.created_at}`} path={path} color="lightgreen"/>)}</>
}


export const EditPathsButton: React.FC<any> = ({}) => {
  const { t } = useTranslation()
  const setView = useSetView()
  return <IonButton fill="clear" onClick={() => setView("path_editor", {
    showField: true,
    showSelectedPaths: true,
  })}>{t("Edit paths")}</IonButton>
}