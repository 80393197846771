import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonButton,
  IonImg,
} from '@ionic/react';

import React from 'react';
import { useLocation } from 'react-router-dom';
import { gql, useSubscription } from '@apollo/client';
import './Menu.css';
import { pages } from '../dashboard-app/pages';

import ApolloStatus from './ApolloStatus'
import getUsername from '../lib/getUsername'
import logo from '../theme/F_Logo_Dark.png'
import getRoles from '../utils/getRoles';
import { useTranslation } from 'react-i18next';
import { GraphQLState } from './GraphQLState';


const Menu: React.FC<any> = ({logout}) => {
  const { loading, data, error } = useSubscription(gql`
    subscription ($username: String) {
      me: users(where: {name: {_eq: $username}}) {
        id,
        name,
        teams {
          role
          team {
            name,
            id
          }
        }
      }
    }`,
    {
      variables: {"username": getUsername()
    }});
  const location = useLocation();
  const {t} = useTranslation()

  const has_team = data?.me[0].teams?.length > 0;
  const my_roles = getRoles();
  
  let pages_flt = pages.filter(page=>{
    if (has_team === false && page.requiresTeam) {
      return false
    }
    let intersection = page.roles.filter(r=>my_roles.has(r));
    return intersection.length > 0
  });

  return (
    <IonMenu contentId="main" type="reveal" side="end">
      <IonContent>
        <GraphQLState error={error} loading={loading}/>
        <IonImg style={{maxWidth: "300px"}} src={logo}></IonImg>
        <IonList id="inbox-list">
          {!loading && pages_flt.map((page, index) => {
              return (<React.Fragment key={index}>
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname[0] == page.route ? 'selected' : ''} routerLink={page.route} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" md={page.icon} />
                  <IonLabel>{t(page.name)}</IonLabel>
                </IonItem>
              </IonMenuToggle>
              {page.name == "About me" && <hr style={{borderTop: "1px solid lightgrey"}} />}
              </React.Fragment>)
          })}
        </IonList>

        <IonButton color="secondary" expand="full" onClick={logout} style={{marginBottom: "calc(1em + 40px + 0.5em)"}}>logout</IonButton>
      </IonContent>
    </IonMenu>

    
  );
};

export default Menu;
