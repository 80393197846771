import { IonButton, IonIcon, IonInput, IonItem, IonLabel, IonList, IonPopover, IonToggle, } from '@ionic/react';
import { caretDown, reload, } from 'ionicons/icons';
import React, {useState} from 'react';
import { AccessType, getAccessType, usePublisher, useTopic } from '../hooks/rosHooks';
import { useTranslation } from 'react-i18next';
import { Status } from './StatusShared';
import { AggregatedStatusList, Status as DiagStatus } from '../utils/DiagnosticTypes';
import { BackButton } from './BackButton';
import { TreatmentWatchdog } from './TreatmentWatchdog';

import DIAGNOSTIC_ERROR_TYPES from '../utils/diagnostic_error_types.json';


export const useDiagnosticErrorInfo = (software_key: string|undefined) => {
  const {t} = useTranslation()
  const v = software_key ? (DIAGNOSTIC_ERROR_TYPES as any)[software_key] : null
  if (!v) {return {
    title: null, description: null, action: null, consequence_of_ignoring: null
  }}
  return {
    title: t(v["title_translation"] || "Unknown error"),
    description: v["description_translation"] && t(v["description_translation"]),
    action: v["action_translation"] && t(v["action_translation"]),
    consequenceOfIgnoring: v["consequence_of_ignoring_translation"] && t(v["consequence_of_ignoring_translation"]),
  }
}


export const Diag: React.FC<any> = ({}: any) => {
  const [event, setEvent] = useState<any>(null)
  const data : AggregatedStatusList | undefined = useTopic("/main/drive_monitor/diagnostics_agg_compact", "fr_diagnostics_msgs/AggregatedStatusList");
  const ignoreRemainingTime_s = data ? Math.floor(data?.remaining_ignore_time_s) : NaN
  const ok = data?.aggregation_ok || false
  const data_available = !!data
  const {t} = useTranslation()

  const accessType = getAccessType()

  const isRemoteAccess = accessType === AccessType.RemoteHttps
  const ignoreCommandPub = usePublisher(`/main/drive_monitor/ignore_command_${isRemoteAccess ? "remote": "local"}`, "fr_diagnostics_msgs/IgnoreCommand")
  const ignore = (newStatus : DiagStatus | null = null, checked: boolean = true) => {
    if (data===undefined) {
      return
    }
    if (!checked && newStatus) {
      // Remove status from ignored statuses
      ignoreCommandPub({
        ignored_status_namespaces: data.ignored_status_namespaces.filter(({namespaces}) => JSON.stringify(namespaces) !== JSON.stringify(newStatus.namespaces)),
        user: "app",
      })
    }
    else if (!newStatus) {
      // Prolong status ignoring
      ignoreCommandPub({
        ignored_status_namespaces: data.ignored_status_namespaces,
        user: "app",
      })
    }
    else {
      // Add status to ignored statuses
      ignoreCommandPub({
        ignored_status_namespaces: [...data?.ignored_status_namespaces, {namespaces: newStatus.namespaces}],
        user: "app",
      })
    }
  }

  const [color, text] = (() => {
    if (!data_available) {return ["medium", t("Loading...")]} // Unknown
    else if (ok) {
      if (ignoreRemainingTime_s > 0) {
        return ["medium", t("Ignored")]
      }
      else {
        return ["success", t("OK")]
      }
    }
    else {return ["danger", t("Blocked")]}
  })()

  return <div style={{display: "flex", flexGrow: 1, flexDirection: "column", marginRight: "1px", gap: -2}}>
    <IonButton color={color} onClick={setEvent} disabled={!data_available} style={{height: 39}}>
      {text}
      {data_available && <IonIcon icon={caretDown}  style={{fontSize: "30px", right: 0}} slot="end"/>}
    </IonButton>
    {ok && (ignoreRemainingTime_s > 0) && ((data?.ignored_status_namespaces?.length || 0) > 0) && <IonButton
        color="danger" onClick={e => {e.stopPropagation(); ignore()}} style={{width: "fit-content", alignSelf: "end"}}>
      {ignoreRemainingTime_s}
      <IonIcon icon={reload} slot="end"/>
    </IonButton>}
    
    <TreatmentWatchdog/>

    <IonPopover isOpen={!!event} event={event} onDidDismiss={() => setEvent(null)} className="wide-popover">
      <DiagDetails ignore={ignore}/>
    </IonPopover>
  </div>
};


function areArraysEqual<T>(array1: T[], array2: T[]): boolean {
    if (array1.length !== array2.length) return false;
    for (let i = 0; i < array1.length; i++) {
        if (array1[i] !== array2[i]) return false;
    }
    return true;
}


export const SelectedStatus: React.FC<any> = ({selectedStatus, statusOptions, ...props}: any) => {
  const [showDetails, setShowDetails] = useState(false)
  const {t, i18n} = useTranslation()
  const {action, description, consequenceOfIgnoring} = useDiagnosticErrorInfo(selectedStatus.namespaces.at(-1))
  return <>
    <Status status={selectedStatus} statusOptions={statusOptions}/>
    {description && <IonItem>
      {description}
    </IonItem>}

    {action && <IonItem>
      <IonLabel position="stacked">{t("Recommended action")}</IonLabel>
      {action}
    </IonItem>}

    {consequenceOfIgnoring && <IonItem>
      <IonLabel position="stacked">{t("Consequence of ignoring the error")}</IonLabel>
      {consequenceOfIgnoring}
    </IonItem>}

    {(selectedStatus.key_values.length || showDetails) && <IonButton fill="clear" expand='block' onClick={() => setShowDetails(!showDetails)}>
      {showDetails ? t("Hide details"): (i18n.language.includes("en") ? t("Show details (English)") : t("Show details"))}
    </IonButton>}

    {showDetails && <IonList>
      {selectedStatus.key_values.map(({key, value}: any, idx: number) => <IonItem key={`${key}-${idx}`}>
        <IonLabel style={{textTransform: "capitalize"}}>{key}</IonLabel>
        {value}
      </IonItem>)}
    </IonList>}
  </>
}



export const DiagDetails: React.FC<any> = ({children, ignore, ...props}: any) => {
  const data : AggregatedStatusList | undefined = useTopic("/main/drive_monitor/diagnostics_agg", "fr_diagnostics_msgs/AggregatedStatusList");
  const {t} = useTranslation()
  const [statusOptions, setStatusOptions] = useState({showOk: false, showDisabledStatuses: false, nameFilter: ""})
  
  const ignoreNamespacesJson = data?.ignored_status_namespaces?.map(({namespaces}) => JSON.stringify(namespaces)) || [];
  const [selectedNamespaces, setSelectedNamespaces] = useState<any>(null)
  const ignoreRemainingTime_s = data ? Math.floor(data?.remaining_ignore_time_s) : NaN;
  const isIgnored = (status : DiagStatus) => ignoreNamespacesJson.includes(JSON.stringify(status.namespaces))
  const errorIsIgnored = data?.statuses.some((status) => isIgnored(status) && ["ERROR", "STALE"].includes(status.level) && !status.user_ignoring_disabled) || false;
  const allErrorsAreIgnored = data?.statuses.every((status) => isIgnored(status) || !["ERROR", "STALE", "WARN"].includes(status.level) || status.disabled) || false;
  const idx_per_namespace : Map<string, number> = new Map();
  
  const selectedStatus = data?.statuses?.find(({namespaces}: any) => selectedNamespaces && areArraysEqual(namespaces, selectedNamespaces))

  return <>
    {!data?.aggregation_ok && allErrorsAreIgnored && errorIsIgnored && <IonButton expand="block" onClick={() => ignore()} fill="solid" color={ignoreRemainingTime_s > 0 ? undefined : "danger"}>
      {t("Ignore again")}
      {ignoreRemainingTime_s > 0 && <> ({ignoreRemainingTime_s}s left)</>}
      <IonIcon icon={reload} slot="end"/>
    </IonButton>}
    
    {selectedNamespaces && <div>
      <BackButton onClick={() => setSelectedNamespaces(null)}/>
      {selectedStatus && <SelectedStatus selectedStatus={selectedStatus} statusOptions={statusOptions}/>}
    </div>}

    {!selectedNamespaces && <IonList style={{maxHeight: "400px", overflowY: "auto", padding: 0}}>
      {!selectedNamespaces && data?.statuses?.map((status ) => {
        let key_prefix = status.namespaces.join('_');
        let idx = idx_per_namespace.get(key_prefix) || 0;
        let key = `${key_prefix}-${idx}`;
        idx_per_namespace.set(key_prefix, idx+1);
        if (idx > 0) {
          if ((window as any)["duplicate_diag_keys"] === undefined) {
            (window as any)["duplicate_diag_keys"] = new Set();
          }
          let s = ((window as any)["duplicate_diag_keys"] as Set<string>);
          if (!s.has(key_prefix)) {
            console.warn(`Duplicate diagnostic key/namespaces: "${key_prefix}"\nCheck window.duplicate_diag_keys to view all duplicate keys.`)
          }
          s.add(key_prefix);
        }
        return <Status
          status={status} statusOptions={statusOptions} ignore={ignore}
          ignored={isIgnored(status)} ignoreRemainingTime_s={ignoreRemainingTime_s}
          allErrorsAreIgnored={allErrorsAreIgnored} key={key} onExpand={() => setSelectedNamespaces(status.namespaces)}
        />})}
    </IonList>}
    {!selectedNamespaces && <div style={{display: "flex", borderTop: "1px solid"}}>
      <IonItem>
        <IonInput placeholder="Filter" value={statusOptions.nameFilter} onIonChange={({detail: {value}}: any) => setStatusOptions({...statusOptions, nameFilter: value})} clearInput/>
      </IonItem>
      <IonItem style={{minWidth: "130px"}}>
        <IonLabel position="stacked">
          {t("Only relevant errors")}
        </IonLabel>
        <IonToggle checked={!(statusOptions.showOk || statusOptions.showDisabledStatuses)} onIonChange={({detail: {checked}}: any) => 
          setStatusOptions({
            ...statusOptions, showOk: !checked, showDisabledStatuses: !checked
          })
        }/>
      </IonItem>
    </div>}
  </>

}