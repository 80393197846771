import { Point, } from 'ts-2d-geometry'


export default function compareStems ({groundTruthStems, stems, maxDistance = 0.04}: any) {
    if (!stems) {
        return {"error": `No stems`}
    }
    if (!groundTruthStems) {
        return {"error": `No groundtruth`}
    }
    if (groundTruthStems.length < 2) {
        return {"error": `Insufficient groundtruth stems (${groundTruthStems?.length})`}
    }
    const stemBounds: Point[]|null = stems.length < 2 ? null : [
        new Point(stems[0][0], stems[0][1]),
        new Point(stems[stems.length - 1][0], stems[stems.length - 1][1]),
    ]
    // The direction vector is set by stems (not gt stems) because gt stems might not be in order
    const stemDirVect = stemBounds ? stemBounds[1].minus(stemBounds[0]).normed() : null

    // Get distances between groundtruth stems: both stems furthest apart describe direction vector
    const largestGtDistances = groundTruthStems.map((s1: any) => groundTruthStems.map((s2: any) => {
        const distance = Math.hypot(s1[0] - s2[0], s1[1] - s2[1])
        return [distance, s1, s2]
    })).flat().sort((a: any, b: any) => b[0] - a[0])[0]

    const groundTruthStemBounds: Point[] = [
        new Point(largestGtDistances[1][0], largestGtDistances[1][1]),
        new Point(largestGtDistances[2][0], largestGtDistances[2][1]),
    ]
    const groundTruthStemDirVect = groundTruthStemBounds ? groundTruthStemBounds[1].minus(groundTruthStemBounds[0]).normed() : null

    // Track the stems that still need to be associated
    const gt_remaining = new Set<number>(groundTruthStems.map((s: any, i: number) => i))
    const remaining = new Set<number>(stems.map((s: any, i: number) => i))

    // Get closest matches of gt to stems
    const matches: any[] = groundTruthStems
    ?.map(([gt_x, gt_y]: any, gt_i: number) => stems.map(([x, y]: any, i: number) => {
        const distance = Math.hypot(gt_x - x, gt_y - y)
        return {gt_i, i, distance}
    }))
    ?.flat()
    ?.filter(({distance}: any) => distance < maxDistance)
    ?.sort((a: any, b: any) => a.distance - b.distance)
    ?.filter(({gt_i, i}: any) => {
        // Start with the smallest distance and fill
        if (!gt_remaining.has(gt_i) || !remaining.has(i)) {return false /* those stems were already associated using a smaller distance */ }
        gt_remaining.delete(gt_i)
        remaining.delete(i)
        return true
    })

    const falsePositives = [...remaining.values()].map((i: number) => stems[i]).filter((s: any) => {
        // Check that point is after the first ground truth point and before the last
        const p = new Point(s[0], s[1])
        if (groundTruthStemDirVect) {
            return p.minus(groundTruthStemBounds[0]).dot(groundTruthStemDirVect) >= 0 && p.minus(groundTruthStemBounds[1]).dot(groundTruthStemDirVect) <= 0
        }
        else {
            return true
        }
    })

    const falseNegatives = [...gt_remaining.values()].map((i: number) => groundTruthStems[i]).filter((s: any) => {
        // Check that point is after the first prediction and before the last
        const p = new Point(s[0], s[1])
        if (s[2] === true) {  // Ground truth stem is "ignore"
            return false
        }
        if (stemBounds && stemDirVect) {
            return p.minus(stemBounds[0]).dot(stemDirVect) >= 0 && p.minus(stemBounds[1]).dot(stemDirVect) <= 0
        }
        else {
            return true  // count all false negatives if no stem bounds (e.g. no single stem was recognized)
        }
    })
    return {
        matchedStems: matches.map(({distance, gt_i, i}: any) => ({
            distance,
            stem: stems[i],
            groundtruthStem: groundTruthStems[gt_i],
        })),
        falsePositives,
        falseNegatives,
    }
}