import {
  IonButton,
} from "@ionic/react";
import React, { } from "react";
import { gql } from "@apollo/client";
import {
  useMutationWithElevatedRole,
} from "../hooks/hasuraHooks";


export const useInsertIngredients = () => useMutationWithElevatedRole(gql`
    mutation InsertIngredients($objects: [ingredients_insert_input!] = []) {
      insert_ingredients(objects: $objects) {
        returning {
          id
        }
      }
    }
  `
)


export const NewIngredientButton: React.FC<any> = ({ children, ...props }: any) => {
  const [insertIngredients] = useInsertIngredients()
  return <IonButton {...props} onClick={() => insertIngredients({variables: {objects: [{}]}})}>
    {children}
  </IonButton>
}