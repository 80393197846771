export interface Color {
    a: number;
    r: number;
    b: number;
    g: number;
}

export enum FrameID {
    Map = "map",
}

export interface Time {
    secs:  number;
    nsecs: number;
}

export interface Header {
    stamp:    Time;
    frame_id: FrameID;
    seq:      number;
}

export interface Vec3 {
    y:  number;
    x:  number;
    z:  number;
}

export interface Quaternion {
    y:  number;
    x:  number;
    z:  number;
    w:  number;
}

export interface Pose {
    position:    Vec3;
    orientation: Quaternion;
}

export enum Action {
    ADD = 0,
    DELETE = 2,
    DELETEALL = 3
}

export enum Type {
    ARROW=0,
    CUBE=1,
    SPHERE=2,
    CYLINDER=3,
    LINE_STRIP=4,
    LINE_LIST=5,
    CUBE_LIST=6,
    SPHERE_LIST=7,
    POINTS=8,
    TEXT_VIEW_FACING=9,
}

export interface Marker {
    mesh_use_embedded_materials: boolean;
    scale:                       Vec3;
    frame_locked:                boolean;
    color:                       Color;
    text:                        string;
    pose:                        Pose;
    mesh_resource:               string;
    header:                      Header;
    colors:                      any[];
    points:                      Vec3[];
    action:                      Action;
    lifetime:                    Time;
    ns:                          string;
    type:                        Type;
    id:                          number;
}

export interface MarkerArray {
    markers: Marker[];
}


export function color_to_rgba(c : Color) : string {
  return `rgba(${255*c.r},${255*c.g},${255*c.b},${c.a})`
}