import { useQuery, gql } from '@apollo/client';
import React, { useState } from 'react';
import { IonList, IonButton, IonContent, IonPage, IonItem, IonLabel, IonBadge, IonIcon, IonChip, IonPopover, IonToolbar, IonCard, IonCardHeader, IonCardContent, IonCardSubtitle, IonModal, IonCardTitle, IonCheckbox, IonButtons, IonFab, IonFabButton, IonSkeletonText, IonInput, IonCol, IonRow, IonListHeader, IonSelect, IonSelectOption, IonSegment, IonSegmentButton, IonItemDivider, } from '@ionic/react';
import { addCircle, arrowUndo, chevronDown, chevronUp, copy, pencil, reader, trash } from 'ionicons/icons';
import CopyButton from './CopyButton';
import { SearchableSelect } from './SearchableSelect';
import ConfirmButton from './ConfirmButton';
import useWindowDimensions from '../useWindowDimensions';
import getMaxRole from '../lib/getMaxRole';
import { useMutationWithElevatedRole, useQueryWithElevatedRole } from '../hooks/hasuraHooks';
import { TagPicker } from './TagPicker';


export const FilePaths: React.FC<any> = ({file_paths}: any) => {
  return <>
    {file_paths.map((file_path: any) => <FilePathBadge key={file_path.path} file_path={file_path}/>)}
  </>
}


export const FilePathBadge: React.FC<any> = ({file_path, prefix="on:"}: any) => {
  const [event, setEvent] = useState<any>(null)
  const onD1 = file_path.computer_name === "d1"

  const [insertFileCopyOperation] = useMutationWithElevatedRole(gql`
    mutation InsertFileCopyOperation($file_path_id: Int!) {
      insert_file_copy_operations_one(object: {file_path_id: $file_path_id}) {id}
    }
  `)

  return <>
    <IonBadge onClick={setEvent} style={{cursor: "pointer"}} color={onD1 ? "secondary" : undefined}>
      {prefix}{file_path.computer_name || "?"}
    </IonBadge>
    <IonPopover event={event} isOpen={!!event} onDidDismiss={() => setEvent(null)}>
      {!onD1 && <IonButton onClick={() => insertFileCopyOperation({variables: {file_path_id: file_path.id}})}>Copy to d1</IonButton>}
      <code><pre>{JSON.stringify(file_path, null, 2)}</pre></code>
    </IonPopover>
  </>
}