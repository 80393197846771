import Menu from './components/Menu';
import React, { useEffect, useState } from 'react';
import { IonApp, IonFab, IonFabButton, IonIcon, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { useQuery, gql } from '@apollo/client';
import UserContext from './userContext'
import { close, menu } from 'ionicons/icons';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import history from './history'
import RouterOutlet from './components/RouterOutlet'


const SplitPane: React.FC<any> = ({logout}) => {
  return <>
    <IonSplitPane contentId="main" when="false">
        <RouterOutlet/>
        <Menu logout={logout} contentId="main"/>
    </IonSplitPane>
  </>
}


const AuthenticatedApp: React.FC<any> = ({logout}) => {
  let username = null
  try {
    // get username from jwt
    username = JSON.parse(atob(window.localStorage!.getItem("jwt")!.split(".")[1]))["https://hasura.io/jwt/claims"]["x-hasura-user-id"]
  } catch {
    logout();
  }
  
  // query to check if token ok
  const { loading, error, data } = useQuery(gql`
    query MyQuery ($username:String) {
      users(where: {name : {_eq: $username}}) {
        id
        name
      }
    }`,
    {
      "variables": {
        "username": username
      }
    }
  );

  if (
       (!loading && data === undefined)
    || (error?.graphQLErrors.some((e: any) => (e.message  === "Invalid token.")))
  ) {
    logout();
    return null;
  }

  if (loading) {return null}

  return (
    <IonApp>
      <UserContext.Provider value={data.users[0].name}>
        <IonReactRouter history={history}>
          <SplitPane logout={logout} />
        </IonReactRouter>
      </UserContext.Provider>
    </IonApp>
  );
};

export default AuthenticatedApp;
