import {
  IonButton,
  IonItem,
  IonLabel,
  IonChip,
  IonAccordion,
  IonCol,
  IonGrid,
  IonRow,
  IonAccordionGroup,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonToggle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import history  from "../history";
import useWindowDimensions from "../useWindowDimensions";
import {
  useMutationWithElevatedRole,
} from "../hooks/hasuraHooks";
import "./AquilaItem.css";


// Keys to be ignored for Taurus form
const ignoreKeys = ["computer", "calibrations"]


export const taurusFieldColors = {
  "2021_distribox": "danger",
  "2023_distribox": "tertiary",
  "schmotzer_high": "success",
  "schmotzer_low": "medium",
  "micontrol": "primary",
  "linak": "success",
}


export const fields = [
  {name: "sprayer_version", options: ["", "2024-02-25"]},
  {name: "generator_pcb_version", options: ["using_barth_pcu", "v2", "diesel_v1"]},
  {name: "taurus_pcb_version", options: ["using_barth_pcu", "v2"]},
  {name: "safety_pcb_version", options: ["using_barth_pcu", "v2"]},
  {name: "light_pcb_version", options: ["using_motor_controllers", "v1", "v2"]},
  {name: "chassis_version", options: ["2021", "2023", "2024"]},
  {name: "master_light_trigger_sync", options: ["no", "from_pcb", "manually_added"]},
  {name: "battery_communication", options: ["taico_via_rock_uart_jbd_sp25s003", "taico_via_gateway", "taico_via_rock_uart_jbd_sp24s004v1_3"]},
  {name: "control_panel_type", options: ["pendant 2021", "pendant 2023", "Juuko JNC_JK600-HS-SAF2"]},
  {name: "gps_antenna_type", options: ["ublox ANN-MB-01", "Bynav BY500"]},
  {name: "implement_beam", options: ["schmotzer_low", "schmotzer_low_lifted", "schmotzer_high"]},
  {name: "connector_plug", options: ["2021_distribox", "2023_distribox", "2024_aquila"]},
]


export const CANInterfaceSelect: React.FC<any> = ({ value, selectProps, }: any) => <IonSelect value={value} {...selectProps}>
    {["innomaker_can0", "innomaker_can1", "xavier_can0", "xavier_can1", "orin_can0", "orin_can1", "can_rk3588", "can_mcp2515_spi"].map((t: string) => <IonSelectOption value={t} key={t}>
      {t}
    </IonSelectOption>)}
  </IonSelect>


export const CANBaudrateSelect: React.FC<any> = ({ value, selectProps, }: any) => <IonSelect value={value} {...selectProps}>
    {[1000000, 500000, 250000].map((t: number) => <IonSelectOption value={t} key={t}>
      {t.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") /* add thousands separator */}
    </IonSelectOption>)}
  </IonSelect>


export const DriveTransmissionSelect: React.FC<any> = ({ value, selectProps, }: any) => <IonSelect value={value} {...selectProps}>
    {[163.5, 261.6].map((t: number) => <IonSelectOption value={t} key={t}>
      {t}
    </IonSelectOption>)}
  </IonSelect>


export const TaurusItem: React.FC<any> = ({ taurus, showArchives }: any) => {
  const [updateTaurus] = useMutationWithElevatedRole(
    gql`
      mutation UpdateTaurus($id: Int!, $_set: tauruses_set_input = {}) {
        update_tauruses_by_pk(pk_columns: { id: $id }, _set: $_set) {
          id
        }
      }
    `,
    {}
  );

  const [tmpTaurus, setTmpTaurus] = useState({...taurus})

  const changedFields = Object.keys(tmpTaurus).filter((k: string) => !ignoreKeys.includes(k) && tmpTaurus[k] !== taurus[k])

  const reset = () => {
    setTmpTaurus({...taurus})
  };
  useEffect(reset, [taurus])
  const robotConfigId = taurus?.taurus_configs[0]?.robot_config?.id

  const { isLargeScreen } = useWindowDimensions();
  const onClick = (e: any, robotConfigId: number) => {
    e.stopPropagation();
    history.push(`/robot/${robotConfigId}`);
  };
  const save = () => {
    if (changedFields.length < 1) return
    const _set = Object.fromEntries(changedFields.map((k: string) => ([k, tmpTaurus[k]])))
    console.log(`Save ${JSON.stringify(_set)} for GT${taurus.id}`)
    updateTaurus({
      variables: {
        id: taurus.id,
        _set: _set
      },
    });
  };

  const fieldSetter = (fieldName: string) => {
    return ({detail: {value, checked}}: any) => setTmpTaurus((prev: any) => ({...prev, [fieldName]: checked !== undefined ? checked : value}))
  }

  const hasChanged = (fieldName: string) => changedFields.includes(fieldName)

  const props = (fieldName: string) => ({
    onIonChange: fieldSetter(fieldName),
    style: {fontWeight: hasChanged(fieldName) ? "bolder" : undefined},  // Change weight if it has changed
  })

  return (
    <>
      <IonAccordionGroup
        expand="inset"
        style={{ display: taurus?.obsolete && !showArchives ? "none" : "" }}
      >
        <IonAccordion value={taurus?.id}>
          <IonItem slot="header" color="light">
            <IonLabel
              style={{ textDecoration: taurus?.obsolete ? "line-through" : "" }}
            >
              GT{taurus?.id}
            </IonLabel>
            {isLargeScreen && taurus?.description && (
              <i style={{ margin: "0 15px" }}>{taurus?.description}</i>
            )}
            {isLargeScreen && <>
              <IonChip color={taurusFieldColors[(taurus.steerer_type as "micontrol"|"linak")]} outline
                style={{display: "flex", flexDirection: "column", width: "fit-content"}} 
              >
                {taurus.steerer_type}
                <span style={{
                  fontSize: "10px", marginTop: "-3px"}}>steerer_type</span>
              </IonChip>
              <IonChip color={taurusFieldColors[(taurus.connector_plug as "2021_distribox"|"2023_distribox")]} outline>
                {taurus.connector_plug}
              </IonChip>
              <IonChip color={taurusFieldColors[(taurus.implement_beam as "schmotzer_low"|"schmotzer_high")]} outline>
                {taurus.implement_beam}
              </IonChip>
            </>}
            {robotConfigId && (
              <IonChip
                onClick={(e: any) =>
                  onClick(e, robotConfigId)
                }
                outline
              >
                #{robotConfigId}
              </IonChip>
            )}
          </IonItem>

          <IonGrid slot="content" style={{bottomBorder: "groove"}}>
            
            <IonItem>
              {changedFields.length > 0 && <>
                <IonButton onClick={save} slot="start">
                  Save
                </IonButton>
                <IonButton fill="outline" color="danger" onClick={reset} slot="start">
                  Reset
                </IonButton>
              </>}
              <IonChip slot="end"><IonLabel>{taurus.updated_at} (last update)</IonLabel></IonChip>
            </IonItem>

            <IonRow>
              <IonCol>
                CAN0 interface
              </IonCol>
              <IonCol>
                <CANInterfaceSelect value={tmpTaurus.can0_interface_type} selectProps={props("can0_interface_type")}/>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                CAN0 Baudrate
              </IonCol>
              <IonCol>
                <CANBaudrateSelect value={tmpTaurus.can0_baudrate} selectProps={props("can0_baudrate")}/>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                CAN1 interface
              </IonCol>
              <IonCol>
                <CANInterfaceSelect value={tmpTaurus.can1_interface_type} selectProps={props("can1_interface_type")}/>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                CAN1 Baudrate
              </IonCol>
              <IonCol>
                <CANBaudrateSelect value={tmpTaurus.can1_baudrate} selectProps={props("can1_baudrate")}/>
              </IonCol>
            </IonRow>

            {fields.map(({name, options}) => <IonRow>
              <IonCol>
                {name}
              </IonCol>
              <IonCol>
                <IonSelect value={tmpTaurus[name]} {...props(name)} placeholder={name}>
                  {options.map((option: String) => <IonSelectOption value={option}>{option}</IonSelectOption>)}
                </IonSelect>
              </IonCol>
            </IonRow>)}

            <IonRow>
              <IonCol>
                Drive transmission
              </IonCol>
              <IonCol>
                <DriveTransmissionSelect value={tmpTaurus.drive_transmission} selectProps={props("drive_transmission")}/>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                Obsolete
              </IonCol>
              <IonCol>
                <IonToggle checked={tmpTaurus.obsolete} {...props("obsolete")}/>
              </IonCol>
            </IonRow>

          </IonGrid>
        </IonAccordion>
      </IonAccordionGroup>
    </>
  );
};
