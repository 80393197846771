import React, { useCallback, useEffect, useState } from 'react';
import { IonList, IonContent, IonPage, IonModal, IonProgressBar, IonButton, IonLabel, IonItem, IonItemDivider, IonSelect, IonSelectOption, IonListHeader, IonInput, IonToggle, IonPopover, IonIcon, } from '@ionic/react';
import Toolbar from '../../components/Toolbar'
import { gql } from '@apollo/client';
import { BagItem, BAG_FRAGMENT, PlantVarietySelect, useAddTagsMutation} from '../../components/BagItem';
import { BagFilter, useBagFilter } from '../../components/apiFilters';
import { AddEvaluation, EvaluationItemFromId } from '../../components/EvaluationItem';
import CopyButton from '../../components/CopyButton';
import { useMutationWithElevatedRole, useQueryWithElevatedRole, useSubscriptionWithElevatedRole } from '../../hooks/hasuraHooks';
import { Pagination, usePagination } from '../../components/Pagination';
import { TagPicker } from '../../components/TagPicker';
import { closeCircle, trash } from 'ionicons/icons';


export const takeNBagsPerDayAndRobot = (bags: any, nBagsPerGroup: number, takeNBagsSkipTagged: boolean) => {
  // Get n bags per day+robot group of bags
  if (!bags) {return bags}
  const bagDay = (bag: any) => `GT${bag.taurus_id} - ${bag.started_recording_at.split("T")[0]}`
  // Group bags by GT and date
  const groupedBags = bags.filter((bag: any) => bag.started_recording_at).reduce((result: any, currentBag: any) => {
    (result[bagDay(currentBag)] = result[bagDay(currentBag)] || []).push(
      currentBag
    )
    return result
  }, {})

  return Object.keys(groupedBags).map((key: string) => {
    const bags = groupedBags[key]
    // Indices of the bag list to be picked. Pick nBagsPerDay bags with equal distance and with the first and the last bag
    const indices = Array.from(new Set([...new Array(nBagsPerGroup)].map((_, i) => Math.round((bags.length - 1) * i / Math.max(1, nBagsPerGroup - 1)))))
    return indices.map((index: number) => bags[index])
  }).flat().filter((bag: any) => !takeNBagsSkipTagged || bag.bag_tags.length === 0)
}


// Remove all tags at once for selected bags
const TagRemover: React.FC<any> = ({cumulatedTags}) => {
  const [deleteBagTag] = useMutationWithElevatedRole(gql`
    mutation DeleteBagTag($id: Int!) {
      delete_bag_tags_by_pk(id: $id) {id tag_id bag_id}
    }
  `, {})
  const [event, setEvent] = useState<any>(null)
  return <>
    <IonButton onClick={setEvent}>Remove tags</IonButton>
    <IonPopover event={event} isOpen={!!event} onDidDismiss={() => setEvent(null)} className="wide-popover">
      <IonList>
        <IonItemDivider>Click to remove from all bags</IonItemDivider>
        {Object.entries(cumulatedTags).map(([tagName, tagIds]: any) => <IonItem key={tagName}>
          <IonButton onClick={() => tagIds.forEach((tagId: number) => deleteBagTag({variables: {id: tagId}}))}>
            <IonIcon icon={closeCircle} slot="start"/>
            {tagName} ({tagIds.length})
          </IonButton>
        </IonItem>)}
      </IonList>
    </IonPopover>
  </>
}


const Bags: React.FC = () => {
  // Special mode: all bags are sorted by taurus and date and N bags are picked (very slow fetch)
  const takeNBagsOption = "N Bags / GT / day"
  const orderByOptions = [
    ["Last uploaded", {id: "desc"}],
    ["Newest", {started_recording_at: "desc", name: "asc"}],
    ["Oldest", {started_recording_at: "asc", name: "asc"}],
    ["Least tags", {bag_tags_aggregate: {count: "asc"}}],
    [takeNBagsOption, {taurus_id: "asc", started_recording_at: "asc", id: "desc"}]
  ]
  const [bagFilter, setBagFilter] = useBagFilter()
  const [showBulkAddEvaluation, setShowBulkAddEvaluation] = useState(false)
  const [orderByType, setOrderByType] = useState(orderByOptions[0][0])
  const [nBagsPerGroup, setNBagsPerGroup] = useState<number>(3)
  const [takeNBagsSkipTagged, setTakeNBagsSkipTagged] = useState(false)  // Use to find un-tagged bags
  const orderByOption = orderByOptions.find((e: any) => e[0] === orderByType)
  const orderBy: any = orderByOption ? orderByOption[1] : {}
  const pagination = usePagination()

  const takeNBags = orderByType === takeNBagsOption

  useEffect(() => {
    if (takeNBags) {
      pagination.setState((prev: any) => ({...prev, limit: 10000}))  // No limit when taking n bags
    }
  }, [takeNBags])

  // Note: bagFilter is deep copied here to force update the fetching. The reason why this is required not clear
  // The bagFilter is changed when one of its parameters changes and thus fetchVariables as well.
  // useSubscription somehow outsmarts this and does not update the subscription unless there is a deep copy here.
  const fetchVariables = {bagFilter: {...bagFilter}, orderBy, ...pagination.state}

  const { loading: bag_loading, data: bag_data } = useSubscriptionWithElevatedRole(gql`
    ${BAG_FRAGMENT}
    subscription Bags ($bagFilter: bags_bool_exp!, $limit: Int!, $offset: Int!, $orderBy: [bags_order_by!]!) {
        bags (order_by: $orderBy, where: $bagFilter, limit: $limit, offset: $offset) {
          ...BagFields
        }
    }
  `, {
    variables: fetchVariables,
  })

  const {loading: bag_aggregate_loading, data: bag_aggregate_data} = useSubscriptionWithElevatedRole(gql`
    subscription BagsAggregate($bagFilter: bags_bool_exp!) {
      bags_aggregate (where: $bagFilter) {aggregate{count}}
    }
  `, {variables: fetchVariables});

  const data = {
    ...bag_data,
    ...bag_aggregate_data
  };
  const loading = bag_loading || bag_aggregate_loading;
  
  const [updateBags] = useMutationWithElevatedRole(gql`
    mutation UpdateBags($where: bags_bool_exp = {}, $_set: bags_set_input = {}) {
      update_bags(where: $where, _set: $_set) {
        affected_rows
      }
    }
  `)

  const [insertFileCopyOperations] = useMutationWithElevatedRole(gql`
    mutation InsertFileCopyOperations($objects: [file_copy_operations_insert_input]!) {
      insert_file_copy_operations(objects: $objects) {
        returning {id}
      }
    }
  `)

  const labelAllBagsCommand = `rosrun weeding_recording prepare_annotation.py ${data?.bags?.map(({id}: any) => id)?.join(" ")}`
  
  const [addTags] = useAddTagsMutation()

  // takeNBags mode has to post-process the list of bags
  const bags = takeNBags ? takeNBagsPerDayAndRobot(data?.bags, nBagsPerGroup || 1, takeNBagsSkipTagged) : data?.bags
  const totalBags = takeNBags ? bags?.length : data?.bags_aggregate?.aggregate?.count
  
  const downloadAllBags = useCallback(() => {
    insertFileCopyOperations({variables: {objects: bags
        .filter(({file_paths}: any) => file_paths.length > 0 && file_paths.every(({computer_name, file_copy_operations}: any) => computer_name !== "d1" && file_copy_operations.length === 0))  // Ignore bags on d1 or that have copy ops
        .map(({file_paths}: any) => ({file_path_id: file_paths.at(0).id}))
    }})
  }, [bags])
  
  const addTagToAllBags = useCallback((tag_id: number) => addTags({variables: {objects: bags
      ?.filter(({bag_tags}: any) => !bag_tags.some(({tag}: any) => tag.id === tag_id))  // Skip bags that already have that tag
      ?.map(({id}: any) => ({bag_id: id, tag_id: tag_id}))}  // Add a bag_tag
    })
  , [bags])

  // Get object with tagname as a key and list of bag_tag ids as a value
  const cumulatedTags = bags?.map(({bag_tags}: any) => bag_tags)?.flat()?.reduce((prev: any, next: any) => {
    return {...prev, [next.tag.name]: [...(prev[next.tag.name] || []), next.id]}  // Add ids of bag_tags to key tag.name
  }, {})

  return (
    <IonPage>
      <Toolbar name="Bags">
        <div style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}>
          <BagFilter bagFilter={bagFilter} setBagFilter={setBagFilter}/>

          <IonItem color="transparent">
            <IonLabel position="stacked">Sort</IonLabel>
            <IonSelect value={orderByType} onIonChange={(e: any) => setOrderByType(e.detail.value)}>
              {orderByOptions.map(([key, _]: any[])=> <IonSelectOption value={key} key={key}>{key}</IonSelectOption>)}
            </IonSelect>
          </IonItem>
          {takeNBags && <>
            <IonItem color="transparent">
              <IonLabel position="stacked">N bags</IonLabel>
              <IonInput value={nBagsPerGroup} type="number" min="1" max="100" step="1" onIonChange={(e: any) => setNBagsPerGroup(parseInt(e.detail.value))}/>
            </IonItem>
            <IonItem color="transparent">
              Only bags with no tags<IonToggle checked={takeNBagsSkipTagged} onIonChange={(e: any) => setTakeNBagsSkipTagged(e.detail.checked)}/>
            </IonItem>
          </>}
        </div>

      </Toolbar>
      <IonContent fullscreen>
        {loading && <IonProgressBar type="indeterminate"/>}
        {bags && <>
          <IonModal isOpen={showBulkAddEvaluation !== false} onDidDismiss={() => setShowBulkAddEvaluation(false)}>
            <AddEvaluation
              bags={bags}
              onClose={() => setShowBulkAddEvaluation(false)}
            />
          </IonModal>
          <IonItem>
            <IonButton color="secondary" onClick={() => setShowBulkAddEvaluation(true)} fill="outline">Evaluate all bags</IonButton>
            <TagPicker name={"Add tag to all bags"} onChange={addTagToAllBags}/>
            <PlantVarietySelect plant_variety_id={null} text="Set crop for all bags"
              onChangePlantVariety={(plant_variety_id: number) => updateBags({variables:
                {where: {id: {_in: bags.map(({id}: any) => id)}}, _set: {plant_variety_id: plant_variety_id}}})}/>

            <IonButton onClick={() => {
              updateBags({variables: {
                where: {id: {_in: bags.map(({id}: any) => id)}},
                _set: {archived: true},
              }})
            }} fill="clear">
              Archive all bags
            </IonButton>

            <CopyButton text={labelAllBagsCommand}>
              Label all bags
            </CopyButton>
            <IonButton onClick={downloadAllBags}>
              Download all bags
            </IonButton>
            <TagRemover cumulatedTags={cumulatedTags}/>
          </IonItem>

          <Pagination numItems={bags?.length} itemType={"bags"} {...pagination} totalItems={totalBags}/>
          <IonList>
            {bags.map((bag: any) => <BagItem key={bag.id} bag={bag} AddEvaluation={AddEvaluation} EvaluationItemFromId={EvaluationItemFromId}/>)}
          </IonList>

          <Pagination numItems={bags?.length} itemType={"bags"} {...pagination} totalItems={totalBags}/>
        </>}
      </IonContent>
    </IonPage>
  );
};

export default Bags;
