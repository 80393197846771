import { IonBadge, IonPopover } from "@ionic/react"
import { useState } from "react"


export const SimBadge: React.FC<any> = ({simulationName, ...props}) => {
  const [show, setShow] = useState(false)
  if (!simulationName) {return null}
  return <>
    <IonBadge color="danger" {...props} onMouseOver={() => setShow(true)} onMouseOut={() => setShow(false)}>
        sim
        {show && <>{": "}{simulationName}</>}
    </IonBadge>
  </>
}