import { IonItem, IonLabel, IonRange } from '@ionic/react';
import React, {useState} from 'react';
import {Circle} from 'react-konva';


export const Stem: React.FC<any> = ({stem, stroke, scale, radius=0.05, id, confidence, area, nObservations, layerRotation, setInfo, onMouseOver, onMouseOut, ignoreGroundTruth, ...props}: any) => {
  const onMouseOverWrapper = () => {
    if (onMouseOver) return onMouseOver()
    if (setInfo) {
      const text = <>
        {id !== undefined && <>Id: {id}{" "}</>}
        {confidence !== undefined && <>Confidence: {confidence.toFixed(4)}{" "}</>}
        {area !== undefined && <>Area: {area}{" "}</>}
        {nObservations !== undefined && <>Observations: {nObservations}{" "}</>}
        {ignoreGroundTruth !== undefined && <>{ignoreGroundTruth
          ? "Ignore stem in scoring (no false positive / negative at this location)" : "Ground truth stem used in scoring"}{" "}
        </>}
      </>
      setInfo(text)
    }
  }
  const onMouseOutWrapper = () => {
    if (onMouseOut) return onMouseOut()
    if (setInfo) return setInfo(null)
  }
  return <>
    <Circle onMouseOver={onMouseOverWrapper} onMouseOut={onMouseOutWrapper} x={stem[0]} y={stem[1]}
          strokeWidth={radius / ((scale < 1200) ? 2. : 8.)} radius={radius} stroke={stroke} {...props}/>
  </>
}


export const StemSliders: React.FC<any> = ({config, setConfig}: any) => {
  return <>
    <IonItem>
      <IonLabel position='stacked'>Stem confidence: {config.stemMinConfidence} to {config.stemMaxConfidence}</IonLabel>
      <IonRange dualKnobs={true} value={{lower: config.stemMinConfidence, upper: config.stemMaxConfidence}} min={0} max={1} step={0.05}
        onIonChange={(e: any) => setConfig((prev: any) => ({...prev, stemMinConfidence: e.detail.value.lower, stemMaxConfidence: e.detail.value.upper}))}/>
    </IonItem>
    <IonItem>
      <IonLabel position='stacked'>Stem blob area: {">"} {config.stemMinArea}</IonLabel>
      <IonRange value={config.stemMinArea} min={0} max={1000} 
        onIonChange={(e: any) => setConfig((prev: any) => ({...prev, stemMinArea: e.detail.value}))}/>
    </IonItem>
    <IonItem>
      <IonLabel position='stacked'>Stem observations: {config.stemMinObservations} to {config.stemMaxObservations}</IonLabel>
      <IonRange dualKnobs={true} value={{lower: config.stemMinObservations, upper: config.stemMaxObservations}} min={0} max={100} 
        onIonChange={(e: any) => setConfig((prev: any) => ({...prev, stemMinObservations: e.detail.value.lower, stemMaxObservations: e.detail.value.upper}))}/>
    </IonItem>
  </>
}


export const useStemConfig = () => {
  const [config, setConfigWrapped] = useState<any>({
    stemMinConfidence: 0.,
    stemMaxConfidence: 1,
    stemMinArea: 0,
    stemMinObservations: 0,
    stemMaxObservations: 100,
  })

  const setConfig = (newConfigFn: any) => {
    // Workaround for ionic bug that triggers onIonChange several times: check if config changed before calling callback
    const newConfig = newConfigFn(config)
    if (JSON.stringify(newConfig) !== JSON.stringify(config)) {
      setConfigWrapped(newConfig)
    }
  }
  
  const stemFilter = (stem: any) => (
        stem[2] >= config?.stemMinConfidence 
    && stem[2] <= config?.stemMaxConfidence 
    && (stem[3] === undefined || (stem[3] >= config?.stemMinArea))
    && (stem[4] === undefined || (stem[4] >= config?.stemMinObservations && stem[4] <= config?.stemMaxObservations))
  )
  
  return [config, setConfig, stemFilter]
}
