import { useUserId } from '../hooks/userHooks';
import React, { useEffect, useState } from 'react';

import { useMutationWithElevatedRole, useSubscriptionWithElevatedRole, } from '../hooks/hasuraHooks';
import { gql } from '@apollo/client';
import { IonButton, IonIcon, IonInput, IonItem, IonSelect, IonSelectOption, IonSpinner, IonTextarea } from '@ionic/react';
import { call, send, text } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { ComponentIdSelect, ComponentType } from './ComponentIdSelect';


export const AddMessage: React.FC<any> = ({fixedTaurusId = null}) => {
  const [text_md, setTextMd] = useState("")
  const [taurusId, setTaurusId] = useState<any>(fixedTaurusId)
  const [urgent, setUrgent] = useState<boolean>(false)
  const [phone_number, setPhoneNumber] = useState<string>("")
  const [language, setLanguage] = useState<string>("de")
  const author_user_id = useUserId()
  const {t} = useTranslation()
  const [insertMessages, insertMessagesContext] = useMutationWithElevatedRole(gql`
    mutation InsertMessages($objects: [messages_insert_input!] = []) {
      insert_messages(objects: $objects) {
        returning {
          id text_md
        }
      }
    }
  `)
  
  const {data} = useSubscriptionWithElevatedRole(
    gql`
      subscription TaurusesWithConfig {
        tauruses(order_by: {id: asc}) {
          id 
          taurus_configs(limit: 1, order_by: {id: desc}) {
            robot_config_id
          }
        }
      }
    `, {}
  )
  const taurusAndConfigs = (data?.tauruses
    ?.map(({id, taurus_configs}: any) => ({id, robotConfigId: taurus_configs?.at(0)?.robot_config_id}))
    ?.filter(({robotConfigId}: any) => robotConfigId)
  ) || []
  const robot_config_id = taurusAndConfigs.find(({id}: any) => taurusId === id)?.robotConfigId
  useEffect(() => {
    if (insertMessagesContext?.data?.insert_messages?.returning?.at(0)?.text_md === text_md) {
        setTextMd("")
        insertMessagesContext.reset()
    }
  }, [insertMessagesContext, text_md])

  const sendDisabled = !text_md || insertMessagesContext?.loading || !author_user_id  || (fixedTaurusId && !robot_config_id)
  return <div style={{display: "flex", flexDirection: "column", minHeight: 150, border: "2px solid", margin: 2}}>
    <IonTextarea autoGrow={false} rows={4} placeholder={t("Type message here")} value={text_md} onIonChange={({detail: {value}}: any) => setTextMd(value)}/>
    <div style={{display: "flex"}}>
      {fixedTaurusId === null && <ComponentIdSelect componentType={ComponentType.Taurus} componentId={taurusId}
          showProgressBar={false}
          setComponentId={(newValue: number) => setTaurusId(newValue)}
          selectChildren={<IonSelectOption value={null}>{t("No specific robot")}</IonSelectOption>}/>}
      <IonSelect value={urgent} onIonChange={({detail: {value}}: any) => setUrgent(value)}>
        <IonSelectOption value={false}>{t("Not urgent")}</IonSelectOption>
        <IonSelectOption value={true}>{t("Urgent")}</IonSelectOption>
      </IonSelect>
      <IonSelect value={language} onIonChange={({detail: {value}}: any) => setLanguage(value)}>
        {["de", "en", "fr", "es", "pl", "pt", "nl"].map((l: string) => <IonSelectOption key={l} value={l}>{l}</IonSelectOption>)}
      </IonSelect>

      <IonItem>
        <IonInput value={phone_number} onIonChange={({detail: {value}}: any) => setPhoneNumber(value)} placeholder={t("Phone number")} type="tel"></IonInput>
      </IonItem>
    </div>
    <div style={{display: "flex"}}>
      <IonButton disabled={sendDisabled} color="secondary" onClick={() => insertMessages({variables: {objects: [
          {author_user_id, text_md, robot_config_id, urgent, phone_number, language}
      ]}})}>
        <IonIcon icon={send} slot="start"/>
        {t("Send")}
      </IonButton>
      
      <IonButton disabled={sendDisabled || !phone_number} onClick={() => insertMessages({variables: {objects: [
          {author_user_id, text_md, robot_config_id, urgent, phone_number, language, must_be_called_back: true}
      ]}})}>
        <IonIcon icon={call} slot="start"/>
        {t("Ask for call back")}
      </IonButton>

      {insertMessagesContext?.loading && <IonSpinner slot="end"/>}
    </div>
  </div>
}