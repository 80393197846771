import React, { useContext, useEffect, useState } from 'react';
import { AreaList, DeleteAreaButton } from './AreaList';
import { FieldEditorContext, dataTemplates, useFieldEditorData, useGetArea } from './useFieldEditorData';
import { IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonChip, IonIcon, IonInput, IonItem, IonLabel, IonToggle } from '@ionic/react';
import { caretBack, checkmark, close, closeCircle, closeOutline, grid, leaf, pencil, save, trash } from 'ionicons/icons';
import { MiniatureField } from './MiniatureField';
import { FieldSchema } from './FieldSchema';
import { CropSelector } from './RuleSelector';
import { DefinitionEdit } from './DefinitionEdit';
import { useTranslation } from 'react-i18next';


export const SelectedObjectView: React.FC<any> = ({closeButton, skipCropsWithNoRule}: any) => {
  const {rules_by_crop, data, setData, selectedObject, interrow_distance, areas, setInterrowDistance, number_of_bed_rows, setSeederWidth, interbed_distance, setInterbedDistance} = useContext(FieldEditorContext)
  const {getArea, getAreaColor, getAreaName} = useGetArea(areas)
  const { t } = useTranslation()
  if (selectedObject === "row-edit") {
    return <>
      <IonItem color="transparent" lines="none">
        <CropSelector skipCropsWithNoRule={skipCropsWithNoRule} style={{width: "100%"}} rules_by_crop={rules_by_crop}
          cropId={data.crop_id} setCropId={(crop_id: number) => setData({...data, crop_id})} setDefaultCropId={true} />
        <IonButtons slot="end">{closeButton}</IonButtons>
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">{interbed_distance > 0 ? t("Bed width") : t("Seeder width")} ({t("rows")})</IonLabel>
        <IonInput type="number" step="1" min="1" value={number_of_bed_rows} onIonChange={(e: any) => setSeederWidth(parseInt(e.detail.value) || 0)}/>
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">{t("Interrow distance")}</IonLabel>
        <div style={{display: "flex", width: "100%", marginTop: 5}}>
          <IonBadge>{(interrow_distance * 100).toFixed(0)} cm</IonBadge>
          <input style={{width: "100%", border: "black solid"}} type="range" min={10} max={100} step={1}
              value={interrow_distance * 100} onChange={(e: any) => setInterrowDistance(Number(e.target.value) / 100.0)} />
        </div>
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">
          {interbed_distance > 0 ? t("Interbed distance") : t("Beds")}
        </IonLabel>
        <IonToggle checked={interbed_distance > 0} onIonChange={(e: any) => e.detail.checked ? setInterbedDistance(0.5) : setInterbedDistance(0.0)}/>
        {interbed_distance > 0 && <div style={{display: "flex", width: "100%", marginTop: 5}}>
          <IonBadge>{(interbed_distance * 100).toFixed(0)} cm</IonBadge>
          <input style={{width: "100%", border: "black solid"}} type="range" min={10} max={100} step={1}
              value={interbed_distance * 100} onChange={(e: any) => setInterbedDistance(Number(e.target.value) / 100.0)} />
        </div>}
      </IonItem>
    </>
  }

  if (selectedObject.area_id) {  // It's an area
    const area = getArea(selectedObject.area_id)
    if (!area) return null
    return <IonCardContent style={{background: getAreaColor(selectedObject.area_id)}}>
      <IonItem color="transparent">
        <IonLabel>{getAreaName(area.id)}</IonLabel>
        <IonButtons>
          <DeleteAreaButton area_id={selectedObject.area_id} fill="clear" slot="end">
            <IonIcon icon={trash} slot="icon-only"/>
          </DeleteAreaButton>
          {closeButton}
        </IonButtons>
      </IonItem>
      <DefinitionEdit area={area}/>
    </IonCardContent>
  }
  return null
}


export const FieldEditor: React.FC<any> = ({initialData, onSave, oldConfig, rules_by_crop, skipCropsWithNoRule=true, backButton, developer = true}: any) => {
  const fieldEditorData = useFieldEditorData(initialData, rules_by_crop)
  const {setSelectedObject, selectedObject, setFromTemplate, areas, data, setName} = fieldEditorData
  const [showSchemas, setShowSchemas] = useState(true)
  const {t} = useTranslation()

  // Only check areas when comparing schemas (not any other values)
  // We want to highlight the schema that corresponds to the current config
  const equalsAreas = (as: any) => JSON.stringify(as) === JSON.stringify(areas)
  const pickedSchemaIndex = dataTemplates?.findIndex((schemaConfig: any) => equalsAreas(schemaConfig.fieldConfig.areas))

  const cropName_EN = rules_by_crop?.find((crop: any) => crop.id === data?.crop_id)?.name_EN  

  useEffect(() => {
    // By default: do not show schemas if no schema was picked. Otherwise: show the schemas
    if (pickedSchemaIndex > -1) {
      setShowSchemas(true)
    }
    else {
      setShowSchemas(false)
    }
  }, [pickedSchemaIndex])

  return <>
    <div style={{maxHeight: "120px", position: "absolute", maxWidth: "100%", display: "flex", overflowX: "auto", overflowY: "hidden", zIndex: 1000}}>
      <IonButton onClick={() => setShowSchemas(!showSchemas)} fill="clear">
        <IonIcon slot="icon-only" icon={showSchemas ? caretBack : grid}/>
      </IonButton>
      {/* schema picker: only show if no local changes were made, e.g. config equals to a schema  */}
      {showSchemas && dataTemplates.map((dataTemplate: any, i: number) => <MiniatureField
        {...dataTemplate} key={i}
        style={{
          boxSizing: "border-box", background: "white", border: "solid 3px", borderColor:  pickedSchemaIndex === i ? "gray" : "white",
          position: "relative", cursor: "pointer", margin: "2px"
        }}
        onClick={() => setFromTemplate(dataTemplate) /* only set the area, keep interrow distance */}
      >
        <IonBadge style={{position: "absolute"}}>{i + 1}</IonBadge> {/* number of the schema */}
      </MiniatureField>)}
    </div>

    <FieldEditorContext.Provider value={fieldEditorData}>
      <div style={{border: "1px solid", height: "100%", width: "100%", position: "absolute"}}>
        {/* schema visualization and editor (konva) */}
        <FieldSchema/>
      </div>

      <div style={{position: "absolute", bottom: 0, zIndex: 1000, maxHeight: "70%", width: "100%", background: "white", overflowY: "auto"}}>
        {selectedObject && <IonCard style={{border: "solid"}}>
          <SelectedObjectView  skipCropsWithNoRule={skipCropsWithNoRule} closeButton={<IonButton fill="clear" onClick={() => setSelectedObject(null)} slot="end">
            <IonIcon icon={closeCircle} slot="icon-only"/>
          </IonButton>}/>
        </IonCard>}

        <IonButton fill={selectedObject === "row-edit" ? "outline": "clear"} expand='block'
            onClick={() => setSelectedObject(selectedObject === "row-edit" ? null : "row-edit")} slot="end">
          {data.fieldConfig.interrow_distance * 100} cm x {data.fieldConfig.number_of_bed_rows}
          {cropName_EN ? <> - {cropName_EN}</> : <IonBadge color="danger">No crop set</IonBadge>}
          <IonIcon slot="end" icon={pencil}/>
        </IonButton>

        {/* area list for advanced editing */}
        {developer && <AreaList oldConfig={oldConfig}/>}

        <div style={{display: "flex"}}>
          {backButton && backButton}
          <IonInput value={window.decodeURIComponent(data.fieldConfig.name)} placeholder="Field name" onIonChange={(e: any) => setName(window.encodeURIComponent(e.detail.value))}/>
          {onSave && <IonButton color="secondary" onClick={() => onSave(data)} fill="solid">
            <IonIcon icon={checkmark} slot="start"/>
            {t("Save")}
          </IonButton>}
        </div>
      </div>

    </FieldEditorContext.Provider>
  </>
}