import { useState, useEffect, useRef } from 'react';
import {
  Chart as ChartJS,
  LineController,
  BarController,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarController,
  LineController,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export const CameraHeightEstimation: React.FC<any> = ({ evaluation, resultData }: any) => {
  let pts = resultData?.estimations?.map((msg: any) => msg.estimated_camera_height_m)
  let labels = resultData?.estimations?.map((msg: any) => {
    return new Date(msg.stamp * 1000).toISOString()
  })
  let inlier_ratios = resultData?.estimations?.map((msg: any) => msg.inlier_ratio)

  let estimation_data = pts?.map((pt: number, i: number) => ({y: pt, x: labels[i]}))
  let inlier_ratio_data = inlier_ratios?.map((ratio: number, i: number) => ({y: ratio, x: labels[i]}))

  let configured_heights_data: Array<any> = []
  if (resultData?.hasOwnProperty("configured_camera_heights")) {
    for (const [cam, tups] of Object.entries(resultData.configured_camera_heights)) {
      let d = (tups as Array<number>).map((tup: any) => ({x: tup[0], y: tup[1]}));
      // show the configured height over the full plot
      d.push({y: d[d.length-1].y, x: labels[labels.length-1]})
      configured_heights_data.push({
        label: cam + ' configured height',
        data: d,
        spanGaps: true,
        borderColor: 'red'
      })
    }
  }

  const chartRef = useRef<any>(null);

  useEffect(() => {
    if (pts) {

      let d = [{
            label: 'estimated camera height',
            //data: pts,
            data: estimation_data,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
          }, {
            label: 'inlier_ratio',
            data: inlier_ratio_data,
            fill: false
          }].concat(configured_heights_data)

        console.log(configured_heights_data)


      const ctx = chartRef?.current?.getContext('2d');
      new ChartJS(ctx, {
        type: 'line',
        data: {
          //labels: labels,
          datasets: d
        },
        options: {
        }
      });
    }
  }, [pts]);

  return <canvas ref={chartRef}></canvas>
}
