import { IonList, IonButton, IonItem, IonLabel, IonBadge, IonChip, IonPopover, IonIcon, } from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';
import { gql, useMutation, useQuery, } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { SimBadge } from './SimBadge';
import { NumberInput } from './NumberInput';
import { fixObjectForHasuraMany2ManyInsert } from '../lib/fixObjectForHasuraMany2ManyInsert';
import { GraphQLState } from './GraphQLState';
import { t } from 'i18next';
import { caretDown, caretUp } from 'ionicons/icons';
import { ComponentIdSelect, ComponentType } from './ComponentIdSelect';
import { referenceVolumePerHa } from './ReferenceSprayingArea';
import useWindowDimensions from '../useWindowDimensions';
const moment = require('moment-twitter');


export const useInsertReplenishment = () => useMutation(gql`
  mutation InsertReplenishment($object: replenishments_insert_input!) {
    insert_replenishments_one(object: $object) {
      id
    }
  }
`)


export const useUpdateReplenishment = () => useMutation(gql`
  mutation UpdateReplenishment($id: Int!, $_set: replenishments_set_input!) {
    update_replenishments_by_pk(pk_columns: {id: $id}, _set: $_set) {
      id
    }
  }
`)


export const ReplenishmentIngredient: React.FC<any> = ({replenishmentIngredient, fullVolume_L, onAmountChange}) => {
  const {name_DE, unit} = replenishmentIngredient.ingredient
  return <IonItem lines="none">
    <IonLabel>{name_DE}</IonLabel>
    {fullVolume_L && <IonBadge style={{marginLeft: 10}}>
      {formatConcentrationForReferenceArea(replenishmentIngredient, fullVolume_L)}
    </IonBadge>}
    <NumberInput value={replenishmentIngredient.amount} onChange={onAmountChange} isFloat style={{textAlign: "right"}} decimals={2} step={0.1} />
    {unit}
  </IonItem>
}


export const LPerUnit = (unit: string) => {
  if (unit === "L") {return 1.0}
  if (unit === "kg") {return 1.0}  // Approximation: the ingredient could be more or less dense than water
  if (unit === "g") {return 0.001}  
  console.error(`Invalid unit ${unit}`)
  return 0.0  // Not known: use 0. We don't raise exceptions here to avoid crashes if a 'wrong' unit is used
}


export const ReplenishmentContent: React.FC<any> = ({replenishment}) => {
  const [tmpReplenishment, setTmpReplenishment] = useState(replenishment)
  const [showPreviousEvent, setShowPreviousEvent] = useState(false)
  const {t} = useTranslation()
  const [insertReplenishment] = useInsertReplenishment()
  const [updateReplenishment] = useUpdateReplenishment()
  const save = useCallback(() => {
    insertReplenishment({variables: {object: fixObjectForHasuraMany2ManyInsert(tmpReplenishment)}})
    updateReplenishment({variables: {id: replenishment.id, _set: {archived: true}}})  // Archive previous replenishment
  }, [tmpReplenishment])
  
  // Subtract all ingredients from total volume to get the expected water volume
  const waterVolumeEstimate_L = tmpReplenishment.volume_after_filling_L - tmpReplenishment.ingredients.reduce(
    (p: number, n: any) => p + n.amount * LPerUnit(n.ingredient.unit)
  , 0) - tmpReplenishment.previous_replenishment_amount_L

  return <IonList>
    
    <IonItem>
      <IonButton onClick={save}>{t("Save")}</IonButton>
      <IonButton onClick={() => updateReplenishment({variables: {id: replenishment.id, _set: {archived: !replenishment.archived}}})}>
        {replenishment.archived ? t("Restore") : t("Archive")}
      </IonButton>
    </IonItem>


    <IonItem>
      <IonLabel position="stacked">{t("Planned field size")}</IonLabel>
      {replenishment.planned_ha.toFixed(2)}
      {" "}ha
    </IonItem>
    
    <IonItem>
      <IonLabel>{t("Main tank volume")}</IonLabel>
      <NumberInput value={tmpReplenishment.volume_after_filling_L} style={{textAlign: "right"}} decimals={2} isFloat step={0.1}
        onChange={(newValue: number) => setTmpReplenishment((prev: any) => ({...prev, volume_after_filling_L: newValue}))}
      />
      {" "}L
    </IonItem>

    {sortIngredients(tmpReplenishment.ingredients).map((r: any) => <ReplenishmentIngredient
      replenishmentIngredient={r} key={r.id} fullVolume_L={tmpReplenishment.volume_after_filling_L}
      onAmountChange={(newAmount: number) => setTmpReplenishment((prev: any) => ({...prev, ingredients: prev.ingredients.map((ri: any) => {
        if (ri.id === r.id) {return ({...ri, amount: newAmount})}
        else {return ri}
      })}))}
    />)}

    {tmpReplenishment.previous_replenishment_id && <>
      <IonItem lines="none">
        <IonButton onClick={() => setShowPreviousEvent(!showPreviousEvent)}>
          <IonIcon icon={showPreviousEvent ? caretUp : caretDown} slot="start"/>
          {t("Previous replenishment (leftover mixture)")}
        </IonButton>
        <NumberInput value={tmpReplenishment.previous_replenishment_amount_L} style={{textAlign: "right"}} decimals={2} isFloat step={0.1}
          onChange={(newValue: number) => setTmpReplenishment((prev: any) => ({...prev, previous_replenishment_amount_L: newValue}))}
        />
        {" "}L
      </IonItem>
      {showPreviousEvent && <IonList style={{paddingLeft: 15}}>
        <ReplenishmentItemById replenishmentId={tmpReplenishment.previous_replenishment_id} showFullVolume={false}/>
      </IonList>}
    </>}

    <IonItem>
      <IonLabel>{t("Water volume (estimate)")}</IonLabel>
      <IonChip slot="end">
        {waterVolumeEstimate_L.toFixed(2)}
        {" "}L
      </IonChip>
    </IonItem>

    
    <IonItem>
      <IonLabel>{t("Robot")}</IonLabel>
      <ComponentIdSelect componentType={ComponentType.Taurus} componentId={tmpReplenishment.taurus_id}
        setComponentId={(newValue: number) => setTmpReplenishment((prev: any) => ({...prev, taurus_id: newValue}))}/>
    </IonItem>

    <IonItem>
      <IonLabel>{t("Rinse tank volume")}</IonLabel>
      <NumberInput value={tmpReplenishment.clearwater_volume_after_filling_L} style={{textAlign: "right"}} decimals={2} isFloat step={0.1}
        onChange={(newValue: number) => setTmpReplenishment((prev: any) => ({...prev, clearwater_volume_after_filling_L: newValue}))}
      />
      {" "}L
    </IonItem>

  </IonList>
}


export const ReplenishmentItemById: React.FC<any> = ({replenishmentId, ...props}) => {
  const {data, loading, error} = useQuery(gql`query GetReplenishment($id: Int!) {
      replenishments_by_pk (id: $id) {
        id
        created_at updated_at
        taurus_id
        robot_config_id
        simulation_name

        planned_ha
        planned_herbicide_savings
        planned_volume_L
        volume_after_filling_L
        archived

        ingredients {
          id
          amount
          ingredient {
            id name_DE unit
          }
        }

        previous_replenishment_id
        previous_replenishment_amount_L
      }
  }`, {variables: {id: replenishmentId}})
  return <>
    <GraphQLState error={error} loading={loading}/>
    {data?.replenishments_by_pk && <ReplenishmentItem replenishment={data.replenishments_by_pk} {...props} />}
  </>
}


export const sortIngredients = (ingredients: any) => ingredients.toSorted((a: any, b: any) => 
  b.amount * LPerUnit(b.ingredient.unit) - a.amount * LPerUnit(a.ingredient.unit)
)



export const formatConcentrationForReferenceArea = (i: any, fullVolume_L: number) => {
    return `${(i.amount / fullVolume_L * referenceVolumePerHa).toFixed(1)} ${i.ingredient.unit} / ha*`
}


export const formatIngredientReplenishmentM2M = (i: any, fullVolume_L: number) => {
    // TODO: add translations here
    return `${i.ingredient.name_DE} (${formatConcentrationForReferenceArea(i, fullVolume_L)})`
}


export const ReplenishmentItem: React.FC<any> = ({replenishment, showFullVolume=true, ...props}) => {
  // Sort ingredient by amount
  const [mouseOverIngredients, setMouseOverIngredients] = useState(false)
  const {isLargeScreen} = useWindowDimensions()

  return <IonItem button lines="none" {...props} color={replenishment.archived ? "medium" : undefined}>
    {isLargeScreen && replenishment.created_at && <IonBadge color="medium" slot="start" style={{marginRight: 5}}>{moment(replenishment.created_at).twitterShort()}</IonBadge>}
    {replenishment.taurus_id && <IonBadge style={{marginRight: 5}}>GT{replenishment.taurus_id}</IonBadge>}
    {replenishment.robot_config_id && <IonBadge style={{marginRight: 5}}>#{replenishment.robot_config_id}</IonBadge>}
    {isLargeScreen && <SimBadge simulationName={replenishment.simulation_name} slot="start" style={{marginRight: 5}}/>}

    {showFullVolume && <IonLabel>
      {replenishment.volume_after_filling_L ? <>
        {replenishment.volume_after_filling_L.toFixed(1)} L
      </> : <>
        ? L
      </>}
    </IonLabel>}

    <IonLabel onMouseOver={() => setMouseOverIngredients(true)} onMouseOut={() => setMouseOverIngredients(false)} style={{
      whiteSpace: mouseOverIngredients ? "pre-wrap": undefined
    }}>
      {sortIngredients(replenishment.ingredients).map((i: any) => formatIngredientReplenishmentM2M(i, replenishment.volume_after_filling_L)).join(", ")}
    </IonLabel>
  </IonItem>
}


export const Replenishment: React.FC<any> = ({replenishment}) => {
  const [event, setEvent] = useState<any>(null)
  if (!replenishment) {return null}

  return <>
    <ReplenishmentItem onClick={setEvent} replenishment={replenishment} />

    <IonPopover isOpen={!!event} event={event} onDidDismiss={() => setEvent(null)} className="wide-popover">
      <IonList>
        <ReplenishmentContent replenishment={replenishment}/>
      </IonList>
      {replenishment.simulation_name && <IonItem>
        <IonLabel>{t("Simulation name")}</IonLabel>
        {replenishment.simulation_name}
      </IonItem>}
    </IonPopover>
  </>
}