import { IonChip, IonItemDivider } from "@ionic/react";

export const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce((groups, item) => {
    (groups[key(item)] ||= []).push(item);
    return groups;
  }, {} as Record<K, T[]>);


export const groupComponents = (components: any[], ignoreKeys: string[] = ["id", "updated_at", "obsolete", "calibrations"]) => {
  // Group the components 
  if (!components) return []
  const groupIdentifier = (component: any) => JSON.stringify(Object.fromEntries(Object.entries(component).filter(([k, v]: any) => !ignoreKeys.includes(k))))
  const groups = groupBy(components, groupIdentifier)
  return Object.keys(groups).map((k: string) => [JSON.parse(k), groups[k]])
}


export const ComponentGroup: React.FC<any> = ({groupDescription, components, factory, componentName, componentNamePlural, valueColors}: any) => {
  return <>
    <div style={{display: "flex", paddingTop: 10}}>
        {Object.entries(groupDescription).map(([key, value]: any) => {
            // Use color from valueColors (if boolean use key if enum use the value)
            const color = valueColors ? valueColors[[true, false].includes(value) ? key : value] : undefined
            if (value === false) {return null}  // Skip is value is a flag that is not set
            else if (value === true) {return <IonChip color={color} outline={!!color}>{key.replace("has_", "")}</IonChip>}  // Show flag if set to true
            else {return <IonChip style={{display: "flex", flexDirection: "column", width: "fit-content"}} color={color} outline={!!color}>
                {value}
                <span style={{fontSize: "10px", marginTop: "-3px"}}>{key}</span>
            </IonChip>}
        })}
    </div>
    
    <IonItemDivider>
      {components.length} {components.length > 1 ? (componentNamePlural || componentName + "s") : componentName}
    </IonItemDivider>
    {components.map(factory)}
  </>
}