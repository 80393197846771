import React, { useState } from 'react';
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { caretDown, checkboxOutline, close, squareOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';


export const SearchableSelect: React.FC<any> = ({value, options, name, ...props}) => {
  const [show, setShow] = useState<boolean>(false)
  if (props.multiple === undefined) {
    props.multiple = (value?.length !== undefined)
  }
  const multiple = props.multiple
  const checked = (checkedValue: any) => multiple ? value?.includes(checkedValue) : value === checkedValue
  const checkedOptions = options?.filter(([v, text]: any) => checked(v))
  const valueText = multiple ? (value?.length === 0 ? undefined : checkedOptions.map(([v, t]: any) => t).join(", ")) : (checkedOptions[0]?.length ? checkedOptions[0][1] : undefined) // Text for value (multiple or not)

  return <>
    <IonModal isOpen={show} onDidDismiss={() => setShow(false)}>
      <SearchableSelectContent value={value} options={options} checked={checked} multiple={multiple} onClose={() => setShow(false)} {...props}/>
    </IonModal>
    <SelectButton onClick={() => setShow(true)} valueText={valueText} name={name}/>
  </>
}


export const SelectButton: React.FC<any> = ({onClick, valueText, name}) => {
  return <div style={{padding: "10px 5px 10px 16px", display: "flex", justifyContent: "space-between", cursor: "pointer", maxWidth: "200px", maxHeight: "40px"}}
        onClick={onClick}>
      { /* Show placeholder if no value */
      valueText !== undefined ? valueText : <div className='select-text select-placeholder' style={{  color: "gray" }}>{name}</div>}
      <IonIcon icon={caretDown} size="small" style={{color: "gray", fontSize: "10px"}}/>
  </div>
}


export const SearchableSelectContent: React.FC<any> = ({options, name, value, onChange, checked, multiple, closeOnChange = false, children, onClose}) => {
    const [filter, setFilter] = useState("")
    const { t } = useTranslation()
    const filteredOptions = options?.filter(([v, text]: any) => text?.toLowerCase()?.includes(filter?.toLowerCase()) || checked(v))  // Keep options that are checked or within search pattern
    const update = (newValue: any) => {
        // Called when a value has been clicked
        const newValueIsChecked = !checked(newValue)
        if (!checked && !multiple) {
            return // do nothing: non-multiple cannot be un-checked
        }
        else if (!multiple) {
            onChange(newValue)  // set new value (not multiple)
        }
        else if (newValueIsChecked) {
            onChange(value.concat([newValue]))  // add to multiple
        }
        else {
            onChange(value.filter((v: any) => v !== newValue))  // remove from multiple
        }
        if (closeOnChange && onClose) {
          onClose()
        }
    }
    return <>
      {name && <div style={{display: "flex", justifyContent: "space-between"}}>
        {name && <IonTitle>{name}</IonTitle>}
      </div>}
      <IonItem>
        <IonSearchbar value={filter} onIonChange={(e: any) => setFilter(e.detail.value)}/>
        {children}
        {multiple && value.length < options?.length && <IonButton fill="clear" onClick={() => onChange(options.map(([v, text]: any) => v))}>{t("Select all")}</IonButton>}
        {multiple && value.length > 0 && <IonButton fill="clear" onClick={() => onChange([])}>{t("clear")}</IonButton>}
        {onClose && <IonButton expand="full" onClick={onClose} fill="clear"><IonIcon icon={close}/></IonButton>}
      </IonItem>
      <IonContent>
        {filteredOptions?.map(([v, t]: any) => {
            return <IonItem onClick={() => update(v)} style={{cursor: "pointer"}} key={t}>
                <IonIcon slot="start" icon={checked(v) ? checkboxOutline : squareOutline}/>
                <IonLabel style={{overflowX: "auto", textOverflow: "unset"}}>{t}</IonLabel>
            </IonItem>
        })}
      </IonContent>
    </>
}