import { IonBadge, IonPopover, IonTextarea } from "@ionic/react"
import { useEffect, useState } from "react"
import { Layer, Line, Stage } from "react-konva"


export const EditableShape: React.FC<any> = ({points, children, onChange, ...props}: any) => {
  const [show, setShow] = useState(false)
  const [pointsText, setPointsText] = useState(JSON.stringify(points, null, 2))  // Text that will can be edited: points is updated accordingly in useEffect

  useEffect(() => {
    // parse pointsText
    try {
      const newData = JSON.parse(pointsText)
      onChange({detail: {value: newData}})  // Mimic onIonChange
    }
    catch (e: any) {
      console.error(e)  // Show JSON errors in console. TODO: could be nice to show info to user
      return
    }
  }, [pointsText])
  
  return <>
    <MiniatureShape width={80} height={80} points={points} onClick={() => setShow(true)} {...props}>
      {children}
    </MiniatureShape>
    <IonPopover isOpen={show} onDidDismiss={() => setShow(false)} className="wide-popover">
        <div style={{display: "flex", width: "100%"}}>
          <MiniatureShape width={300} height={300} points={points} onClick={() => setShow(true)}/>
          <IonTextarea value={pointsText} rows={30} onIonChange={(e: any) => setPointsText(e.detail.value)}/>
        </div>
    </IonPopover>
  </>
}


export const MiniatureShape: React.FC<any> = ({points, style = {}, width=100, height=100, children, text, ...props}: any) => {
  style.width = width
  style.height = height
  style.cursor = props.onClick ? "pointer" : undefined;
  return <div style={style} onClick={props.onClick}>
    {children}
    {text && <div style={{position: "absolute", width, height, textAlign: "center", lineHeight: `${height}px`, fontWeight: 500, fontSize: 20}}>
      <IonBadge>{text}</IonBadge>
    </div>}
    {points && <MiniatureShapeContent {...props} width={width} height={height} points={points}/>}
  </div>
}


// Return rectangle coordinates that contains all points: use this for scaling
export const getBoundariesFromList = (points: any[]) => points
  .reduce((prev: any, {x, y}: any) => {
    if (prev === null) {
      return {xMin: x, xMax: x, yMin: y, yMax: y}
    }
    else {
      return {
        xMin: Math.min(prev.xMin, x),
        xMax: Math.max(prev.xMax, x),
        yMin: Math.min(prev.yMin, y),
        yMax: Math.max(prev.yMax, y),
      }
    }
  }, null)


export const MiniatureShapeContent: React.FC<any> = ({width, height, points, onClick}) => {
  if (!points) {return null}
  if (Object.keys(points).length < 1) {return null}

  // Scale to fully fit in the area
  const boundaries = getBoundariesFromList(points.map(([x, y]: any[]) => ({x, y})))
  const mapWidth = boundaries.xMax - boundaries.xMin
  const mapHeight = boundaries.yMax - boundaries.yMin
  const scale = Math.min(width / mapWidth, height / mapHeight) * 0.9

  // Compute offsets of canvas to center areas
  const x = width / 2 - (boundaries.xMin + boundaries.xMax) / 2. * scale
  const y = height / 2 + (boundaries.yMin + boundaries.yMax) / 2. * scale

  return <Stage height={height} width={width} scaleX={scale} scaleY={scale} style={{width: width, height: height}}  x={x} y={y}>
    <Layer scaleY={-1  /* y goes down in konva */}>
      <Line points={points.flat()} stroke={'black'}
        strokeWidth={1 / scale} closed={true}
        onClick={onClick}
        onTap={onClick}
      />
    </Layer>
  </Stage>
}