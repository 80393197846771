import { IonButton, IonModal, IonItem, IonContent, IonTitle, IonToolbar, IonProgressBar, } from '@ionic/react';
import React, { useState } from 'react';
import { gql, } from '@apollo/client';
import { useSubscriptionWithElevatedRole } from '../hooks/hasuraHooks';
import { useTranslation } from 'react-i18next';
import { formatIngredientReplenishmentM2M, sortIngredients } from './Replenishment';


export const useReplenishments = (where: any = {}) => useSubscriptionWithElevatedRole(gql`
  subscription GetReplenishments($where: replenishments_bool_exp!) {
    replenishments (order_by: {id: desc}, where: $where) {
      id
      created_at
      volume_after_filling_L
      ingredients {
        amount
        ingredient {
          id unit default_amount_per_ha
          name_DE name_PL name_EN
        }
      }
    }
  }`, {variables: {where}});


export const ReplenishmentPicker: React.FC<any> = ({children, title, onSelect, ...props }) => {
  const [show, setShow] = useState(false)
  const {t} = useTranslation()
  
  return <>
    <IonModal isOpen={show} onDidDismiss={() => setShow(false)}>
      {title && <IonToolbar>
        <IonTitle>
          {title}
        </IonTitle>
      </IonToolbar>}
      <ReplenishmentPickerContent onSelect={(replenishment: any) => {onSelect(replenishment); setShow(false)}} />
      <IonButton onClick={() => setShow(false)}>{t("Cancel")}</IonButton>
    </IonModal>
    <IonButton {...props} onClick={() => setShow(true)}>
      {children}
    </IonButton>
  </>
}


export const ReplenishmentPickerContent: React.FC<any> = ({onSelect}) => {
  const { data, loading } = useReplenishments({archived: {_eq: false}})
  return <IonContent>
    {loading && <IonProgressBar type="indeterminate"/>}
    {data?.replenishments?.map((replenishment: any) => <IonItem detail onClick={() => onSelect(replenishment)} key={replenishment.id}>
      {sortIngredients(replenishment.ingredients).map((i: any) => formatIngredientReplenishmentM2M(i, replenishment.volume_after_filling_L)).join(", ")}
    </IonItem>)}

  </IonContent>
}