import { IonLabel, IonItem, IonSelect, IonSelectOption, IonSegment, IonSegmentButton, IonButton, IonToggle, IonIcon, } from '@ionic/react';
import React, {useContext, } from 'react';
import { FieldEditorContext, updateArea, useGetArea, def_types } from './useFieldEditorData';
import { NumberInput } from './NumberInput';
import { useTranslation } from 'react-i18next';
import ConfirmButton from './ConfirmButton';
import { trash } from 'ionicons/icons';


export const DefinitionEdit: React.FC<any> = ({area}: any) => {
  const {areas, setAreas, selectedObject, isBed, number_of_bed_rows} = useContext(FieldEditorContext)
  const {getAreaName} = useGetArea(areas)
  const edit = selectedObject?.area_id === area.id
  if (!edit) {return null}
  return <DefinitionEditBase area={area} developer={false} setAreas={setAreas} areaName={getAreaName(area.id)} isBed={isBed} numberOfBedRows={number_of_bed_rows}/>
}


export const DefinitionEditBase: React.FC<any> = ({area, areas, setAreas, config, maxFixedBeds=3, allowChangingType = true}: any) => {
  const {t} = useTranslation()
  const def = area.def

  // "Jung"-case: think in tracks not in rows
  const hasVariableInterrowDistance = config?.has_variable_interrow_distance
  const numberOfBedRows = config?.number_of_bed_rows || 1

  const setDef = (newDef: any) => {
    if (JSON.stringify(area.def) === JSON.stringify(newDef)) {
      return  // Skip update: nothing changed. This is required because of a bug in onIonChange that causes it to sometimes trigger without a change
    }
    // Set default values for types
    if (newDef.type === "rows" && newDef.beds === undefined) {newDef.beds = 5}
    if (newDef.type === "side-track" || newDef.type === "extremity-straight") {
      if (newDef.safe_space_m === undefined) {newDef.safe_space_m =5.0}
      if (newDef.no_turns === undefined) {newDef.no_turns = false}
    }
    const updatedAreas = updateArea(areas, {...area, def: newDef})
    setAreas(updatedAreas)
  }
  // Ignore extremity def types (cannot be set by user)
  const labelProps = {style: {whiteSpace: "break-spaces"}}
  return <>
    {def.type === "rows" && <IonItem>
      <IonLabel {...labelProps} position="stacked">{t("Width (rows)")}</IonLabel>
      <IonSelect value={def.beds} style={{textTransform: "unset"}} onIonChange={({detail: {value}}: any) => setDef({...def, beds: value})}>
        {[...new Array(maxFixedBeds)].map((_, i: number) => <IonSelectOption value={i+1} key={i}>
          {hasVariableInterrowDistance ? <>
            {i + 1} {t("tracks")}
          </> : <>
            {(i + 1) * numberOfBedRows} {t("Rows")} ({i + 1}x {numberOfBedRows})
          </>}
        </IonSelectOption>)}
        <IonSelectOption value={null}>{t("? Rows")}</IonSelectOption>
      </IonSelect>
    </IonItem>}

    
    {(def.type === "side-track" || (def.type === "extremity-straight" && !def.within_parent)) && <>
      {/* allow lengthening the areas */}
      <IonItem>
        <IonLabel {...labelProps} position="stacked">{t("Width (m)")}</IonLabel>
        <NumberInput isFloat min={1.5} value={def.safe_space_m} onChange={(newValue: number) => setDef({...def, safe_space_m: newValue})}/>
      </IonItem>
    </>}

    {(def.type === "side-track" || def.type === "extremity-straight") && <IonItem>
      <IonLabel>
        {t("Allow turns here")}
      </IonLabel>
      <IonToggle checked={!def.no_turns} onIonChange={(e: any) => setDef({...def, no_turns: !e.detail.checked})}/>
    </IonItem>}

    {def.archived && <IonButton expand="block" size="small" fill="clear" onClick={() => setDef({...def, archived: false})}>
      {t("Restore")}
    </IonButton>}
    {!def.archived && <ConfirmButton text={`${t('Delete')}?`} expand="block" size="small" fill="clear" onClick={() => setDef({...def, archived: true})}>
      <IonIcon icon={trash} slot="start"/>
      {t("Delete")}
    </ConfirmButton>}

</>
}