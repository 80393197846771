import { IonButton, IonIcon, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonToggle, IonBadge, IonItemDivider, IonItemGroup, IonRow, IonCardSubtitle, IonCardContent, IonCard, IonCardHeader, IonDatetime } from '@ionic/react';
import { close, cog, colorWand, build, speedometerOutline, infinite, globe, options, eye, rainy, shield, barbell, flask, informationCircle, flashlightOutline, statsChart, checkmarkCircle, warningOutline, alertCircle, cloudOffline, batteryFullOutline, browsersOutline, power, codeOutline, lockOpenOutline, wifiOutline, globeOutline, caretBack, caretForward } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import Message from '../../components/Message';
import OvalParams, { ParamSetter } from '../../components/OvalParams';
import { RainConfig } from '../../components/RainConfig';
import { SprayerConfig, } from '../../components/SprayerConfig';
import { useCallService, useOverrideParams, useParam, useTopic, AccessType, getAccessType, getAccessTypeName, usePublisher } from '../../hooks/rosHooks';
import { useDeveloper } from '../../hooks/useDeveloperMode';
import { RuleSelectorSimple } from '../../components/RuleSelector';
import { SearchableSelect } from '../../components/SearchableSelect';
import { OverrideParam as OverrideParamWidget, minusPlusWidget, } from '../../utils/overrideParamWidget';
import { useTranslation } from 'react-i18next';
import { LifterControl } from './SectionControlSection';
import { OverrideParamMemo } from '../../components/OverrideParam';
import { t } from 'i18next';
import LivePlotSection from './LivePlot';
import TalpaCalibrationSection from './TalpaCalibrationSection';
import EnduranceTestSection from './EnduranceTestSection';
import { BandwidthStatsSection } from '../../components/BandwidthStatsSection';
import DiagnosticSection from './DiagnosticSection';
import { AdditionalServicesButton } from '../../components/AdditionalServicesButton';
import { useSetView } from '../../components/ViewConfig';
import { BackButton } from '../../components/BackButton';
import ConfirmButton from '../../components/ConfirmButton';
import PauseButton from '../../components/PauseButton';


const accessType = getAccessType()


const CameraHeightSetting: React.FC<any> = ({ resultingParams, setParams, }: any) => {
  const { t } = useTranslation()
  const [autoCamheightEstimation, setAutoCamheightEstimation] = useState(resultingParams["/main/camera_height_estimator/active"])
  return <>
    <ParamSetter paramKey={"/main/camera_height_manager/fallback_camera_height_m"} defaultValue={0.5} resultingParams={resultingParams} setParams={setParams}
      component={({ tempValue, setParam }: any) => <>
        <IonItem lines='inset'>
          <IonLabel>{t("Camera height (cm)")}</IonLabel>
          <IonInput disabled={autoCamheightEstimation} style={{ textAlign: "right" }} value={tempValue * 100} type="number" step="0.5"
            onIonChange={(e: any) => setParam(e.detail.value / 100.)} min="20" max="150" />
        </IonItem>
        <ParamSetter paramKey={"/main/camera_height_estimator/active"} defaultValue={false} resultingParams={resultingParams} setParams={setParams}
          component={({ tempValue, setParam }: any) => <IonItem>
            <IonLabel>{t("Automatic camera height estimation")}</IonLabel>
            <IonToggle checked={tempValue} onIonChange={(e: any) => {
              setAutoCamheightEstimation(e.detail.checked)
              setParam(e.detail.checked)
            }} />
          </IonItem>} />
      </>} />
  </>
}


export const ConfigSectionButton: React.FC<any> = ({ ...props }: any) => {
  const setView = useSetView()
  return <IonButton fill="outline" onClick={() => setView("config", {
    showField: true,
  })}>
    <IonIcon icon={options} slot="icon-only" />
  </IonButton>
}


const SectionControlConfig: React.FC<any> = ({ }) => {
  const { t } = useTranslation()
  const lifterStates = useTopic("/lifter_states", "weeding_implement_manager/LifterStates")
  if (!lifterStates) { return null }
  return <LifterControl lifterStates={lifterStates} />
}


const DriveConfig: React.FC<any> = ({ }: any) => {
  const { developer } = useDeveloper()
  const { setParams, resultingParams, } = useOverrideParams()
  const { t } = useTranslation()

  return <>
    <table style={{ width: '100%' }}><tbody>
      <OverrideParamWidget param={{
        name: t("Speed"),
        key: "/taurus_controller/lin_vel",
        min: 10, max: 70,
        step: 5,
        defaultValue: 0.3,
        renderer: minusPlusWidget,
        render_args: { unit: 'cm/s', digits: 0, factor: 100, align: 'center' }
      }} />
    </tbody></table>

    <IonCard>
      <IonCardHeader>
        {t("Axle calibration")}
      </IonCardHeader>
      <IonCardContent>
        <ParamSetter paramKey={"/localization/pose_computer/yaw_offset"} defaultValue={0.0} resultingParams={resultingParams} setParams={setParams}
          component={({ tempValue, setParam }: any) => <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }}>
            <IonButton
              disabled={tempValue <= -0.1}
              onClick={() => setParam(Math.min(+0.1, Math.max(-0.1, tempValue - 0.01)))}>
              <IonIcon icon={caretBack} slot="start" />{t("rear axle to left")}
            </IonButton>
            <IonButton
              disabled={tempValue >= +0.1}
              onClick={() => setParam(Math.min(+0.1, Math.max(-0.1, tempValue + 0.01)))}>
              <IonIcon icon={caretForward} slot="end" />{t("rear axle to right")}
            </IonButton>
          </div>} />
      </IonCardContent>
    </IonCard>
    {developer && <ParamSetter paramKey={"/taurus_controller/cropline_pid/active"} defaultValue={true} resultingParams={resultingParams} setParams={setParams}
      component={({ tempValue, setParam }: any) => <IonItem>
        <IonLabel>{t("Row following")}</IonLabel>
        <IonToggle checked={tempValue} onIonChange={(e: any) => setParam(e.detail.checked)} />
      </IonItem>} />}

    {developer && <ParamSetter paramKey={"/taurus_controller/track_pid/active"} defaultValue={true} resultingParams={resultingParams} setParams={setParams}
      component={({ tempValue, setParam }: any) => <IonItem>
        <IonLabel>{t("GPS track following")}</IonLabel>
        <IonToggle checked={tempValue} onIonChange={(e: any) => setParam(e.detail.checked)} />
      </IonItem>} />}

    {developer && <ParamSetter paramKey={"/main/track_trend_estimator/allow_curves"} defaultValue={true} resultingParams={resultingParams} setParams={setParams}
      component={({ tempValue, setParam }: any) => <IonItem>
        <IonLabel>{t("Allow curves in croplines")}</IonLabel>
        <IonToggle checked={tempValue} onIonChange={(e: any) => setParam(e.detail.checked)} />
      </IonItem>} />}

    {developer && <ParamSetter paramKey={"/main/track_updater/cropline_disable_updates"} defaultValue={false} resultingParams={resultingParams} setParams={setParams}
      component={({ tempValue, setParam }: any) => <IonItem lines="none">
        <IonLabel>{t("Update track with croplines")}</IonLabel>
        <IonToggle checked={!tempValue} onIonChange={(e: any) => setParam(!e.detail.checked)} />
      </IonItem>} />}
  </>
}


const AquilaCamState: React.FC<any> = ({ cam }: any) => {
  const state = useTopic(`/${cam}/light_controller_node/state`, "std_msgs/Bool")
  if (!state) {
    return <IonBadge color="danger" >{cam}: ?</IonBadge>
  } else if (state.data) {
    return <IonBadge color="success" >{cam}: on</IonBadge>
  } else {
    return <IonBadge color="medium" >{cam}: off</IonBadge>
  }
}

const AquilaLight: React.FC<any> = () => {
  const { value: aquilas } = useParam("/aquilas");
  const set_aquila_light = usePublisher("/light_on", "std_msgs/Bool")

  return (
    <>
      <IonItem>
        <IonLabel>{t("Lights")}</IonLabel>
        <IonLabel>
          <IonRow>
            {aquilas && Object.keys(aquilas).map((cam: string) => <div style={{ margin: 5 }}><AquilaCamState cam={cam} /></div>)}
          </IonRow>
        </IonLabel>
        <IonRow>
          <IonButton size='default' style={{ width: 80 }} onClick={() => set_aquila_light({ data: true })} >ON</IonButton>
          <IonButton size='default' style={{ width: 80 }} onClick={() => set_aquila_light({ data: false })} >OFF</IonButton>
        </IonRow>
      </IonItem>
    </>
  )
}

const PlantDetectionConfig: React.FC<any> = ({ }: any) => {
  const { developer } = useDeveloper()
  const { setParams, deleteParams, state, error, resultingParams, } = useOverrideParams()
  const callService = useCallService()
  const availableRulesJson = useTopic("/main/param_server/available_rules_json", "std_msgs/String")
  const availableRules = availableRulesJson ? JSON.parse(availableRulesJson?.data) : null
  if (error) { return <Message error={true}>{error}</Message> }

  return <>
    <RuleSelectorSimple
      ruleId={state.rule_id}
      developer={developer}
      setRuleId={(rule_id: number) => callService("/main/param_server/set_rule", "weeding_param/SetRule", { rule_id: rule_id })}
      rules_by_crop={[{ id: state.crop_id, param_config_rules: availableRules }]} cropId={state.crop_id} />
    <CameraHeightSetting resultingParams={resultingParams} setParams={setParams} deleteParams={deleteParams} />
    {developer && <AquilaLight />}
  </>
}


const ParamsConfig: React.FC<any> = ({ }: any) => {
  const { setParams, deleteParams, state, error, resultingParams, overrideParams } = useOverrideParams()

  const [newKey, setNewKey] = useState("")
  const [newValue, setNewValue] = useState<any>(null)
  useEffect(() => {
    if (!resultingParams) { return }
    const correspondingValue = resultingParams[newKey]
    if (correspondingValue !== null) {
      setNewValue(correspondingValue)
    }
  }, [newKey])

  if (error) { return <Message error={true}>{error}</Message> }

  return <>
    {state.error.length > 0
      ? <IonItem color="danger">Error: {state.error}</IonItem>
      : <IonItem>
        {`Config #${state.recommended_param_config_id} (field:${state.field_id} => crop:${state.crop_id} | rule:${state.rule_id})`}
      </IonItem>
    }
    {state.last_update.length > 0 && <IonItem>Last update: {state.last_update}</IonItem>}

    {state.error.length < 1 && <>
      {Object.keys(overrideParams)?.map((k: string) => <IonItem>
        <IonLabel position="stacked">{k}</IonLabel>
        <OverrideParamMemo k={k} initialValue={overrideParams[k]} setParam={(k: string, v: any) => setParams({ [k]: v })} />
        <IonButton slot="end" fill="clear" onClick={() => deleteParams([k])}><IonIcon icon={close} slot="icon-only" /></IonButton>
      </IonItem>)}

      <>
        <IonItem style={{ maxHeight: "100px" }}>
          {resultingParams && <SearchableSelect name="Available params"
            onChange={(key: string) => { setNewValue(null); setNewKey(key) }} value={newKey} closeOnChange={true} multiple={false}
            options={Object.keys(resultingParams).map((k: string) => [k, `${k}: ${resultingParams[k]}`])} />}
        </IonItem>
        <IonItem>
          <IonItem>
            <IonInput placeholder='Key' value={newKey} onIonChange={(e: any) => setNewKey(e.detail.value)} />
          </IonItem>
          <IonItem>
            <IonSelect value={typeof (newValue)} onIonChange={(e: any) => {
              if (e.detail.value == typeof (newValue) || newValue === null || e.detail.value === "object") { return }
              else if (e.detail.value === "number") { setNewValue(0.) }
              else if (e.detail.value === "boolean") { setNewValue(false) }
              else if (e.detail.value === "string") { setNewValue("") }
            }}>
              {["number", "boolean", "string"].map((t: string) => <IonSelectOption key={t}>{t}</IonSelectOption>)}
            </IonSelect>
          </IonItem>
          <IonItem>
            <OverrideParamMemo k={newKey} alwaysShowSetButton={true} initialValue={newValue} setParam={(k: string, v: any) => {
              setParams({ [k]: v })
              setNewValue(null)
              setNewKey("")
            }} />
          </IonItem>
        </IonItem>
      </>
    </>}
  </>


}


const BagRecorderConfig: React.FC<any> = ({ }: any) => {
  const { setParams, error, resultingParams, } = useOverrideParams()
  if (error) { return <Message error={true}>{error}</Message> }
  return <>
    <ParamSetter paramKey={"/periodic_interval_checker/pipeline_eval/period"} defaultValue={3600} resultingParams={resultingParams} setParams={setParams}
      component={({ tempValue, setParam }: any) => <IonItem lines="none">
        <IonLabel>Record pipeline_eval bags</IonLabel>
        <SearchableSelect value={tempValue} onChange={setParam} closeOnChange={true}
          options={[60, 10, 2, 0].map((minutes: any) => [minutes * 60, `Every ${minutes} minutes`])} />
      </IonItem>} />
    <ParamSetter paramKey={"/pipeline_eval/bag_length/period"} defaultValue={30} resultingParams={resultingParams} setParams={setParams}
      component={({ tempValue, setParam }: any) => <IonItem lines="none">
        <IonLabel>Pipeline_eval bag length</IonLabel>
        <SearchableSelect value={tempValue} onChange={setParam} closeOnChange={true}
          options={[60, 45, 30].map((seconds: any) => [seconds, `${seconds} seconds`])} />
      </IonItem>} />
  </>
}


const AboutConfig: React.FC<any> = ({ }: any) => {
  const swVersionInfo = useTopic("/main/version_info", "fr_version_msgs/VersionInfo")
  // format: <release><-m>
  // <release>: current release of farming-gt-meta repo
  // <(modified)>: if files in some of the repos were modified compared to the git version
  // if other branches of the sub-repos of farming-gt-meta are checked out, "based on <release>"
  const releaseDesc = swVersionInfo ?
    ((swVersionInfo.repos_match_release ? `${swVersionInfo.last_release_name}` : `${t("based on")} ${swVersionInfo.last_release_name}`)
      + (swVersionInfo.files_modified ? (" (" + t("modified") + ")") : ""))
    : t("unknown")

  // TODO (daniel) ntrip client interface should be unified between bynav and ublox
  const ntripInfoMsgUblox = useTopic("/ublox/base/ntrip_info", "nmea_navsat_driver/NtripInfo")
  const ntripInfoMsgBynav = useTopic("/bynav/ntrip_info", "bynav_driver/NtripInfo")
  const ntripInfoMsg = ntripInfoMsgBynav ? ntripInfoMsgBynav : ntripInfoMsgUblox;
  const ntripAccountDesc = ntripInfoMsg ? `${ntripInfoMsg.username}@${ntripInfoMsg.caster_url}` : t("unknown")

  return <>
    <IonItem lines="none">
      <IonIcon slot={"start"} icon={cog} />
      <IonLabel>{t('Software Version: {{releaseDesc}}', { releaseDesc: releaseDesc })}</IonLabel>
    </IonItem>
    <IonItem lines="none">
      <IonIcon slot={"start"} icon={globe} />
      <IonLabel>{`${t("Ntrip configuration")}: ${ntripAccountDesc}`}</IonLabel>
    </IonItem>
  </>
}

const LidarCalibrationSection: React.FC<any> = ({ }: any) => {
  const aquilaMapping = useParam("/aquilas")?.value
  const status = useTopic("/main/lidar_calibrator/status", "height_estimation_msgs/LidarCalibrationStatus")
  // TODO add functionality to check for lidar obstructions

  // TODO: this is kind of ugly. maybe create the description string in the ROS side
  const statusToString = (status_msg: any) => {
    switch (status_msg.status) {
      case 0:
        return "idle"
      case 1:
        const ratio = (status_msg.num_total_scans_to_collect > 0) ?  ((status_msg.num_scans_collected / status_msg.num_total_scans_to_collect) * 100.).toFixed(0) : 0
        return `collecting scans... ${ratio}%`
      case 2:
        return "calculating angular offset"
      case 3:
        return "waiting for z offset calibration"
      case 4:
        return "succeeded"
      case 5:
        return "failed! cause: " + status_msg.error_message
      default:
        return "unknown"
    }
  };
  const timestampToDate = (ts: any) => {
    const d = (new Date(ts.secs * 1000))
    const cutoffdate = new Date(2020, 1, 1)
    if (d < cutoffdate) {
      return "-"
    } else {
      return d.toLocaleString()
    }
  }

  const start_calibration = usePublisher("/main/lidar_calibrator/start_calibration", "std_msgs/Empty")

  return <>
    <h1>Lidar Calibration</h1>
    {status?.per_lidar_status?.sort((a: any, b: any) => a.position.localeCompare(b.position)).map((msg: any) => <>
      <IonItem>
        <IonLabel><b>{msg.position} {msg.position in aquilaMapping && `(aql${aquilaMapping[msg.position]})`}</b></IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>Status: {statusToString(msg)}</IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>Last calibrated: {timestampToDate(msg.stamp)}</IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>Distance to ground: {(msg.dist_to_ground_m * 100).toFixed(2)}cm</IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>Angular offset: {((msg.angular_offset_rad * 180) / Math.PI).toFixed(2)}°</IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>Height offset: {(msg.z_offset_m * 100).toFixed(2)}cm</IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>Point fit quality (inlier ratio): {(msg.inlier_ratio * 100).toFixed(2)}%</IonLabel>
      </IonItem>
    </>
    )
    }
    <IonItem>
      To calibrate the Lidar sensors, please put the robot on a flat surface. The ground should be parallel to the robot body.
    </IonItem>
    <IonButton onClick={_ => {
      start_calibration({})
    }} disabled={!status}>Calibrate</IonButton>

  </>
}


const LightsConfig: React.FC<any> = ({ }: any) => {
  const { setParams, error, resultingParams, } = useOverrideParams()
  if (error) { return <Message error={true}>{error}</Message> }
  return <ParamSetter paramKey={"/main/light_manager/headlights_on"} defaultValue={false} resultingParams={resultingParams} setParams={setParams}
    component={({ tempValue, setParam }: any) => <IonItem lines="none">
      <IonLabel>{t("headlights")}</IonLabel>
      <IonToggle checked={tempValue} slot="end"
        onIonChange={(e: any) => setParam(e.detail.checked)} />
    </IonItem>} />
}

const ConnectionType: React.FC<any> = ({ }: any) => {
  return <IonItem lines='full'>
    {accessType === AccessType.AppDevServer && <IonIcon slot="start" icon={codeOutline} style={{ pointerEvents: 'none' }} />}
    {accessType === AccessType.LocalHttp && <IonIcon slot="start" icon={lockOpenOutline} style={{ pointerEvents: 'none' }} />}
    {accessType === AccessType.LocalHttps && <IonIcon slot="start" icon={wifiOutline} style={{ pointerEvents: 'none' }} />}
    {accessType === AccessType.RemoteHttps && <IonIcon slot="start" icon={globeOutline} style={{ pointerEvents: 'none' }} />}
    {accessType === AccessType.VPN && <IonBadge slot="start">VPN</IonBadge>}
    <IonLabel>{t("Connection type")}</IonLabel>
    <IonLabel style={{ textTransform: 'capitalize' }} slot='end'>{getAccessTypeName(accessType)}</IonLabel>
  </IonItem>
}


const sections = [
  { name: "Autonomous Driving", icon: barbell, c: () => <DriveConfig /> },
  { name: "Sprayer", icon: flask, c: () => <SprayerConfig />, checkFunc: ({ hasSprayer }: any) => hasSprayer },
  { name: "Section control", icon: statsChart, c: () => <SectionControlConfig /> },
  { name: "Rain sensor", icon: rainy, c: () => <RainConfig /> },
  { name: "Plant detection", icon: eye, c: () => <PlantDetectionConfig /> },
  { name: "Safety distance", icon: shield, c: () => <OvalParams /> },
  { name: "Live Plot", devOnly: true, icon: statsChart, c: () => <LivePlotSection /> },
  { name: "Talpa Calibration", devOnly: false, icon: build, c: () => <TalpaCalibrationSection /> },
  { name: "Lidar Calibration", devOnly: false, icon: build, c: () => <LidarCalibrationSection /> },
  { name: "Endurance Test", devOnly: true, icon: infinite, c: () => <EnduranceTestSection /> },
  { name: "Diagnostics", devOnly: true, statusIcon: true, c: () => <DiagnosticSection /> },
  { name: "Band Width Statistics", devOnly: true, icon: speedometerOutline, c: () => <BandwidthStatsSection /> },
  { name: "Bag recorder", devOnly: true, c: () => <BagRecorderConfig /> },
  { name: "Params", devOnly: true, c: () => <ParamsConfig /> },
  { name: "Lights", icon: flashlightOutline, c: () => <LightsConfig /> },
  { name: "Power Tests", devOnly: true, icon: power, c: () => <AdditionalServicesButton /> },
  { name: "System", icon: informationCircle, c: () => <AboutConfig /> }
]


const Section: React.FC<any> = ({ name, children }: any) => {
  const { t } = useTranslation()
  const section = sections.find((s: any) => s.name === name)
  return <IonItemGroup>
    <IonItemDivider sticky color="secondary" style={{ alignItems: "center", fontSize: "larger" }}>
      {section?.icon && <IonIcon icon={section.icon} style={{ fontSize: "larger" }} slot="start" />}
      <IonLabel>
        {section?.devOnly ? name : t(name) /* No need to translate if devOnly */}
      </IonLabel>
    </IonItemDivider>
    {children}
  </IonItemGroup>
}


export const ConfigSection: React.FC<any> = ({ backButton }: any) => {
  const { developer, setDeveloper } = useDeveloper()
  const { t } = useTranslation()

  const [sectionName, setSectionName] = useState<any>(null)  // null: no section picked, 'all': all must be shown

  // Check is sprayer should be shown
  const taurusConfigs = useParam("/robot_config/taurus_configs")?.value || []
  const hasSprayer = taurusConfigs[0]?.taurus?.sprayer_version
  const status = useTopic("/diagnostics_toplevel_state", "diagnostic_msgs/DiagnosticStatus")
  const level = status?.level !== undefined ? status?.level : 2  // 2: error
  const icons = [checkmarkCircle, warningOutline, alertCircle, cloudOffline]

  const contentRef = useRef<any>(null);

  const scrollToTop = () => {
    contentRef.current.scrollToTop({ behavior: 'smooth' });
  };
  const callService = useCallService()
  const shutdown = () => callService("/shutdown_all_computers", "std_srvs/Empty", {})

  return <div>
    <div style={{ overflow: "auto", height: `${(window.visualViewport?.height || 1000) - 200}px` }}>
      {sectionName === null && <>
        {sections
          .filter(({ devOnly, checkFunc }: any) => (!devOnly || developer) && (!checkFunc || checkFunc({ hasSprayer })))
          .map(({ devOnly, name, icon, statusIcon }: any) => <IonItem detail button lines='full' key={name} onClick={() => setSectionName(name)}>
            {(icon || statusIcon) && <IonIcon slot="start" icon={statusIcon ? icons[level] : icon} />}
            {devOnly ? name : t(name)}
          </IonItem>)}

        <IonItem detail button lines='full' onClick={() => { setSectionName("all"); scrollToTop() }}>
          {t("Scroll through all settings")}
        </IonItem>

        <ConnectionType />

        <IonItem lines="none">
          <IonIcon slot={"start"} icon={colorWand} />
          <IonLabel>{t("Developer mode")}</IonLabel>
          <IonToggle checked={developer} onIonChange={(e: any) => setDeveloper(!developer)} slot="end" />
        </IonItem>

      </>}

      {sectionName !== null && <>
        {sections
          .filter(({ name, devOnly, checkFunc }: any) => (sectionName === "all" || sectionName == name) && (!devOnly || developer) && (!checkFunc || checkFunc({ hasSprayer })))
          .map(({ name, c }: any) => <Section name={name} key={name}>
            <div style={{ marginBottom: "30px" }}>
              {c && c()}
            </div>
          </Section>)}
      </>}
    </div>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
      {sectionName !== null
        ? <>
          <BackButton onClick={() => setSectionName(null)} />
          <PauseButton hidePlay={true} />
        </>
        : <>
          {backButton}
          <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
            <ConfirmButton onClick={shutdown} text={<>
              <IonIcon icon={power} slot="end" />
              {t("Shutdown")}
            </>}>{t("Shutdown")}</ConfirmButton>
            <AdditionalServicesButton fill="solid" />
          </div>
          <PauseButton hidePlay={true} />
        </>}
    </div>
  </div>
}
