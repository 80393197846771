import { IonIcon, IonButton } from '@ionic/react';
import React from 'react';
import { locationOutline } from 'ionicons/icons';

const CoordinatesButton: React.FC<any> = ({coordinates, icon=locationOutline, ...props}) => {
    props.fill = props.fill || "clear"
    props.color = props.color || "secondary"

    return <IonButton href={`https://www.google.com/maps?q=${coordinates.latitude},${coordinates.longitude}`} color="secondary" {...props}>
        <IonIcon slot="icon-only" icon={icon}/>
    </IonButton>
};

export default CoordinatesButton;
