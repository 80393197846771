// Konva does not pass contexts: use MultiContextProvider within Konva element to pass a context to children
import { useContext } from "react"


export const ContextProvider: React.FC<any> = ({context, children}) => {
  const value = useContext(context)
  return <context.Provider value={value}>{children}</context.Provider>
}


export const MultiContextProvider: React.FC<any> = ({contexts=[], children}) => {
  const firstContext: any = contexts.pop(0)
  if (firstContext) {
    return <MultiContextProvider contexts={contexts} key={contexts.length}>
      {children}
    </MultiContextProvider>
  }
  else {
    return children
  }
}