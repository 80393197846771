import { IonButton, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonList, IonMenu, IonModal, IonPage, IonProgressBar, IonSelect, IonSelectOption, IonSplitPane, IonToggle, } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import Toolbar from '../../components/Toolbar'
import { gql } from '@apollo/client';
import { useMutationWithElevatedRole, useQueryWithElevatedRole } from '../../hooks/hasuraHooks';
import { Pagination, usePagination } from '../../components/Pagination';
import { BagFilter, useBagFilter } from '../../components/apiFilters';
import { CircleMarker, MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents } from 'react-leaflet'
import { Icon,LatLng, Layer, point } from 'leaflet'
import { Polyline } from 'react-leaflet';
import { BAG_FRAGMENT, BagItem, PlantVarietySelect, useAddTagsMutation } from '../../components/BagItem';
import { AddEvaluation, EvaluationItemFromId } from '../../components/EvaluationItem';
import 'leaflet/dist/leaflet.css';
import CopyButton from '../../components/CopyButton';
import { TagPicker } from '../../components/TagPicker';
import Bags, { takeNBagsPerDayAndRobot } from './Bags';
import './BagOnField.css';
import { bag, expand, star } from 'ionicons/icons';
import { Circle } from 'react-konva';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import { useGeomanControls } from 'react-leaflet-geoman-v2'
import { saveSharp, eye } from 'ionicons/icons';


const myIcon = new Icon({
  iconUrl: "https://app.farming-revolution.com/assets/icon/favicon.png",
  iconSize: [32,32]
 })

function ChangeSelectedAreas({e, setSelectedAreas}: any)
{
  const vs = e.layer._latlngs[0].map((element: any) => {
    return Object.values(element)
  })
  setSelectedAreas((prev: any) => {
    prev[e.layer._leaflet_id] = vs
    return {...prev}
  })
}

function Drawing({setSelectedAreas}: any) {
  useGeomanControls({
    options: { drawText: true, drawCircle: true, drawPolyline: false, drawMarker: false, drawCircleMarker: false },
    onCreate: (e) => {ChangeSelectedAreas({e, setSelectedAreas})},
    onEdit: (e) => {ChangeSelectedAreas({e, setSelectedAreas})},
    onLayerRemove: (e) => {setSelectedAreas((prev: any) => {
      delete prev[(e.layer as any)._leaflet_id]
      return {...prev}
    })}
  })
  return null
}

function MarkerOverlay({start, ende, bag, detailView}: {start: LatLng, ende: LatLng, bag: any, detailView: any})
{
  const map = useMap()
  const [color, setColor] = useState('lime')
  const limeOptions = { color: color,
                        opacity: 0.3}
  if(detailView)
  {
    return (
      <div>
        <CircleMarker center={start} radius={1} color={'#3188ff'} interactive={false}/>
        <CircleMarker center={ende} radius={1} color={'#3188ff'} interactive={false}/>
        <Polyline  pathOptions={limeOptions} lineCap={'butt'} positions={[start, ende]} weight={5} interactive={false}/>
      </div>
    )
  }
  return(
    <Marker icon={myIcon} position={start}>
    </Marker>
  )
}


function BagOverlay({bag, setBagIdFilter, detailView}: any)
{
  const start = new LatLng(bag.start_latitude, bag.start_longitude)
  const ende = new LatLng(bag.end_latitude, bag.end_longitude)

  return(
  <div>
    <MarkerOverlay start={start} ende={ende} bag={bag} detailView={detailView}/>
  </div>
  )
}

function changedMap(map: any, setDetailView: any)
{
  if(map.getZoom()> 15)
  {
    setDetailView(true)
  }
  else
  {
    setDetailView(false)
  }
}

function BagsOverlay({setBagIdFilter, setBagFilter, bagFilter, is_map_visible}: {setBagIdFilter: any, setBagFilter: any, bagFilter: any, is_map_visible: boolean})
{
  const pagination = usePagination(4000)
  const fetchVariables = {...pagination.state, bagFilter}
  const [selectedAreas, setSelectedAreas] = useState<{[key: string]: []}>({})
  const { loading, data, refetch } = useQueryWithElevatedRole(gql`
    query Bags ($bagFilter: bags_bool_exp!, $limit: Int!, $offset: Int!){
        bags (where: $bagFilter, limit: $limit, offset: $offset) {
          end_latitude
          start_latitude
          end_longitude
          start_longitude
          id
        }
        bags_aggregate (where: $bagFilter) {aggregate{count}}
    }
  `, {
    variables: fetchVariables,
  })

  useEffect(() => {
    refetch(fetchVariables)
  }, [bagFilter, pagination.state])

  useEffect(() => {
    setBagFilter((prev: any) => {
      prev.start_latitude = {_is_null: false}
      return {...prev}
    })
  }, [])

  useEffect(() => {
    const bags = data?.bags
    if(bags != undefined)
    {
      var pointInPolygon = require('point-in-polygon');
      var bagInside: any[] = []
      for (var i = 0; i < bags.length; i++) {
        for(const key in selectedAreas)
        {
          if (true == pointInPolygon([bags[i].start_latitude, bags[i].start_longitude], selectedAreas[key]))
          {
            bagInside.push(bags[i].id)
          }
        }
      }
      setBagIdFilter((prev: any) => {
        prev.id = {_in: bagInside}
        return {...prev}
      })
    }
    
  }, [selectedAreas, data?.bags])

  const [detailView, setDetailView] = useState(false)

  const map = useMapEvents({
    zoomend: () => { changedMap(map, setDetailView) 
      console.log('bags_aggregate', data?.bags_aggregate)
    },
  })

  if (data && data.bags.length > 0) {
    return(
      <div>
        {data.bags.map((bag: any) => <BagOverlay key={bag.id} bag={bag} detailView={detailView} />)}
        <Drawing setSelectedAreas={setSelectedAreas}/>
      </div>
    )
  }
  else
  {
    return(<>no bags</>)
  }
}

function ComponentResize()
{
  const map = useMap()

  setTimeout(() => {
      map.invalidateSize()
  }, 0)

  return null
}

function BagOnField(): any
{
  // Special mode: all bags are sorted by taurus and date and N bags are picked (very slow fetch)
  const takeNBagsOption = "N Bags / GT / day"
  const orderByOptions = [
    ["Last uploaded", {id: "desc"}],
    ["Newest", {started_recording_at: "desc"}],
    ["Oldest", {started_recording_at: "asc"}],
    ["Least tags", {bag_tags_aggregate: {count: "asc"}}],
    [takeNBagsOption, {taurus_id: "asc", started_recording_at: "asc"}]
  ]
  const [bagFilter, setBagFilter] = useBagFilter()
  const [bagIdFilter, setBagIdFilter] = useBagFilter()
  const [showBulkAddEvaluation, setShowBulkAddEvaluation] = useState(false)
  const [orderByType, setOrderByType] = useState(orderByOptions[0][0])
  const [nBagsPerGroup, setNBagsPerGroup] = useState<number>(3)
  const [takeNBagsSkipTagged, setTakeNBagsSkipTagged] = useState(false)  // Use to find un-tagged bags
  const orderByOption = orderByOptions.find((e: any) => e[0] === orderByType)
  const orderBy: any = orderByOption ? orderByOption[1] : {}
  const pagination = usePagination()
  const [is_map_visible, setIsMapVisible] = useState(true)

  const takeNBags = orderByType === takeNBagsOption

  useEffect(() => {
    if (takeNBags) {
      pagination.setState((prev: any) => ({...prev, limit: 10000}))  // No limit when taking n bags
    }
  }, [takeNBags])

  const fetchVariables = {bagIdFilter, orderBy, ...pagination.state}

  const { loading, data, refetch } = useQueryWithElevatedRole(gql`
    ${BAG_FRAGMENT}
    query Bags ($bagIdFilter: bags_bool_exp!, $limit: Int!, $offset: Int!, $orderBy: [bags_order_by!]!){
        bags (order_by: $orderBy, where: $bagIdFilter, limit: $limit, offset: $offset) {
          ...BagFields
        }
        bags_aggregate (where: $bagIdFilter) {aggregate{count}}
    }
  `, {
    variables: fetchVariables,
  })
  
  useEffect(() => {
      refetch(fetchVariables)
  }, [bagIdFilter, orderBy, pagination.state])

  const [createScenarios] = useMutationWithElevatedRole(gql`
    mutation InsertScenarios($objects: [scenarios_insert_input!] = []) {
      insert_scenarios(objects: $objects) {
        returning {
          id
        }
      }
    }
  `)

  const [updateBags] = useMutationWithElevatedRole(gql`
    mutation UpdateBags($where: bags_bool_exp = {}, $_set: bags_set_input = {}) {
      update_bags(where: $where, _set: $_set) {
        affected_rows
      }
    }
  `)

  const createBulkScenarios = () => createScenarios({variables: {
    objects: bagsWithNoScenarios?.map((bag: any) => ({bag_id: bag.id, start: 0}))
  }})
  const labelAllBagsCommand = `rosrun weeding_recording prepare_annotation.py ${data?.bags?.map(({id}: any) => id)?.join(" ")}`
  const [addTags] = useAddTagsMutation()

  // takeNBags mode has to post-process the list of bags
  const bags = takeNBags ? takeNBagsPerDayAndRobot(data?.bags, nBagsPerGroup || 1, takeNBagsSkipTagged) : data?.bags
  const bagsWithNoScenarios = bags?.filter((b: any) => b?.scenarios?.length < 1)
  const totalBags = takeNBags ? bags?.length : data?.bags_aggregate?.aggregate?.count

  var splitpane = useRef(null)

  function toggleMenu()
  {
    if(splitpane.current)
    {
      const tmp = splitpane.current as any
      tmp.classList.toggle('split-pane-visible')
      setIsMapVisible(tmp.classList.contains('split-pane-visible'))
    }
  }



  return (
    <IonPage>
      <Toolbar>
        <div style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}>
          <BagFilter bagFilter={bagFilter} setBagFilter={setBagFilter}/>

          <IonItem color="transparent">
            <IonLabel position="stacked">Sort</IonLabel>
            <IonSelect value={orderByType} onIonChange={(e: any) => setOrderByType(e.detail.value)}>
              {orderByOptions.map(([key, _]: any[])=> <IonSelectOption value={key} key={key}>{key}</IonSelectOption>)}
            </IonSelect>
          </IonItem>
          {takeNBags && <>
            <IonItem color="transparent">
              <IonLabel position="stacked">N bags</IonLabel>
              <IonInput value={nBagsPerGroup} type="number" min="1" max="100" step="1" onIonChange={(e: any) => setNBagsPerGroup(parseInt(e.detail.value))}/>
            </IonItem>
            <IonItem color="transparent">
              Only bags with no tags<IonToggle checked={takeNBagsSkipTagged} onIonChange={(e: any) => setTakeNBagsSkipTagged(e.detail.checked)}/>
            </IonItem>
          </>}
        </div>
      </Toolbar>
      <IonContent>
        <IonSplitPane className={"half-screen"} when="xl" style={{height: "100%"}} contentId="maintest" ref={splitpane} >
          <IonMenu contentId="maintest" menu-id="map">
            <MapContainer center={[50.89731, 9.19161]} zoom={5} maxZoom={25} scrollWheelZoom={true} style={{height:"100vh", width: "100vw"}}>
              <ComponentResize/>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <BagsOverlay setBagIdFilter={setBagIdFilter} bagFilter={bagFilter} setBagFilter={setBagFilter} is_map_visible={is_map_visible}/>
            </MapContainer>
          </IonMenu>
          <IonContent style={{height:"100vh", width: "100vw"}} id="maintest">
            {loading && <IonProgressBar type="indeterminate"/>}
            {bags && <>
              <IonModal isOpen={showBulkAddEvaluation !== false} onDidDismiss={() => setShowBulkAddEvaluation(false)}>
                <AddEvaluation
                  bags={bags}
                  onClose={() => setShowBulkAddEvaluation(false)}
                />
              </IonModal>
              <IonItem >
                <IonButton onClick={()=>{toggleMenu()}}>
                  <IonIcon icon={expand}></IonIcon>
                </IonButton>
                <IonButton color="secondary" onClick={() => setShowBulkAddEvaluation(true)} fill="outline">Evaluate all bags</IonButton>
                {bagsWithNoScenarios?.length > 0 && <IonButton expand="block" fill="outline" onClick={createBulkScenarios}>
                  Create default scenario for bags with no scenario
                </IonButton>}
                <TagPicker name={"Add tag to all bags"} onChange={(tag_id: number) => addTags({variables: {objects: bags?.map(({id}: any) => ({bag_id: id, tag_id: tag_id}))}})}/>
              
                <PlantVarietySelect plant_variety_id={null} text="Set crop for all bags"
                  onChangePlantVariety={(plant_variety_id: number) => updateBags({variables:
                    {where: {id: {_in: bags.map(({id}: any) => id)}}, _set: {plant_variety_id: plant_variety_id}}})}/>
                <CopyButton text={labelAllBagsCommand}>
                  Label all bags
                </CopyButton>
              </IonItem>

              <Pagination numItems={bags?.length} itemType={"bags"} {...pagination} totalItems={totalBags}/>
              <IonList>
                {bags.map((bag: any) => <BagItem key={bag.id} bag={bag} AddEvaluation={AddEvaluation} EvaluationItemFromId={EvaluationItemFromId}/>)}
              </IonList>

              <Pagination numItems={bags?.length} itemType={"bags"} {...pagination} totalItems={totalBags}/>
              </>}
          </IonContent>
        </IonSplitPane>
      </IonContent>
    </IonPage>
  );
};

export default BagOnField;
