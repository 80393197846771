import React, { useEffect, useState } from 'react';
import {
  IonInput,
} from '@ionic/react';


export const NumberInput: React.FC<any> = ({value, onChange, min=0, max=null, isFloat = false, decimals = 1, style, ...props}: any) => {
  // Keep valueString and only update value if string is not empty
  props.type = "number"
  props.step = props.step || (isFloat ? "0.5" : "1")
  const [valueString, setValueString] = useState<string>("")
  const invalid = valueString.length == 0 || ((parseFloat(valueString) || 0) < min) || (max !== null && (parseFloat(valueString) || 0) > max)
  useEffect(() => {
    if (invalid) {
      // Do nothing: empty string
    }
    else if (isFloat) {
      const newValue = parseFloat(valueString) || 0.0
      if (newValue !== value) {
        onChange(newValue)
      }
    }
    else {
      const newValue = parseInt(valueString) || 0
      if (newValue !== value) {
        onChange(newValue)
      }
    }
  }, [valueString])
  useEffect(() => {
    if (typeof(value) === "string") {
      setValueString(value)  // FIXME: this is only required for 'old' configs where some numbers are saved as strings
    }
    else if (typeof(value) !== "number") {
      setValueString("0")
    }
    else if (isFloat) {
      setValueString(value.toFixed(decimals))
    }
    else {
      setValueString(value.toFixed(0))
    }
  }, [value])
  props.max = max
  return <>
    <IonInput min={`${min}`} style={{background: invalid ? "rgba(250,100,100,0.5)": undefined, ...style}} value={valueString} {...props} onIonChange={(e: any) => setValueString(e.detail.value)}/>
  </>
}