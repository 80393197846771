import { IonButton, IonCard, IonIcon, IonItem, IonLabel, IonList, IonSelect, IonSelectOption, IonSpinner } from '@ionic/react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Layer, Line, Stage, Text } from 'react-konva';
import { OverrideParam, minusPlusWidget, numSliderWidget, singleChoiceWidget } from '../../utils/overrideParamWidget';
import { RosHandleContext, useCallService, useParam, useTopic } from '../../hooks/rosHooks';
import { useTranslation } from 'react-i18next';
import { TalpaConfig, AquilaConfig, SideOfRow } from '../../utils/RobotConfigTypes';
import ROSLIB from 'roslib';
import { trash } from 'ionicons/icons';
import { SdoState, UpdateWatchedSdoRequest, UpdateWatchedSdoResponse } from '../../utils/canopen_override_types';
import { t } from 'i18next';

const MAX_HISTORY_SIZE = 100;

function loading () {
    return <p>loading...</p>
}

interface TalpaEntry {
    camera_namespace : string,
    side_of_row : SideOfRow,
    row_namespace : string
}

export interface Time {
    secs:  number;
    nsecs: number;
}

type Chopper = any;
type Lifter = any;

interface MotorData {
    desired : number,
    actual : number
}

interface Swivel {
    angle: MotorData,
    talpa_id : number,
    swivel_is_left_side : boolean    
}

interface MotorsStamped {
    swivels : Swivel[];
    choppers : Chopper[];
    lifters: Lifter[];
    stamp: Time
}

interface StampedSwivelAngles {
    gear_angle: number[],
    time: Time[]
}

function rad2deg(r: number) : number {
    return 180 * r / Math.PI;
}

function deg2rad(d: number) : number {
    return Math.PI * d / 180;
}

function to_sec(t : Time) {
    return t.secs + t.nsecs * 1e-9
}

export interface DriveModeInfo {
    stamp: {
        secs:  number;
        nsecs: number;
    };
    mode:                                 string;
    sub_mode:                             string;
    warning:                              string;
    would_work_if_talpas_manually_lifted: boolean;
}

const Y_SCALE = 50;
const ANGLES_SCALE = [0, 15, 30, 45, 60, 75, 90];
const SCALE_LINES = ANGLES_SCALE.map(angle_deg=>{
    const y = 190 - (deg2rad(angle_deg) * Y_SCALE);
    return <Line points={[30, y, 300, y]} stroke="#444" dash={[1, 2]}/>
});
const SCALE_TEXT = ANGLES_SCALE.map(angle_deg=>{
    const y = 185 - deg2rad(angle_deg)*Y_SCALE;
    return <div style={{ left: '5px', top: `${y}px`, position: 'absolute', color: 'white', fontSize: '10px', width: '20px', height: '10px', textAlign: 'right', lineHeight: 1, textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black', backgroundColor: 'rgba(0,0,0,.5)'}}>{angle_deg} °</div>
});

interface TopicExt<T> extends ROSLIB.Topic<T> {
    ros : ROSLIB.Ros
}

interface CanopenInfo {
        available_sdos: [string, string][][],
        motor_ids: number[],
        motor_names: string[]
}

interface WatchedSdo {
    motor_id : number,
    sdo_name : string,
    value? : string,
    should_set : boolean,
    previous_value? : string,
}

const CanOpenParametersWidget : React.FC<{ talpa_entry : TalpaEntry}> = ({talpa_entry}) => {

    const [selectedMotorId, setSelectedMotorId] = useState<number|null>(null);
    const [sdoSearchInput, setSdoSearchInput] = useState("");
    const sdoSearchInputRef = useRef<HTMLInputElement>(null);
    const sdoSearchAutocompleteUlRef = useRef<HTMLUListElement>(null);
    const [selectedAutocompleteIdx, setSelectedAutocompleteIdx] = useState<number|null>(null);

    const inFlightRef = useRef(0);
    const [inFlight, setInFlight] = useState(0);

    const callService = useCallService();

    const info_json = useTopic<{data: string}>(`/${talpa_entry.camera_namespace}/canopen_info`, "std_msgs/String");
    const watched_sdos_json = useTopic<{data: string}>(`/${talpa_entry.camera_namespace}/watched_sdos`, "std_msgs/String");
    
    if (info_json === undefined || watched_sdos_json === undefined) {
        console.log("loading");
        return loading()
    }

    const info : CanopenInfo = JSON.parse(info_json.data);
    const watched_sdos : WatchedSdo[] = JSON.parse(watched_sdos_json.data);

    Object.assign(window, {info, watched_sdos, selectedMotorId})

    function getMotorName(motor_id : number) : string {
        for (let idx = 0; idx < info.motor_ids.length; idx++) {
            if (info.motor_ids[idx] === motor_id) {
                return info.motor_names[idx]
            }
        }
        return "unknown motor"
    }

    let autocomplete_widget = <></>;
    let sdo_name_is_valid = false;
    const matches : [string, string][] = [];
    let available_sdos : [string, string][] = [];
    if (selectedMotorId !== null && sdoSearchInput !== "") {
        const search_input_lc = sdoSearchInput.toLowerCase();

        available_sdos = info.available_sdos[info.motor_ids.indexOf(selectedMotorId)]
        
        for (let [id, name] of available_sdos) {
            const name_lc = name.toLowerCase();
            if (name_lc.includes(search_input_lc) || id.includes(search_input_lc)) {
                matches.push([id, name]);
                if (name === sdoSearchInput) {
                    sdo_name_is_valid = true;
                }
            }
        }

        if (selectedAutocompleteIdx !== null && matches.length <= selectedAutocompleteIdx) {
            setSelectedAutocompleteIdx(matches.length-1)
        }

        if (matches.length > 0 && !sdo_name_is_valid) {
            autocomplete_widget = <ul ref={sdoSearchAutocompleteUlRef} style={{
                listStyleType: 'none',
                paddingLeft: 0,
                position: 'absolute',
                top: '100%',
                left: 0,
                width: '100%',
                background: 'white',
                border: '1px solid #dedede',
                maxHeight: '7em',
                overflowY: 'scroll',
                zIndex: 999900
            }}>
                {matches.map(([id, name], idx)=>{
                    return <li key={id} style={{
                        backgroundColor: (idx===selectedAutocompleteIdx)?'#ccc':'#fff',
                    }} onClick={_=>{
                        sdoSearchInputRef.current!.value = name;
                        setSdoSearchInput(name);
                    }}><pre style={{display: 'inline'}}>{id}</pre> {name}</li>
                })}
            </ul>
        }
    }

    function scrollSelectedIntoView(idx : number) {
        window.setTimeout(()=>{
            const OPTS : ScrollIntoViewOptions = {
                behavior: 'auto', block: 'nearest', inline: 'nearest'
            };
            if (sdoSearchAutocompleteUlRef.current !== null) {
                const css_idx = idx + 1;
                const selected_child = sdoSearchAutocompleteUlRef.current.querySelector(`li:nth-child(${css_idx})`);
                selected_child?.scrollIntoView(OPTS);
            }
        }, 0);
    }

    function callUpdateWatchedSdo(motor_name : string, sdo_name : string, state : SdoState, value_as_string : string | undefined) {
        inFlightRef.current += 1;
        setInFlight(inFlightRef.current);

        let req : UpdateWatchedSdoRequest = {
            motor_name,
            sdo_name,
            state,
            value_as_string: value_as_string || ''
        };
        
        callService(
            `/${talpa_entry.camera_namespace}/update_watched_sdo`, "weeding_canopen/UpdateWatchedSdo",
            req,
            (res : UpdateWatchedSdoResponse) => {
                inFlightRef.current -= 1;
                setInFlight(inFlightRef.current);
                if (!res.success) {
                    alert(res.message)
                }
            }
        );
    }

    function deleteWatchedSdo(motor_id : number, name : string) {
        callUpdateWatchedSdo(
            info.motor_names[info.motor_ids.indexOf(motor_id)],
            name, 
            SdoState.Delete,
            undefined
        );
    }

    function watchSdo(motor_id : number, sdo_name : string) {
        callUpdateWatchedSdo(
            info.motor_names[info.motor_ids.indexOf(motor_id)],
            sdo_name,
            SdoState.Watch,
            undefined
        )
    }

    function setSdo(motor_id : number, sdo_name : string, raw_value: string) {
        let value = raw_value || "0";
        try {
            value = parseInt(/0x([a-fA-F0-9]+)/.exec(value)![1], 16).toString()
        } catch (_) {
            // pass
        }
        callUpdateWatchedSdo(
            info.motor_names[info.motor_ids.indexOf(motor_id)],
            sdo_name,
            SdoState.Set,
            value
        )
    }

    const controls_enabled = inFlight === 0;

    return <div>

        <IonItem><IonLabel><strong>Configure SDOs</strong></IonLabel></IonItem>

        <IonItem>
            <IonLabel>Motor</IonLabel>
            <IonSelect interface="popover" placeholder='select motor' value={selectedMotorId} onIonChange={evt=>setSelectedMotorId(evt.detail.value)}>
                {info.motor_ids.map((motor_id, idx)=>{
                    const motor_name = info.motor_names[idx]!;
                    return <IonSelectOption key={motor_id} value={motor_id}><pre>{motor_id}</pre> {motor_name}</IonSelectOption>
                })}
            </IonSelect>
        </IonItem>

        <IonItem style={{overflow: 'visible'}}>
            <IonLabel>Property</IonLabel>
            <div style={{position: 'relative', flexGrow: 1}}>
                <input
                    disabled={!controls_enabled || selectedMotorId === null}
                    placeholder={selectedMotorId===null?"select motor first (above)":"search CANOpen properties…"}
                    type="text"
                    style={{width: '100%', background: sdo_name_is_valid?'lightgreen':'white'}}
                    onKeyUp={evt=>{
                        evt.preventDefault();
                        setSdoSearchInput((evt.nativeEvent.target as any ).value);
                    }}
                    onKeyDown={evt=>{
                        let idx;
                        switch (evt.key) {
                            case "ArrowDown":
                                idx = (selectedAutocompleteIdx===null?-1:selectedAutocompleteIdx) + 1;
                                setSelectedAutocompleteIdx(idx)
                                scrollSelectedIntoView(idx);
                                evt.preventDefault();
                                break;
                            case "ArrowUp":
                                idx = (selectedAutocompleteIdx===null?1:selectedAutocompleteIdx) - 1;
                                setSelectedAutocompleteIdx(idx);
                                scrollSelectedIntoView(idx);
                                evt.preventDefault();
                                break;
                            case "Tab":
                            case "Enter":
                                if (!sdo_name_is_valid) {
                                    const sel = matches[selectedAutocompleteIdx||0][1];
                                    setSdoSearchInput(sel)
                                    sdoSearchInputRef.current!.value = sel;
                                    evt.preventDefault();
                                }
                                break;
                            default:
                                break;
                        }
                    }}
                    ref={sdoSearchInputRef}
                />
                {autocomplete_widget}
            </div>
        </IonItem>
        
        <div style={{width: '100%', display: 'flex', borderBottom: '1px solid #dedede', margin: '.3em 16px', paddingBottom: '.3em', alignItems: 'center'}}>
            <IonButton disabled={controls_enabled && !sdo_name_is_valid && selectedMotorId !== null} onClick={_=>watchSdo(
                selectedMotorId!,
                sdoSearchInput,
            )}>Watch</IonButton>
            <IonButton disabled={controls_enabled && !sdo_name_is_valid} onClick={_=>{
                setSdo(
                    selectedMotorId!,
                    sdoSearchInput,
                    window.prompt("sdo value? (prefix with 0x for hex input)") || ""
                )
            }}>Set value</IonButton>
            {<IonSpinner style={{visibility: inFlight>0 ? "visible": "hidden", marginLeft: '1em'}}/>}
        </div>

        <table className='watched-sdos'>
            <tr>
                <th>ID</th>
                <th>motor name</th>
                <th>SDO name</th>
                <th>value</th>
                <th>{/* trash icon */}</th>
            </tr>
            {watched_sdos.map(watched_sdo=> {
                const value_as_float = Number.parseFloat(watched_sdo.value||"");
                const is_number = !isNaN((watched_sdo.value||"not a number") as any as number);
                const value_as_int = Number.parseInt(watched_sdo.value||"");
                let hex = null;
                if (is_number && value_as_float === value_as_int && value_as_int >= 0) {
                    hex = ` ( = 0x${value_as_int.toString(16)})`;
                }
                
                return <tr key={`${watched_sdo.motor_id}-${watched_sdo.sdo_name}`} style={{cursor: 'pointer'}} onClick={evt=>{
                    setSelectedMotorId(watched_sdo.motor_id);
                    sdoSearchInputRef.current!.value = watched_sdo.sdo_name;
                    setSdoSearchInput(watched_sdo.sdo_name);
                    sdoSearchInputRef.current!.focus();
                }}>
                    <td>{watched_sdo.motor_id}</td>
                    <td>{getMotorName(watched_sdo.motor_id)}</td>
                    <td>{watched_sdo.sdo_name}</td>
                    <td>{watched_sdo.value!==null?watched_sdo.value : '-'}{hex}{watched_sdo.previous_value!==null?(<i> (was: {watched_sdo.previous_value})</i>):(null)}</td>
                    <td><button style={{display: 'inline-flex', height: '3em', padding: '1em', alignItems: 'center'}} onClick={_=>{
                        deleteWatchedSdo(watched_sdo.motor_id, watched_sdo.sdo_name)
                    }}><IonIcon icon={trash} /> reset</button></td>
                </tr>
            })}
            {watched_sdos.length===0 && <tr><td colSpan={5}>(No SDOs selected)</td></tr>}
        </table>
        <hr style={{border: '1px solid #dedede'}}/>
    </div>
}

const TalpaPlot : React.FC<{tlp : TalpaEntry}> = ({tlp}) => {

    const history = useRef<{angles: MotorData[], ts: Time[]}>({angles: [], ts: []});
    const stamped_swivel_angles = useRef<null | StampedSwivelAngles>(null);
    const [last_msg, set_last_msg] = useState<any>();

    const [selected_talpa, set_selected_talpa] = useState<null | TalpaEntry>(tlp);
    const ros_state = useRef<any>(null);
    const ros = useContext(RosHandleContext);
    const { t } = useTranslation();

    function unsubscribe() {
        if (ros_state.current !== null) {
            ros_state.current.topic_motor_data.unsubscribe();
            ros_state.current.topic_stamped_swivel_angles.unsubscribe();
            history.current.angles = [];
            history.current.ts = []
        }
    }
    useEffect(() => {
        let ros_ = ros as any;
        let topic_motor_data : TopicExt<MotorsStamped> = new ros_.Topic({ros: ros, name: `/${tlp.camera_namespace}/motor_data`, messageType: "motor_msgs/MotorsStamped"});
        topic_motor_data.ros = ros!;
        topic_motor_data.subscribe((msg : MotorsStamped) => {
            let swivel = msg.swivels.find(s=>s.swivel_is_left_side==(selected_talpa?.side_of_row==SideOfRow.Left));
            history.current.angles = [...history.current.angles.slice(-(MAX_HISTORY_SIZE-1)), swivel?.angle!];
            history.current.ts = [...history.current.ts.slice(-(MAX_HISTORY_SIZE - 1)), msg.stamp];
            set_last_msg(swivel?.angle);
        });
        
        let topic_stamped_swivel_angles : TopicExt<StampedSwivelAngles> = new ros_.Topic({ros: ros, name: `/${tlp.camera_namespace}/planner_${tlp.row_namespace}_${tlp.side_of_row}/stamped_swivel_angles`, messageType: "stem_based_treatment_planner/StampedSwivelAngles"});
        topic_stamped_swivel_angles.ros = ros_;
        topic_stamped_swivel_angles.subscribe((msg : StampedSwivelAngles) => {
            stamped_swivel_angles.current = msg;
        });

        set_selected_talpa(tlp);
        ros_state.current = {topic_motor_data, topic_stamped_swivel_angles};        

        return unsubscribe
    }, [])

    const ts = history.current.ts.map(t=>t.secs+t.nsecs*1e-9);
    const tmax = ts[ts.length-1];

    const desired = history.current.angles.map(a=>a.desired);
    const actual = history.current.angles.map(a=>a.actual);

    let points_desired = [];
    let points_actual = [];
    for (let idx in ts) {
        const x = (300 - (tmax - ts[idx]) * 150);
        const y_desired = (190 - (desired[idx] * Y_SCALE));
        const y_actual =  (190 - (actual[idx] * Y_SCALE));
        points_desired.push(x, y_desired);
        points_actual.push(x, y_actual);
    }

    let points_ssa : number[] = [];
    const sig0e = stamped_swivel_angles.current?.time[0];
    const sig0 = sig0e?to_sec(sig0e):undefined;
    let x_t0 : number | undefined = undefined;
    for (let idx = 0; idx < (stamped_swivel_angles.current?.time.length || 0); idx++) {
        const t = stamped_swivel_angles.current!.time[idx];
        const s = stamped_swivel_angles.current!.gear_angle[idx];
        points_ssa.push(10+ (to_sec(t) - sig0!)*120);
        if (to_sec(t) > tmax && x_t0 === undefined) {
            x_t0 = points_ssa.slice(-1)[0];
        }
        points_ssa.push(80 - (s * 100));
    }
    (window as any)["points_ssa"] = points_ssa;

    return <div style={{flexDirection: "row", display: "flex", alignItems: "start", margin: '0 16px'}}>
        <div>
            <div style={{position: 'relative'}}>
                <Stage width={300} height={200} style={{backgroundColor: "black", width: "300px"}}>
                    <Layer>

                        {SCALE_LINES}

                        <Line points={points_desired} stroke="lightblue"/>
                        <Line points={points_actual} stroke="white"/>
                        <Line points={points_ssa} stroke="green" />
                        <Line points={[x_t0 || 0, 0, x_t0 || 0, 100]} stroke="red" />
                        <Line points={[0, 100, 300, 100]} stroke="white" />

                    </Layer>
                </Stage>
                {SCALE_TEXT /* use DOM nodes instead of canvas for text drawing due to obscure exceptions in firefox when konva tries to set a font inside the canvas */}
            </div>
            {last_msg && <pre>{`t = ${(tmax||0).toFixed(3)}
desired = ${rad2deg(last_msg?.desired).toFixed(2)} °
actual  = ${rad2deg(last_msg?.actual).toFixed(2)} °
    `}</pre>}
        </div>
    </div>
}

const LivePlotSection: React.FC<any> = ({}) => {

    const [selected_talpa, set_selected_talpa] = useState<null | TalpaEntry>(null);

    const {value : talpa_configs} = useParam<TalpaConfig[]>("/robot_config/talpa_configs");
    const {value : aquila_configs} = useParam<AquilaConfig[]>("/robot_config/aquila_configs");
    const {value : aquilas} = useParam<{[key:string]:number}>("/aquilas");

    const driveMode = useTopic<DriveModeInfo>(
        "/main/drive_mode_switch/info",
        "weeding_drive_mode_switch/DriveModeInfo",
        (msg)=>msg.mode
    );
    const is_in_endurance_test_mode = driveMode?.mode === 'test';
    console.log({is_in_endurance_test_mode, driveMode})

    if (talpa_configs === undefined || aquila_configs === undefined || aquilas === undefined) {
        return loading();
    }

    let available_talpas : TalpaEntry[] = [];
    for (let tc of talpa_configs) {
        const aquila_config = aquila_configs.find(ac=>ac.aquila.id===tc.connected_aquila_id);
        
        if (aquila_config === undefined) {
            continue;
        }

        if (!tc.talpa.hardware_version.has_arm) {
            continue
        }

        const camera_namespace = Object.entries(aquilas).find(([prefix, aquila_id])=>aquila_id===aquila_config?.aquila.id)![0];
        const row_namespace = tc.row_name;

        let sides : SideOfRow[] = [tc.talpa.side_of_row];
        if (tc.talpa.side_of_row == SideOfRow.Both) {
            sides = [SideOfRow.Left, SideOfRow.Right]
        }
        
        for (let side_of_row of sides) {
            available_talpas.push({camera_namespace, side_of_row, row_namespace})
        }
    }

    return <IonCard style={{height: "100%"}}>
        <IonList>
            <IonItem>
                <IonSelect
                    interface="popover"
                    placeholder="Select talpa"
                    value={selected_talpa}
                    compareWith={(a : TalpaEntry | null, b: TalpaEntry | null)=> {
                        if (a===null || b===null) {
                            return false;
                        }
                        return a.camera_namespace===b.camera_namespace && a.row_namespace===b.row_namespace && a.side_of_row===b.side_of_row
                    }}
                    onIonChange={(e : any) => set_selected_talpa(e.detail.value) }
                >
                    {available_talpas.map((tlp, idx)=>{
                        return <IonSelectOption
                            key={idx}
                            value={tlp}
                        >
                            <pre>/{tlp.row_namespace}/{tlp.side_of_row}</pre> ({tlp.camera_namespace})
                        </IonSelectOption>
                    })}
                </IonSelect>
            </IonItem>
        </IonList>

        {selected_talpa && <>
            <IonItem><IonLabel><strong>live plot</strong></IonLabel></IonItem>
            <TalpaPlot tlp={selected_talpa}/>
        </>|| <p style={{paddingLeft: '1em'}}>select talpa to view plot</p>}

        {selected_talpa && <CanOpenParametersWidget talpa_entry={selected_talpa}/>}

        <IonItem><IonLabel><strong>Parameters for synthetic talpa movements (endurance test mode)</strong></IonLabel></IonItem>
        {is_in_endurance_test_mode && <table style={{width: "100%", tableLayout: "fixed"}}><tbody>
            <tr><td colSpan={3} style={{paddingLeft: "1em"}}>
            <OverrideParam param={{
            // name: t("move wheels"),
            key: "/main/endurance_test/rect",
            defaultValue: false,
            renderer: singleChoiceWidget,
            render_args: {
                choices: [
                    [false, t("realistic movements")],
                    [true, t("rectangle wave")]
                ]
            }}} />
            </td></tr>
            <OverrideParam param={{
                name: t("rectangle wave frequency"),
                key: "/main/endurance_test/rect_freq_hz",
                renderer: minusPlusWidget,
                render_args: {unit: 'Hz', digits: 1},
                min: 0.2, max: 3.1, step: 0.2
            }} />
            <OverrideParam param={{
                name: t("rectangle minimum"),
                key: "/main/endurance_test/rect_gear_angle_a_deg",
                renderer: numSliderWidget,
                render_args: {unit: '°', digits: 0},
                min: 0.0, max: 100, step: 1
            }} />
            <OverrideParam param={{
                name: t("rectangle maximum"),
                key: "/main/endurance_test/rect_gear_angle_b_deg",
                renderer: numSliderWidget,
                render_args: {unit: '°', digits: 0},
                min: 0.0, max: 100, step: 1
            }} />
            
        </tbody></table> || <p style={{paddingLeft: '1em'}}>(enable endurance test in main menu first)</p>}

        <IonItem><IonLabel><strong>Parameters for hoe planner</strong></IonLabel></IonItem>
        <table style={{width: 'calc(100% - 1em)'}}>
            <OverrideParam param={{
                name: "max\xa0vel\xa0",
                key: "/path_to_angles/max_angular_velocity_rad_per_s",
                defaultValue: 2,
                min: 0.0,
                max: 20.0,
                step: 0.1,
                renderer: numSliderWidget,
                render_args: {unit: 'rad/s\xa0', digits: 1}
            }} />
            <OverrideParam param={{
                name: "max\xa0acc\xa0",
                key: "/path_to_angles/max_angular_acceleration_rad_per_s2",
                defaultValue: 6,
                min: 0.0,
                max: 20.0,
                step: 0.1,
                renderer: numSliderWidget,
                render_args: {unit: 'rad/s²', digits: 1}
            }} />
        </table>
    </IonCard>
}

export default LivePlotSection;