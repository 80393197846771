import { IonBadge, IonButton, IonChip, IonContent, IonIcon, IonItem, IonLabel, IonList, IonModal, IonPopover, IonSearchbar, IonTitle } from '@ionic/react';
import { close } from 'ionicons/icons';
import React, { useState } from 'react';


const ParamConfigButton: React.FC<any> = ({param_config, ...props}: any) => {
  const [show, setShow] = useState(false)
  const [filter, setFilter] = useState("")
  return <>
    <IonButton {...props} onClick={() => setShow(true)} fill="outline" style={{width: '5em' }} >
      #{param_config.id} 
    </IonButton>
    <IonModal onDidDismiss={() => setShow(false)} isOpen={show}><IonContent>
      <IonItem>
        <strong>Params (ID: #{param_config.id})</strong>
        <IonButton slot="end" onClick={() => setShow(false)}><IonIcon icon={close} slot="icon-only"/></IonButton>
      </IonItem>
      {param_config.description && <IonItem lines="none">{param_config.description}</IonItem>}
      
      <IonItem>
        {param_config.data["/application_logic/use_stem_extraction"] === true && <IonChip>Blob detection</IonChip>}
        {param_config.data["/application_logic/use_stem_extraction"] === false && <IonChip>Stem classifier</IonChip>}
      </IonItem>

      <IonList>
        <IonSearchbar value={filter} onIonChange={(e: any) => setFilter(e.detail.value)}/>
        {Object.keys(param_config.data).sort().filter((k: string) => k.includes(filter)).map((k: string) => <IonItem key={k}>
          <IonLabel>
            <IonBadge>{k}</IonBadge>
          </IonLabel>
          {`${param_config.data[k]}`}
        </IonItem>)}
      </IonList>
    </IonContent></IonModal>
  </> 
}
export default ParamConfigButton