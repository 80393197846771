import { gql } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { IonBadge, IonButton, IonCardTitle, IonCheckbox, IonChip, IonIcon, IonItem, IonLabel, IonModal, IonProgressBar, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonTitle, } from '@ionic/react';
import { SearchableSelectContent, SelectButton } from './SearchableSelect';
import { useQueryWithElevatedRole } from '../hooks/hasuraHooks';
import { add, addCircle, ban, close, closeCircle } from 'ionicons/icons';


export const TagPicker: React.FC<any> = ({onChange, value, name, showBadgeList = false, ...props}: any) => {
  const [show, setShow] = useState<boolean>(false)
  if (props.multiple === undefined) {
    props.multiple = (value?.length !== undefined)
  }
  const { loading, data} = useQueryWithElevatedRole(gql`
    query Tags {
        tags {
            id name
        }
    }
  `)
  const selectedTags = data?.tags?.filter(({id}: any) => value?.includes(id))
  const valueText = selectedTags?.map(({name}: any) => name)?.join(", ") || name
  const badgeList = <div style={{display: "flex", width: "100%", flexWrap: "wrap"}}>
    {selectedTags?.map((t: any) => <IonBadge style={{cursor: "pointer"}} onClick={() => onChange(value?.filter((id: number) => id !== t.id))}>
        <IonIcon icon={closeCircle}/>
        {t.name}
    </IonBadge>)}
  </div>
  return <>
    <IonModal isOpen={show} onDidDismiss={() => setShow(false)}>
      <TagPickerContent value={value} name={name} onClose={() => setShow(false)} onChange={onChange} {...props}/>
      {badgeList}
    </IonModal>
    {showBadgeList && badgeList}
    <SelectButton onClick={() => setShow(true)} valueText={showBadgeList ? "Edit" : valueText} name={name}/>
  </>
}


export const TagPickerContent: React.FC<any> = ({value, onClose, name, closeOnChange, onChange, skipTags=[], ...props}: any) => {
    const [mode, setMode] = useState<"seeded_size"|"transplanted_size"|"all">("seeded_size")
    const where = {
      all: {},
      seeded_size: {is_seeded_size_tag: {_eq: true}},
      transplanted_size: {is_transplanted_size_tag: {_eq: true}},
    }[mode]
    const { loading, data} = useQueryWithElevatedRole(gql`
      query Tags($where: tags_bool_exp!) {
          tags(where: $where) {
            id name
          }
      }
    `, {variables: {where: where}})
    // Handle close on change here
    const onChangeWrapper = (newValue: any) => {
        onChange(newValue)
        if (closeOnChange && onClose) {
            onClose()
        }
    }
    const checked = (checkedValue: any) => props.multiple ? value?.includes(checkedValue) : value === checkedValue
    // skipTags is used to hide tag options that are already selected
    const options = data?.tags?.filter((t: any) => !skipTags.includes(t.id))?.map((t: any) => [t.id, t.name]) || []
    return <>
      <IonButton expand="full" onClick={onClose} fill="clear"><IonIcon icon={close}/></IonButton>
      {name && <IonCardTitle style={{paddingLeft: "5px"}}>{name}</IonCardTitle>}
      <IonSegment value={mode} onIonChange={(e: any) => {e.stopPropagation(); setMode(e.detail.value)}}>
          <IonSegmentButton value="seeded_size"><IonLabel>Size (seeded)</IonLabel></IonSegmentButton>
          <IonSegmentButton value="transplanted_size"><IonLabel>Size (transplanted)</IonLabel></IonSegmentButton>
          <IonSegmentButton value="all"><IonLabel>All</IonLabel></IonSegmentButton>
      </IonSegment>
      {loading && <IonProgressBar type="indeterminate"/>}
      <SearchableSelectContent value={value} options={options} checked={checked} onChange={onChangeWrapper} {...props}/>
    </>
}


export const useTagWhere = () => {
    const [condition, setCondition] = useState([])
    const [notCondition, setNotCondition] = useState([])
    const [tagWhere, setTagWhere] = useState({})

    const hasurifyCondition: any = (condition: any, not: Boolean = false) => {
        if (not) {
            return {_not: hasurifyCondition(condition, false)}
        }
        return {
            bag_tags: {
                tag_id: {
                    _in: condition  // all tags are valid
                }
            }
        }
    }
    
    useEffect(() => {
        setTagWhere(() => {
            if (condition.length > 0 && notCondition.length > 0) {
                return {
                    _and: {
                        ...hasurifyCondition(condition),
                        ...hasurifyCondition(notCondition, true),
                    }
                }
            }
            else if (condition.length > 0) {
                return hasurifyCondition(condition)
            }
            else if (notCondition.length > 0) {
                return hasurifyCondition(notCondition, true)
            }
            else {
                return {}
            }
        })
    }, [condition, notCondition])

    return {
        condition, setCondition,
        notCondition, setNotCondition,
        tagWhere,
    }
}


export const ComplexTagPicker: React.FC<any> = ({onClose, name, closeOnChange, onChange, skipTags=[], state, ...props}: any) => {
    const [show, setShow] = useState<boolean>(false)
    const {condition, notCondition, setCondition, setNotCondition} = state
    const noFilter = condition.length == 0 && notCondition.length == 0   // Nothing set in filter
    return <>
        <IonModal isOpen={show} onDidDismiss={() => setShow(false)}>
            <TagCondition condition={condition} setCondition={setCondition}/>
            <TagCondition condition={notCondition} setCondition={setNotCondition} not/>
        </IonModal>
        <SelectButton onClick={() => setShow(true)} valueText={noFilter ? undefined : "Custom"} name={name}/>
    </>
}


export const TagCondition: React.FC<any> = ({condition, setCondition, not=false}: any) => {
    return <IonItem color="white" lines="none">
        <IonIcon icon={not ? ban : addCircle}  color={not ? "danger" : "success"} slot="start"/>
        <TagPicker showBadgeList value={condition} onChange={(value: any) => {setCondition(Object.assign([], value))}}/>
    </IonItem>
}
