import React from 'react';
import {
  IonCard,
  IonCardContent,
} from '@ionic/react';


const Message: React.FC<any> = ({children, error = false}) => <IonCard color={error ? "danger": "medium"}>
    <IonCardContent>
        {children}
    </IonCardContent>
</IonCard>

export default Message