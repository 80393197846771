import React, {useState} from 'react';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  gql,
  DefaultOptions,
  HttpLink,
  split
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../theme/variables.css';
import '../theme/globalDashboard.css';
import './App.css';

import AuthenticatedApp from '../AuthenticatedApp'
import UnauthenticatedApp from './UnauthenticatedApp'
import getMaxRole from '../lib/getMaxRole';


export interface ApplePrivateRelayState {
  is_private_relay : boolean,
  user_confirmed : boolean
}

const App: React.FC = () => {
  const [client, setClient] = useState<any>({authenticated: false, client: null});

  if (window.location.search.startsWith("?session_state")) {
    window.history.pushState("/", "/", "/");
  }

  const params = new URLSearchParams(window.location.search);

  // use public hasura server if production=true in url or production app, else use local hasura session
  const uri = (
    (params.get('production') !== "false" || process.env.NODE_ENV !== "development")   // default is production = true
    ? 'https://api.farming-revolution.com/v1/graphql'
    : 'http://localhost:6543/v1/graphql'
  )

  const logout = () => {
    console.log("logout")
    if (client.client) {
      // If we are logging out from a session with a client
      // Delete apollo cache and the token
      localStorage.removeItem("jwt")
      localStorage.removeItem("apollo-cache-persist")
    }
    const newClient = new ApolloClient({
      link: createHttpLink({
          uri: uri,
      }),
      cache: new InMemoryCache()
    });
    setClient({authenticated: false, client: newClient})
  }

  const login = async (token: string) => {
        console.log("login")
        localStorage.setItem("jwt", token)
        document.cookie = `jwt=${token}; SameSite=Lax; ${window.location.protocol==='https:'?"Secure; ":""}Path=/${window.location.hostname!='localhost'?'; domain=farming-revolution.com':''}`;
        
        const httpLink = createHttpLink({
          uri: uri,
          headers: {"Authorization": `Bearer ${token}`}
        });
        
        const basicWsLink = new GraphQLWsLink(createClient({
          url: uri.replace(/^https:/, "wss:"),
          connectionParams: async () => {
            return {
              headers: {
                'Authorization': `Bearer ${token}`,
              },
            };
          },
        }));
        
        const elevatedWsLink = new GraphQLWsLink(createClient({
          url: uri.replace(/^https:/, "wss:"),
          connectionParams: async () => {
            return {
              headers: {
                'X-Hasura-Role': getMaxRole(),
                'Authorization': `Bearer ${token}`,
              },
            };
          },
        }));

        const wsLink = split(
          ({getContext})=>{
            let context = getContext();
            return context.use_max_role || false
          },
          elevatedWsLink,
          basicWsLink
        );

        const splitLink = split(
          ({ query, getContext }) => {
            const definition = getMainDefinition(query);
            return (
              definition.kind === 'OperationDefinition' &&
              definition.operation === 'subscription'
            );        
          },
          wsLink,
          httpLink,
        );
        
        // Never cache (FIXME: could go back to caching in the future but needs to be handled with care)
        const defaultOptions: DefaultOptions = {
          watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
          },
          query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
          },
        }

        const cache = new InMemoryCache()
        const client = new ApolloClient({
          link: splitLink,
          cache: cache,
          defaultOptions: defaultOptions,
        });

        // Here init can take place
        try {
            // Basic query to check if access to Hasura is ok
            await client.query({query: gql`query Users {users { name }}`})
        } catch(e) {
            console.log("Login check with token failed: logout")
            return logout()  // Logout if insert users failed
        }

        persistCache({
          cache,
          storage: new LocalStorageWrapper(window.localStorage)
        }).then(() => {
          client.onResetStore(async () => logout())
          setClient({authenticated: true, client: client})
        });
  }


  // Initially: no client
  if (!client.client) {
      const jwt = localStorage.getItem("jwt")
      if (jwt) {
          console.log("Previous session token found")
          login(jwt)
      }
      else {
          console.log("No session token found. logout to allow login back in")
          logout()
      }
      return null
  }

  if (client.authenticated) {
    return <>
      <ApolloProvider client={client.client}>
        <AuthenticatedApp logout={logout} graphqlUri={uri}/>
        </ApolloProvider>
    </>
  }
  else {
     return <>
      <ApolloProvider client={client.client}>
        <UnauthenticatedApp setToken={login} graphqlUri={uri}/>
     </ApolloProvider>
    </>
  }
}

export default App;
