import { IonButton, IonContent, IonPage, IonItem, IonModal, IonProgressBar, IonToggle, IonInput, IonItemDivider, IonChip, IonBadge, IonIcon, IonLabel, } from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';
import Toolbar from '../../components/Toolbar'
import { gql } from '@apollo/client';
import { AddEvaluation, EvaluationItem, EVALUATION_FRAGMENT, fixEvaluationsRegardingGroundTruth, useUpdateEvaluation } from '../../components/EvaluationItem';
import { EvaluationFilter, useEvaluationFilter } from '../../components/apiFilters';
import { useMutationWithElevatedRole, useQueryWithElevatedRole } from '../../hooks/hasuraHooks';
import { Pagination, usePagination } from '../../components/Pagination';
import { ScoreButton, ScoreErrors, useScoreEvaluation } from '../../components/EvaluationScore';


const Evaluations: React.FC = () => {
  const [expandAll, setExpandAll] = useState(false)
  const [evaluationFilter, setEvaluationFilter] = useEvaluationFilter()
  // An offset is set based on the page number (but only if expandAll is set)
  const pagination = usePagination()
  const fetchVariables = {evaluationFilter, ...pagination.state}
  // Sort by id (faster than sort by updated_at)
  const { loading, data, refetch } = useQueryWithElevatedRole(gql`
    ${EVALUATION_FRAGMENT}
    query Evaluations ($limit: Int!, $offset: Int!, $evaluationFilter: evaluations_bool_exp!) {
        evaluations (order_by: {id: desc}, limit: $limit, offset: $offset, where: $evaluationFilter) {
          ...EvaluationFields
        }
        evaluations_aggregate (where: $evaluationFilter) {aggregate{count}}
    }
  `, {
    variables: fetchVariables,
    pollInterval: 1000,
  });

  useEffect(() => {
    if (expandAll) {
      pagination.setState((prev: any) => ({...prev, limit: 5}))  // Limit to 5 evals in expand all mode
    }
  }, [expandAll])

  useEffect(() => {
    refetch(fetchVariables)
  }, [evaluationFilter, pagination.state])

  const [updateEvaluation] = useUpdateEvaluation()

  const [insertGroundtruths] = useMutationWithElevatedRole(gql`
    mutation InsertGroundtruths($objects: [ground_truths_insert_input]!) {
      insert_ground_truths(objects: $objects) {
        returning {id}
      }
    }
  `)

  const groundtruthableEvaluations = data?.evaluations?.filter((e: any) => e.score_passed === true && e.bag.ground_truths?.length < 1)
  const cancelableEvaluations = data?.evaluations?.filter((e: any) => !e.result_data && !e.result_error)
  const createBulkGroundtruth = () => {
    insertGroundtruths({variables: {objects: groundtruthableEvaluations.map((evaluation: any) => ({
      evaluator_config_id: evaluation.evaluator_config_id,
      bag_id: evaluation.bag_id,
      data: {
        fused_stems: evaluation.result_data.stems.fused_stems
      }
    }))}})
  }
  const cancelEvaluations = () => {
    cancelableEvaluations.forEach((evaluation: any) => updateEvaluation({variables: {id: evaluation.id, _set: {result_error: "Canceled"}}}))
  }

  const [addEvaluation, setAddEvaluation] = useState<any>(null)

  return (
    <IonPage>
      <Toolbar name="Evaluations">
        <div style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}>
          <EvaluationFilter evaluationFilter={evaluationFilter} setEvaluationFilter={setEvaluationFilter} >
            <IonItem color="transparent"><IonLabel>Expand all</IonLabel><IonToggle checked={expandAll} onIonChange={(e: any) => setExpandAll(e.detail.checked)}/></IonItem>
          </EvaluationFilter>
        </div>
      </Toolbar>

      <IonContent fullscreen>
        {loading && <IonProgressBar type="indeterminate"/>}
        <IonModal isOpen={addEvaluation !== null} onDidDismiss={() => setAddEvaluation(null)}>
          {addEvaluation !== null && <AddEvaluation
            bags={[addEvaluation?.bag]}
            initialParamConfigIds={[addEvaluation?.param_config_id]}
            initialEvaluatorIds={[addEvaluation?.evaluator_config_id]}
          onClose={() => setAddEvaluation(null)}/>}
        </IonModal>

        {data && <>
          <Pagination numItems={data?.evaluations?.length} itemType={"evaluations"} {...pagination} totalItems={data?.evaluations_aggregate?.aggregate?.count}/>
          <div style={{display: "flex"}}>
            {groundtruthableEvaluations?.length > 0 && <IonButton key='gt' onClick={createBulkGroundtruth}>Create {groundtruthableEvaluations.length} ground truth(s)</IonButton>}
            <ScoreButton evaluations={data?.evaluations} fill="clear"/>
            {cancelableEvaluations?.length > 0 && <IonButton fill="clear" key='cancel' onClick={cancelEvaluations}>Cancel {cancelableEvaluations.length} evaluation(s)</IonButton>}
          </div>

          {data.evaluations.length <= 100 && fixEvaluationsRegardingGroundTruth(data.evaluations).map((evaluation: any) => {
            return <EvaluationItem forceExpand={expandAll} evaluation={evaluation} key={evaluation.id} setAddEvaluation={setAddEvaluation}/>
          })}
          {data.evaluations.length > 100 && <IonItem>
            Too many evaluations to show (bulk operations still work)
          </IonItem>}
          <Pagination numItems={data?.evaluations?.length} itemType={"evaluations"} {...pagination} totalItems={data?.evaluations_aggregate?.aggregate?.count}/>
        </>}
      </IonContent>
    </IonPage>
  );
};

export default Evaluations;
