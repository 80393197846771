export enum SdoState {
    Watch = 1,
    Set = 2,
    Delete = 3
}

export interface UpdateWatchedSdoRequest {
    motor_name: string,
    sdo_name: string,
    state: SdoState,
    value_as_string: string
}

export interface UpdateWatchedSdoResponse {
    success: boolean,
    message: string
}

