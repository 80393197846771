import { useState, useEffect, useRef } from 'react';
import {
  Chart as ChartJS,
  LineController,
  BarController,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataset,
  Color
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarController,
  LineController,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)
function selectColor(n: number): string {
  const hue = n * 137.508; // use golden angle approximation
  return `hsl(${hue},50%,75%)`;
}

export const TalpaDepthGuidance: React.FC<any> = ({ evaluation, resultData }: any) => {
  let estimations = resultData?.estimations
  const chartRef = useRef<any>(null);

  useEffect(() => {

    if (estimations) {
      let talpanames_set = new Set<string>()

      for (const [frame, height] of Object.entries(estimations[0]["estimations"])) {
        if (frame.endsWith("tools_on_ground")) {
          talpanames_set.add(frame)
        }
      }

      const stamps = estimations.map((msg: any) => msg.stamp)
      let d = []

      for (const tlp of talpanames_set) {
        const heights = estimations.map((msg: any) => msg["estimations"][tlp])
        const col: Color = selectColor(d.length)
        const dset: ChartDataset = {
          label: tlp,
          data: heights,
          fill: false,
          borderColor: col
        }
        d.push(dset)
      }

      const ctx = chartRef?.current?.getContext('2d');
      new ChartJS(ctx, {
        type: 'line',
        data: {
          labels: stamps,
          datasets: d
        },
        options: {
        }
      });

    }
  }, [estimations]);

  return <canvas ref={chartRef}></canvas>
}
