import React, { useState } from "react";
import { Arrow, Group, Rect, Image } from "react-konva";
import {
  IonBadge,
  IonButton,
  IonChip,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
} from "@ionic/react";
import useImage from "use-image";
import wheel from "../assets/images/reifen_2.svg";
import { KonvaHtml } from './KonvaHtml';
import { close, navigate, } from "ionicons/icons";
import { Html } from "react-konva-utils";

export const KonvaHtmlRos: React.FC<any> = ({...props}: any) => <KonvaHtml {...props}/>

export const Axle: React.FC<any> = ({
  x,
  trackWidth,
  axleWidth = 0.1,
  wheelRadius = 0.445,
  wheelWidth = 0.3,
  steeringAngle = 0,
  leftSpeed = 0,
  rightSpeed = 0,
}: any) => {

  return (
    <>
      <Group x={x} y={0}>
        <CenteredRect
          y={0}
          height={trackWidth}
          width={axleWidth}
          offsetY={trackWidth / 2}
          offsetX={axleWidth / 2}
          fill="gray"
        />
        <RobotWheel
          y={trackWidth / 2}
          width={wheelRadius * 3.5}
          height={wheelWidth * 5}
          rotation={steeringAngle / Math.PI * 180.}
          speed={leftSpeed}
        />
        <RobotWheel
          y={-trackWidth / 2}
          width={wheelRadius * 3.5}
          height={wheelWidth * 5}
          rotation={steeringAngle / Math.PI * 180.}
          speed={rightSpeed}
        />
      </Group>
    </>
  );
};

export const RobotConfigs: React.FC<any> = ({
  x,
  robotConfig,
  axleWidth = 0.1,
  width,
  height,
  trackWidth,
  showPopover,
  setShowPopover,
  lifterStates,
  magnify = 0,
  ...props
}: any) => {
  const [event, setEvent] = useState<any>(null);
  const [selectedTalpa, setSelectedTalpa] = useState<any>(null);

  return (
    <>
      <Group x={x / 2} y={0}>
        <CenteredRect
          y={0}
          height={trackWidth}
          width={axleWidth}
          offsetY={trackWidth / 2}
          offsetX={axleWidth / 2}
          fill="black"
          opacity={0.5}
        />
        {robotConfig?.aquila_configs.map(
          (aquila_config: any, index: number) => {
            return (
              <CenteredRect
                key={aquila_config.aquila_id}
                x={0.1}
                y={(magnify || 0.01) * -aquila_config.offset_y}
                height={axleWidth * (magnify ? 2.8 : 3)}
                width={magnify ? aquila_config.height * 200: aquila_config.height}
                fill="grey"
                offsetY={(axleWidth * 3) / 2}
                offsetX={0}
                opacity={0.7}
                onClick={() => setShowPopover(!showPopover)}
              />
            );
          }
        )}
        {robotConfig?.talpa_configs.map((talpa_config: any, i: number) => {
          const width = magnify? axleWidth*12 : trackWidth / 3.0

          let depth = lifterStates && (
            lifterStates?.lifters?.filter(
              (val: any) =>
                val.row_name === talpa_config.row_name &&
                val.side_of_row === talpa_config.talpa.side_of_row
            )[0].talpa_depth_m * 100
          ).toFixed(1);
          return (
            <React.Fragment key={i}>
              {lifterStates && <KonvaHtmlRos x={-0.5} y={-0.01 * talpa_config.offset_y}>
                <IonBadge
                  color="dark"
                  style={{ cursor: "pointer", fontSize: "5px" }}
                  {...props}
                  onClick={(e) => {
                    setEvent(e);
                    setSelectedTalpa(depth);
                  }}
                >
                  {depth}
                </IonBadge>
              </KonvaHtmlRos>
              }
              <CenteredRect
                x={-0.2}
                y={(magnify || 0.01) * -talpa_config.offset_y}
                height={axleWidth / 2}
                width={magnify? width/ magnify * 1.3:width}
                offsetY={axleWidth / 4}
                offsetX={magnify? width/magnify * 1.3:width}
                fill="black"
                opacity={0.7}
              />
            </React.Fragment>
          );
        })}
        <KonvaHtmlRos x={-0.6}>
          <IonPopover
            event={event}
            isOpen={!!event}
            onDidDismiss={() => setEvent(null)}
          >
            <IonButton
              expand="full"
              onClick={() => setEvent(null)}
              fill="clear"
            >
              <IonIcon icon={close} />
            </IonButton>
            <IonList style={{ overflow: "auto" }}>
              <IonItem lines="none">
                <IonLabel>{"Depth"}</IonLabel>
                {selectedTalpa} cm
              </IonItem>
            </IonList>
          </IonPopover>
        </KonvaHtmlRos>
      </Group>
    </>
  );
};

export const CenteredRect: React.FC<any> = ({
  width,
  height,
  offsetY,
  offsetX,
  ...props
}: any) => (
  <Rect
    width={width}
    height={height}
    offsetY={offsetY}
    offsetX={offsetX}
    {...props}
  />
);

export const RobotWheel: React.FC<any> = ({ y, width, height, rotation, speed = 0}: any) => {
  const [image] = useImage(wheel);
  const arrowWidth = 0.2
  const arrowLength = Math.abs(speed / 0.7) * arrowWidth
  return <Group y={y} rotation={rotation}>
    <Image
      image={image}
      width={width}
      height={height}
      offsetX={width / 2.}
      offsetY={height / 2.}
    />
    {Math.abs(speed) > 0.05 && <Arrow
      offsetX={-arrowLength / 2.}
      pointerLength={arrowLength}
      pointerWidth={arrowWidth}
      points={[0, 0, 0, 0]}
      strokeWidth={0.025}
      opacity={0.5}
      stroke="white"
      rotation={speed > 0 ? 0 : 180 }
    />}
  </Group>
};

export const RobotArrow: React.FC<any> = ({
  ...props
}: any) => (
  <Group {...props}>
    <Html
      transformFunc={(a: any) => {a.scaleX /= 100.; a.scaleY /= -100.; return a}}
    >
      <IonChip color="secondary" outline style={{
        display: "flex",
        backgroundColor: "rgba(255, 255, 255, 0.9)", border: "3px solid", width: 46, height: 32, marginLeft: -23, marginTop: -16}}>
        <IonIcon icon={navigate} style={{transform: "rotate(45deg)", margin: 0}}/>
      </IonChip>
    </Html>
  </Group>
);
