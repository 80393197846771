import { IonContent, IonPage, IonItem, IonProgressBar, IonLabel, IonGrid, IonRow, IonCol, IonButton, IonIcon, IonModal, IonBadge, IonList, IonToggle, IonItemDivider, } from '@ionic/react';
import React, { useState } from 'react';
import Toolbar from '../../components/Toolbar'
import { gql } from '@apollo/client';
import { BagFilter, useBagFilter, } from '../../components/apiFilters';
import { useQueryWithElevatedRole } from '../../hooks/hasuraHooks';
import { SearchableSelect } from '../../components/SearchableSelect';
import { caretDown, caretUp, close, } from 'ionicons/icons';
import { AddEvaluation, EvaluationItem, EvaluationItemFromId, EVALUATION_FRAGMENT, } from '../../components/EvaluationItem';
import ParamConfigButton from '../../components/ParamConfigButton';
import { ScoreButton, } from '../../components/EvaluationScore';
import { Pagination, usePagination } from '../../components/Pagination';


const Scores: React.FC = () => {
  // Sort by id (faster than sort by updated_at)
  const [bagFilter, setBagFilter] = useBagFilter()

  const { loading, data} = useQueryWithElevatedRole(gql`
    query Bags ($bagFilter: bags_bool_exp!){
      bags (where: $bagFilter) {
        id
        name
        ground_truths(where: {archived: {_eq: false}}, order_by: {id: desc}, limit: 1) {id evaluator_config_id}
      }
      param_configs(order_by: {id: desc}, where: {archived: {_eq: false}}) {
        id description
      }
    }
  `, {
    variables: {bagFilter: bagFilter},
  });

  const [paramConfigIds, setParamConfigIds] = useState<number[]>([])

  return (
    <IonPage>
      <Toolbar name="Scores">
        <div style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}>
          <BagFilter bagFilter={bagFilter} setBagFilter={setBagFilter} defaultGtMode={"With groundtruth"}/>
          {data && <IonItem color="transparent">{data.bags.length} bags</IonItem>}
          {data && <IonItem color="transparent">
            <IonLabel position="stacked">Param configs</IonLabel>
            <SearchableSelect name="Param configs" value={paramConfigIds} onChange={setParamConfigIds}
              canSelectAll={true}
              options={data.param_configs.map((t: any) => [t.id, `#${t.id} ${t.description}`])} />
          </IonItem>}
        </div>

      </Toolbar>

      <IonContent fullscreen>
        {loading && <IonProgressBar type="indeterminate"/>}
        {data && <>
          <ScoreBoard bags={data.bags} paramConfigIds={paramConfigIds}/>
        </>}
      </IonContent>
    </IonPage>
  );
};


const getBestEvaluation = (evaluations: any[]) => {
  let bestEvaluation: any = null
  evaluations.forEach((evaluation: any) => {
    if (bestEvaluation === null
        || evaluation.score_passed && !bestEvaluation.score_passed
        || evaluation.score_data && !bestEvaluation.score_data
        || evaluation.score_data?.recall > bestEvaluation.score_data?.recall
      ) {
      bestEvaluation = evaluation
    }
  })
  return bestEvaluation
}

const computeStats = (paramConfig: any, bags: number[]) => {
  let evalsPerBag: any = {}

  bags.forEach((bag: any) => {
    evalsPerBag[`${bag.id}`] = []
  })

  paramConfig.evaluations.forEach((evaluation: any) => {
    const k = `${evaluation.bag_id}`
    if (!evalsPerBag[k]) {evalsPerBag[k] = []}
    evalsPerBag[k].push(evaluation)
  })

  const missingBagIds = Object.keys(evalsPerBag).filter((k: any) => evalsPerBag[k].length < 1).map((k: any) => parseInt(k))
  const missingBags = bags.filter((bag: any) => missingBagIds.includes(bag.id))
  // Get all evaluations (1 per bag). Use best score if several evaluations for bag
  const evals = Object.keys(evalsPerBag).map((k: any) => getBestEvaluation(evalsPerBag[k])).filter((e: any) => !!e)
  const inProgressEvals = evals.filter((e: any) => !e.execution_computer_id)
  const notInProgressEvals = evals.filter((e: any) => e.execution_computer_id)
  const unscoredEvals = notInProgressEvals.filter((e: any) => !e.score_data)
  const scoredEvals = notInProgressEvals.filter((e: any) => e?.score_data)
  const nScoredEvals = scoredEvals.length  // 1 evaluation / bag
  const nPassed = scoredEvals.filter((e: any) => e.score_passed).length
  const nFP = scoredEvals.reduce((sum, current) => sum + current.score_data.nFP, 0)
  const nTP = scoredEvals.reduce((sum, current) => sum + current.score_data.nTP, 0)
  const nFN = scoredEvals.reduce((sum, current) => sum + current.score_data.nFN, 0)
  const recall = nTP / (nTP + nFN)
  const precision = nTP / (nTP + nFP)
  return {
    paramConfig,
    missingBags,
    nPassed,
    nFP,
    nTP,
    nFN,
    recall,
    precision,
    unscoredEvals,
    nScoredEvals,
    scoredEvals,
    inProgressEvals,
  }
}


const ScoreBoard: React.FC<any> = ({bags, paramConfigIds}) => {
  const { loading, data, refetch} = useQueryWithElevatedRole(gql`
    query ScoreBoard($bagIds: [Int!]!, $paramConfigIds: [Int!]!){
      param_configs(where: {id: {_in: $paramConfigIds}}) {
        id
        data
        description
        evaluations (order_by: {id: desc}, where: {bag_id: {_in: $bagIds}, result_error: {_is_null: true}}) {
          id
          bag_id
          bag {name}
          score_passed
          score_data
          score_details
          execution_computer_id
        }
      }
    }
  `, {
    variables: {bagIds: bags.map(({id}: any) => id), paramConfigIds},
  })
  const [addEvaluation, setAddEvaluation] = useState<any>(null)
  if (!data) return null
  const sortedStats = data.param_configs.map((paramConfig: any) => computeStats(paramConfig, bags)).sort((a: any, b: any) => b.nScoredEvals - a.nScoredEvals)
  return <>
    {loading && <IonProgressBar  type="indeterminate"/>}
    {addEvaluation && <IonModal isOpen={!!addEvaluation} onDidDismiss={() => setAddEvaluation(null)}>
      <AddEvaluation
        bags={addEvaluation?.bags}
        initialParamConfigIds={[addEvaluation?.paramConfigId]}
        onClose={() => setAddEvaluation(null)}
      />
    </IonModal>}

    <IonGrid>
      <IonRow style={{fontWeight: 800}}>
        <IonCol size="1">Config</IonCol>
        <IonCol size="6">Evals</IonCol>
        <IonCol>Success rate</IonCol>
        <IonCol>Micro precision</IonCol>
        <IonCol>Micro recall</IonCol>
      </IonRow>

      {sortedStats.map((stats: any) => <IonRow style={{borderTop: "solid 1px lightgray"}} key={stats.paramConfig.id}>
        <IonCol size="1">
          <ParamConfigButton param_config={stats.paramConfig}/>
        </IonCol>
        <IonCol size="6">
          <ShowEvaluationsButton evaluations={stats.scoredEvals}>scored</ShowEvaluationsButton>
          <ShowEvaluationsButton evaluations={stats.inProgressEvals}>in progress</ShowEvaluationsButton>
          <ShowEvaluationsButton evaluations={stats.unscoredEvals} scoreAllButton refetch={refetch}>unscored</ShowEvaluationsButton>
          {stats.missingBags.length > 0 && <IonButton fill="clear"
            onClick={() => setAddEvaluation({bags: stats.missingBags, paramConfigId: stats.paramConfig.id})}
          >
            {stats.missingBags.length} missing evals
          </IonButton>}
        </IonCol>
        <IonCol>
          <ShowMetricButton evaluations={stats.scoredEvals} metricName={"passed"} metricFunc={(e: any) => e.score_passed ? 1 : 0}>
            {(stats.nPassed / stats.nScoredEvals * 100.).toFixed(2)} %
          </ShowMetricButton>
        </IonCol>
        <IonCol>
          <ShowMetricButton evaluations={stats.scoredEvals} metricName={"precision"} metricFunc={(e: any) => e.score_data["precision"]}>
            {(stats.precision * 100.).toFixed(2)} %
          </ShowMetricButton>
        </IonCol>
        <IonCol>
          <ShowMetricButton evaluations={stats.scoredEvals} metricName={"recall"} metricFunc={(e: any) => e.score_data["recall"]}>
            {(stats.recall * 100.).toFixed(2)} %
          </ShowMetricButton>
        </IonCol>
      </IonRow>)}
    </IonGrid>
  </>
}


const ShowEvaluationsButton: React.FC<any> = ({children, evaluations, setEvaluationIds, ...props}) => {
  const [show, setShow] = useState(false)
  if (evaluations.length < 1) return null
  return <>
    <IonButton fill="clear" onClick={() => setShow(true)}>
      {evaluations.length}{" "}{children}
    </IonButton>
    <IonModal isOpen={show} onDidDismiss={() => setShow(false)} className="fullscreen">
      <IonButton expand='full' fill="clear" onClick={() => setShow(false)}><IonIcon icon={close}/></IonButton>
      <EvaluationList evaluationIds={evaluations.map(({id}: any) => id)} {...props}/>
    </IonModal>
  </>
}


const EvaluationList: React.FC<any> = ({evaluationIds, refetch: refetchParent}) => {
  const pagination = usePagination(50)
  const { loading, data, refetch } = useQueryWithElevatedRole(gql`
    ${EVALUATION_FRAGMENT}
    query Evaluations ($limit: Int!, $offset: Int!, $evaluationFilter: evaluations_bool_exp!) {
        evaluations (order_by: {id: desc}, limit: $limit, offset: $offset, where: $evaluationFilter) {
          ...EvaluationFields
        }
        evaluations_aggregate (where: $evaluationFilter) {aggregate{count}}
    }
  `, {
    variables: {evaluationFilter: {id: {_in: evaluationIds}}, ...pagination.state},
  })
  return <IonContent>
    <IonList>
      {loading && <IonProgressBar type='indeterminate'/>}
      {data && <>
        <ScoreButton evaluations={data.evaluations} expand="block"/>
        <Pagination numItems={data?.evaluations?.length} itemType={"evaluations"} {...pagination} totalItems={data?.evaluations_aggregate?.aggregate?.count}/>
        {data.evaluations.map((evaluation: any) => <EvaluationItem evaluation={evaluation} key={evaluation.id}/>)}
        <Pagination numItems={data?.evaluations?.length} itemType={"evaluations"} {...pagination} totalItems={data?.evaluations_aggregate?.aggregate?.count}/>
      </>}
    </IonList>
  </IonContent>
}


const ShowMetricButton: React.FC<any> = ({children, ...props}) => {
  const [show, setShow] = useState(false)
  return <>
    <IonButton fill="clear" onClick={() => setShow(true)}>
      {children}
    </IonButton>
    <IonModal isOpen={show} onDidDismiss={() => setShow(false)} className="fullscreen">
      <IonButton expand='full' fill="clear" onClick={() => setShow(false)}><IonIcon icon={close}/></IonButton>
      <LazyEvaluationList {...props}/>
    </IonModal>
  </>
}



const LazyEvaluationList: React.FC<any> = ({evaluations, metricName, metricFunc}) => {
  // Only show evaluations once expanded
  const pagination = usePagination(50)
  //const scoreFunc = (evaluation: any) => evaluation.score_data["recall"]
  const [reversed, setReversed] = useState(false)
  const evalsSorted = evaluations.sort((a: any, b: any) => (reversed ? -1 : 1) * (metricFunc(b) - metricFunc(a)))
  return <>
    <IonItem onClick={() => setReversed(!reversed)}>
      <IonButton slot="end">
        {metricName}
        <IonIcon icon={reversed ? caretUp : caretDown } slot="end"/>
      </IonButton>
    </IonItem>
    <IonContent>
      <IonList>
        {evalsSorted.map((evaluation: any) => <LazyEvaluation evaluation={evaluation}>
          {evaluation?.bag?.name}
          <IonBadge slot="end">{(metricFunc(evaluation) * 100).toFixed(2)}%</IonBadge>
        </LazyEvaluation>)}
      </IonList>
    </IonContent>
  </>
}


const LazyEvaluation: React.FC<any> = ({evaluation, children}) => {
  const [expanded, setExpanded] = useState(false)
  return <>
    <IonItem onClick={() => setExpanded(!expanded)} lines="none" style={{borderTop: "solid 1px lightgray"}}>
      {children}
    </IonItem>
    {expanded && <EvaluationItemFromId evaluation_id={evaluation.id} forceExpand={true}/>}
  </>
}


export default Scores;