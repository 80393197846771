import { IonLabel, IonCard, IonContent, IonPage, IonCardHeader, IonCardSubtitle, IonBadge, IonItemDivider, IonCardTitle, IonItemGroup, IonIcon, } from '@ionic/react';
import React, {} from 'react';
import { Group, Stage, Layer } from "react-konva";
import Toolbar from '../../components/Toolbar'
import useWindowDimensions from '../../useWindowDimensions'
import history from '../../history'
import { Axle, RobotConfigs, RobotArrow } from '../../components/KonvaRobot';
import { gql, useSubscription } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { RobotConfigChecklistModal } from './RobotConfigChecklist';
import { SoftwareVersionBadge, useLastReleasedSoftwareVersion, } from '../../components/SoftwareVersionBadge';
import { GraphQLState } from '../../components/GraphQLState';
import { createOutline, openOutline, pencil, pencilOutline } from 'ionicons/icons';


const RobotConfig: React.FC<any> = ({robot_config, isLargeScreen, lastSoftwareVersion}: any) => {
  const standaloneAquila = robot_config.aquila_configs.find(({standalone}: any) => standalone)
  const softwareVersionState = robot_config.software_version_states[0]

  const trackWidth = robot_config?.taurus_configs[0]?.track_width
  const wheelBase = robot_config?.taurus_configs[0]?.wheel_base
  const wheelRadius = 60
  const wheelWidth = 40
  const axleWidth = 15

  const name = standaloneAquila ? `AQL${standaloneAquila.aquila_id} (standalone)`: `GT${robot_config?.taurus_configs[0]?.taurus_id}`
  const scale = 0.15
  const {t} = useTranslation()
    
  const openRobotConfig = () => {
      history.push(`/robot/${robot_config.id}`)
  }
 
  return <IonCard style={{
    width: "100%", padding: 4, height: 75, margin: 4,
    display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between",
  }}>
    <div>
      <IonCardTitle style={{fontWeight: "bolder", textDecoration: "underline", cursor: "pointer"}} onClick={openRobotConfig}>
        {name}
      </IonCardTitle>
      <IonCardSubtitle style={{display: "flex", alignItems: "center", marginTop: 0}}>
        <SoftwareVersionBadge softwareVersionState={softwareVersionState} lastSoftwareVersion={lastSoftwareVersion}/>
        {robot_config?.taurus_configs[0]?.has_pfeifer_u_langen_box && (
          <IonBadge color="secondary">
            P&L
          </IonBadge>
        )}
        {isLargeScreen && robot_config.taurus_configs[0]?.track_width && <IonBadge color="medium">
          <IonLabel>{robot_config.taurus_configs[0].track_width} m</IonLabel>
        </IonBadge>}
        <RobotConfigChecklistModal robotConfigId={robot_config.id} size="small" fill="clear" style={{padding: 0}}>
          {t("Checklist")}
        </RobotConfigChecklistModal>
      </IonCardSubtitle>
    </div>
    <div style={{
      height: "100%", border: "1px solid lightgray", display: "flex", alignItems: "center", width: 150, justifyContent: "center",
      cursor: "pointer", position: "relative", borderRadius: 4,  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    }} onClick={openRobotConfig}>
      <IonIcon icon={createOutline} style={{position: "absolute", bottom: 0, margin: "auto", right: 0}} />
      <Stage
        width={(wheelBase * 150 + 300) * scale}
        height={(trackWidth * 150 + 100) * scale}
        style={{height: "fit-content"}}
      >
        <Layer scaleX={scale} scaleY={scale}>
          <Group x={100} y={trackWidth * 100}>
            <Axle
              trackWidth={trackWidth * 150}
              axleWidth={axleWidth}
              wheelRadius={wheelRadius}
              wheelWidth={wheelWidth}
            />
            <RobotConfigs
              x={wheelBase * 150}
              robotConfig={robot_config}
              trackWidth={trackWidth * 150}
              axleWidth={axleWidth}
              magnify={1.5}
            />
            <Axle
              trackWidth={trackWidth * 150}
              axleWidth={axleWidth}
              wheelRadius={wheelRadius}
              wheelWidth={wheelWidth}
              x={wheelBase * 150}
            />
          </Group>
        </Layer>
      </Stage>
    </div>

  </IonCard>
}


export const RobotConfigFields = gql`
  fragment RobotConfigFields on robot_configs {
    id
    fleet_id
    generator_id
    taurus_configs {
      taurus_id
      track_width
      wheel_base
      has_pfeifer_u_langen_box
      taurus {
        id
        taurus_hardware_version {
          battery_type
          has_generator_control
          pcu_version
          id
          release_date
        }
      }
    }
    aquila_configs(order_by: {offset_y: asc}){
      standalone
      aquila_id
      offset_y
      height
    }
    talpa_configs(order_by: {offset_y: asc}){
      talpa_id
      offset_y
    }
    taurusbox_configs {
      taurusbox_id
    }
    row_configs(order_by: {offset_y: asc}) {
      offset_y
    }
    description
    software_version_states(limit: 1, order_by: {id: desc}) {
      id dirty software_version {
        id released_at description developer_description
      }
    }
  }
`

const Fleets: React.FC = () => {
  const {isLargeScreen} = useWindowDimensions()
  const { t } = useTranslation();

  const { data, error, loading } = useSubscription(gql`
    ${RobotConfigFields}
    subscription GetFleets {
      fleets(order_by: {updated_at: desc}) {
        id
        name
        description
        tauruses(order_by: {id: asc}) {
          taurus_configs(order_by: {id: desc}, limit: 1) {
            robot_config {
              ...RobotConfigFields
            }
          }
        }
        aquilas(order_by: {id: asc}) {
          aquila_configs(order_by: {id: desc}, limit: 1) {
            standalone
            robot_config {
              ...RobotConfigFields
            }
          }
        }
      }
    }`
  )
    
  const lastSoftwareVersion = useLastReleasedSoftwareVersion()

  // Only keep fleets with aquilas or tauruses
  const fleets = data?.fleets?.filter(({aquilas, tauruses}: any) => aquilas.map(({standalone}: any) => standalone).length > 0 || tauruses.length > 0)
  return (
    <IonPage>
      <Toolbar name={t("Fleets")}/>
      <IonContent fullscreen>
        <GraphQLState error={error} loading={loading}/>
        {fleets?.map((fleet: any) => <IonItemGroup key={fleet.id}>
          <IonItemDivider sticky color="light">
            <IonLabel>
              {fleet.name || t("Unnamed fleet")}
            </IonLabel>
          </IonItemDivider>
          
          <div style={{display: "flex", flexWrap: "wrap", flexDirection: "row"}}>
            {fleet?.tauruses?.map(({taurus_configs}: any) => taurus_configs?.map(({robot_config}: any) => <RobotConfig
              robot_config={robot_config} key={robot_config.id} isLargeScreen={isLargeScreen} lastSoftwareVersion={lastSoftwareVersion} />))}
            {/* Show standalone aquilas */}
            {fleet?.aquilas?.map(({aquila_configs}: any) => aquila_configs?.filter(({standalone}: any) => standalone)?.map(({robot_config}: any) => <RobotConfig
              robot_config={robot_config} key={robot_config.id} isLargeScreen={isLargeScreen} lastSoftwareVersion={lastSoftwareVersion} />))}
          </div>
        </IonItemGroup>)}
      </IonContent>
  </IonPage>
  );
};

export default Fleets;
