import { IonBadge, IonButton, IonCardContent, IonContent, IonChip, IonCard, IonIcon, IonItem, IonLabel, IonSpinner, } from '@ionic/react';
import { checkmark, expand, reload, } from 'ionicons/icons';
import React, {} from 'react';
import { useCallService } from '../hooks/rosHooks';
import { GeofenceButton } from '../control-app/sections/GeofenceSection';
import ConfirmButton from './ConfirmButton';
import { EstopButton } from './Estop';
import { RemotelyDisabledButton } from './RemotelyDisabledButton';
import PauseButton from './PauseButton';
import { useTranslation } from 'react-i18next';
import RainError from './RainError';
import { PowerInfoDiagItem } from './PowerInfo';
import { InfoPopover } from './InfoPopover';
import { alertCircle, cameraOutline, checkmarkCircle, close, gitNetworkOutline, hourglass, warning, } from 'ionicons/icons';
import { useDiagnosticErrorInfo } from './Diag';



export const getIconAndColor: any = ({level}: any) => {
  if (!level) {
    return [hourglass, "danger"]
  }
  // Return color and icons for diagnostics
  if (level === "OK") return [checkmarkCircle, "success"]
  if (level === "WARN") return [warning, "warning"]
  if (level === "ERROR") return [alertCircle, "danger"]
  if (level === "TRANSITIONING") return [null, "medium"]
  // Everything else is stale
  return [hourglass, "danger"]
}


const filterNamespaces = (namespaces: string[]) => {
  // Remove namespaces that are not relevant for end user (such as auto) unless we have a 'general' status
  // General status happens when for example a monitor crashes (e.g. auto_monitor). All namespaces are relevant in that case to understand the issue
  if (namespaces.some((namespace: string) => namespace === "general")) {
    return namespaces
  }
  return namespaces.filter((n: string) => !["auto", "auto_switch", "weeding"].includes(n))
}


export const StatusContent: React.FC<any> = ({status, icon=null, color=null}: any) => {
  const [defaultIcon, defaultColor] = getIconAndColor(status)
  const actualColor = color || defaultColor
  
  const namespaces = filterNamespaces(status.namespaces)
  const { title } = useDiagnosticErrorInfo(namespaces.at(-1))
  if (title && namespaces.length > 0) {
    namespaces[namespaces.length - 1] = title
  }

  return <>
    {status.level === "TRANSITIONING" ? <IonSpinner color={actualColor} slot="start"/> : <IonIcon
      color={actualColor} icon={icon || defaultIcon} slot="start" style={{marginRight: 5}}/>}
    <div style={{display: "flex"}}>
      {filterNamespaces(status.namespaces).map((n: string, i: number) => <IonBadge key={`${n}_${i}`} color={actualColor}>{n}</IonBadge>)}
      {status.disabled && <IonBadge color="medium">disabled</IonBadge>}
    </div>
  </>
}


export const StatusDetails: React.FC<any> = ({status, onClose, itemContent, bg="white", children}: any) => {
    return <>
      {onClose && <IonButton fill="clear" onClick={onClose}>
        <IonIcon icon={close}/>
      </IonButton>}
      <IonContent>
        <IonCard color={bg} >
          <IonItem style={{margin:0, padding: 0}} color="transparent" lines="none">
            {itemContent}
          </IonItem>
        </IonCard>
        {status.key_values.map(({key, value}: any) => <IonItem key={key}>
          <IonLabel style={{textTransform: "capitalize"}}>{key}</IonLabel>
          {value}
        </IonItem>)}
      </IonContent>
      {children}
    </>
}