import { gql, useMutation, useQuery } from '@apollo/client'

export const TALPA_FRAGMENT = `
fragment TalpaParts on talpas {
  id
  end_of_line_test_passed
  controller_swivel
  controller_chopper
  can_id_swivel
  can_id_chopper
  can_id_lifter

  can_id_swivel_left
  can_id_swivel_right
  lifter_motor_address

  side_of_row
  hardware_version {
    id release_date has_chopper description
  }

  calibrations(order_by: {created_at: desc}, limit: 5) {
    created_at
    data
  }

  sprayer_calibrations(order_by: {created_at: desc}, limit: 50) {
    created_at
    id
    nozzle_angle_x_deg
    nozzle_angle_y_deg
    opening_duration_ms
    pressure_bar
    spot_mass_mg
    talpa_id
  }

}
`

export const useRobot: any = (id: any) => {

  let { data, refetch, updateQuery, ...robotApollo } = useQuery(gql`
    ${TALPA_FRAGMENT}
    query RobotConfig($id : Int!) {
      current_config: robot_configs_by_pk(id: $id) {
        id
        fleet_id
        implement_length
        description
        aquila_configs {
          standalone
          offset_y
          aquila_id
          slot_id
          aquila {
            name
            camera_type
          }
        }
        generator_id
        talpa_configs(order_by: {offset_y: asc}) {
          offset_y
          talpa_id
          connected_aquila_id
          aquila_slot_id
          slot_id
          disabled
          talpa_tool_id
          blade_turning_angle_pointing_backwards_deg
          treatment_direction
          talpa {
            ...TalpaParts
          }
        }
        calibrationboard_configs {
          offset_y
          calibrationboard_id
        }
        taurus_configs {
          track_width
          wheel_base
          taurus_id
          keybox_code
          has_pfeifer_u_langen_box
          disable_drive_motor_back_left
          disable_drive_motor_back_right
          disable_drive_motor_front_left
          disable_drive_motor_front_right
        }
        taurusbox_configs {
          id
          ip_subnet
          taurusbox_id
          gps_type
          ntrip_account_id
          ntrip_account {
            id url
          }
          wifi_password
          wifi_ssid
          websocket_external_port
          sim_card1_id
          sim_card2_id
          sim_card1 {
            id
            fleet_id
            supplier
            card_number
            expires_at
          }
          sim_card2 {
            id
            fleet_id
            supplier
            card_number
            expires_at
          }
        }
        row_configs {
          offset_y
        }
        sprayer_configs {
          offset_x
          offset_y
          sensor_type
          connected_aquila_id
          aquila_slot_id
          sprayer_calibration_id
          can_address_pump_hex
          can_address_query_hex
          can_address_spray_hex        
        }
        laser_configs {
          offset_x
          offset_y
          offset_z
          calibration_yaw
          calibration_roll
          calibration_pitch
        }
      }
    }`,
    {notifyOnNetworkStatusChange: true, "variables" : {"id": id}}
  );

  if (robotApollo.error) {robotApollo.error.extraInfo = "robot fetch"}  // add info for error display

  return {data, refetch, robotApollo}
}



// generated type definitions:
export interface RobotConfig {
  id:                       number;
  fleet_id:                 number;
  implement_length:         number;
  description:              string;
  aquila_configs:           AquilaConfig[];
  talpa_configs:            TalpaConfig[];
  calibrationboard_configs: any[];
  taurus_configs:           TaurusConfig[];
  taurusbox_configs:        TaurusboxConfig[];
  row_configs:              RowConfig[];
  sprayer_configs:          SprayerConfig[];
  laser_configs:            LaserConfig[];
  generator_id:             number;
}

export interface AquilaConfig {
  standalone: boolean;
  offset_y:   number;
  aquila_id:  number;
  slot_id:    string;
  aquila:     Aquila;
}

export interface LaserConfig {
  offset_x : number;
  offset_y : number;
  offset_z : number;
  calibration_yaw : number;
  calibration_roll : number;
  calibration_pitch : number;
}

export interface Aquila {
  name:        string;
  camera_type: string;
}

export interface RowConfig {
  offset_y: number;
}

export enum SensorType {
  Pressure = "pressure",
  VolumetricFlowRate = "volumetric_flow_rate",
  FillingLevel = "filling_level",
}

export interface SprayerConfig {
  offset_x: number;
  offset_y: number;
  sensor_type: SensorType | null;
  connected_aquila_id : number | null;
  aquila_slot_id?: string;
  sprayer_calibration_id : number | null;
  can_address_pump_hex : string;
  can_address_query_hex : string;
  can_address_spray_hex : string;
}

export enum TreatmentDirection {
  INWARD = "inward",
  OUTWARD_LEFT = "outward_left",
  OUTWARD_RIGHT = "outward_right"
}

export interface TalpaConfig {
  offset_y:            number;
  talpa_id:            number;
  connected_aquila_id: number | null;
  aquila_slot_id:      string | null;
  slot_id:             string;
  disabled:            boolean;
  talpa_tool_id:       number | null;
  talpa:               Talpa;
  blade_turning_angle_pointing_backwards_deg: number | null;
  treatment_direction: TreatmentDirection;
}

export interface Talpa {
  id:                      number;
  end_of_line_test_passed: boolean;
  controller_swivel:       string;
  controller_chopper:      string;
  can_id_swivel:           number;
  can_id_chopper:          number;
  can_id_lifter:           number;
  side_of_row:             "left" | "right" | "both";
  hardware_version:        HardwareVersion;
  calibrations:            any[];
  sprayer_calibrations:    SprayerCalibration[];
}

export interface SprayerCalibration {
  created_at:          Date;
  id:                  number;
  nozzle_angle_x_deg:  number;
  nozzle_angle_y_deg:  number;
  opening_duration_ms: number[];
  pressure_bar:        number[];
  spot_mass_mg:        Array<number[]>;
  talpa_id:            number;
}

export interface HardwareVersion {
  id:           number;
  release_date: Date;
  has_chopper:  boolean;
}

export interface TaurusConfig {
  track_width:                     number;
  wheel_base:                      number;
  taurus_id:                       number;
  keybox_code:                     number;
  has_pfeifer_u_langen_box:        boolean;
  disable_drive_motor_back_left:   boolean;
  disable_drive_motor_back_right:  boolean;
  disable_drive_motor_front_left:  boolean;
  disable_drive_motor_front_right: boolean;
}

export interface TaurusboxConfig {
  id:                      number;
  ip_subnet:               string;
  taurusbox_id:            number;
  gps_type:                string;
  ntrip_account_id:        number;
  ntrip_account:           NtripAccount;
  wifi_password:           string;
  wifi_ssid:               string;
  websocket_external_port: number;
  sim_card1_id:            number;
  sim_card2_id:            number;
  sim_card1:               SimCard;
  sim_card2:               SimCard;
}

export interface NtripAccount {
  id:  number;
  url: string;
}

export interface SimCard {
  id:          number;
  url:         string;
  fleet_id:    number;
  supplier:    string;
  card_number: string;
  expires_at:  Date;
}

export interface TalpaTool {
  id:                        number;
  talpa_hardware_version_id: number;
  shape_left:                Array<number[]>;
  shape_right:               Array<number[]>;
  description:               string;
}