import { IonLabel, IonIcon, IonItem, IonRange, IonSegment, IonSegmentButton, IonToggle, IonButton, IonCard, IonCardContent, IonBadge } from '@ionic/react';
import { gameController, repeatSharp, caretUp, caretDown } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import './JoyPad.css';
import 'react-nipple/lib/styles.css';
import Draggable from 'react-draggable';
import { usePublisher, useRosClock } from '../hooks/rosHooks';
import { useTranslation } from 'react-i18next';

export const Pad: React.FC<any> = ({active=true, position, onDrag, onStop, onDoubleClick, maxDistance, axis, showIcon=true, ...options}) => {
  return (
    <div className="middle" {...options}>
      <div className={active ? "pad": "pad-inactive"} onClick={(e: any) => (e.detail > 1) ? onDoubleClick() : null /* center when double-clicking on the pad / stick */ }>
        <Draggable
            axis={axis}
            handle=".handle"
            bounds={{left: -1 * maxDistance, right: maxDistance,
                    top: -1 * maxDistance, bottom: maxDistance
            }}
            scale={1}
            position={position}
            onDrag={onDrag}
            onStop={onStop}
        >
            <div className="handle stick">
                {showIcon && <IonIcon icon={gameController} style={{width: 30, height: 50, paddingLeft: 10}} color="white"/>}
            </div>
        </Draggable>
      </div>
    </div>
  )
}


enum InputMode {
  Free = "free",  // back to 0 on release (default)
  Sticky = "sticky",  // keep last value
  Lock = "lock",  // don't allow change in value
}

enum SteeringMode {
  Ackerman = "Ackerman",
  Crab = "Crab",
  Turn = "Normal"
}

enum Speed {
  Slow = "0.15",
  Medium = "0.3",
  Fast = "0.7"
}

const Joypad: React.FC<any> = ({ maxAng = 0.50, sessionId, top }) => {
  const publishTwist = usePublisher("/main/drive_mode_switch/cmd", "weeding_drive_mode_switch/CommandWithMode")
  const maxDistance = 130
  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation()

  const {now} = useRosClock();

  const [cmd, setCmdWrapped] = useState({
    angle: 0,
    throttle: 0,  // value in [-maxDistance; maxDistance]
    speed: parseFloat(Speed.Medium),
    mode: SteeringMode.Turn,
    reverse: false,
    steering: InputMode.Free
  })

  const setCmd = (newCommand: any) => {
    if (newCommand.steering === InputMode.Free && cmd.steering !== InputMode.Free) {
      newCommand.angle = 0   // Set angle to 0 when changing to free: usually this is handled by onStop
    }
    setCmdWrapped(newCommand)
  }

  const maxAngDeg = Math.round(maxAng / Math.PI * 180)
  const angleRad = cmd.angle / maxDistance * maxAng
  const angleDeg = angleRad / Math.PI * 180.0
  let front_steering_angle = angleRad * (cmd.reverse ? -1 : 1)
  let rear_steering_angle = angleRad * (cmd.reverse ? 1 : -1)
  let speed = -1. * cmd.throttle / maxDistance * cmd.speed * (cmd.reverse ? -1 : 1)

  if (cmd.mode === SteeringMode.Crab) {
    if (cmd.reverse) {
      front_steering_angle *= -1
    }
    else {
      rear_steering_angle *= -1
    }
  }

  if (cmd.mode === SteeringMode.Ackerman) {
    if (cmd.reverse) {
      front_steering_angle = 0
    }
    else {
      rear_steering_angle = 0
    }
  }

  const [lastPublishTime, setLastPublishTime] = useState<any>(null)
  useEffect(() => {
    const timeSeconds = new Date().getTime() / 1000;

    publishTwist({
      stamp: now(),
      mode: "manual",
      sub_mode: sessionId,
      data: {
        front_steering_angle: front_steering_angle * -1,
        rear_steering_angle: rear_steering_angle * -1,
        speed: speed,
        flags: {
          talpas_must_be_up: true,
          treatment: false,
          ignore_pause: true,
        }
      }
    })
    const timeout = setTimeout(() => setLastPublishTime(timeSeconds), 100)  // Publish message again in 100ms
    return () => clearTimeout(timeout)
  }, [lastPublishTime]); // Publish message every time lastPublishTime is updated
  useEffect(() => () => publishTwist({}), []) // Publish empty message when closing component
  return <>
    <Pad
      style={{ marginBottom: 0, top: top - 50 / 2, position: "absolute", width: "100%" }}
      position={{ y: cmd.throttle, x: cmd.angle }}
      onDoubleClick={() => setCmd({ ...cmd, angle: 0 })}
      onDrag={(e: any, { x, y }: any) => {
        if (cmd.steering !== InputMode.Lock) {
          cmd.angle = x   // When lock the angle cannot change
        }
        setCmd({ ...cmd, throttle: y })
      }}
      onStop={() => {
        if (cmd.steering === "free") cmd.angle = 0
        cmd.throttle = 0
        setCmd({ ...cmd })
      }}
      maxDistance={maxDistance}
      axis={"y"}
    />

    <IonButton onClick={(e: any) => { setShowMore(!showMore) }} style={{ position: "absolute", top: top + 55, right: 0, zIndex: 20001 }} fill="solid" shape="round">
      <IonIcon icon={showMore ? caretUp : caretDown} slot="icon-only" />
    </IonButton>

    {showMore && <IonCard color="primary" style={{ zIndex: 20000, display: "flex", flexDirection: "column", justifyContent: "space-between", top: top + 100 - 50 / 2, position: "absolute", width: "100%" }}>
      <IonCardContent>
        {showMore && <>
          <IonItem>
            <IonSegment onIonChange={evt => setCmd({ ...cmd, speed: parseFloat(`${evt.detail.value || ''}`), throttle: 0 })} value={`${cmd.speed}`}>
              {Object.values(Speed).map(s =>
                <IonSegmentButton value={s as string} style={{ textTransform: "lowercase" }} key={s}>
                  {s} m / s
                </IonSegmentButton>
              )}
            </IonSegment>
          </IonItem>
          <IonItem>
            <IonSegment value={cmd.mode} onIonChange={({ detail: { value } }: any) => setCmd({ ...cmd, mode: value })}>
              {Object.values(SteeringMode).map((m: string) =>
                <IonSegmentButton value={m} key={m}>
                  {t(`${m}`)}
                </IonSegmentButton>
              )}
            </IonSegment>
          </IonItem>
          <IonItem>
            <IonBadge style={{ width: 40 }}>
              {angleDeg.toFixed(0)} °
            </IonBadge>
            <IonSegment value={cmd.steering} onIonChange={(e: any) => setCmd({ ...cmd, steering: e.detail.value })}>
              {Object.values(InputMode).map((s: InputMode) => <IonSegmentButton value={s} key={s}>
              {t(`${s}`)}
              </IonSegmentButton>)}
            </IonSegment>
          </IonItem>
          {cmd.steering === InputMode.Lock && <IonItem>
            <IonButton disabled={angleDeg < -maxAngDeg} onClick={() => setCmd({ ...cmd, angle: cmd.angle - 1 / 180 * Math.PI / maxAng * maxDistance })}>-1</IonButton>
            <IonRange color="secondary" value={angleDeg} onIonChange={(e: any) => setCmd({ ...cmd, angle: e.detail.value / maxAng / 180 * Math.PI * maxDistance })}
              snaps min={-maxAngDeg} max={maxAngDeg} step={1} />
            <IonButton disabled={angleDeg > maxAngDeg} onClick={() => setCmd({ ...cmd, angle: cmd.angle + 1 / 180 * Math.PI / maxAng * maxDistance })}>+1</IonButton>
          </IonItem>}

          <IonItem>
            <IonToggle onIonChange={({ detail: { checked } }: any) => setCmd({ ...cmd, reverse: checked })} checked={cmd.reverse} slot="end" />
            <IonLabel>{t("Reverse robot direction")}</IonLabel>
            <IonIcon icon={repeatSharp} slot="start" />
          </IonItem>
        </>}
      </IonCardContent>
    </IonCard>}
  </>
}

export default Joypad