import { useMutation, useQuery } from '@apollo/client';
import { IonButton, IonCardContent, IonCard, IonCardHeader, IonCardTitle, IonCheckbox, IonSpinner } from '@ionic/react';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { UAParser } from 'ua-parser-js';

import "./NotificationSettings.css";

function base64ToUint8Array(s: string): Uint8Array {
  // simplified from the original: https://raturi.in/blog/webpush-notification-using-python-and-flask/
  return Uint8Array.from(
    Array.from(
      window.atob(
        s
          .replace(/-/g, '+')
          .replace(/_/g, '/')
        + "=".repeat((4 - s.length % 4) % 4)
      )
    )
      .map(c => c.charCodeAt(0))
  );
}

const NOTIFICATIONS_APPLICATION_SERVER_PUBKEY_B64 = "BCpSLArQQGrCvPPyKm1TlGcH7vsmhE73lFtgprfE9mo6bNb66sYK3FxkDd2OM-Lheh__XyK-Ytwx-QhHMauSkeQ";
const NOTIFICATIONS_APPLICATION_SERVER_PUBKEY = base64ToUint8Array(NOTIFICATIONS_APPLICATION_SERVER_PUBKEY_B64);

const this_browser = (() => {
  const p = new UAParser();
  const res = p.getResult();
  return `${res.browser.name} / ${res.os.name}`;
})();

const QUERY_GET_PUSH_SUBSCRIPTIONS = gql(`
  query GetPushSubscriptions {
    push_subscriptions {
      id
      created_at
      user_id
      token_json
      browser_identifier
      taurus_id_whitelist
    }
  }
`);

const QUERY_GET_VISIBLE_TAURUSES = gql(`
  query GetVisibleTauruses {
    tauruses {
      id
    }
  }
`)

const MUTATION_INSERT_PUSH_SUBSCRIPTION = gql(`
  mutation InsertPushSubscription($user_id: Int, $token_json: jsonb, $browser_identifier: String, $taurus_id_whitelist : _int4) {
    insert_push_subscriptions_one(object: {user_id: $user_id, token_json: $token_json, browser_identifier: $browser_identifier, taurus_id_whitelist: $taurus_id_whitelist}) {
      id
      created_at
      user_id
      token_json
      browser_identifier
      taurus_id_whitelist
    }
  }
`);

const MUTATION_DELETE_PUSH_SUBSCRIPTION = gql(`
  mutation DeletePushSubscription($id: Int!) {
    delete_push_subscriptions_by_pk(id:$id) {
      id
    }
  }
`);

const MUTATION_UPDATE_WHITELIST = gql(`
  mutation UpdateWhitelist($id : Int!, $whitelist : _int4) {
    update_push_subscriptions_by_pk(pk_columns: {id: $id}, _set: {taurus_id_whitelist: $whitelist}) {
      id
    }
  }
`);

export interface Keys {
  auth: string;
  p256dh: string;
}

export interface Token {
  endpoint: string;
  expirationTime: string;
  keys: Keys;
}

interface HasuraPushSubscriptionEntry {
  id: number,
  created_at: string,
  user_id: number,
  token_json: Token,
  browser_identifier: string,
  taurus_id_whitelist: number[]
}

const NotificationSettings: React.FC<({ userId: number })> = ({ userId }) => {

  const { data: push_subscriptions_query_res, refetch: refetch_push_subscriptions, observable: push_subscriptions_observable } = useQuery<{ push_subscriptions: HasuraPushSubscriptionEntry[] }>(QUERY_GET_PUSH_SUBSCRIPTIONS);
  const [push_subscriptions, set_push_subscriptions] = useState<HasuraPushSubscriptionEntry[]>([]);
  useEffect(()=>{
    push_subscriptions_observable.subscribe(({data})=>{
      set_push_subscriptions(data.push_subscriptions);
    })
  }, []);
  const [insertPushSubscription, insertPushSubscriptionResult] = useMutation(MUTATION_INSERT_PUSH_SUBSCRIPTION);
  const [deletePushSubscription, deletePushSubscriptionResult] = useMutation(MUTATION_DELETE_PUSH_SUBSCRIPTION);
  
  const [subscription_to_delete_id, set_subscription_to_delete_id] = useState<null | number>(null);

  const { data: visible_tauruses_query_res, refetch: refetch_visible_tauruses } = useQuery<{ tauruses: { id: number }[] }>(QUERY_GET_VISIBLE_TAURUSES);
  const visible_tauruses = visible_tauruses_query_res?.tauruses.map(t => t.id).sort() || [];

  const [update_whitelist, update_whitelist_result] = useMutation(MUTATION_UPDATE_WHITELIST);

  const serviceWorkerRegistration: ServiceWorkerRegistration | undefined = (window as any)["serviceWorkerRegistration"];

  let [permissionState, setPermissionState] = useState<null | PermissionState>(null);
  function checkNotificationPermission() {
    if (serviceWorkerRegistration) {
      serviceWorkerRegistration.pushManager.permissionState().then(setPermissionState)
    }
  }
  useEffect(checkNotificationPermission, []);

  let [ourPushSubscription, setOurPushSubscription] = useState<PushSubscription | null>(null);
  function checkPushSubscription() {
    serviceWorkerRegistration?.pushManager.getSubscription().then(ps => {
      setOurPushSubscription(ps);
    })
  }
  useEffect(checkPushSubscription, [push_subscriptions_query_res]);

  if (serviceWorkerRegistration === undefined) {
    return <p>This browser does not support notifications.</p>
  }

  // used for displaying loading indicator
  const loading = update_whitelist_result.loading || deletePushSubscriptionResult.loading || insertPushSubscriptionResult.loading;  

  const this_browsers_push_subscription = push_subscriptions?.find(p => p.token_json.endpoint === ourPushSubscription?.endpoint);
  
  return <IonCard>
    <IonCardHeader>
      <IonCardTitle>Notifications</IonCardTitle>
    </IonCardHeader>
    <IonCardContent>
      <div style={{display: "flex", alignItems: "center", minHeight: "28px", paddingLeft: "0.5em", marginBottom: "1em"}}>
        <span style={{paddingRight: "1em"}}>The following devices receive notifications for important robot events:</span> {loading && <IonSpinner name='dots' />}
      </div>
      <div style={{width: "100%", overflowX: 'auto'}}>
      <table style={{ minWidth: "20cm" }} className="push-subscriptions-table">
        <thead>
          <tr>
            <th style={{minWidth: "3cm"}}>device</th>
            <th style={{minWidth: "2cm"}}>date added</th>
            {visible_tauruses.map(id=>{
              return <th key={id} className="push-subscriptions-gt-head">GT{id}</th>
            })}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {push_subscriptions?.map(ps => <tr key={`ps-${ps.id}`}>
            <td>{ps.browser_identifier}{ps === this_browsers_push_subscription ? <strong><br />(this device)</strong> : ''}</td>
            <td>{new Date(ps.created_at).toLocaleString()}</td>
            {visible_tauruses.map(id=>{
              return <td key={id} className="push-subscriptions-gt-checkmark">
                <IonCheckbox disabled={subscription_to_delete_id === ps.id} checked={ps.taurus_id_whitelist.includes(id)} onIonChange={e=>{
                  let new_whitelist = [...ps.taurus_id_whitelist, id].filter(tid=>{
                    if (tid !== id) {
                      return true;
                    }
                    return e.detail.checked;
                  });
                  ps.taurus_id_whitelist = new_whitelist;
                  set_push_subscriptions([...push_subscriptions]);
                  update_whitelist({variables: {id: ps.id, whitelist: `{${new_whitelist.join(",")}}`}}).then(refetch_push_subscriptions)
                }} />
              </td>
            })}
            <td>
              <button
                onClick={e => {
                  set_subscription_to_delete_id(ps.id);
                  deletePushSubscription({ variables: { id: ps.id } }).then(() => { refetch_push_subscriptions(); set_subscription_to_delete_id(null) })
                }}
                disabled={subscription_to_delete_id === ps.id}
                className='delete-button'
                style={{
                  pointerEvents: subscription_to_delete_id === ps.id ? 'none' : 'unset',
                  opacity: subscription_to_delete_id === ps.id ? '0.5' : '1.0'
                }}
              >×</button>
            </td>
          </tr>)}
          {push_subscriptions?.length === 0 && <td className="notifications-no-devices" colSpan={3 + visible_tauruses.length}>(no devices added)</td>}
        </tbody>
      </table>
      </div>
      {!this_browsers_push_subscription && (permissionState !== 'denied') && <IonButton
        onClick={async e => {
          let subscription = await serviceWorkerRegistration!.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: NOTIFICATIONS_APPLICATION_SERVER_PUBKEY
          }).catch(e => {
            checkNotificationPermission();
            alert(e);
          });

          if (subscription) {
            insertPushSubscription({
              variables: {
                user_id: userId,
                token_json: subscription.toJSON(),
                browser_identifier: this_browser,
                taurus_id_whitelist: `{${visible_tauruses.join(",")}}`
              }
            }).then(() => {
              refetch_push_subscriptions();
            }).catch(e => alert(e));
            checkNotificationPermission();
          }
        }}
      >
        Enable notifications for this device
      </IonButton>}
      {permissionState === 'denied' && <strong>Notification permission has been denied in this browser.</strong>}
    </IonCardContent>
  </IonCard>
}

export default NotificationSettings;
