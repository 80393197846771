export function make_polynomial(coeffs: number[]) {
    return (x: number) => coeffs
        .map((coef, exp) => coef * Math.pow(x, exp))
        .reduce((acc, v) => acc + v, 0)
}

export function make_gradient(cr: number[], cg: number[], cb: number[]) {
    const pr = make_polynomial(cr);
    const pg = make_polynomial(cg);
    const pb = make_polynomial(cb);
    return (x: number) => {
        const r = pr(x);
        const g = pg(x);
        const b = pb(x);
        return `rgb(${255*r}, ${255*g}, ${255*b})`;
    }
}

// adapted from the seaborn python library for consistent look
// https://github.com/mwaskom/seaborn/blob/9bdfc7484c15894dd35d58d3f16475389dfe4fe5/seaborn/cm.py#L5C1-L262C2
export const COLORMAP_SEABORN_ROCKET = make_gradient(
    [1.16856792e-02, 1.00907499e+00, 7.52920809e+00, -5.58031652e+01,
        2.14564688e+02, -4.29011356e+02, 4.48015958e+02, -2.32743385e+02,
        4.74060185e+01],
    [5.36403549e-03, 1.93536412e+00, -2.68650007e+01, 2.22027284e+02,
        -9.47672196e+02, 2.14167234e+03, -2.59591380e+03, 1.60163978e+03,
        -3.95916535e+02],
    [9.76295076e-02, 1.00443501e+00, 2.44148109e+00, -3.50225074e+01,
        1.95782789e+02, -5.94787310e+02, 9.26253583e+02, -6.95182323e+02,
        2.00294047e+02]
);

export function relative_luminance(RsRGB : number, GsRGB : number, BsRGB : number) {
    // all three parameters in range [0; 1].
    // text color selection: https://github.com/mwaskom/seaborn/blob/9bdfc7484c15894dd35d58d3f16475389dfe4fe5/seaborn/matrix.py#L257
    // definition of luminance: https://www.w3.org/WAI/GL/wiki/Relative_luminance
    const R = RsRGB <= 0.03928 ? RsRGB/12.92 : Math.pow(((RsRGB+0.055)/1.055), 2.4);
    const G = GsRGB <= 0.03928 ? GsRGB/12.92 : Math.pow(((GsRGB+0.055)/1.055), 2.4);
    const B = BsRGB <= 0.03928 ? BsRGB/12.92 : Math.pow(((BsRGB+0.055)/1.055), 2.4);
    return 0.2126 * R + 0.7152 * G + 0.0722 * B;
}

export function TEXT_COLOR_SEABORN_ROCKET(rgb : string) {
    const [r, g, b] = rgb.replace('rgb', '').replace('(', '').replace(')','').split(', ').map(parseFloat).map(x=>x/255);
    const L = relative_luminance(r, g, b);
    return L > .408 ? "rgb(38.25, 38.25, 38.25)" : "white";
}