import { IonIcon, IonLabel, IonButton, IonItem, IonSelect, IonSelectOption, IonItemDivider, IonGrid, IonRow, IonCol, IonBadge, IonInput, IonContent, IonSegment, IonSegmentButton, IonToggle, } from '@ionic/react';
import React, {useContext, useState} from 'react';
import { code, codeSlash, list, shuffle, trash, } from 'ionicons/icons';
import { FieldEditorContext, canHaveLimits, deleteArea, deleteLimit, updateArea, useGetArea, Side, } from './useFieldEditorData';
import { DefinitionEdit } from './DefinitionEdit';


const LimitEdit: React.FC<any> = ({extremity, area}: any) => {
  const limits = area[extremity]
  const {areas, setAreas, selectedObject} = useContext(FieldEditorContext)
  const {getAreaColor, getAreaName} = useGetArea(areas)  // Helper methods
  const edit = selectedObject?.area_id === area.id
  const limitingAreas = new Set(limits.map(({area_id}: any) => area_id))
  // Limits that have not yet been set can be added (also: should not be the own area)
  const addableAreas = areas.filter(({id, def}: any) => !limitingAreas.has(id) && id !== area.id && canHaveLimits(area) && !def.type.startsWith("extremity"))

  return <IonCol>
      {limits.map((limit: any) => <div key={limit.area_id}>
        {edit ? <IonItem key={limit.area_id}>
          <IonItem  style={{backgroundColor: `${getAreaColor(limit.area_id)}`, padding: '5px'}}>
            <IonSelect value={limit.area_id} onIonChange={(e: any) => {
              limit.area_id = parseInt(e.detail.value)
              setAreas([...areas])
            }}>
              <IonSelectOption value={limit.area_id}>{getAreaName(limit.area_id)}</IonSelectOption>
              {addableAreas.map((a: any) => <IonSelectOption key={a.id} value={a.id}>{getAreaName(a.id)}</IonSelectOption>)}
            </IonSelect>
          </IonItem>
          {" "}
          <IonItem><IonSelect value={limit.side} onIonChange={(e: any) => {
            limit.side = e.detail.value
            setAreas([...areas])
          }}>
            {(["left", "right"] as Side[]).map((side: Side) => <IonSelectOption key={side} value={side}>{side}</IonSelectOption>)}
          </IonSelect></IonItem>
          {/* close button */ edit && <IonButton slot="end" onClick={() => setAreas(deleteLimit(areas, area.id, extremity, limit.area_id))} fill="clear">
            <IonIcon slot="icon-only" icon={trash}/>
          </IonButton>}
        </IonItem>
        : <IonItem lines="none" color="transparent">
            <IonLabel slot={extremity} style={{backgroundColor: getAreaColor(limit.area_id), padding: "5px"}}>
              {getAreaName(limit.area_id)}  {limit.side}
            </IonLabel>
          </IonItem>}
      </div>)}
      {edit && addableAreas.length > 0 && <IonItem>
        <IonLabel>Add limit</IonLabel>
        <IonSelect onIonChange={(e: any) => setAreas(
          updateArea(areas, {...area, [extremity]: [...limits, {area_id: e.detail.value, side: "left"}]})
        )}>
          {addableAreas.map((a: any) => <IonSelectOption value={a.id} key={a.id}>{getAreaName(a.id)}</IonSelectOption>)}
        </IonSelect>
      </IonItem>}
  </IonCol>
}


export const DeleteAreaButton: React.FC<any> = ({area_id, children, ...props }: any) => {
  const {areas, setAreas, } = useContext(FieldEditorContext)
  return <IonButton {...props} onClick={() => setAreas(deleteArea(areas, area_id))}>
    {children}
  </IonButton>
}


const Area: React.FC<any> = ({area}: any) => {
  const {areas, setAreas, selectedObject, setSelectedObject, number_of_bed_rows} = useContext(FieldEditorContext)
  const {getAreaColor, getAreaName} = useGetArea(areas)
  const selected = selectedObject?.area_id === area.id
  return <IonRow style={{
      marginBottom: "5px",
    }}>
    <LimitEdit area={area} extremity={"start"}/>
    <IonCol style={{
      backgroundColor: `${getAreaColor(area.id)}`,
    }}>
      {!selected && <IonItem lines='none' onClick={() => setSelectedObject({area_id: area.id})} color="transparent">
        <IonLabel><strong>{getAreaName(area.id)}</strong></IonLabel>
        {area.def.type.startsWith("extremity") && `${area.def.safe_space_m} m`}
        {area.def.type === "rows" && `${area.def.beds} x ${number_of_bed_rows} rows`}
      </IonItem>}
      {selected && <IonItem lines='none'>
        <IonLabel><strong>{getAreaName(area.id)}</strong></IonLabel>
        <IonBadge slot="end"><code>A{area.id}</code></IonBadge>
        <DeleteAreaButton area_id={area.id} fill="clear">
          <IonIcon slot="icon-only" icon={trash}/>
        </DeleteAreaButton>
      </IonItem>}
      <DefinitionEdit area={area}/>
    </IonCol>
    <LimitEdit area={area} extremity={"end"}/>
  </IonRow>
}


const AreaSwitcher: React.FC<any> = ({}: any) => {
  const {
    areas, setData, points 
  } = useContext(FieldEditorContext)
  const areasSorted = areas.sort((a: any, b: any) => a.id - b.id)
  const [areaId1, setAreaId1] = useState<number|null>(null)
  const [areaId2, setAreaId2] = useState<number|null>(null)
  const maxAreaId = Math.max(...areas.map(({id}: any) => id))
  
  // Method to fix the ids
  const r = (id: number) => {
    if (id === areaId1) return areaId2
    if (id === areaId2) return areaId1
    return id
  }

  const onClick = () => {
    if (!areaId1 || !areaId2) {return}
    // Use -1 placeholder and ensure that both ids are completely exchanged
    const newAreas = areas.map((a: any) => ({
      ...a, id: r(a.id), def: {...a.def, area_id: r(a.def.area_id)},
      start: a.start.map((l: any) => ({...l, area_id: r(l.area_id)})),
      end: a.end.map((l: any) => ({...l, area_id: r(l.area_id)})),
    }))
    const newPoints = Object.fromEntries(Object.entries(points).map(([area_id, areaPoints]: any) => [r(parseInt(area_id)), Object.fromEntries(
      Object.entries(areaPoints).map(([pointName, point]: any) => [pointName, {...point, area_id: r(point.area_id)}]))]
    ))
    setData((prev: any) => ({...prev, fieldConfig: {...prev.fieldConfig, areas: newAreas}, points: newPoints}))
  }
  return <>
    <IonItem>
      <IonLabel>Replace area</IonLabel>
      <IonSelect value={areaId1} onIonChange={(e: any) => setAreaId1(e.detail.value)}>
        {areasSorted.map((area: any) => <IonSelectOption value={area.id}>A{area.id}</IonSelectOption>)}
      </IonSelect>
    </IonItem>
    <IonItem>
      <IonLabel>By area</IonLabel>
      <IonSelect value={areaId2} onIonChange={(e: any) => setAreaId2(e.detail.value)}>
          {[...Array(maxAreaId + 1).keys()].map((areaId: number) => <IonSelectOption value={areaId + 1}>A{areaId + 1}</IonSelectOption>)}
      </IonSelect>
    </IonItem>
    {areaId1 && areaId2 && <IonButton onClick={onClick} expand="full">Replace A{areaId1} by A{areaId2}</IonButton>}
  </>
}


export const AreaList: React.FC<any> = ({oldConfig, children}) => {
  const {
    areas, addDefaultArea, data,
  } = useContext(FieldEditorContext)
  const {getAreaName} = useGetArea(areas)
  const [view, setView] = useState<string>("")
  if (!areas) {return null}
  // Replace end by A to ensure that A1-end comes after A1-start
  const areasSorted = areas.sort((a: any, b: any) => getAreaName(a.id).replace("end", "Z").localeCompare(getAreaName(b.id).replace("end", "Z")))

  const options = [list, code, shuffle].concat(oldConfig ? [codeSlash] : [])
  const bar = <IonItem>
    {options.map((v: string) =>
      <IonButton key={v} fill={view === v ? "solid" : "clear"} onClick={() => setView(view === v ? "" : v)}><IonIcon icon={v} slot="icon-only"/></IonButton>)}
    {children}
  </IonItem>

  return <IonGrid>
    {bar}

    {/* Show different views (mostly for debugging) */}
    {view === list && <>
      <IonRow>
        <IonCol>
          <IonItemDivider>Start limits</IonItemDivider>
        </IonCol>
        <IonCol>
          <IonItemDivider>Areas</IonItemDivider>
        </IonCol>
        <IonCol>
          <IonItemDivider>End limits</IonItemDivider>
        </IonCol>
      </IonRow>
      {areasSorted.map((area: any) => <Area area={area} key={area.id}/>)}
      <IonRow>
        <IonCol/>
        <IonCol>
          <IonButton expand="block" fill="clear" onClick={addDefaultArea}>Add area</IonButton>
        </IonCol>
        <IonCol/>
      </IonRow>
    </>}

    {view === code &&
      <pre>
        {JSON.stringify(data.fieldConfig, null, 2)}
      </pre>
    }
    {view === codeSlash && <>
      <pre>
        {JSON.stringify(oldConfig, null, 2)}
      </pre>
    </>}
    {view === shuffle && <AreaSwitcher/>}
    {view !== "" && bar}
  </IonGrid>
}