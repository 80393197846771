import { ApolloError } from '@apollo/client';
import { IonItem, IonProgressBar, IonBadge} from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';


export const GraphQLState: React.FC<{loading: boolean, error: ApolloError|undefined}> = ({loading, error}) => {
    // Try to use a consistent method to show GraphQL loading and error info
    const {t} = useTranslation()
    return <>
        {loading && <IonProgressBar type="indeterminate"/>}
        {error && <IonItem><IonBadge color="danger" slot="start">{t("Error")}</IonBadge>{JSON.stringify(error)}</IonItem>}
    </>
}