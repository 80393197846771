import { IonContent, IonPage, IonProgressBar } from '@ionic/react';
import React, {useState} from 'react';
import { useParams } from 'react-router';
import { gql} from '@apollo/client';
import Toolbar from '../../components/Toolbar';
import { useQueryWithElevatedRole } from '../../hooks/hasuraHooks';
import { statusCompValue, StatusList } from '../../components/StatusList';


export const EVENT_FRAGMENT = gql`
  fragment EventFields on events {
    id
    type {id name}
    diagnostics
    created_at
    is_sim
  }
`


const Event: React.FC = () => {
  const { id } = useParams<{ id: string; }>();
  const { loading, data, } = useQueryWithElevatedRole(gql`
    ${EVENT_FRAGMENT}
    query Event($id: Int!) {
      events_by_pk(id: $id) {
        ...EventFields
      }
  }`, {"variables": {"id": parseInt(id)}});

  const sortedStatuses = data?.events_by_pk?.diagnostics?.statuses?.sort((a: any, b: any) => statusCompValue(a) - statusCompValue(b))

  return <IonPage>
    <Toolbar name={`Event ${id}`}></Toolbar>
    <IonContent fullscreen>
      {loading && <IonProgressBar type="indeterminate"/>}
      {sortedStatuses && <StatusList statuses={sortedStatuses}/>}
    </IonContent>
  </IonPage>
};

export default Event;
