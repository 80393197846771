import { IonButton, IonButtons, IonCardContent, IonCardHeader, IonCardTitle, IonIcon, IonItem, IonLabel, IonPopover } from '@ionic/react';
import { ellipsisVertical, } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useCallService, useParam, usePublisher, useTopic } from '../hooks/rosHooks';
import ConfirmButton from './ConfirmButton';
import { useTranslation } from 'react-i18next';
import { useDeveloper } from '../hooks/useDeveloperMode';

export enum Visibility {
  Always,
  DevOnly
}

export const AdditionalServicesButton: React.FC<any> = ({showMoreOptions = true, ...props}: any) => {
  const callService = useCallService()
  const {developer: developer} = useDeveloper();
  const { t } = useTranslation()
  const [event, setEvent] = useState<any>(null)

  const {value: hardwareVersion} = useParam("/main/taurus21_hardware_interface/hardware_version")

  const [services, setServices] = useState<[Visibility, string, Function][]>([]);
  useEffect(()=>{
    if (hardwareVersion === undefined) {
      return;
    }
    if (services.length !== 0) {
      // services already initialized
      return
    }
    const shutdown = () => callService("/shutdown_all_computers", "std_srvs/Empty", {})
    const new_services : [Visibility, string, Function][] = [];
    new_services.push([Visibility.Always, "Shutdown", shutdown])
    new_services.push([Visibility.Always, "Restart Software" , () => callService("/restart_ros", "std_srvs/Empty", {})])
    new_services.push([Visibility.Always, "Power Cycle" , () => callService("/power_cycle", "std_srvs/Empty", {})])
    if (hardwareVersion as string < "2023-09-20") {
      // electronics 2021
      // NOTE: the 'Shutdown' string gets translated in the component
      new_services.push([Visibility.DevOnly, "Ring alarm" , () => callService("/main/pcu_driver/set_buzzer_flag", "std_srvs/SetBool", {data: true})])
    } else {
      // electronics 2023
      new_services.push([Visibility.Always, "Ring alarm", () => callService("/buzzer", "std_srvs/Trigger", {})])
      new_services.push([Visibility.Always, "Trigger e-stop", ()=>callService("/estop_control/trigger", "std_srvs/Trigger", {})])
      new_services.push([Visibility.DevOnly, "Reset e-stop", ()=>callService("/estop_control/reset", "std_srvs/Trigger", {})])
      new_services.push([Visibility.DevOnly, "Reboot main computer" , () => callService("/main/pcu_driver/set_reboot_flag", "std_srvs/SetBool", {data: true})])
      new_services.push([Visibility.DevOnly, "Power-cycle main computer" , () => callService("/main/pcu_driver/set_rock_power_cycle_flag", "std_srvs/SetBool", {data: true})])
      new_services.push([Visibility.DevOnly, "Power-cycle 48 V PoE (router)", ()=>callService("/power/powercycle_poe_48v", "std_srvs/Trigger", {})])
      new_services.push([Visibility.DevOnly, "Power-cycle bynav GPS module", ()=>callService("/power/reset_bynav", "std_srvs/Trigger", {})])
      new_services.push([Visibility.DevOnly, "Power-cycle distribution box 48 V", ()=>callService("/power/powercycle_distribox_talpa_48v", "std_srvs/Trigger", {})])
      new_services.push([Visibility.DevOnly, "Power-cycle distribution box 24 V", ()=>callService("/power/powercycle_distribox_talpa_24v", "std_srvs/Trigger", {})])
      new_services.push([Visibility.DevOnly, "Power-cycle axle box (front) 24 V", ()=>callService("/power/powercycle_axlebox_front_24v", "std_srvs/Trigger", {})])
      new_services.push([Visibility.DevOnly, "Power-cycle axle box (rear) 24 V", ()=>callService("/power/powercycle_axlebox_rear_24v", "std_srvs/Trigger", {})])
      new_services.push([Visibility.DevOnly, "Reset brake chopper error state", ()=>callService("/brake_chopper_reset_error_state", "std_srvs/Empty", {})])
    }
    setServices(new_services)
  }, [callService, hardwareVersion]);

  const i_charge_relais = usePublisher("/charger/i_charge_relais", "std_msgs/Bool");
  const fan_taurus = usePublisher("/power/fan_taurus", "std_msgs/Bool");

  const shownServices = services.filter(([visibility, _, __]: [Visibility, string, Function]) => (visibility === Visibility.Always || developer))
  if (shownServices.length === 0) {return null /* E.g. in simulation */}
  return <>
    <IonButton onClick={setEvent} {...props}>
      <IonIcon icon={ellipsisVertical} slot="icon-only"/>
    </IonButton>

    <IonPopover event={event} isOpen={!!event} onDidDismiss={() => setEvent(null)} className="wide-popover">
        {shownServices.map((entry: any) => {
          const [_visibility, identifier, call] = entry;
          return <ConfirmButton
                onClick={()=>call()} fill="clear"
                text={t("Confirm") + ":\n" + t(identifier)}
                >{t(identifier)}</ConfirmButton>
        })}

        {developer && (hardwareVersion!==undefined) && hardwareVersion as string >= "2023-09-20" && <>
          <IonCardHeader style={{display: 'flex'}}>
            <IonCardTitle style={{flexGrow: 1}}>Power Tests</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonItem>
              <IonLabel>I charge relais</IonLabel>
              <IonButtons>
                <IonButton style={{width: "100%"}} onClick={()=>i_charge_relais({data: true})}>On</IonButton>
                <IonButton style={{width: "100%"}} onClick={()=>i_charge_relais({data: false})}>Off</IonButton>
              </IonButtons>
            </IonItem>
            <IonItem>
              <IonLabel>Fan taurus</IonLabel>
              <IonButtons>
                <IonButton style={{width: "100%"}} onClick={()=>fan_taurus({data: true})}>On</IonButton>
                <IonButton style={{width: "100%"}} onClick={()=>fan_taurus({data: false})}>Off</IonButton>
              </IonButtons>
            </IonItem>
          </IonCardContent>
        </>}

    </IonPopover>
  </>
}
