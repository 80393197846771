import { IonPage } from '@ionic/react';
import React from 'react';
import { useParams } from 'react-router';
import { gql, useSubscription } from '@apollo/client';
import { ShowEvaluation } from '../../components/ShowEvaluation';
import { AddEvaluation, EvaluationItemFromId, EVALUATION_FRAGMENT } from '../../components/EvaluationItem';
import { useQueryWithElevatedRole, useSubscriptionWithElevatedRole } from '../../hooks/hasuraHooks';

const Evaluation: React.FC = () => {
  const { id, name } = useParams<{ id: string; name: string}>();
  const ids = id.split(",");

  const { data } = useSubscriptionWithElevatedRole(gql`
    ${EVALUATION_FRAGMENT}
    subscription GetEvaluationsById($ids: [Int!]) {
      evaluations(where: {id: {_in: $ids}}){
        id
        ...EvaluationFields
      }
    }
  `, {
    variables: {"ids": ids},
  });
  const height = data?.evaluations ? (window.visualViewport ? window.visualViewport.height / data.evaluations.length : window.innerHeight / data.evaluations.length) : null;
  
  return (
    <IonPage>
      {data?.evaluations && data.evaluations.map((e:any)=><div key={e.id} style={{"height": `${height}px`, "overflow": "hidden", "backgroundColor": "white"}}>
        <ShowEvaluation style={null} evaluation={e} height={height} AddEvaluation={AddEvaluation} EvaluationItemFromId={EvaluationItemFromId} />
      </div>)}
    </IonPage>
  );
};
export default Evaluation;