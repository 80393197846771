import React, {useState} from 'react';
import {IonCard, IonCol, IonChip, IonRow, IonRadio, IonRadioGroup, IonCardSubtitle, IonIcon, IonCheckbox, IonModal, IonToolbar, IonCardContent, IonCardHeader, IonToast, IonButton, IonContent, IonTitle, IonLabel, IonPage, IonList, IonItem, useIonViewDidEnter, IonBadge, IonCardTitle } from '@ionic/react';
import ErrorCard from './ErrorCard';
import { copy, gameController, logIn } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';


const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};


const Connect: React.FC<any> = ({current_config}) => {

  const wifi_ssid = current_config?.taurusbox_configs[0]?.wifi_ssid
  const wifi_password = current_config?.taurusbox_configs[0]?.wifi_password

  const taurus_id = current_config?.taurus_configs[0]?.taurus_id;
  const domain = taurus_id && `gt${taurus_id}.robot.farming-revolution.com`;

  const [clipboardContent, setClipboardContent] = useState("");

  const [showToast, setShowToast] = useState(false)
  const { t } = useTranslation();

  function click_copy_button(str : string) {
    setClipboardContent(str);
    copyToClipboard(str);
    setShowToast(true)
  }

  if (!taurus_id) {
    return null
  }

  return <>
  <IonCard>
    <IonCardHeader><IonCardSubtitle>{t("Connect to GT")} {taurus_id}</IonCardSubtitle></IonCardHeader>
    <IonCardContent>
      <IonItem>{t("Turn key and press power button to turn on")}</IonItem>
      <IonItem>
          <IonLabel>{t("WIFI SSID")}</IonLabel>
          <strong>
            {wifi_ssid &&
              <>
              {wifi_ssid}
              <IonButton onClick={() => {click_copy_button(wifi_ssid)}} fill="clear">
                <IonIcon icon={copy} slot="icon-only"/>
              </IonButton>
              </>
            }
          </strong>
        </IonItem>
        <IonItem>
          <IonLabel>{t("WIFI Password")}</IonLabel>
          <strong style={{userSelect: "text"}}>
            {wifi_password &&
              <>
              {wifi_password}
              <IonButton onClick={() => {click_copy_button(wifi_password)}} fill="clear">
                <IonIcon icon={copy} slot="icon-only"/>
              </IonButton>
              </>
            }
          </strong>
        </IonItem>

        <IonItem className="open-robot-control">
          <IonIcon icon={gameController} slot="start"/>
          <IonLabel>
            {t("Open robot control")}
          </IonLabel>
          {domain && <a href={`https://${domain}/?t=${(new Date()).toISOString()}`} >{`https://${domain}`}</a>}
        </IonItem>
      </IonCardContent>
      </IonCard>

      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={`copied '${clipboardContent}' to clipboard.`}
        duration={200}
      />
    </>
};

export default Connect;
