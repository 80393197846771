import { gql, MutationFunction } from '@apollo/client';
import { IonBadge, IonButton, IonCardContent, IonCardHeader, IonCardTitle, IonChip, IonContent, IonIcon, IonInput, IonItem, IonItemDivider, IonPage, IonPopover, IonProgressBar, IonSearchbar } from '@ionic/react';
import { open } from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import Loading from '../../components/Loading';
import Toolbar from '../../components/Toolbar';
import { useMutationWithElevatedRole, useQueryWithElevatedRole } from '../../hooks/hasuraHooks';
import { RobotConfigFields } from './Fleets';


const AddToFleetContent: React.FC<any> = ({id, componentType, fleet, onSuccess}: any) => {
  const mapping: any = {
    gt: "tauruses",
    gen: "generators",
    aql: "aquilas",
    tlp: "talpas",
    tbox: "taurusboxes",
    robot_config: "robot_configs",
  }
  // robot_config has a 'fleet' which is not supposed to change: we create a new robot config to change the fleet
  // all components (except robot_config) have a 'current_fleet' which can change and sets the current authorization for the components
  const fleetField = ["robot_config"].includes(componentType) ? "fleet" : "current_fleet"
  const mutation = gql`
    mutation SetFleet($id: Int!, $fleet_id: Int!) {
      update_${mapping[componentType]}(
        where: {
          id: {_eq: $id}
        },
        _set: {${fleetField}_id: $fleet_id}
      ) {
        affected_rows
      }
    }
  `
  const query = gql`
    query Component($id: Int!) {
      components: ${mapping[componentType]}_by_pk(id: $id) {
        ${fleetField} {
          id
          name
        }
      }
    }
  `
  const {data, loading} = useQueryWithElevatedRole(query, {variables: {id: id}})
  const [setFleet, status] = useMutationWithElevatedRole(mutation)
  return <>
    {loading && <IonProgressBar type="indeterminate"/>}
    {data?.components[fleetField] && <IonItem>Currently on fleet {data.components[fleetField].name}</IonItem>}
    <IonButton expand='full' onClick={() => setFleet({variables: {id: id, fleet_id: fleet.id}}).then(onSuccess)}>Move to here</IonButton>
  </>
}


const ComponentInRobotConfig: React.FC<any> = ({componentType, id, idsInFleet, fleet}: any) => {
  const componentIsInFleet = idsInFleet.includes(id)
  const [event, setEvent] = useState<any>(null)
  return <>
    <IonPopover event={event} isOpen={!!event} onDidDismiss={() => setEvent(null)}>
      <AddToFleetContent id={id} componentType={componentType} fleet={fleet} onSuccess={() => {
        idsInFleet.push(id)
        setEvent(null)
      }}/>
    </IonPopover>
    <IonChip onClick={componentIsInFleet ? undefined : setEvent} color={componentIsInFleet ? undefined : "danger"} outline>
      {componentType}{id}
      {!componentIsInFleet && <> (not in fleet)</>}
    </IonChip>
  </>
}


const RobotConfigListItem: React.FC<any> = ({robot_config, fleetComponents, fleet}: any) => {
  const standaloneAquila = robot_config.aquila_configs.find(({standalone}: any) => standalone)
  const fleetRobotConfigIds = robot_config.fleet_id === fleet.id ? [robot_config.id] : []
  return <li key={robot_config.id} style={{cursor: "auto"}}>
    <IonItemDivider>
      <IonBadge slot="start">
        {standaloneAquila 
          ? <strong>AQL{standaloneAquila.aquila_id} (standalone)</strong>
          : <strong>GT {robot_config?.taurus_configs[0]?.taurus_id}</strong>
        }
      </IonBadge>
      <ComponentInRobotConfig fleet={fleet} id={robot_config.id} key={`robotConfig${robot_config.id}`} componentType={"robot_config"} idsInFleet={fleetRobotConfigIds}/>

      <IonButton href={`/robot/${robot_config.id}/`} slot="end" fill='clear'>
        <IonIcon icon={open} slot="icon-only"/>
      </IonButton>

    </IonItemDivider>

    <div className='robotComponents'>
      {robot_config?.generator_id && <ComponentInRobotConfig
        fleet={fleet} id={robot_config.generator_id} key={`gen${robot_config.generator_id }`} componentType={"gen"} idsInFleet={fleetComponents.gen}/>}

      {robot_config?.taurus_configs?.map(({taurus_id}: any) => <ComponentInRobotConfig
        fleet={fleet} id={taurus_id} key={`gt${taurus_id}`} componentType={"gt"} idsInFleet={fleetComponents.gt}/>)}
      {robot_config?.taurusbox_configs?.map(({taurusbox_id}: any) => <ComponentInRobotConfig
        fleet={fleet} id={taurusbox_id} key={`tbox${taurusbox_id}`} componentType={"tbox"} idsInFleet={fleetComponents.tbox}/>)}
      {robot_config?.aquila_configs?.map(({aquila_id}: any) => <ComponentInRobotConfig
        fleet={fleet} id={aquila_id} key={`aql${aquila_id}`} componentType={"aql"} idsInFleet={fleetComponents.aql}/>)}
      {robot_config?.talpa_configs?.map(({talpa_id}: any) => <ComponentInRobotConfig
        fleet={fleet} id={talpa_id} key={`tlp${talpa_id}`} componentType={"tlp"} idsInFleet={fleetComponents.tlp}/>)}
    </div>
  </li>
}


const TEAMS_QUERY = gql`
${RobotConfigFields}
query PermissionsQuery{
  teams {
    id
    name,
    description,
    fleets {
      role
      fleet_id
    }
    users {
      role
      user_id
    }
  }
  fleets {
    id,
    name,
    description,
    generators {
      id
    }
    talpas {
      id
    }
    taurusboxes {
      id
    }
    tauruses(order_by: {id: asc}) {
      id
      taurus_configs(order_by: {id: desc}, limit: 1) {
        robot_config {
          ...RobotConfigFields
        }
      }
    }
    aquilas(order_by: {id: asc}) {
      id
      aquila_configs(order_by: {id: desc}, limit: 1) {
        standalone
        robot_config {
          ...RobotConfigFields
        }
      }
    }
  }
  users {
    id
    name
    created_at
  }
}`;

interface Data {
  teams:  Team[];
  fleets: Fleet[];
  users:  User[];
}

interface Fleet {
  name:          string;
  description:   string;
  generators:    Component[];
  aquilas:       Component[];
  talpas:        Component[];
  tauruses:      Component[];
  taurusboxes:   Component[];
  robot_configs: RobotConfig[];
  id:            number;
}

interface Component {
  id:         number;
  __typename: string;
}

interface RobotConfig extends Component {
  aquila_configs : [{aquila_id : number}]
  talpa_configs : [{talpa_id : number}]
  taurus_configs : [{taurus_id : number}]
  taurusbox_configs : [{taurusbox_id : number}]
}

interface Team {
  name:        string;
  description: string;
  fleets:      TeamFleet[];
  users:       TeamUser[];
  id:          number;
}

interface TeamFleet {
  role:       Role;
  fleet_id:   number;
}

enum Role {
  Editor = "editor",
  Owner = "owner",
  Viewer = "viewer",
}

interface TeamUser {
  role:       Role;
  user_id:    number;
}

interface User {
  id:         number;
  name:       string;
  created_at: Date;
}

const QUERY_ADD_USER_TO_TEAM = gql(`
mutation AddUserToTeam ($role: String!, $team_id: Int!, $user_id: Int!) {
  insert_users_m2m_teams_one(object: {role: $role, user_id: $user_id, team_id: $team_id}) {
    id
  }
}`)

const QUERY_REMOVE_USER_FROM_TEAM = gql(`
mutation ($user_id: Int!, $team_id: Int!) {
  delete_users_m2m_teams(where: {_and: [
    {user_id: {_eq: $user_id}},
    {team_id: {_eq: $team_id}}
  ]}) {
    affected_rows
  }
}`);

const QUERY_CHANGE_USER_ROLE = gql(`
mutation ($user_id: Int!, $team_id: Int!, $new_role: String!) {
  update_users_m2m_teams(
    where: {_and: [
      {user_id: {_eq: $user_id}},
      {team_id: {_eq: $team_id}}]
    },
    _set: {role: $new_role}
  ) {
    affected_rows
  }
}`)

const QUERY_ADD_TEAM = gql(`
mutation ($name:String!, $description :String){
  insert_teams_one (
    object: {
      name: $name,
      description: $description
    }
  ){
    id
  }
}`)

const QUERY_ADD_FLEET = gql(`
mutation ($name:String!, $description :String){
  insert_fleets_one (
    object: {
      name: $name,
      description: $description
    }
  ){
    id
  }
}`)

const QUERY_ADD_TEAM_TO_FLEET = gql(`
mutation ($role: String!, $team_id: Int!, $fleet_id: Int!) {
  insert_fleets_m2m_teams_one(object: {role: $role, fleet_id: $fleet_id, team_id: $team_id}) {
    id
  }
}`)

const QUERY_REMOVE_TEAM_FROM_FLEET = gql(`
mutation ($fleet_id: Int!, $team_id: Int!) {
  delete_fleets_m2m_teams(where: {_and: [
    {fleet_id: {_eq: $fleet_id}},
    {team_id: {_eq: $team_id}}
  ]}) {
    affected_rows
  }
}`);

const QUERY_CHANGE_TEAM_ROLE = gql(`
mutation ($fleet_id: Int!, $team_id: Int!, $new_role: String!) {
  update_fleets_m2m_teams(
    where: {_and: [
      {fleet_id: {_eq: $fleet_id}},
      {team_id: {_eq: $team_id}}]
    },
    _set: {role: $new_role}
  ) {
    affected_rows
  }
}`)

interface Handles {
  addUserToTeam: (user: User, team: Team, role: Role) => void;
  removeUserFromTeam: (user: User, team: Team) => void;
  changeUserRole: (user : User, team: Team, new_role : Role) => void;
  addTeamToFleet: (team: Team, fleet : Fleet, role: Role) => void;
  removeTeamFromFleet: (team: Team, fleet : Fleet) => void;
  changeTeamRole: (team: Team, fleet : Fleet, new_role : Role) => void;
}

const Teams: React.FC = () => {

  const query_res = useQueryWithElevatedRole(TEAMS_QUERY, {fetchPolicy: 'network-only'});

  const [mutAddUserToTeam, mutAddUserToTeamStatus] = useMutationWithElevatedRole(
    QUERY_ADD_USER_TO_TEAM,
    {
      refetchQueries: [
        'PermissionsQuery'
      ]
    });
  const [mutRemoveUserFromTeam, mutRemoveUserFromTeamStatus] = useMutationWithElevatedRole(
    QUERY_REMOVE_USER_FROM_TEAM,
    {
      refetchQueries: [
        'PermissionsQuery'
      ]
    });
  const [mutChangeUserRole, mutChangeUserRoleStatus] = useMutationWithElevatedRole(
    QUERY_CHANGE_USER_ROLE,
    {
      refetchQueries: [
        'PermissionsQuery'
      ]
    });
  
  const [mutAddTeam, mutAddTeamStatus] = useMutationWithElevatedRole(
    QUERY_ADD_TEAM,
    {
      refetchQueries: [
        'PermissionsQuery'
      ]
    });

  const [mutAddFleet, mutAddFleetStatus] = useMutationWithElevatedRole(
    QUERY_ADD_FLEET,
    {
      refetchQueries: [
        'PermissionsQuery'
      ]
    });
  const [mutAddTeamToFleet, mutAddTeamToFleetStatus] = useMutationWithElevatedRole(
    QUERY_ADD_TEAM_TO_FLEET,
    {
      refetchQueries: [
        'PermissionsQuery'
      ]
    });
  const [mutRemoveTeamFromFleet, mutRemoveTeamFromFleetStatus] = useMutationWithElevatedRole(
    QUERY_REMOVE_TEAM_FROM_FLEET,
    {
      refetchQueries: [
        'PermissionsQuery'
      ]
    });
  const [mutChangeTeamRole, mutChangeTeamRoleStatus] = useMutationWithElevatedRole(
    QUERY_CHANGE_TEAM_ROLE,
    {
      refetchQueries: [
        'PermissionsQuery'
      ]
    });
  
  
  const loading = (
       query_res.loading
    || mutAddUserToTeamStatus.loading
    || mutRemoveUserFromTeamStatus.loading
    || mutChangeUserRoleStatus.loading
    || mutAddTeamStatus.loading
    || mutAddFleetStatus.loading
    || mutAddTeamToFleetStatus.loading
    || mutRemoveTeamFromFleetStatus.loading
    || mutChangeTeamRoleStatus.loading
  );

  const error = (
       mutAddUserToTeamStatus.error
    || mutRemoveUserFromTeamStatus.error
    || mutChangeUserRoleStatus.error
    || mutAddTeamStatus.error
    || mutAddFleetStatus.error
    || mutAddTeamToFleetStatus.error
    || mutRemoveTeamFromFleetStatus.error
    || mutChangeTeamRoleStatus.error
  )
  
  const data : Data | undefined = query_res.data;

  const [selectedTeamId, setSelectedTeamId] = useState(1);
  const [selectedFleetId, setSelectedFleetId] = useState(1);

  const selected_team = data?.teams.find(t=>t.id===selectedTeamId);
  const selected_fleet = data?.fleets.find(f=>f.id===selectedFleetId);
  
  const users_in_team = [];
  const users_outside_team = [];
  for (const user of data?.users || []) {
    if (selected_team?.users.find(tu=>tu.user_id===user.id)) {
      users_in_team.push(user)
    } else {
      users_outside_team.push(user);
    }
  }
  // Get ids that belong to team. They could still be used within a robot config, but that is an error => dangling components
  const fleetComponents: any = {
    gen: selected_fleet?.generators?.map(({id}: any) => id),
    gt: selected_fleet?.tauruses?.map(({id}: any) => id),
    tbox: selected_fleet?.taurusboxes?.map(({id}: any) => id),
    aql: selected_fleet?.aquilas?.map(({id}: any) => id),
    tlp: selected_fleet?.talpas?.map(({id}: any) => id),
  }
  const robotConfigs = 
    selected_fleet?.tauruses?.map(({taurus_configs}: any) => taurus_configs?.map(({robot_config}: any) => robot_config))  // all configs based on tauruses
    .concat(
      selected_fleet?.aquilas?.map(({aquila_configs}: any) => aquila_configs?.filter(({standalone}: any) => standalone)?.map(({robot_config}: any) => robot_config))  // Add standalone aquilas
    ).flat(2)  // flatten with depth 2
  const robotConfigComponents: any = {
    gt: new Set(robotConfigs?.map(({taurus_configs}: any) => taurus_configs?.map(({taurus_id}: any) => taurus_id)).flat()),
    tbox: new Set(robotConfigs?.map(({taurusbox_configs}: any) => taurusbox_configs?.map(({taurusbox_id}: any) => taurusbox_id)).flat()),
    aql: new Set(robotConfigs?.map(({aquila_configs}: any) => aquila_configs?.map(({aquila_id}: any) => aquila_id)).flat()),
    tlp: new Set(robotConfigs?.map(({talpa_configs}: any) => talpa_configs?.map(({talpa_id}: any) => talpa_id)).flat()),
  }
  
  const handles : Handles = {
    addUserToTeam(user, team , role ) {
      mutAddUserToTeam({variables: {user_id: user.id, team_id: team.id, role}})
    },
    removeUserFromTeam(user, team) {
      mutRemoveUserFromTeam({variables: {user_id : user.id, team_id : team.id}})
    },
    changeUserRole(user, team, new_role) {
      mutChangeUserRole({variables: {user_id : user.id, team_id : team.id, new_role : new_role}})
    },
    addTeamToFleet(team, fleet, role) {
      mutAddTeamToFleet({variables: {team_id : team.id, fleet_id : fleet.id, role: role}})
    },
    changeTeamRole(team, fleet, new_role) {
      mutChangeTeamRole({variables: {team_id : team.id, fleet_id: fleet.id, new_role}})
    },
    removeTeamFromFleet(team, fleet) {
      mutRemoveTeamFromFleet({variables: {team_id: team.id, fleet_id: fleet.id}})
    },
  }

  if (loading) {
    return <Loading />
  }

  if (error) {
    alert(JSON.stringify(error));
    window.location.reload()
  }

  return (
    <IonPage>
      <Toolbar name="Teams"/>
      <IonContent fullscreen>
         <table className='accessControlTable'>
          <thead>
            <tr>
              <th>Users {selected_team && <UserSelectionButton selected_team={selected_team} users={users_outside_team} handles={handles} />}</th>
              <th>Teams <AddGroup typename='team' mutation={mutAddTeam} /></th>
              <th>Fleets <AddGroup typename='fleet' mutation={mutAddFleet} /></th>
              <th>Configs</th>
            </tr>
          </thead>
          <tbody>
            <tr className="belowHeader">
              <td>
                <div><p style={{textAlign:'center'}}>in team <strong>{selected_team?.name}</strong>:</p></div>
              </td>
              <td colSpan={2}>
                {selected_team && selected_fleet && <TeamFleetRelations team={selected_team} fleet={selected_fleet} handles={handles} />}
              </td>
              <td>
                <div><p style={{textAlign:'center'}}>in fleet <strong>{selected_fleet?.name}</strong>:</p></div>
              </td>
            </tr>
            <tr>
              <td className="usersList">
                <ul>
                  {users_in_team.map(user=>
                    <UserInTeam key={user.id} user={user} selected_team={selected_team!} handles={handles} />
                  )}
                </ul>
              </td>
              <td className='teamsList' style={{position: 'relative'}}>
                <ul>
                  {data?.teams.map(team=>{
                    const classes = [];
                    if (team === selected_team) {
                      classes.push("selected");
                    }
                    if (team.fleets.map(tf=>tf.fleet_id).includes(selected_fleet?.id || -Infinity)) {
                      classes.push("teamHasAccessToSelectedFleet")
                    }
                    return <li
                      key={team.name}
                      onClick={_evt=>setSelectedTeamId(team.id)}
                      className={classes.join(" ")}
                      >
                      <span className="teamName">{team.name}</span>
                      <span className="teamDescription">{team.description}</span>
                    </li>
                })}
                </ul>
              </td>
              <td className='fleetsList'>
                {data?.fleets.map(fleet=>{
                  const classes = [];
                  if (fleet === selected_fleet) {
                    classes.push("selected");
                  }
                  if (selected_team?.fleets.map(tf=>tf.fleet_id).includes(fleet.id)) {
                    classes.push("selectedTeamHasAccess")
                  }
                  return <li
                    key={fleet.id}
                    onClick={_evt=>setSelectedFleetId(fleet.id)}
                    className={classes.join(" ")}
                    >
                    <span className="fleetName">{fleet.name}</span>
                    <span> (id: {fleet.id})</span>
                    <span className="fleetDescription">{fleet.description}</span>
                    {(()=>{
                      let role = selected_team?.fleets.find(tf=>tf.fleet_id===fleet.id)?.role
                      if (role !== undefined) {
                        return <small style={{display:"block"}}>Team <strong>{selected_team?.name}</strong> is <strong>{role}</strong></small>
                      }
                    })()}
                  </li>
                })}
              </td>
              <td>
                <ul>
                  {robotConfigs?.map((robot_config: any) =>
                    <RobotConfigListItem key={robot_config.id} robot_config={robot_config} fleetComponents={fleetComponents} fleet={selected_fleet}/>
                    )}
                </ul>
                <div style={{padding: "0.5em"}}>
                {["gt", "tbox", "tlp", "aql"].map((componentType: string) => {
                  const key = componentType
                  // Components that are not in a robot config are considered dangling and are shown separately
                  const danglingIds = (fleetComponents[key] as any[])?.filter((id: number) => !robotConfigComponents[key].has(id))
                  if (danglingIds?.length > 0) {
                    return <p>
                      Dangling {componentType}s: {danglingIds.map((id: number) => `${id}`).join(", ")}
                    </p>
                  }
                  return null
                })}
                </div>
              </td>
            </tr>
          </tbody>
         </table>
      </IonContent>
    </IonPage>
  );
};

const UserInTeam : React.FC<{selected_team : Team, user : User, handles : Handles}> = (props) => {
  const popover = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const openPopover = (e: any) => {
    popover.current!.event = e;
    setPopoverOpen(true);
  };

  const my_role = props.selected_team.users.find(tu=>tu.user_id===props.user.id)!.role;

  return (
    <li>
      <span onClick={openPopover} className='userName'>{props.user.name}</span>
      <span onClick={openPopover} className='userRole'>{props.selected_team.users.find(tu=>props.user.id===tu.user_id)?.role}</span>
      <IonPopover ref={popover} isOpen={popoverOpen} onDidDismiss={() => setPopoverOpen(false)}>
        <IonCardContent>
        <div>
          Edit user <b>{props.user.name}</b> in <b>{props.selected_team.name}</b>:
        </div>
        <IonButton expand='block' color='danger' onClick={_=>{
          setPopoverOpen(false);
          setTimeout(()=>{
            props.handles.removeUserFromTeam(props.user, props.selected_team)
          }, 0)
        }}>remove from team</IonButton>
        {Object.values(Role).filter(role=>role!==my_role).map(role=>
          <IonButton expand='block' key={role} onClick={_=>{
            setPopoverOpen(false);
            setTimeout(()=>props.handles.changeUserRole(props.user, props.selected_team, role), 0);
          }}>change to {role}</IonButton>  
        )}
        </IonCardContent>
      </IonPopover>
    </li>
  )
}

const UserSelectionButton : React.FC<{selected_team : Team, users: User[], handles : Handles}> = (props) => {
  const popover = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const searchbar = useRef<HTMLIonSearchbarElement>(null);

  const openPopover = (e: any) => {
    popover.current!.event = e;
    setPopoverOpen(true);
    setTimeout(()=>{
      searchbar.current?.querySelector("input")?.focus();
    }, 10)
  };
  const [searchString, setSearchString] = useState("");

  return (<>
    <IonButton size='small' onClick={openPopover}>Add to team</IonButton>
    <IonPopover className='wide-popover' ref={popover} isOpen={popoverOpen} onDidDismiss={() => setPopoverOpen(false)}>
      <IonCardHeader style={{display: 'flex', alignItems: 'center'}}>
          <IonCardTitle style={{flexGrow: 1}}>Select user</IonCardTitle>
          <IonButton color={'danger'} size='small' onClick={_=>{
            setPopoverOpen(false)
          }}>
            abort
          </IonButton>
        </IonCardHeader>
      <IonContent class="ion-padding">
        <IonSearchbar ref={searchbar} placeholder='search for e-mail address' onIonChange={evt=>setSearchString(evt.detail.value?.toLocaleLowerCase()||"")} />
        {props.users.filter(u=>searchString===''||u.name.toLocaleLowerCase().includes(searchString)).map(user=>
            <AddUserToTeamButton key={user.id} team={props.selected_team} user={user} handles={props.handles} closeParent={()=>setPopoverOpen(false)} />
        )}
      </IonContent>
    </IonPopover>
  </>)
};

const TeamFleetRelations : React.FC<{team : Team, fleet: Fleet, handles : Handles}> = (props) => {
  const current_role = props.team.fleets.find(tf=>tf.fleet_id===props.fleet.id)?.role;
  const description = <p style={{margin: 0, fontSize: ".75em"}}>Team <b>{props.team.name}</b> has <b>{current_role || "no"}</b> access to fleet <b>{props.fleet.name}</b></p>
  
  const popover = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const openPopover = (e: any) => {
    popover.current!.event = e;
    setPopoverOpen(true);
  };

  return <div>
    {description}
    <div>
      <IonButton size='small' onClick={openPopover}>Change access level</IonButton>
      <IonPopover ref={popover} className="userSelectPopover" isOpen={popoverOpen} onDidDismiss={() => setPopoverOpen(false)}>
        <IonCardHeader style={{display: 'flex', alignItems: 'center'}}>
          <IonCardTitle style={{flexGrow: 1}}>Select role</IonCardTitle>
          <IonButton color={'danger'} size='small' onClick={_=>{
            setPopoverOpen(false)
          }}>
            abort
          </IonButton>
        </IonCardHeader>
        <IonContent>
          {[...Object.values(Role), undefined].filter(role=>role!==current_role).map(role=>
            <IonItem
              style={{cursor: 'pointer'}}
              key={role}
              onClick={_=>{
                setPopoverOpen(false);
                setTimeout(()=>{
                  if (role === undefined) {
                    props.handles.removeTeamFromFleet(props.team, props.fleet)
                  } else {
                    if (current_role===undefined) {
                      props.handles.addTeamToFleet(props.team, props.fleet, role)
                    } else {
                      props.handles.changeTeamRole(props.team, props.fleet, role)
                    }
                  }
                }, 0)
              }}
            >
              {role || "no access"}
            </IonItem>
          )}
        </IonContent>
      </IonPopover>
    </div>
  </div>;
}

const AddUserToTeamButton: React.FC<{team: Team, user: User, handles : Handles, closeParent : Function}> = (props) => {
  const popover = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const openPopover = (e: any) => {
    popover.current!.event = e;
    setPopoverOpen(true);
  };

  return (
    <>
      <IonItem onClick={openPopover} style={{cursor: 'pointer'}}>{props.user.name}</IonItem>
      <IonPopover ref={popover} className="userSelectPopover" isOpen={popoverOpen} onDidDismiss={() => setPopoverOpen(false)}>
        <IonCardHeader style={{display: 'flex', alignItems: 'center'}}>
          <IonCardTitle style={{flexGrow: 1}}>Select role</IonCardTitle>
          <IonButton color={'danger'} size='small' onClick={_=>{
            props.closeParent()
            setPopoverOpen(false)
          }}>
            abort
          </IonButton>
        </IonCardHeader>
        <IonContent>
          
            {Object.values(Role).map(role=>
              <IonItem
                style={{cursor: 'pointer'}}
                key={role}
                onClick={_=>{
                  setPopoverOpen(false);
                  props.closeParent();
                  setTimeout(_=>props.handles.addUserToTeam(props.user, props.team, role), 0);
                }}
              >
                {role}
              </IonItem>
            )}
          
        </IonContent>
      </IonPopover>
    </>
  );
}

const AddGroup: React.FC<{typename : string, mutation : MutationFunction}> = (props) => {
  const popover = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const openPopover = (e: any) => {
    popover.current!.event = e;
    setPopoverOpen(true);
  };
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  
  return (<>
    <IonButton onClick={openPopover} size='small' style={{cursor: 'pointer'}}>Add {props.typename}</IonButton>
    <IonPopover ref={popover} className="userSelectPopover" isOpen={popoverOpen} onDidDismiss={() => setPopoverOpen(false)}>
      <IonCardHeader style={{display: 'flex', alignItems: 'center'}}>
        <IonCardTitle style={{flexGrow: 1}}>Add {props.typename}</IonCardTitle>
        <IonButton color={'danger'} size='small' onClick={_=>{
          setPopoverOpen(false)
        }}>
          abort
        </IonButton>
      </IonCardHeader>
      <IonContent>
        <IonItem>
          <IonInput placeholder='name' onIonChange={evt=>setName(evt.detail.value||"")} />
        </IonItem>
        <IonItem>
          <IonInput placeholder='description' onIonChange={evt=>setDescription(evt.detail.value||"")} />
        </IonItem>
        <IonButton color='primary' onClick={_=>{
          setPopoverOpen(false);
          setTimeout(()=>{
            props.mutation({variables: {name, description}})
          }, 0)
        }}>create</IonButton>
      </IonContent>
    </IonPopover>
  </>)
}

export default Teams;
