import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonContent, IonIcon, IonItem, IonList } from '@ionic/react';
import { close, expand, image, pencilOutline, refresh, returnUpBack, settings, videocam } from 'ionicons/icons';
import React, { ChangeEvent, CSSProperties, useCallback, useContext, useEffect, useRef, useState, PointerEvent as ReactPointerEvent, ReactNode } from 'react';
import { AccessType, getAccessType, RosHandleContext, useCallService, useParam, usePublisher, useTopic, useActionClientWrapper, useOverrideParams} from '../hooks/rosHooks';
import { DeveloperButton, useDeveloper } from '../hooks/useDeveloperMode';
import { useTranslation } from 'react-i18next';
import i18n, { TFunction } from 'i18next';
import './Image.css';

export const Image: React.FC<{showUi: boolean, presetTopic : string|undefined}> = ({showUi, presetTopic}) => {
  return <>
    <IonCard style={{width: "100%", maxWidth: "600px", height: "fit-content"}}>
      <ImageContent showUi={showUi} presetTopic={presetTopic} onClose={null}/>
    </IonCard>
  </>
}

export const ImagePopover: React.FC<any> = ({...props}) => {
  const { showPopover, setShowPopover } = props;
  const mouseDownPosition = useRef({clientX:0, clientY:0})
  const pinchState = useRef([] as React.PointerEvent<HTMLDivElement>[])
  const buttonRef = useRef<HTMLIonButtonElement>(null);

  const [shape, setShape] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0
  });

  useEffect(()=>{
    const PADDING = 10;
    const DEFAULT_ASPECT = 4/3;
    const button_position = buttonRef.current?.getBoundingClientRect();
    const width = Math.min(window.innerWidth - (2*PADDING), 600);
    const height = Math.min(window.innerHeight, (2*PADDING) + width / DEFAULT_ASPECT)
    const x = Math.min(window.innerWidth - width - (2*PADDING), Math.max((button_position?.x || 0) - (width / 2), PADDING))
    const y = (button_position?.y || 0) + (button_position?.height || 0);
    setShape({
      width, height, x, y
    })
  }, [showPopover])

  
  const style : CSSProperties = {
    position: 'fixed',
    top: shape.y + 'px',
    left: shape.x + 'px',
    width: shape.width + 'px',
    height: shape.height + 'px',
    zIndex:9001,
    pointerEvents: 'all',
    display: showPopover ? 'block':'none'
  }

  function togglePopover() {
    if (showPopover) {
      setShowPopover(false)
      return
    }
    const PADDING = 10;
    const DEFAULT_ASPECT = 4/3;
    const button_position = buttonRef.current?.getBoundingClientRect();
    const width = Math.min(window.innerWidth - (2*PADDING), 600);
    const height = Math.min(window.innerHeight, (2*PADDING) + width / DEFAULT_ASPECT)
    const x = Math.min(window.innerWidth - width - (2*PADDING), Math.max((button_position?.x || 0) - (width / 2), PADDING))
    const y = (button_position?.y || 0) + (button_position?.height || 0);
    setShape({
      width, height, x, y
    })
    setShowPopover(true)
  }

  function pointerDown(evt : React.PointerEvent<HTMLDivElement>) {
    evt.stopPropagation();
    pinchState.current.push(evt)
    if (evt.buttons === 2) {
      mouseDownPosition.current.clientX = evt.clientX;
      mouseDownPosition.current.clientY = evt.clientY;
    }
  }
  function pointerUp (evt : React.PointerEvent<HTMLDivElement>) {
    evt.stopPropagation();
    pinchState.current = pinchState.current.filter(ce=>ce.pointerId!==evt.pointerId);
  }

  function pointerMove (evt : React.PointerEvent<HTMLDivElement>) {
    const index = pinchState.current.findIndex(
      (old_evt) => old_evt.pointerId === evt.pointerId
    );
    if (index === -1) {
      return
    }
    const old_evt = pinchState.current[index];
    pinchState.current[index] = evt;
    const dx = evt.clientX - old_evt.clientX;
    const dy = evt.clientY - old_evt.clientY;  

    if (pinchState.current.length === 1) {
      if (evt.buttons === 2) {
        // right drag => resize (for non-touch displays in lieu of pinch-to-zoom)
        setShape({
          x: shape.x - dx,
          y: shape.y - dy,
          width: shape.width + 2*dx,
          height: shape.height + 2*dy,
        })
      } else {
        // drag with any other mouse button or single finger => drag the popup
        setShape({
          ...shape,
          x: shape.x + dx,
          y: shape.y + dy,
        })
      }
    } else if (pinchState.current.length === 2) {
      // two finger pinch-to-zoom
      const other_evt = pinchState.current.find(e=>e.pointerId!==evt.pointerId)!;
      const dx = (other_evt.clientX - old_evt.clientX) - (other_evt.clientX - evt.clientX);
      const dy = (other_evt.clientY - old_evt.clientY) - (other_evt.clientY - evt.clientY);
      const is_left = evt.clientX < other_evt.clientX;
      const is_upper = evt.clientY < other_evt.clientY;
      setShape({
        x: shape.x + (is_left?dx:0),
        y: shape.y + (is_upper?dy:0),
        width: shape.width + (is_left?-dx:dx),
        height: shape.height + (is_upper?-dy:dy),
      })
    }
    
  }


  return <>
    <IonButton ref={buttonRef} onClick={togglePopover} {...props}>
      <IonIcon icon={image}/>
    </IonButton>

    <div
      style={style}
      onContextMenu={evt=>evt.preventDefault()}
      onPointerDown={pointerDown}
      onPointerUp={pointerUp}
      onPointerMove={pointerMove}
      onClick={evt=>evt.stopPropagation()}
    >
      <IonCard style={{width: shape.width, height: shape.height, display: 'flex', flexDirection: 'column'}}>
        {showPopover && <ImageContent showUi={true} onClose={() =>  setShowPopover(false)} presetTopic={undefined}/>}
      </IonCard>
    </div>
  </>
}

enum StreamType {
  Topic = "topic",
  Reolink = "reolink",
  Mipc = "mipc",
  FisheyePoeCam = "fisheye_poe",
  RevodataI704PTS = "revodata_i704_p_ts"
}

interface Stream {
  topic : string|undefined,
  streamType : StreamType
}

interface ExtraCamera {
  identifier: number,
  stream_type : StreamType
}

function extraCameraPrettyName(cam : ExtraCamera, t: TFunction, aliases : Map<string, string> | null) : string {
  if (cam.stream_type == StreamType.FisheyePoeCam) {
    return t("Overview camera")
  }
  if (cam.stream_type == StreamType.RevodataI704PTS) {
    return `${t('Tools camera')} (#${cam.identifier})`
  }
  let key = cam.identifier.toString();
  if (aliases !== null && aliases.has(key)) {
    return aliases.get(key)!;
  }
  return `${t("Additional camera")} (${cam.stream_type}; ${cam.identifier})`
}

const prettifyTopicName = (topicName: Stream, t: TFunction, aliases : null | Map<string, string>) => {
  if (topicName.topic !== undefined && aliases !== null && aliases.has(topicName.topic)) {
    return aliases.get(topicName.topic);
  } else if (topicName.streamType === StreamType.Topic) {
    return (topicName.topic || '').replaceAll("/", " ").replaceAll("_", "-").toUpperCase().replace("VISUALIZATION", "").replace(" IMAGE", "").replace("ONLINE-", "")
  } else if (topicName.streamType === StreamType.Reolink) {
    return i18n.t(`Surveillance camera (${topicName.topic || '-'})`)
  } else if (topicName.streamType === StreamType.Mipc) {
    return i18n.t("Tools camera")
  } else {
    return extraCameraPrettyName({identifier: topicName.topic as any as number, stream_type: topicName.streamType}, t, aliases)
  }
}

interface StdMsgsString {
  data: string
}

const AquilaTopics : React.FC<{aquilaName : string, aqlId: number, setStream : ({topic, streamType} : {topic:string, streamType: StreamType}) => void}> = (props) => {
  const { t } = useTranslation();
  const pretty_camera_name = `${t('Camera')} ${props.aquilaName.slice(-1).toLocaleUpperCase()}\xa0\xa0\xa0(AQL ${props.aqlId}):`;
  const user_topics = [
    {pretty: t(`plant detection`), topic: `/${props.aquilaName}/visualization/plant_stem_detection_image`}
  ]
  return <IonItem><div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", padding: ".3em 0", marginLeft: "-.3em"}}>
    <div style={{width: "100%", fontSize: "0.75em", paddingLeft: "1.1em"}}>{pretty_camera_name}</div>
    {user_topics.map(({pretty, topic}, idx)=>
      <IonButton expand="full" fill="clear" key={topic} onClick={() => {
        const new_val = {topic, streamType: StreamType.Topic};
        props.setStream(new_val)
      }}>
        {pretty}
      </IonButton>
    )}
  </div></IonItem>
}

const ExtraCameras : React.FC<{
  setStream : ({topic, streamType} : {topic:string, streamType: StreamType}) => void,
  cameraAliases : Map<string, string>
}> = ({setStream, cameraAliases}) => {
  const available_cameras_json = useTopic<StdMsgsString>("/available_cameras", "std_msgs/String");
  const available_cameras : ExtraCamera[] = JSON.parse(available_cameras_json?.data || "[]");
  const { t } = useTranslation()
  return <>
    {available_cameras.map(cam => {
      return <IonItem>
        <IonButton expand="full" fill="clear" onClick={() => {setStream({topic: cam.identifier.toString(), streamType: cam.stream_type})}}>
          {extraCameraPrettyName(cam, t, cameraAliases)}
        </IonButton>
      </IonItem>
    })}
  </>
}

const MipcControls : React.FC<{isFullscreen: boolean}> = ({isFullscreen})=>{
  const mipc_action_client = useActionClientWrapper("/main/mipc/move_to", "weeding_monitoring/MoveMipcAction")
  const { t } = useTranslation()
  const presets = ["Left", "Center", "Right", "Bottom"]
  const callService = useCallService();

  const [popoverOpen, setPopoverOpen] = useState(false);

  const update_value = (name: String) => {
    callService("/main/mipc/update_preset", "weeding_monitoring/ChangeMipcMovementPreset", {name}, res=>{
      if (!res.success) {
        alert(`error changing position preset: ${res.message}`)
      }
    });
  }

  const take_treatment_snapshot = () => {
    callService("/main/data_sampler/start_treatment_snapshot", "std_srvs/Trigger", {}, res=>{
      if (!res.success) {
        alert(`error taking snapshot: ${res.message}`)
      }
    });
  }

  return <>
      <div className="mipc-move-buttons">
          <IonButton  onMouseDown={_ =>{ mipc_action_client.send_goal("Up_Max")}} onClick={_ =>{ mipc_action_client.cancel_goal()}}>
          </IonButton>
          <IonButton  onMouseDown={_ =>{ mipc_action_client.send_goal("Right_Max")}} onClick={_ =>{ mipc_action_client.cancel_goal()}}>
          </IonButton>
          <IonButton  onMouseDown={_ =>{ mipc_action_client.send_goal("Left_Max")}} onClick={_ =>{ mipc_action_client.cancel_goal()}}>
          </IonButton>
          <IonButton  onMouseDown={_ =>{ mipc_action_client.send_goal("Down_Max")}} onClick={_ =>{ mipc_action_client.cancel_goal()}}>
          </IonButton>
      </div>
      {(popoverOpen) && <div className='mipc-settings'>
          {presets?.map(p=><IonButton
          key={p}
          onClick={_=>{
            update_value(p)
          }}
          >
            {t(`set as ${p}`)}
          </IonButton>)}
      <IonButton onClick={_=>setPopoverOpen(false)}>ok</IonButton>
    </div>}
    <div
      style={{
        position: 'absolute',
        display: 'flex',
        justifyContent: 'space-evenly',
        left: 0,
        width: "100%",
        bottom: 0
      }}
    >
      {(isFullscreen) && presets?.map(p=><IonButton
        key={p}
        onClick={_=>{
          mipc_action_client.send_goal(p)
        }}
        >
          {t(`${p}`)}
        </IonButton>)}
      <IonButton onClick={_=>{
        setPopoverOpen(true)
      }}><IonIcon icon={settings} /></IonButton>
      <IonButton onClick={_=>{take_treatment_snapshot()}}>treatment snapshot<IonIcon icon={videocam} /></IonButton>
    </div>
  </>
}

const ImageContent: React.FC<{showUi: boolean, presetTopic : string|undefined, onClose: any}> = ({showUi, presetTopic, onClose}) => {

  const { t } = useTranslation();
  const accessType = getAccessType()

  useEffect(()=>{
    console.log(`spawned <Image presetTopic=${presetTopic} />`)
  }, [])

  if (showUi === false && presetTopic === undefined) {
    throw Error("Must either show <Image> UI or provide a presetTopic.")
  }

  const [stream, setStream] = useState<Stream>({topic: presetTopic, streamType: StreamType.Topic});

  const [allTopics, setAllTopics] = useState<any>([])
    
  const publishRequestedStreams = usePublisher("/requested_streams", "std_msgs/String")
  const ros: any = useContext(RosHandleContext)

  const refreshTopics = useCallback(() => ros?.getTopicsForType("sensor_msgs/Image", setAllTopics, (e: any) => console.error(e)), [setAllTopics, ros])

  useEffect(() => {
    // refresh list of topics when topic changes or image view is expanded / closed
    refreshTopics()
  }, [stream, refreshTopics])

  const {developer} = useDeveloper()
    
  const { value : aquilas } = useParam("/aquilas");
  const aquila_names = Object.keys(aquilas || {});

  // publish the topic in `/requested_streams`
  useEffect(() => {
    const interval = setInterval(() => {
      if (stream.topic) {
        console.log("Subscribe", stream.topic)
        publishRequestedStreams({'data':`${stream.streamType}:${stream.topic}`})
      }
    }, 1000);
    return () => clearInterval(interval)
  }, [stream]);

  // This iframe is from 
  // https://github.com/farming-revolution/weeding-robot/tree/melodic-devel/video_stream_controller/www
  // Which gets the video feed via:
  // https://gitlab.farming-revolution.com/farming-revolution/gstreamer-main
  let iframe_url;
  const access_type = getAccessType();
  if (access_type === AccessType.LocalHttp || access_type == AccessType.VPN) {
    iframe_url = `http://${window.location.hostname}:8444/#${stream.streamType}:${stream.topic}`;
  } else if (access_type === AccessType.AppDevServer) {
    const ws_url : string = ros.socket.url;
    const RE = /^[^\/]*\/\/([^\/:]+).*$/;
    const ip = RE.exec(ws_url)![1];
    iframe_url = `http://${ ip }:8444/#${stream.streamType}:${stream.topic}`;
  } else {
    // access via https (remote or locally)
    iframe_url = `https://${ window.location.hostname }/video/#${stream.streamType}:${stream.topic}`;
  }

  const iframe = <>{stream && <iframe src={iframe_url} style={{
    "aspectRatio": showUi?"unset":"4/3",
    "width": "100%",
    "height": showUi?"100%":"unset",
    "border": "none",
    pointerEvents: "none"
  }} />}</>;

  // for fullscreen:
  // - activation: call .requestFullscreen() on native element
  // - deactivation: call document.exitFullscreen()
  // - subscribe fullscreenchange event on document so we can have
  //   fullscreen state in react state andset styles accordingly.
  //   Note: just querying document.fullscreenElement!==null would
  //         not do the job, because exiting fullscreen via [Esc]
  //         does not trigger a react re-render.
  const elemRef = useRef<HTMLIonCardContentElement|null>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  useEffect(()=>{
    function fullScreenHandler () {
      setIsFullscreen(document.fullscreenElement!==null)
    }
    document.addEventListener("fullscreenchange", fullScreenHandler);
    return ()=>document.removeEventListener("fullscreenchange", fullScreenHandler)
  }, []);

  const {setParams, overrideParams, resultingParams, error, state} = useOverrideParams();

  const cameraAliases = new Map();
  if (overrideParams) {
    for (const [k, v] of Object.entries(overrideParams)) {
      if (k.startsWith("/camera_aliases/")) {
        cameraAliases.set(k.replace(/^\/camera_aliases\//, ''), v);
      }
    }
  }
  
  function setCameraAlias(topicName : string, alias : string) {
    cameraAliases.set(topicName, alias);
    const entries = Array.from(cameraAliases.entries()).map(([k, v])=>{
      return [`/camera_aliases/${k}`, v]
    })
    setParams(Object.fromEntries(entries));
  }
  Object.assign(window, {cameraAliases, setCameraAlias, overrideParams, resultingParams, error, state});

  const available_legacy_cameras_msg = useTopic<{data: string}>("/available_legacy_cameras", "std_msgs/String");
  let available_legacy_cameras = available_legacy_cameras_msg?.data.split(",") || [];

  if (!showUi) {
    return iframe
  }

  return <>
      <IonCardHeader style={{display: 'flex'}}>
        {stream && <span style={{overflow: 'hidden', display: 'flex'}}>
          <IonButton
            onClick={_ => {
                setStream({topic: undefined, streamType: StreamType.Topic}) /* unset the current topic to choose new topic*/ 
            }}
            style={{overflow: 'hidden', flexShrink: 1}}
            color='light'
          >
            {prettifyTopicName(stream, t, cameraAliases)}
          </IonButton>
          {stream.topic !== undefined && <IonButton color='light' onClick={_=>{
              let new_alias = prompt(t("rename camera"));
              if (new_alias !== null) {
                setCameraAlias(stream.topic!, new_alias)
              }
          }}>
            <IonIcon icon={pencilOutline} />
          </IonButton>}
        </span>}
        <span style={{flexGrow:1}} />
        <IonButtons>
          {[AccessType.VPN, AccessType.AppDevServer].includes(accessType) && !stream.topic && <DeveloperButton fill="clear"/>}
          {!stream.topic && <IonButton onClick={refreshTopics}>
            <IonIcon icon={refresh} slot="icon-only"/>
          </IonButton>}
          
          {stream.topic && <IonButton
            onClick={async _=>{
              await elemRef?.current?.requestFullscreen();
            }}
          >
            <IonIcon icon={expand} slot='icon-only' />
          </IonButton>}
          
          {onClose && <IonButton onClick={onClose} fill="clear">
            <IonIcon icon={close} slot="icon-only"/>
          </IonButton>}
        </IonButtons>
      </IonCardHeader>
    
      <IonCardContent ref={elemRef} className={isFullscreen?'imageFullscreen':''} style={{flexGrow: 1, overflow: stream.topic?'unset':'auto', userSelect: 'none'}}>
        {isFullscreen && <IonButton
          className='exitFullscreenButton'
          onClick={_=>{
            document.exitFullscreen();
          }}
        >Exit full screen</IonButton>}

        {!stream.topic && <IonList>
            {developer && allTopics?.length === 0 && <IonItem>{t("No images available")}</IonItem>}
            {developer && allTopics?.map((topic: string) => {
              const new_val = {topic: topic, streamType: StreamType.Topic};
              return <IonItem>
                <IonButton expand="full" fill="clear" onClick={() => {setStream(new_val)}}>
                  {prettifyTopicName(new_val, t, cameraAliases)}
                </IonButton>
              </IonItem>
            })}
            {!developer && aquila_names.map(aquilaName=><AquilaTopics aquilaName={aquilaName} key={aquilaName} setStream={setStream} aqlId={aquilas[aquilaName]}/>)}
            {["front", "back"].map(direction=>{
              if (!available_legacy_cameras.includes(`${direction}_camera`)) {
                return null
              }
              const new_val = {topic: direction, streamType : StreamType.Reolink};
              return <IonItem>
                <IonButton expand="full" fill="clear" onClick={() => {setStream(new_val)}}>
                  {prettifyTopicName(new_val, t, cameraAliases)}
                </IonButton>
              </IonItem>
            })}
            {available_legacy_cameras.includes("observation_camera") && <IonItem>
              <IonButton expand="full" fill="clear" onClick={() => {setStream({streamType: StreamType.Mipc, topic: "mipc"})}}>
                {t("Tools camera")}
              </IonButton>
            </IonItem>}
            <ExtraCameras setStream={setStream} cameraAliases={cameraAliases} />
        </IonList>}
        {stream.streamType === StreamType.Mipc && <MipcControls isFullscreen={isFullscreen} />}
        {stream.topic && iframe}
      </IonCardContent>
  </>
}
export default Image
