import { Layer, Line, Stage } from "react-konva"
import { useGetArea } from "./useFieldEditorData"
import { AreaKonva } from "./AreaKonva"


export const MiniatureField: React.FC<any> = ({fieldConfig, points, style = {}, width, height, children, ...props}: any) => {
  style.width = width
  style.height = height
  return <div style={style} onClick={props.onClick}>
    {children}
    {fieldConfig && points && <MiniatureFieldContent {...props} width={width} height={height} fieldConfig={fieldConfig} points={points}/>}
  </div>
}



export const getBoundariesFromList = (points: any[]) => points
  .reduce((prev: any, {x, y}: any) => {
    if (prev === null) {
      return {xMin: x, xMax: x, yMin: y, yMax: y}
    }
    else {
      return {
        xMin: Math.min(prev.xMin, x),
        xMax: Math.max(prev.xMax, x),
        yMin: Math.min(prev.yMin, y),
        yMax: Math.max(prev.yMax, y),
      }
    }
  }, null)


// Get min and max points for proper scaling
export const getBoundaries = (points: any) => getBoundariesFromList(Object.keys(points).map((key: string) => points[key])
  .map((a: any) => [a.start_left, a.start_right, a.end_left, a.end_right])
  .flat()
  .map((p: any) => p.point))


export const MiniatureFieldContent: React.FC<any> = ({width = 100, height = 100, fieldConfig, points, onClick}) => {
  const {getAreaColor} = useGetArea(fieldConfig.areas)
  if (!fieldConfig?.areas || !points) {return null}
  if (Object.keys(points).length < 1) {return null}

  // Scale to fully fit in the area
  const boundaries = getBoundaries(points)
  const mapWidth = boundaries.xMax - boundaries.xMin
  const mapHeight = boundaries.yMax - boundaries.yMin
  const scale = Math.min(width / mapWidth, height / mapHeight) * 0.9

  // Compute offsets of canvas to center areas
  const x = width / 2 - (boundaries.xMin + boundaries.xMax) / 2. * scale
  const y = height / 2 + (boundaries.yMin + boundaries.yMax) / 2. * scale

  return <Stage height={height} width={width} scaleX={scale} scaleY={scale} style={{width: width, height: height}}  x={x} y={y}>
    <Layer scaleY={-1  /* y goes down in konva */}>
    {fieldConfig.areas.map((area: any) => <Area key={area.id} area={area} points={points[area.id]} getAreaColor={getAreaColor} strokeWidth={2.0} onClick={onClick}/>)}
    </Layer>
  </Stage>
}


export const Area: React.FC<any> = ({area, selected = false, strokeWidth = 0.8, getAreaColor, onClick, points}: any) => {
  let sides = null  // Side to be highlighted
  if (area.def.type === "rows") {
    sides = ["left", "right"]
  }
  else if (area.def.type === "side-track") {
    sides = [area.def.defined_side]
  }
  return <>{sides
  ?.map((side: string) => [points[`start_${side}`], points[`end_${side}`]])
  ?.map((points: any) => <Line
    stroke={getAreaColor(area.id, 1.0)}
    fill={selected ? getAreaColor(area.id): undefined} strokeWidth={strokeWidth} closed={true}
    onClick={onClick}
    onTap={onClick}
    points={points.flatMap((p: any) => [p.point.x, p.point.y])}
  />)}</>
}