import React from 'react';
import Loading from './Loading'
import Refresher from './Refresher'
import Error from './Error'

const ApolloStatus: React.FC<any> = ({errors = [], loadings = [], refetch = null}) => {
    return <>
        {loadings.some((l: any) => l) && <Loading/>}
         <Error errors={errors}/>
        {refetch && <Refresher refetch={refetch}/>}
    </>
}
export default ApolloStatus;
