import { IonIcon, IonList, IonChip, IonCard, IonSplitPane, IonButton, IonItem, IonMenu, IonPage, IonAccordionGroup, IonAccordion, IonContent, IonTitle, IonCardHeader, IonItemDivider, IonProgressBar, IonToggle, IonSegment, IonSegmentButton, IonGrid, IonRow, IonCol, IonBadge, IonItemSliding, IonInput, IonButtons, IonThumbnail, IonLabel, IonSearchbar } from '@ionic/react';
import React, {useState, useEffect} from 'react';
import { MapContainer, Marker, TileLayer, useMap, Tooltip } from 'react-leaflet'
import { Icon, LatLng } from 'leaflet'
import { orderByDistance } from 'geolib'
import Toolbar from '../../components/Toolbar'
import { gql } from '@apollo/client';
import { chevronUp, chevronDown, shareSocial, navigate, } from 'ionicons/icons';
import { useQueryWithElevatedRole, useSubscriptionWithElevatedRole } from '../../hooks/hasuraHooks';
import CopyButton from '../../components/CopyButton';
import useWindowDimensions from '../../useWindowDimensions';
import CoordinatesButton from '../../components/CoordinatesButton';
import { GraphQLState } from '../../components/GraphQLState';

const defaultCordinates = {
  latitude_min: 44.59046718130883,
  longitude_min: 0.30761718750000006,
  latitude_max: 63.05495931065107,
  longitude_max: 63.05495931065107
}

const myIcon = new Icon({
  iconUrl: "https://app.farming-revolution.com/assets/icon/favicon.png",
  iconSize: [32, 32],
});

// ComponentResize : helper component for map to resize and fit peroperly into the size given
const ComponentResize = () => {
  const map = useMap();
  setTimeout(() => {
    map.invalidateSize();
  }, 0);

  return null;
};


const FieldItem: React.FC<any> = ({field, ...props}: any) => {
  const configV2 = field.config_v2
  const {isLargeScreen} = useWindowDimensions()
  const [expanded, setExpanded] = useState(false)
  const taurus_id = field.created_by_taurus_id

  const headerItem = <IonItem lines="none" color={field.archived ? "medium" : 'undefined'} {...props} onClick={() => setExpanded(!expanded)} style={{textTransform: "capitalize"}}>
      <IonThumbnail slot="start">
        <IonBadge style={{position: "absolute", top: 15, left: 15, zIndex: 1001}}>
          #{field.id}
        </IonBadge>
      </IonThumbnail>
      <div>
        {window.decodeURIComponent(field.config_v2?.name || "?")}
        <IonLabel style={{ fontSize: 10}}>Location: {field.datum_latitude}, {field.datum_longitude}</IonLabel>
      </div>
      {field.crop && <IonChip slot="end">{field.crop.name_EN}</IonChip>}
      <IonButtons slot='end'>
        {taurus_id && (
          <IonChip onClick={(e: any) =>e.stopPropagation()} outline color="secondary">
            GT{taurus_id}
          </IonChip>
        )}
        <CopyButton text={`https://app.farming-revolution.com/field/${field.id}`} icon={shareSocial}/>
        <CoordinatesButton coordinates={{latitude: field.datum_latitude, longitude: field.datum_longitude}} icon={navigate}/>
        {isLargeScreen && <IonButton slot="end" onClick={(e: any) => {setExpanded(!expanded); e.stopPropagation()}} fill="clear">
          <IonIcon icon={expanded ? chevronUp : chevronDown} slot={"icon-only"}/>
        </IonButton>}
      </IonButtons>
    </IonItem>

  return (
    <IonCard style={{ marginInline:  expanded && '16px', backgroundColor: '#f4f5f8' }}>
      {headerItem}
      {expanded && <pre style={{ color: 'black', padding: '10px 30px', margin: 0, backgroundColor: 'white'}}>
        {JSON.stringify(configV2, null, 2)}
      </pre>}
    </IonCard>
  )
}


const ListView = ({ showArchived, fields, fields_aggregate, loading } : any) => {
  return (
    <>
      {loading && <IonProgressBar type="indeterminate"/>}
      {fields && <>
        <IonItemDivider>{fields_aggregate?.aggregate?.count} Fields</IonItemDivider>
        <IonList>
          {fields.length === 0 ? <div className="ion-text-center">No fields at this region</div> : (
            fields.map((field: any) => <FieldItem field={field} key={field.id}/>)
          )}
        </IonList>
      </>}
    </>
  )
}

const FieldMarks = ({field, detailView}:any) => {
  if (!field.datum_latitude || !field.datum_longitude) {
    return null
  }
  const position = new LatLng(field.datum_latitude, field.datum_longitude)
  return(
    <Marker icon={myIcon} position={position}>
      {detailView && <Tooltip direction="right" offset={[0, 0]} opacity={1} permanent>{window.decodeURIComponent(field?.config_v2?.name || "?")}</Tooltip> }
    </Marker>
  )
}

const MapView = ({fields, getLatLng, searchFilter, updateMapView }: any) => {
  const map = useMap()

  useEffect(() => {
    if (fields && fields.length > 0) {
      if (searchFilter) {
        // When searchFilter is present, fit the map to the bounds of the filtered fields
        const bounds = fields.map((field: any) => [
          field.datum_latitude,
          field.datum_longitude
        ]);
        map.fitBounds(bounds);
      } else {
        const updateCoordinates = () => {
          getLatLng(map.getBounds());
        };
        
        map.on("zoomend dragend", updateCoordinates);

        return () => {
          map.off("zoomend dragend", updateCoordinates);
        };
      }
    }
  }, [fields, map, getLatLng, searchFilter]);

  useEffect(() => {
    if (updateMapView){
      getLatLng(map.getBounds());
    }
  }, [updateMapView]);

  return(
    <>
      <ComponentResize/>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {fields?.map((field:any , i:number )=> <FieldMarks key={field.id} field={field} detailView={map.getZoom()> 15} />)}
    </>
  )
}


const Fields: React.FC = () => {
  const [showArchived, setShowArchived] = useState(false)
  const [searchFilter, setSearchFilter] = useState('')
  const [coordinates, setCoordinates] = useState({
    latitude_min: defaultCordinates.latitude_min,
    longitude_min: defaultCordinates.longitude_min,
    latitude_max: defaultCordinates.latitude_max,
    longitude_max: defaultCordinates.longitude_max,
  })
  const [updateMapView, setUpdateMapView] = useState(false)

  const where = {
    _and: {
      _and: {
        _and: {
          datum_latitude: {_gte: coordinates.latitude_min},
          datum_longitude: {_gte: coordinates.longitude_min},
        },
        datum_latitude: {_lte: coordinates.latitude_max},
      },
      datum_longitude: {_lte: coordinates.longitude_max},
    },
    archived: showArchived ? {} : {_eq: false}
  }

  const { loading, data, error} = useQueryWithElevatedRole(gql`
    query FilteredFields($where: fields_bool_exp!) {
      fields(order_by: {id: desc}, where: $where) {
        id
        datum_latitude
        datum_longitude
        created_by_taurus_id
        config_v2
        crop {id name_EN}
        archived
      }
      fields_aggregate(where: $where) {aggregate{count}}
    }
  `, {variables: {where}})

  const fields =  data?.fields
  const fields_aggregate = data?.fields_aggregate

  const getLatLng = (coOrd:any) =>{
    if(coOrd !=null){
      setCoordinates(prevState => ({...prevState,
        latitude_min: coOrd._southWest.lat,
        longitude_min: coOrd._southWest.lng,
        latitude_max: coOrd._northEast.lat,
        longitude_max: coOrd._northEast.lng,
      }))
    }
  }

  const handleSearch = (data: any) => {
    const searchKey = data.detail.value;
    setSearchFilter(searchKey);
    if (!searchKey) {
      setUpdateMapView(true)
      return;
    }
    setUpdateMapView(false)

    const [lat, lng] = searchKey.split(',').map(Number);
    const searchCoordinate = { latitude: lat, longitude: lng };

    // Calculate distances and find nearest fields
    const nearestFields = orderByDistance(searchCoordinate, fields.map((field:any) => ({
      latitude: field.datum_latitude,
      longitude: field.datum_longitude,
      ...field
    }))).slice(0, 5); // Get the 3 to 5 closest fields

    // Adjust coordinates to show the nearest fields
    const bounds = nearestFields.reduce((acc:any, field:any) => {
      return {
          latitude_min: Math.min(acc.latitude_min, field.datum_latitude),
          longitude_min: Math.min(acc.longitude_min, field.datum_longitude),
          latitude_max: Math.max(acc.latitude_max, field.datum_latitude),
          longitude_max: Math.max(acc.longitude_max, field.datum_longitude),
          };
        }, {
          latitude_min: Number.POSITIVE_INFINITY,
          longitude_min: Number.POSITIVE_INFINITY,
          latitude_max: Number.NEGATIVE_INFINITY,
          longitude_max: Number.NEGATIVE_INFINITY,
        });
        setCoordinates(bounds);
      };

      return (
        <IonPage>
        <Toolbar name="Fields" buttons={<IonButton fill="solid" onClick={() => setShowArchived(current => !current)}>{showArchived ? 'HIDE ARCHIVED': 'SHOW ARCHIVED'}</IonButton>}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IonSearchbar style={{maxWidth: 500}} placeholder='Search Field' value={searchFilter} debounce={500} onIonChange={handleSearch}/>
        </div>
        </Toolbar>
        <IonContent>
          <IonSplitPane className={"half-screen"} when="xl" style={{height: "100%"}} contentId="fieldContainer">
            <IonMenu contentId="fieldContainer" menu-id="map">
              <MapContainer center={[55, 16]} zoom={5} maxZoom={25} scrollWheelZoom={true} style={{height: "100vh", width: '100%'}}>
                <MapView fields={fields} getLatLng={getLatLng} searchFilter={searchFilter} updateMapView={updateMapView}/>
              </MapContainer>
            </IonMenu>
            <IonContent style={{height:"100vh", width: "100vw"}} id="fieldContainer">
              <GraphQLState error={error} loading={loading}/>
              <ListView fields={fields} fields_aggregate={fields_aggregate} loading={loading} showArchived={showArchived}/>
            </IonContent>
          </IonSplitPane>
        </IonContent>
      </IonPage>
      )
    };
    
    export default Fields;