import { IonIcon, IonModal, IonList, IonChip, IonCard, IonButton, IonItem, IonContent, IonPage, IonCardContent, IonProgressBar, IonBadge, } from '@ionic/react';
import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router';
import { gql, } from '@apollo/client';
import { Path, } from '../../components/MapDashboard';

import { useMutationWithElevatedRole, useQueryWithElevatedRole } from '../../hooks/hasuraHooks';
import { FieldEditor } from '../../components/FieldEditor';
import { getBoundariesFromList, MiniatureField } from '../../components/MiniatureField';
import { arrowUndo, eye, eyeOff, locate, locateOutline, pencil, trash, } from 'ionicons/icons';
import { useGetArea } from '../../components/useFieldEditorData';
import { useKonvaStageProps } from '../../hooks/useKonvaStageProps';
import { Layer, Stage } from 'react-konva';
import ConfirmButton from '../../components/ConfirmButton';
const moment = require('moment-twitter');


export const PathFromId: React.FC<any> = ({pathId, ...props}: any) => {
  const { data} = useQueryWithElevatedRole(gql`
    query Path($id: Int!){
        paths_by_pk(id: $id) {
          id
          type
          number
          area_id
          created_at
          points
        }
    }
  `, {variables: {id: pathId}})
  if (!data) {return null}
  return <Path path={data?.paths_by_pk} {...props}/>
}


const groupPaths = (paths: any) => {
  // Created nested object with keys: area_id => path_description
  const data: any = {}
  paths.forEach((path: any) => {
    let area = data[path.area_id] || null
    if (area === null) {
      area = {}
      data[path.area_id] = area
    }
    const key = `${path.type}_${path.number}`
    let p = area[key] || null
    if (p === null) {
      p = {}
      area[key] = p
    }
    p[path.created_at] = path
  })
  return data
}


const useAreaPoints = (field: any) => {
  if (!field) return []
  const g = groupPaths(field.paths)
  return Object.keys(g).map((area_id: any) => {
    const area = g[area_id]
    const pathDescriptors = Object.keys(area)
    // Pick first and last path objects per area
    return [area[pathDescriptors[0]], area[pathDescriptors[pathDescriptors.length - 1]]]
  })
  .flat()
  .map((pathObject: any) => pathObject[Object.keys(pathObject)[0]] /* select first path per path object (latest created_at) */)
}



export const FieldMap: React.FC<any> = ({selectedPaths, areas, height=300}: any) => {
  const [scale, stageProps, center] = useKonvaStageProps({height})
  const {getAreaColor} = useGetArea(areas)

  const { data} = useQueryWithElevatedRole(gql`
    query Paths($_in: [Int!]!) {
      paths(where: {id: {_in: $_in}}) {
        id
        type
        number
        area_id
        created_at
        points
      }
    }
  `, {variables: {_in: selectedPaths.map(({id}: any) => id)}})

  const centerField = () => {
    if (data?.paths?.length < 2) {return}
    // Ensure all points are within the boundaries
    const boundaries = getBoundariesFromList(data.paths.map(({points}: any) => points).flat())
    const x = (boundaries.xMin + boundaries.xMax) / 2.
    const y = (boundaries.yMin + boundaries.yMax) / 2.
    const mapWidth = boundaries.xMax - boundaries.xMin
    const mapHeight = boundaries.yMax - boundaries.yMin
    const scale = Math.min(stageProps.width / mapWidth, height / mapHeight)
    center(scale, x, y)
  }

  useEffect(() => {
    if (!data) {return}
    // Zoom to center of points initially
    if (stageProps.x != 0. || stageProps.y != 0) {return  /* Only center on paths if stage was not yet moved */}
    // Center on paths
    centerField()
  }, [data])

  return <>
    <IonButton style={{position: "absolute", zIndex: 2000, right: 0, top: height / 2}} fill="clear" onClick={centerField}>
      <IonIcon icon={locateOutline} slot="icon-only"/>
    </IonButton>
    <Stage {...stageProps} style={{width: stageProps.width+"px", height: stageProps.height+"px", "top":0, "left": 0, position: "absolute"}}>
      <Layer scaleY={-1  /* y goes down in konva */}>
        {data?.paths?.map((path: any) => <Path key={path.id} path={path} color={getAreaColor(path.area_id) || "black"} strokeWidth={0.5}/>)}
      </Layer>
    </Stage>
  </>
}

const FieldView: React.FC<any> = ({selectedFieldId, field, ...props}: any) => {
  const [selectedPathIds, setSelectedPathIds] = useState<any>(null)
  useEffect(() => {
    setSelectedPathIds(null)
  }, [field])
  const firstAndLastPerArea = useAreaPoints(field)
  useEffect(() => {
    if (!field) {return}
    if (selectedPathIds !== null) {return  /* Skip if some paths have already been selected*/}
    // Automatically set the paths to be selected
    setSelectedPathIds(new Set(firstAndLastPerArea.map((p: any) => p.id)))
  }, [firstAndLastPerArea, selectedPathIds])
  const toggleSelectedPath = (pathId: number) => {
    if (selectedPathIds === null) {return}  // not ready yet
    if (selectedPathIds.has(pathId)) {
      selectedPathIds.delete(pathId)
    }
    else {
      selectedPathIds.add(pathId)
    }
    setSelectedPathIds(new Set(JSON.parse(JSON.stringify([...selectedPathIds]))))  // Deep clone to ensure react rerender
  }
  const selectedPaths = field.paths.filter((path: any) => selectedPathIds?.has(path.id))
  const areas = field?.config_v2?.areas
  const {getAreaColor} = useGetArea(areas)
  if (!field) {return null}
  return <>
    <IonCard>
      <IonItem>
        Selected {selectedPathIds?.size} of {field.paths.length} paths
        <IonButton slot="end" onClick={() => setSelectedPathIds(null)} fill="clear">Reset</IonButton>
      </IonItem>
      <IonCardContent>
        <IonList style={{overflow: "scroll", height: 200}}>
          {selectedPathIds && field.paths.map((path: any) => <IonItem key={path.id}
              onClick={() => toggleSelectedPath(path.id)}>
            <IonBadge style={{background: getAreaColor(path.area_id)}}>
              A{path.area_id}
            </IonBadge>
            <IonChip color={path.number % 2 === 0 ? "dark": undefined}>{path.type} {path.number > 0 && path.number}</IonChip>
            <IonChip >{moment(path.created_at).twitterLong()}: {path.created_at}</IonChip>
            <IonIcon slot="end" color={selectedPathIds.has(path.id) ? "secondary" : undefined} icon={selectedPathIds.has(path.id) ? eye : eyeOff}/>
          </IonItem>)}
        </IonList>
      </IonCardContent>
    </IonCard>
    <IonCard>
      <IonCardContent>
        <div style={{height: "300px"}}>
          <FieldMap selectedPaths={selectedPaths} areas={areas}/>
        </div>
      </IonCardContent>
    </IonCard>
  </>
}


const Field: React.FC = () => {
  const { id } = useParams<{ id: string; name: string}>();

  return <IonPage>
    <IonContent fullscreen>
      <FieldContent id={id}/>
    </IonContent>
  </IonPage>
}


export const FieldContent: React.FC<any> = ({id}: any) => {
  const [edit, setEdit] = useState(false)

  const { loading, data} = useQueryWithElevatedRole(gql`
    query Fields($id: Int!) {
      fields_by_pk(id: $id) {
        id
        config
        archived
        created_at
        created_by_taurus_id
        paths_aggregate{aggregate{count}}
        config_v2
        schema_points
        crop_id
        crop {
          id
          name_EN
        }
        paths(order_by: [{area_id: asc}, {number: asc}, {created_at: desc}]) {
          id
          type
          number
          area_id
          created_at
        }
      }

      plant_varieties(where: {is_crop: {_eq: true}}) {
        id name_EN
      }
    }
  `, {variables: {id}, pollInterval: 1000})

  const [updateField] = useMutationWithElevatedRole(gql`
      mutation UpdateField($id: Int!, $_set: fields_set_input = {}) {
        update_fields_by_pk(pk_columns: {id: $id}, _set: $_set) {
          id
        }
      }
  `)

  const field = data?.fields_by_pk
  const areas = field?.config_v2?.areas || []
  const {getAreaColor} = useGetArea(areas)
  if (!field) {
    return <IonProgressBar type="indeterminate"/>
  }
  return <>
    {loading && <IonProgressBar type="indeterminate"/>}
    <IonCard onClick={() => setEdit(true)} style={{cursor: "pointer"}}>
      <IonBadge style={{position: "absolute", right: 0}}>
        <IonIcon icon={pencil}/>Edit
      </IonBadge>
      <IonCardContent>
        <h1>{window.decodeURIComponent(field.config_v2?.name || "?")}</h1>
        {field.config_v2 && field.schema_points && <MiniatureField fieldConfig={field.config_v2} points={field.schema_points}/>}
        <IonItem>
          <IonBadge>{field?.crop?.name_EN || "No crop set"}</IonBadge>
          <IonBadge>{field?.config_v2?.interrow_distance * 100} cm x {field?.config_v2?.number_of_bed_rows}</IonBadge>
        </IonItem>
        <IonItem>
          {areas.map((area: any) => <IonBadge key={area.id} style={{background: getAreaColor(area.id)}}>A{area.id}</IonBadge>)}
        </IonItem>
      </IonCardContent>
    </IonCard>

    <FieldView field={field}/>

    <IonItem>
      {field.created_by_taurus_id && <IonChip slot="end">GT {field.created_by_taurus_id}</IonChip>}
      <IonChip >{moment(field.created_at).twitterLong()}: {field.created_at}</IonChip>
      <ConfirmButton text={`${field.archived ? "Restore" : "Archive"} field`} fill="clear"
        onClick={() => updateField({variables: {id: field.id, _set: {archived: !field.archived}}})} slot="end">
        <IonIcon icon={field.archived ? arrowUndo: trash } slot="icon-only"/>
      </ConfirmButton>
    </IonItem>

    <IonModal isOpen={edit} onDidDismiss={() => setEdit(false)} className="fullscreen">
      <FieldEditor
        skipCropsWithNoRule={false  /* Allow setting crops that have no rules yet. This is prohibited on control app.*/}
        rules_by_crop={data.plant_varieties}
        oldConfig={field.config}
        initialData={{fieldConfig: field.config_v2, points: field.schema_points, crop_id: field.crop_id}}
        onClose={() => setEdit(false)}
        onSave={(d: any) => updateField({variables: {id: field.id, _set: {config_v2: d.fieldConfig, schema_points: d.points, crop_id: d.crop_id}}}).then(() => setEdit(false))}
      />
    </IonModal>
  </>
};

export default Field;
