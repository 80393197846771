import { t } from "i18next";
import { useCallService, useParam, useTopic } from "../hooks/rosHooks";
import { useState } from "react";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle } from "@ionic/react";
import { TalpaConfig } from "../utils/RobotConfigTypes";
import { CharacteristicTable, CurrentStep, OverallProgress } from './SprayerCalibrationMatrix';

export interface Jwt {
    exp:                            number;
    iat:                            number;
    jti:                            string;
    iss:                            string;
    aud:                            string;
    sub:                            string;
    typ:                            string;
    azp:                            string;
    session_state:                  string;
    acr:                            string;
    realm_access:                   RealmAccess;
    resource_access:                ResourceAccess;
    scope:                          string;
    sid:                            string;
    email_verified:                 boolean;
    "https://hasura.io/jwt/claims": HTTPSHasuraIoJwtClaims;
    name:                           string;
    preferred_username:             string;
    given_name:                     string;
    family_name:                    string;
    email:                          string;
}

export interface HTTPSHasuraIoJwtClaims {
    "x-hasura-default-role":  string;
    "x-hasura-user-id":       string;
    "x-hasura-allowed-roles": string[];
}

export interface RealmAccess {
    roles: string[];
}

export interface ResourceAccess {
    jwt:     RealmAccess;
    account: RealmAccess;
}

export interface CalibrationProgress {
    state:            "active" | "inactive" | "complete";
    characteristic:   Characteristic;
    current_step:     CurrentStep;
    overall_progress: OverallProgress;
    pressure_ok:      boolean;
}

export interface Characteristic {
    opening_duration_ms: number[];
    pressure_bar:        number[];
    spot_mass_mg:        Array<(null | number)[]>;
    talpa_id?   :        number;
}

const MOCK_DATA : CalibrationProgress = {
    "state": "active",
    "characteristic": {
        "opening_duration_ms": [10, 12, 15, 20, 30, 40],
        "pressure_bar": [2.0, 2.5, 3.0],
        "spot_mass_mg": [
            [null, null, null],
            [null, null, null],
            [null, null, null],
            [null, null, null],
            [null, null, null],
            [null, null, null]
        ]
    },
    "current_step": { "current": 0, "total": 1 },
    "overall_progress": { "opening_duration_idx": 0, "pressure_idx": 0 },
    "pressure_ok": false
};

export interface CalibrationConfig {
    opening_duration_ms: number[];
    pressure_bar:        number[];
    amount:              number;
    interval_m:          number;
    repeat_interval_m:   number;
    repeat:              number;
    vx_ms:               number;
}

export interface SprayerCalibration {
    id:                  number;
    nozzle_angle_x_deg:  number;
    nozzle_angle_y_deg:  number;
    opening_duration_ms: number[];
    pressure_bar:        number[];
    spot_mass_mg:        Array<number[]>;
}



enum WhichToShow {
    CalibrationFromRosparams,
    NewCalibration
}

export const SprayerNozzleCalibration : React.FC<{talpa_config : TalpaConfig}> = ({talpa_config}) => {

    const prefix = `/sprayer/${talpa_config.row_name}`;

    const calibrationStatus_raw = useTopic<{data: string}>(`${prefix}/calibration_progress`, "std_msgs/String");
    const calibrationStatus : CalibrationProgress | undefined = JSON.parse(calibrationStatus_raw?.data || 'false') || MOCK_DATA;
    const calibration_complete = calibrationStatus?.state === "complete";
    const active = calibrationStatus?.state === "active";

    const {value : calibrationConfig, refresh: refreshCalibrationConfig} = useParam<CalibrationConfig>("/nozzle_calibration_config")
    const {value : currentCalibration_list} = useParam<SprayerCalibration[]>(`/rows/${talpa_config.row_name}/both/sprayer_calibrations`);
    const currentCalibration = (currentCalibration_list || [])[0] || undefined;

    const callService = useCallService();
    const startCalibration = ()=>callService(`${prefix}/calibrate_nozzle`, "std_srvs/Empty", {});
    const abortCalibration = ()=>callService(`${prefix}/abort_nozzle_calibration`, "std_srvs/Empty", {});
    
    const [btnDelay, setBtnDelay] = useState(false);
    const tempDisableButtons = (ms : number) => {
        setBtnDelay(true);
        setTimeout(()=>{
            setBtnDelay(false)
        }, ms)
    }

    const [shownCalibration, setShownCalibration] = useState(WhichToShow.CalibrationFromRosparams);

    if (calibrationStatus === undefined) {
        return <div>{t("no calibration status")}</div>
    }
    if (calibrationConfig === undefined) {
        return <div>{t("no calibration config")}</div>
    }

    const cookies = new Map(document.cookie.split(";").map(s=>s.trimStart()).map(s=>{const idx=s.indexOf("="); return [s.slice(0,idx), s.slice(idx+1)]}));
    const jwt_cookie = cookies.get("jwt");
    const jwt_parsed : Jwt | undefined = jwt_cookie===undefined?undefined:JSON.parse(atob((jwt_cookie.split("."))[1]));
    const is_fr_staff = (jwt_parsed!==undefined) && jwt_parsed["https://hasura.io/jwt/claims"]["x-hasura-allowed-roles"].includes("farming-revolution-staff");

    const uploadCalibration = async (calibration : Characteristic) => {
        calibration.talpa_id = talpa_config.talpa.id;
        let res = await fetch("https://api.farming-revolution.com/v1/graphql", {
            "credentials": "include",
            "headers": {
                "content-type": "application/json",
                "Cache-Control": "no-cache",
                "Authorization": `Bearer ${cookies.get("jwt")}`,
                "X-Hasura-Role": "farming-revolution-staff" 

            },
            "body": JSON.stringify({
                "query":"mutation NewNozzleCalibration($obj: sprayer_calibrations_insert_input!) {insert_sprayer_calibrations(objects: [$obj]) { affected_rows }}",
                "variables":{"obj": calibration},
                "operationName":"NewNozzleCalibration"
            }),
            "method": "POST",
        });
        let rest_text = await res.text();
        alert(rest_text);
    }

    Object.assign(window, {calibrationStatus, calibrationConfig})

    return <div className="sprayer_nozzle_calibration">
            <strong>{talpa_config.row_name}, TLP{talpa_config.talpa.id}</strong>
            <p style={{display: 'flex'}}>
                <label style={{paddingRight: '1em'}}>
                <input type='radio' name={prefix} value={WhichToShow.CalibrationFromRosparams} checked={shownCalibration==WhichToShow.CalibrationFromRosparams} onClick={_=>setShownCalibration(WhichToShow.CalibrationFromRosparams)}/> {t("active calibration")}
                </label>
                <label>
                    <input type='radio' name={prefix} value={WhichToShow.NewCalibration} checked={shownCalibration==WhichToShow.NewCalibration} onClick={_=>setShownCalibration(WhichToShow.NewCalibration)}/> {t("new calibration")}
                </label>
            </p>
            {shownCalibration === WhichToShow.NewCalibration && <>
                <CharacteristicTable 
                    pressures_bar={calibrationConfig.pressure_bar}
                    opening_durations_ms={calibrationConfig.opening_duration_ms}
                    spot_masses_mg={calibrationStatus.characteristic?.spot_mass_mg || null}
                    current_step={calibrationStatus.current_step || null}
                    overall_progress={calibrationStatus.overall_progress || null}
                />
                <p>
                    {calibrationStatus.pressure_ok === false ?
                        <span>
                            {t("waiting for pressure adjustment")} <span style={{
                                    padding: '0 1em',
                                    display: 'inline-block',
                                    animation: 'spinner 2s linear infinite'
                            }}>×</span>
                        </span>:
                        <span>
                            {active?'pressure ok':'\xa0'}
                        </span>
                    }
                </p>
                <div className="sprayer-calibration-upload-buttons">
                    <IonButton color="primary" disabled={btnDelay||(calibrationStatus.state==="active")} onClick={_=>{
                        tempDisableButtons(200);
                        setShownCalibration(WhichToShow.NewCalibration);
                        startCalibration();
                    }}>{t("Start calibration")}</IonButton>
                    <IonButton color="danger" disabled={btnDelay||(calibrationStatus.state!=="active")} onClick={_=>{
                        tempDisableButtons(200);
                        abortCalibration();
                    }}>{t("abort")}</IonButton>
                    <IonButton color="success" disabled={!calibration_complete || !is_fr_staff} onClick={_=>{
                        uploadCalibration(calibrationStatus.characteristic)
                    }}>{is_fr_staff?t("upload"):t("ask staff to upload")}</IonButton>
                </div>
            </>}
            {shownCalibration === WhichToShow.CalibrationFromRosparams && <>
                { currentCalibration !== undefined && <CharacteristicTable 
                    pressures_bar={currentCalibration.pressure_bar}
                    opening_durations_ms={currentCalibration.opening_duration_ms}
                    spot_masses_mg={currentCalibration.spot_mass_mg}
                    current_step={null}
                    overall_progress={null}
                />}
                <p>
                    {currentCalibration === undefined ?
                        <span>{t("No previous calibration active. This nozzle is uncalibrated.")}</span> :
                        <span>
                            {t("Active calibration id")}: <code>{currentCalibration.id}</code>
                        </span>
                    }
                </p>
            </>}
        </div>
}
