import { gql } from "@apollo/client"
import { useSubscriptionWithElevatedRole } from "./hasuraHooks"

const componentTypePlural = (componentType: string) => componentType.replace("taurus", "tauruse") + "s"


export const useComponentIds = (componentType: string) => {
  const {loading, data} = useSubscriptionWithElevatedRole(
    gql`
      subscription Components {
        ${componentTypePlural(componentType)} (order_by: {id: asc}) {
          id
        }
      }
    `, {}
  )

  if (loading || !data || !data[componentTypePlural(componentType)]) {
    return null
  }
  // Return the ids
  return data[componentTypePlural(componentType)].map(({id}: any) => id)
}