import { IonList as IonGrid, IonContent, IonPage, IonProgressBar, IonRow, IonCol, IonToggle, IonInput, IonButton, IonSelect, IonSelectOption, IonTextarea, IonLabel, } from '@ionic/react';
import React, { useState } from 'react';
import Toolbar from '../../components/Toolbar'
import { useQuery, gql } from '@apollo/client';
import { useMutationWithElevatedRole } from '../../hooks/hasuraHooks';
import { EditableShape, } from '../../components/MinitatureShape';
import { areEqual } from '../../lib/areEqual';


export const Tools: React.FC = () => {

  const { loading, data } = useQuery(gql`query{
      talpa_tools(order_by: {id: asc}) {
        id

        shape_left
        shape_right

        description
        talpa_hardware_version_id

        material_width_back_mm
        material_width_blade_mm

        archived
        developer_only
      }
      talpa_hardware_versions(order_by: {id: asc}, where: {archived: {_eq: false}}) {
        id
        description
      }
  }`, {pollInterval: 1000})
  return (
    <IonPage>
      <Toolbar name="Tools"/>
      <IonContent fullscreen>
         {loading && <IonProgressBar type="indeterminate"/>}
         {data && <IonGrid>
            <ToolItem header/>
            {data.talpa_tools.map((tool: any) => <ToolItem tool={tool} key={tool.id} talpa_hardware_versions={data.talpa_hardware_versions}/>)}
        </IonGrid>}
      </IonContent>
    </IonPage>
  );
};


export const ToolItem: React.FC<any> = ({tool, header, talpa_hardware_versions}: any) => {
  const [data, setData] = useState(tool)
  const changed = !areEqual(data, tool)
  // Helper to handle onIonChange events
  const changeHandler = (dataKey: string, eventKey: string = "value", floatify=false) => (e: any) => {
    let newValue = e.detail[eventKey]
    if (floatify) {
      newValue = parseFloat(newValue)
    }
    setData((prev: any) => ({...prev, [dataKey]: newValue}))
  }

  const [updateTool] = useMutationWithElevatedRole(gql`
    mutation UpdateTool($id: Int!, $_set: talpa_tools_set_input = {}) {
      update_talpa_tools_by_pk(pk_columns: {id: $id}, _set: $_set) {
        id
      }
    }
  `)

  const [insertTool] = useMutationWithElevatedRole(gql`
    mutation InsertTool($object: talpa_tools_insert_input!) {
      insert_talpa_tools_one(object: $object) {
        id
      }
    }
  `)

  const save = () => {
    const _set = {...data}
    if (_set.id) {delete _set.id}  // Should not be changed
    if (_set.__typename) {delete _set.__typename}   // Remove (from query but cannot be set)
    updateTool({variables: {id: tool.id, _set}})
  }

  const insert = (object: any) => {
    if (object.id) {delete object.id}  // Should not be changed
    if (object.__typename) {delete object.__typename}   // Remove (from query but cannot be set)
    insertTool({variables: {object}})
  }

  // Some tools have no distinction between left and right side
  const sameShapeForLeftAndRight = !data?.shape_right

  return <IonRow style={{fontWeight: header ? "bold": undefined, alignItems: "center"}}>
    <IonCol size="1">
      {!header && <div style={{display: "flex"}}>
        <EditableShape points={data.shape_left} onChange={changeHandler("shape_left")} text={`${tool.id}${sameShapeForLeftAndRight ? "" :"L"}`}/>
        <EditableShape points={data?.shape_right} onChange={changeHandler("shape_right")} text={sameShapeForLeftAndRight ? "-" : `${tool.id}R`}/>
      </div>}
    </IonCol>

    <IonCol>
      {header && "Beschreibung"}
      {!header && <IonTextarea rows={2} value={data.description} style={{border: "1px solid lightgray"}} onIonChange={changeHandler("description")}/>}
    </IonCol>

    <IonCol size="1">
      {header && "Materialstärke Klinge (mm)"}
      {!header && <div>
        <IonInput min="1" max="30" step="1" onIonChange={changeHandler("material_width_blade_mm", "value", true)} value={data.material_width_blade_mm} type="number"/>
      </div>}
    </IonCol>

    <IonCol size="1">
      {header && "Materialstärke Rücken (mm)"}
      {!header && <div>
        <IonInput min="1" max="30" step="1" onIonChange={changeHandler("material_width_back_mm", "value", true)} value={data.material_width_blade_mm} type="number"/>
      </div>}
    </IonCol>

    <IonCol size="1">
      {header && "Talpa Version"}
      {!header && <div>
        <IonSelect value={data.talpa_hardware_version_id} onIonChange={changeHandler("talpa_hardware_version_id")}>
          {talpa_hardware_versions.map((version: any) => <IonSelectOption key={version.id} value={version.id}>{version.description}</IonSelectOption>)}
        </IonSelect>
      </div>}
    </IonCol>

    <IonCol size="0.5">
      {header && "Status"}
      {!header && <div style={{display: "flex", flexDirection: "column"}}>
        <IonLabel position="stacked">archived</IonLabel>
        <IonToggle checked={data.archived} onIonChange={changeHandler("archived", "checked")} style={{paddingBottom: "4px !important"}}/>
        <IonLabel position="stacked">dev only</IonLabel>
        <IonToggle checked={data.developer_only} onIonChange={changeHandler("developer_only", "checked")}/>
      </div>}
    </IonCol>

    <IonCol size="1">
      {!header && <div style={{display: "flex", flexDirection: "column"}}>
        {changed && <>
          <IonButton size="small" onClick={save}>Save</IonButton>
          <IonButton size="small" onClick={() => setData({...tool})}>Reset</IonButton>
        </>}
        {!changed && <IonButton fill="clear" size="small" onClick={() => insert({...tool})}>Copy</IonButton>}
      </div>}
    </IonCol>
  </IonRow>
}