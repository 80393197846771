import { gql } from "@apollo/client";
import getUsername from "../lib/getUsername";
import { useMutationWithElevatedRole } from "./hasuraHooks";
import { useUserId } from "./userHooks";


export const useGetChecklistActions = () => {
  const userId = useUserId()
  const username = getUsername()  // Saved in updates
  const [insertProcessTodoHasura] = useMutationWithElevatedRole(
    gql`
      mutation InsertProcessTodo($object: process_todos_insert_input!) {
        insert_process_todos_one(object: $object) {
          id
        }
      }
    `,
    {}
  );
  const [insertProcessExecutionHasura] = useMutationWithElevatedRole(
    gql`
      mutation InsertProcessExcecution($object: process_executions_insert_input!) {
        insert_process_executions_one(object: $object) {
          id
        }
      }
    `,
    {}
  )
  const [updateTodo] = useMutationWithElevatedRole(
    gql`
      mutation UpdateProcessTodo($id: Int!, $_set: process_todos_set_input!) {
        update_process_todos_by_pk(pk_columns: { id: $id }, _set: $_set) {
          id
        }
      }
    `,
    {}
  );
  const [deleteProcessExecutionHasura] = useMutationWithElevatedRole(
    gql`
      mutation DeleteProcessExcecution($id: Int!) {
        delete_process_executions_by_pk(id: $id) {
          id
        }
      }
    `,
    {}
  )
  const [deleteTodoHasura] = useMutationWithElevatedRole(
    gql`
      mutation DeleteTodo($id: Int!) {
        delete_process_todos_by_pk(id: $id) {
          id
        }
      }
    `,
    {}
  )

  return (processId: number, componentType: string, componentId: number) => ({

    setAsDone: (todoId: number|null = null) => {  // Add a process execution and if available set the related todo as closed by that process execution
      insertProcessExecutionHasura({variables: {object: {
        author_name: username,  // NOTE: would be nicer to use an id here
        process_id: processId,
        [`${componentType}_id`]: componentId,
      }}}).then(({data: {insert_process_executions_one: {id: processExecutionId}}}) => {
        if (todoId) {  // If there is a todo id: mark it as closed from the new process execution
          updateTodo({variables: {
            id: todoId,
            _set: {closed_by_process_execution_id: processExecutionId}
          }})
        }
      })
    },

    assignTodo: (assigned_to_id: number) => {
      insertProcessTodoHasura({variables: {object: {
        created_by_id: userId,
        process_id: processId,
        assigned_to_id,
        [`${componentType}_id`]: componentId,
      }}})
    },

    deleteTodo: (id: number) => deleteTodoHasura({variables: {id}}),
    deleteProcessExecution: (id: number) => deleteProcessExecutionHasura({variables: {id}}),
  })
}