import { Layer, Stage } from "react-konva"
import { MultiContextProvider } from "./MultiContextProvider"
import { useRef, useState } from "react";


export const KonvaStage: React.FC<any> = ({children, contexts=[], stageProps}: any) => {  // memo: memoized (not update from parent field section because has no parameters)
  return <>
    <Stage {...stageProps}>
      <MultiContextProvider contexts={contexts}>
        <Layer scaleY={-1 /* important: y goes down in konva */}>
          {children}
        </Layer>
      </MultiContextProvider>
    </Stage>
  </>
}


export const useKonvaStageProps = ({onChange, additionalStyle = {}, onDoubleClick}: any) => {
  // onDoubleClick can be used for other actions than zooming in
  const [state, setState] = useState<any>({
    // Offsets and scale of stage
    scale: 10,
    x: 0,
    y: 0,

    // For mobile touch
    lastCenter: null,
    lastDist: 0,

    fixed: false,  // Cannot zoom or move
  });
  const stageRef = useRef<any>(null)

  const lastTwoFingerUsageTime = useRef(0);

  const width = window?.visualViewport?.width || 1800
  const height = window?.visualViewport?.height || 400

  function isTouchEnabled() {
    return ( 'ontouchstart' in window ) ||
          ( navigator.maxTouchPoints > 0 );
  }

  const handleWheel = (event: any) => {
    const scaleBy = 1.05
    event.evt.preventDefault();
    if (stageRef.current !== null) {
      const stage = stageRef.current;
      const oldScale = stage.scaleX();
      const { x: pointerX, y: pointerY } = stage.getPointerPosition();
      const mousePointTo = {
        x: (pointerX - stage.x()) / oldScale,
        y: (pointerY - stage.y()) / oldScale,
      };
      const newScale = event.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
      state.scale = newScale
      state.x = pointerX - mousePointTo.x * newScale
      state.y = pointerY - mousePointTo.y * newScale
      setState({...state})
      if (onChange) {
        onChange()
      }
    }
  }

  const handleDblClick = (event: any) => {
    const now = performance.now();
    if ((now - lastTwoFingerUsageTime.current) < 100) {
      // prevent misinterpreting pinch-release as double click
      return;
    }
    
    const scaleBy = 2.5

    if (state.lastCenter !== null) {
      return
    }

    event.evt.preventDefault();
    if (stageRef.current !== null) {
      const stage = stageRef.current;
      const oldScale = stage.scaleX();
      const { x: pointerX, y: pointerY } = stage.getPointerPosition();
      const mousePointTo = {
        x: (pointerX - stage.x()) / oldScale,
        y: (pointerY - stage.y()) / oldScale,
      };
      if (onDoubleClick) {
        return onDoubleClick(mousePointTo)
      }
      const newScale = oldScale * scaleBy;
      state.scale = newScale
      state.x = pointerX - mousePointTo.x * newScale
      state.y = pointerY - mousePointTo.y * newScale
      setState({...state})
      if (onChange) {
        onChange()
      }
    }
  }

  function getDistance(p1: any, p2: any) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  }

  function handleTouch(e: any) {
    e.evt.preventDefault();
    let touch1 = e.evt.touches[0];
    let touch2 = e.evt.touches[1];

    const stage = stageRef.current;
    if (stage === null) {
      return
    }

    if (touch1 && touch2) {
      lastTwoFingerUsageTime.current = performance.now();
      if (stage.isDragging()) {
        stage.stopDrag();
      }

      var p1 = {
        x: touch1.clientX,
        y: touch1.clientY
      };
      var p2 = {
        x: touch2.clientX,
        y: touch2.clientY
      };

      if (state.lastCenter !== null && (getDistance(p2, state.lastCenter) < getDistance(p1, state.lastCenter))) {
        [p1, p2] = [p2, p1]
      }

      if (!state.lastCenter) {
        return setState((prev: any) => ({...prev, lastCenter: {...p1}}))
      }
      var newCenter = {...p1}

      var dist = getDistance(p1, p2);

      if (!state.lastDist) {
        state.lastDist = dist;
      }

      // local coordinates of center point
      var pointTo = {
        x: (newCenter.x - state.x) / state.scale,
        y: (newCenter.y - state.y) / state.scale
      };

      state.scale = state.scale * (dist / state.lastDist);

      // calculate new position of the stage
      var dx = newCenter.x - state.lastCenter.x
      var dy = newCenter.y - state.lastCenter.y
      state.x = newCenter.x - pointTo.x * state.scale + dx
      state.y =  newCenter.y - pointTo.y * state.scale + dy
      state.lastDist = dist;
      state.lastCenter = newCenter;
      setState({...state})
      if (onChange) {
        onChange()
      }
    }

    else if (touch1) {
      const point = ({x: touch1.clientX, y: touch1.clientY})
      if (!state.lastCenter) {
        return setState((prev: any) => ({...prev, lastCenter: point}))
      }
      // local coordinates of center point
      var pointTo = {
        x: (point.x - state.x) / state.scale,
        y: (point.y - state.y) / state.scale
      };
      var dx = point.x - state.lastCenter.x
      var dy = point.y - state.lastCenter.y
      state.x = point.x - pointTo.x * state.scale + dx
      state.y =  point.y - pointTo.y * state.scale + dy
      state.lastCenter = point;
      setState({...state})
      if (onChange) {
        onChange()
      }
    }
  }

  function handleTouchEnd(e: any) {
    e.evt.preventDefault()
    setState({...state, lastCenter: null, lastDist: 0})
  }

  const center = (newScale: number, offsetX : number = 0, offsetY = 0, rotation = 0) => {
    const stage = stageRef?.current
    if (!stage) return
    const rotationDeg = rotation * 180. / Math.PI
    const x = width / 2 - offsetX * newScale * Math.cos(rotation) - offsetY * newScale * Math.sin(rotation)
    const y = height / 2 + offsetY * newScale * Math.cos(rotation) - offsetX * newScale * Math.sin(rotation)
    stage.position({x: x, y: y})
    stage.scale({x: newScale, y: newScale})
    stage.rotation(rotationDeg)  // Konva needs degrees, not radians!
    setState((prev: any) => ({...prev, scale: newScale, x: x, y: y, rotation: rotationDeg}))
  }

  const defaultStageProps = {
    width: width,
    height: height,
    x: state.x,
    y: state.y,
    scaleX: state.scale,
    scaleY: state.scale,
    ref: stageRef,
    rotation: state.rotation,
    style: {width: width+"px", height: height+"px", "top":0, "left": 0, position: "absolute", ...additionalStyle},
  }

  // Only if not fixed
  const dynamicStageProps = {
    onWheel: handleWheel,
    onDblClick: handleDblClick,
    onDblTap: handleDblClick,
    onTouchMove: handleTouch,
    onTouchEnd: handleTouchEnd,
    draggable: !isTouchEnabled(),
  }

  const stageProps = {
    ...defaultStageProps,
    ...(state.fixed ? {} : dynamicStageProps),
  }

  return {
    stageProps,
    center,
    setFixed: (newFixed: boolean) => setState({...state, fixed: newFixed}),
    state,
  }
}