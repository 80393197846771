import { IonButton, IonItem, IonLabel, IonSpinner, IonToggle } from "@ionic/react";
import { MutableRefObject, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Param } from "roslib";
import { getOverrideParamService, RosHandleContext, useCallService } from "../hooks/rosHooks";
import i18n from 'i18next';

export const numSliderWidget = (p: any, nominalValue : number, set : any, state : OverrideParamState, args: any)=>
    <tr>
    <td style={{paddingLeft: "1rem", height: "3rem", textTransform: "capitalize"}}>
        <label>{i18n.t(p.name)}</label>
    </td>
    <td style={{fontFamily: "mono", textAlign: "right", paddingRight: "1rem", whiteSpace: "nowrap", minWidth: "5rem"}}>
        {state != OverrideParamState.Ok ? <IonSpinner/> : <>{nominalValue?.toFixed(args?.digits || 0)}{args?.unit?` ${args.unit}`:''}</>}
    </td>
    <td style={{width: "100%"}}>
        <input style={{width: "100%", border: "black solid"}} type="range" min={p.min} max={p.max} step={p.step === undefined?1:p.step}
            value={nominalValue} onChange={(e: any) => {
                let v = Number(e.target.value);
                (set as (_:number)=>void)(v)
                }} />
    </td>
</tr>

export const minusPlusWidget = (p: any, nominalValue : number, set : any, state : OverrideParamState, args: any)=>{
    const factor : number = args['factor'] || 1;
    const step = (p.step || 1) / factor;
    const visible_value = (nominalValue || 0) * factor;
    const min = p.min / factor;
    const max = p.max / factor;

    return <tr>
        <td style={{paddingLeft: args.paddingLeft || "1rem", height: "3rem"}}>
            <label style={{paddingRight: '.5em'}}>{i18n.t(p.name)}</label>
        </td>
        <td style={{opacity: state===OverrideParamState.Loading?.5:1, backgroundColor: state===OverrideParamState.Error?'red':'transparent'}} colSpan={2}>
            <div style={{width: "100%", display: 'flex', alignItems: 'center'}}>
                <IonButton
                    size="small"
                    disabled={(1*nominalValue)-step<min}
                    onClick={_evt=>{
                        let new_v = 1*nominalValue - step;
                        if (new_v >= min) {
                            set(new_v)
                        }
                    }}
                >-</IonButton>

                <span style={{fontFamily: 'monospace', flexGrow: 1, textAlign: (args.align||'right'), fontSize: '1rem', padding: '0 .5em'}}>{visible_value.toFixed(args['digits']||0)}{args['unit']?' ' + args['unit']:''}</span>
                
                <IonButton
                    size="small"
                    disabled={(1*nominalValue)+step>max}
                    onClick={_evt=>{
                        let new_v = 1*nominalValue + step;
                        if (new_v <= max) {
                            set(new_v)
                        }
                    }}
                >+</IonButton>
            </div>
        </td>
    </tr>
}

export const minusPlusWidgetIonItem = (p: any, nominalValue : number, set : any, state : OverrideParamState, args: any)=>{

    // Use scaling factor so we can display values in a different unit
    // from the underlying parameter (e.g. cm for a param defined in m)
    const factor : number = args['factor'] || 1;
    const gt0 = factor > 0;
    const step = (p.step || 1) / factor;
    const visible_value = (nominalValue || 0) * factor;
    let min = p.min / factor;
    let max = p.max / factor;

    // pad the displayed value with spaces so the text does not jump around
    // depending on the sign and number of digits.
    const formatter = args.padTo ? (s : string)=> `${s}`.padStart(args.padTo, " "): ((s:string)=>s);
    
    return <IonItem>
        <IonLabel>{i18n.t(p.name)}</IonLabel>
        <div style={{opacity: state===OverrideParamState.Loading?.5:1, backgroundColor: state===OverrideParamState.Error?'red':'transparent'}}>
            <div style={{width: "100%", display: 'flex', alignItems: 'center'}}>
                <IonButton
                    size="small"
                    disabled={(gt0 && (1*nominalValue)-step<min) || (!gt0 && (1*nominalValue)-step>max)}
                    onClick={_evt=>{
                        let new_v = 1*nominalValue - step;
                        if ((gt0 && new_v >= min) || (!gt0 && new_v <= max)) {
                            set(new_v)
                        }
                    }}
                >-</IonButton>

                <span style={{fontFamily: 'monospace', flexGrow: 1, textAlign: (args.align||'right'), fontSize: '1rem', padding: '0 .5em', whiteSpace: 'pre'}}>{formatter(`${visible_value.toFixed(args['digits']||0)}${args['unit']?' ' + args['unit']:''}`)}</span>
                
                <IonButton
                    size="small"
                    disabled={(gt0 && (1*nominalValue)+step>max) || (!gt0 && (1*nominalValue)+step<min)}
                    onClick={_evt=>{
                        let new_v = 1*nominalValue + step;
                        if ((gt0 && new_v <= max) || (!gt0 && new_v >= min)) {
                            set(new_v)
                        }
                    }}
                >+</IonButton>
            </div>
        </div>
    </IonItem>
}


// OverrideParam is similar to ParamSetter, but provides realtime ui updates (ui changes
// before new state is synced to the server). This improves the usability of the planner
// parameter sliders and the visualization.
export enum OverrideParamState {
    Ok,
    Loading,
    Error
}

export interface ParamDescription {
    name: string,
    key: string,
    defaultValue: number,
    min: number,
    max: number,
    step: number | undefined,
    renderer: Function,
    render_args: Object | undefined,
    cb: Function | undefined
}

export const OverrideParam : React.FC<any> = ({param} : {param: ParamDescription})=> {
    const callService = useCallService();
    const [nominalValue, setNominalValue] = useState<any>(undefined);
    const [state, setState] = useState(OverrideParamState.Loading);
    const ros = useContext(RosHandleContext);
    const set = useCallback((value : number)=>{
      let overrides : any = {};
      overrides[param.key] = value;
      setNominalValue(value);
      param.cb && param.cb(value);
      setState(OverrideParamState.Loading);
      const param_service = getOverrideParamService();
      callService(
        param_service,
        "weeding_param/OverrideParams",
        {update_override_json: JSON.stringify(overrides)},
        res=>setState(res.success?OverrideParamState.Ok:OverrideParamState.Error));
    }, [callService]);
    useEffect(()=>{
        if (ros) {
            const p = new Param({ros: ros, name: param.key});
            p.get(v=>{
                setNominalValue(v);
                param.cb && param.cb(v);
                setState(OverrideParamState.Ok);
            })
        } else {
            console.error(`could not query initial value of parameter ${param.key}`)
        }
    }, []);
    return param.renderer(param, nominalValue, set, state, param.render_args);
}

export const cmSliderWidget = (p: any, nominalValue : number, set : any, state : OverrideParamState)=>
    <tr>
    <td style={{paddingLeft: "1rem", height: "3rem", textTransform: "capitalize"}}>
        <label>{i18n.t(p.name)}</label>
    </td>
    <td style={{fontFamily: "mono", textAlign: "right", paddingRight: "1rem", whiteSpace: "nowrap", minWidth: "5rem"}}>
        {state != OverrideParamState.Ok ? <IonSpinner/> : <>{Math.round(100 * nominalValue)} cm</>}
    </td>
    <td style={{width: "100%"}}>
        <input style={{width: "100%", border: "black solid"}} type="range" min={`${p.min}`} max={`${p.max}`} step="1"
            value={nominalValue*100} onChange={(e: any) => {
                let v = Number(e.target.value) / 100.0;
                (set as (_:number)=>void)(v)
                }} />
    </td>
</tr>

export const switchWidget = (p: any, nominalValue : boolean, set : any, state : OverrideParamState, render_args : any) => {
    let invert = (!!render_args) && (render_args['invert'] === true);
    let set_ = invert?((v:boolean)=>set(!v)):set;
    return <tr>
        <td style={{paddingLeft: "1rem", height: "3rem"}} colSpan={2}>
            <label>{i18n.t(p.name)} <span style={{display: 'inline-block', width: '30px', height: '0', overflow: 'visible'}}>{(state != OverrideParamState.Ok) && <IonSpinner style={{verticalAlign: "middle"}} />}</span></label>
        </td>
        <td>
            <IonToggle checked={invert?(!nominalValue):nominalValue} disabled={state != OverrideParamState.Ok} onIonChange={evt=>set_(evt.detail.checked)} style={{verticalAlign: "middle"}} />
        </td>
    </tr>
}

export const switchWidgetNoTable = (p: any, nominalValue : boolean, set : any, state : OverrideParamState) =>
    <>
        <label>{i18n.t(p.name)}</label>
        {state != OverrideParamState.Ok ? <IonSpinner /> : <></>}
        <IonToggle checked={nominalValue} disabled={state != OverrideParamState.Ok} onIonChange={evt=>set(evt.detail.checked)} />        
    </>

const SingleChoiceButton: React.FC<{choices : [any, string][], callback : any, initialValue : any}> = ({choices, callback, initialValue}) => {

    const container : MutableRefObject<null | HTMLDivElement> = useRef(null);
    const [selectedIdx, setSelectedIdx] = useState(()=>choices.findIndex(([v, _desc])=>v===initialValue));
    useEffect(() => {
        setSelectedIdx(choices.findIndex(([v, _desc])=>v===initialValue));
      }, [choices, initialValue]);

    const [bgHighlight, setBgHighlight] = useState({
        left: 0,
        width: 0
    })

    if (container.current !== null) {
        let parentRect = container.current.getBoundingClientRect();
        let childRect = container.current.children[selectedIdx+1]?.getBoundingClientRect();
        let left = childRect.x - parentRect.x;
        let width = childRect.width;
        if (childRect !== undefined && left !== bgHighlight.left /* the second check prevents infinite rerenders */) {
            setBgHighlight({
                left,
                width
            })
        }
    }

    return (
        <div
            style={{
                display: 'flex',
                textAlign:'center',
                flexDirection: 'row',
                backgroundColor: '#ddd',
                borderRadius: '0.3em',
                padding: '.5em',
                position: 'relative'
            }}
            ref={container}
        >
            <div style={{
                position: 'absolute',
                backgroundColor: '#fff',
                top: '0.5em',
                left: `${bgHighlight.left}px`,
                width: `${bgHighlight.width}px`,
                height: 'calc(100% - 1em)',
                zIndex: 1,
                transition: 'all 0.15s ease',
                borderRadius: '.3em'
            }} /> 
            {choices.map(([value, desc], idx)=>(
                <button
                    style={{flexGrow: 1, borderRadius: '3px', padding: '.5em', backgroundColor: 'transparent', zIndex: 2}}
                    onClick={_=>{
                        setSelectedIdx(idx);
                        callback(value);
                    }}
                >{desc}</button>
            ))}
        </div>
    )
}

export const singleChoiceWidget = (p: any, nominalValue : number, set : any, state : OverrideParamState, args : any) =>
    <>
        <div style={{display: 'flex', height: '3em', alignItems: 'center'}}><span style={{flexGrow: 1}}>{i18n.t(p.name)}</span> {state !== OverrideParamState.Ok ? <IonSpinner /> : null}</div>
        <SingleChoiceButton callback={set} initialValue={nominalValue} choices={args.choices} />
    </>
