import { IonButton, IonButtons, IonChip, IonIcon, IonItem, IonLabel } from '@ionic/react';
import { saveSharp, trash } from 'ionicons/icons';
import React, { useState, useEffect } from "react";


// useComponentEditor provides methods and properties for forms on components such as
//   showing that fields have changed, reset the fields, update component with the changed fields
// Components can be tables such as aquilas, talpas, sim_cards, etc

export const useComponentEditor = (component: any, updateComponent: any, ignoreKeys: string[] = ["updated_at"]) => {
  const [tmp, setTmp] = useState({ ...component });
  const changedFields = Object.keys(tmp).filter(
    (k: string) => !ignoreKeys.includes(k) && tmp[k] !== component[k]
  );

  // Reset to state on database
  const reset = () => setTmp({ ...component })

  const checkType = (fieldName: any, value: any) => {
    if (value === "") {
      // Empty string means null on database
      return null;
    } else if (
      typeof component[fieldName] == "number"
      || ["filter_max_component_id", "filter_min_component_id"].includes(fieldName)  // Specifically use int parsing (could have been undefined before but now int)
    ) {
      // Parse string to value if field type is number
      return value? parseInt(value): null;
    } else return value;  // Return the value as a string
  };

  // Reset values in tmp if the component has changed (e.g. changed by other user)
  useEffect(reset, [component]);

  // Generic callback for IonInput, IonToggle, etc (parses numbers and text correctly and manages boolean inputs automagically)
  const fieldSetter = (fieldName: string) => {
    return ({ detail: { value, checked } }: any) => {
      setTmp((prev: any) => ({
        ...prev,
        [fieldName]: checkType(fieldName, checked === undefined ? value : checked),
      }));
    };
  };

  const hasChanged = (fieldName: string) => {
    return changedFields.includes(fieldName);
  };

  // Use props("<field_name>") to make the field bold on change and add the correct callback on change
  const props = (fieldName: string, style?: any) => ({
    onIonChange: fieldSetter(fieldName),
    style: { fontWeight: hasChanged(fieldName) ? "bolder" : undefined, ...style},
  });

  // Update the changed fields on hasura database
  const save = () => {
    if (changedFields.length < 1) return;
    const _set = Object.fromEntries(
      changedFields.map((k: string) => [k, tmp[k]])
    );
    updateComponent({
      variables: {
        id: component.id,
        _set: _set
      },
    });
  };

  const toggleArchived = () => updateComponent({
    variables: {
      id: component.id,
      _set: {archived: !component.archived}
    }
  })

  return {
    save, reset, props, changedFields, hasChanged, tmp, setTmp,
    archived: component.archived, toggleArchived
  }
}


// ComponentEditorButtonsItem provides buttons to save and reset changes on a component
//  and shows the timestamp when the component last changed
// Use this in combination with useComponentEditor

export const ComponentEditorButtonsItem = ({ component, changedFields, save, reset, archived, toggleArchived }: any) => {
  return <IonItem>
    <IonButtons slot="start">
      {changedFields.length > 0 && <>
          <IonButton onClick={save} slot="start" fill="solid">
            <IonIcon icon={saveSharp} slot="start"/>
            Save
          </IonButton>
          <IonButton
            onClick={reset}
            slot="start"
          >
            Reset
          </IonButton>
      </>}
      <IonButton onClick={toggleArchived} slot="start" color={archived ? undefined : "danger"} fill="clear">
        {archived ? "Restore" : <><IonIcon icon={trash} slot="start"/>Archive</>}
      </IonButton>
    </IonButtons>
    {component.updated_at && <IonChip slot="end">
      <IonLabel>{component.updated_at} (last update)</IonLabel>
    </IonChip>}
  </IonItem>
}