import { IonBackButton, IonButton, IonCard, IonCardContent, IonCheckbox, IonIcon, IonItem, IonLabel } from '@ionic/react';
import React, {useEffect, useState} from 'react';
import {Stage, Layer, Line, Arrow, Text, Circle, Label, Tag} from 'react-konva';
import { useKonvaStageProps } from '../hooks/useKonvaStageProps';
import { rainbowColor } from '../rainbowColor';
import { Cropline } from './Cropline';
import { SidePanel } from './SidePanel';
import { Stem, StemSliders, useStemConfig } from './Stem';

export const EstimatedTrack: React.FC<any> = ({trend_obj, id, setInfo, info}: any) => {
  // "trend_points" was the old name (which combined the fitted and the extrapolated points)
  const pts = [].concat(...(trend_obj["fitted_points"] || trend_obj["trend_points"] ))
  if (!pts.length) {
    return null
  }
  const has_errors = trend_obj["errors"].length > 0
  const is_selected = (info?.selected_track === id)

  return <>
    <Line key={id} stroke={has_errors ? "red" : "blue"}
  strokeWidth={is_selected ? 0.1 : 0.03} opacity={is_selected ? 0.5 : 0.05} points={pts}
  onMouseOver={() => setInfo({"inlier_ids": new Set(trend_obj["inlier_ids"]),
                              "outlier_ids": new Set(trend_obj["outlier_ids"]),
                             "selected_track": id})}
  onMouseOut={() => setInfo(null)}/>
    </>
}

export const TrackTrendEstimator: React.FC<any> = ({evaluation, resultData}: any) => {
  const [info, setInfo] = useState<any>(null)

  const [scale, stageProps, center] = useKonvaStageProps({}) /* when user moves / change map: deactivate follow mode */

  useEffect(() => {
    if (resultData?.all_stems?.length) {
        const firstStem = resultData?.all_stems[0]
        center(80, firstStem[0], firstStem[1])
    }
  }, [resultData])

  const radius = 0.05
  return <>
    <Stage style={{ height: stageProps.height+"px"}} {...stageProps}>
        <Layer scaleY={-1 /* important: y goes down in konva */}>
            {/* Origin frame */}
            <Arrow points={[0, 0, 0, 1]} stroke="black" strokeWidth={0.05} pointerLength={0.1} pointerWidth={0.1} />
            <Text x={0} y={0.1} text={"y"} scaleY={-1} fontSize={0.05} />
            <Arrow points={[0, 0, 1, 0.]} stroke="black" strokeWidth={0.05} pointerLength={0.1} pointerWidth={0.1} />
            <Text x={0.1} y={0} scaleY={-1} text={"x"} fontSize={0.05} />
            {resultData?.all_stems?.map((stem: any, i: number) => <Circle
                                        x={stem[0]} y={stem[1]} stroke={"gray"}
                                        fill={info?.inlier_ids.has(stem[5]) ? "green" : (info?.outlier_ids.has(stem[5]) ? "red" : "")}
                                        radius={radius} strokeWidth={radius / ((scale < 1200) ? 2. : 8.)}
                                        key={stem[5]}
            />)}
  {resultData?.estimated_track_trends?.map((trend_obj: any, i: number) =>
    <EstimatedTrack trend_obj={trend_obj} id={i} setInfo={setInfo} info={info}/>)}
        </Layer>
    </Stage>
  </>
}
