import React from 'react';
import {IonHeader, IonToolbar, IonButtons, IonBackButton, IonMenuButton, IonTitle } from '@ionic/react'
import { informationCircle } from 'ionicons/icons';


const Toolbar: React.FC<any> = ({ name, children, buttons }) => {

  return (
    <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
             <IonBackButton />
          </IonButtons>
          <IonButtons slot="end">
            {buttons}
            <IonMenuButton />
          </IonButtons>
          {!children && <IonTitle>{name}</IonTitle>}
          {children}
        </IonToolbar>
     </IonHeader>)
 };

export default Toolbar;
