import { IonContent, IonPage, } from '@ionic/react';
import React, { useState } from 'react';
import Toolbar from '../../components/Toolbar'
import { gql } from '@apollo/client';
import Loading from '../../components/Loading'
import { RuleSelector, CropSelector } from '../../components/RuleSelector';
import { useSubscriptionWithElevatedRole } from '../../hooks/hasuraHooks';


export const Demo: React.FC = () => {
  const { loading, data, error} = useSubscriptionWithElevatedRole(gql`
    subscription Demo{
        plant_varieties(where: {is_crop: {_eq: true}}) {
          id name_EN name_DE name_FR name_ES name_LA
          param_config_rules(order_by: {id: desc}, where: {validated: {_eq: true}}) {
            id description
            tags {id tag {name id}}
            recommended_param_config {id description data}
            plant_variety {id}
          }
        }
    }
  `)
  const [cropId, setCropId] = useState<any>()
  const [rule, setRule] = useState()
  return (
    <IonPage>
      <Toolbar name="Browse"/>
      <IonContent fullscreen>
        {loading && <Loading/>}
        {data && <>
          <CropSelector rules_by_crop={data.plant_varieties} setCropId={setCropId} cropId={cropId} skipCropsWithNoRule={true} />
          <RuleSelector rules_by_crop={data.plant_varieties} setRule={setRule} cropId={cropId}/>
        </>}
      </IonContent>
    </IonPage>
  );
};
export default Demo;