import { IonRefresher, IonRefresherContent } from '@ionic/react';
import React from 'react';
import useWindowDimensions from '../useWindowDimensions';
import RefetchButton from './RefetchButton'

const Refresher: React.FC<any> = ({refetch}) => {
  const {isLargeScreen} = useWindowDimensions()
  
  return <>
        <IonRefresher slot="fixed" onIonRefresh={(e: any) => refetch().then(e.detail.complete())}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      {isLargeScreen && <RefetchButton refetch={refetch}/>}
  </>
};

export default Refresher;
