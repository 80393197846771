import React, {useState} from 'react';
import { IonButton, IonIcon, IonToast } from '@ionic/react';
import { copy } from 'ionicons/icons';


const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};


export const CopyButton: React.FC<any> = ({text, icon=copy, children, ...props}) => {
  const [clipboardContent, setClipboardContent] = useState("");
  const [showToast, setShowToast] = useState(false)

  props.fill = props.fill || "clear"
  props.size = props.size || "small"

  function click_copy_button(str : string) {
    setClipboardContent(str);
    copyToClipboard(str);
    setShowToast(true)
  }

  return <>
    <IonButton onClick={(e: any) => {e.stopPropagation(); click_copy_button(text)}} {...props}>
      {children ? children : <IonIcon icon={icon} slot={"icon-only"}/>}
    </IonButton>
    <IonToast
      isOpen={showToast}
      onDidDismiss={() => setShowToast(false)}
      message={`Copied '${clipboardContent}' to clipboard.`}
      duration={200}
    />
  </>
};

export default CopyButton;
