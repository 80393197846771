import { IonBackButton, IonButton, IonCard, IonCardContent, IonCheckbox, IonIcon, IonItem, IonLabel } from '@ionic/react';
import React, {useEffect, useState} from 'react';
import {Stage, Layer, Line, Arrow, Text, Circle, Label, Tag} from 'react-konva';
import { useKonvaStageProps } from '../hooks/useKonvaStageProps';
import { rainbowColor } from '../rainbowColor';
import { Cropline } from './Cropline';
import { SidePanel } from './SidePanel';
import { Stem, StemSliders, useStemConfig } from './Stem';


export const StemMap: React.FC<any> = ({evaluation, resultData}: any) => {
  const [info, setInfo] = useState<any>(null)

  const [colored, setColored] = useState(false)
  const nColors = 10  // Number of colors when colored = true
  const [stemConfig, setStemConfig, stemFilter] = useStemConfig()
  const [scale, stageProps, center] = useKonvaStageProps({}) /* when user moves / change map: deactivate follow mode */

  useEffect(() => {
    if (resultData?.all_stems?.length) {
        const firstStem = resultData?.all_stems[0]
        center(80, firstStem[0], firstStem[1])
    }
  }, [evaluation])

  return <>
    <SidePanel info={info}>
        <StemSliders config={stemConfig} setConfig={setStemConfig} />
        <IonItem>
            <IonLabel>Color consecutive stems</IonLabel>
            <IonCheckbox checked={colored} onIonChange={(e: any) => setColored(e.detail.checked)}/>
        </IonItem>
    </SidePanel>

    <Stage style={{ height: stageProps.height+"px"}} {...stageProps}>
        <Layer scaleY={-1 /* important: y goes down in konva */}>
            {/* Origin frame */}
            <Arrow points={[0, 0, 0, 1]} stroke="black" strokeWidth={0.05} pointerLength={0.1} pointerWidth={0.1} />
            <Text x={0} y={0.1} text={"y"} scaleY={-1} fontSize={0.05} />
            <Arrow points={[0, 0, 1, 0.]} stroke="black" strokeWidth={0.05} pointerLength={0.1} pointerWidth={0.1} />
            <Text x={0.1} y={0} scaleY={-1} text={"x"} fontSize={0.05} />
            {resultData?.all_stems?.filter(stemFilter)?.map((stem: any, i: number) => <Stem
                stem={stem} stroke={colored ? rainbowColor(i / nColors, nColors): "gray"} setInfo={setInfo} key={i}
            />)}
            {resultData?.croplines?.map((cropline: any, i: number) => <Cropline cropline={cropline} setInfo={setInfo} key={i}/>)}
        </Layer>
    </Stage>
  </>
}
