import { IonContent, IonPage, IonItem, IonBadge, IonItemDivider, IonSearchbar, IonToolbar, IonButton, IonPopover, IonIcon, IonInput, } from '@ionic/react';
import React, { useState } from 'react';
import Toolbar from '../../components/Toolbar'
import { useMutationWithElevatedRole, useSubscriptionWithElevatedRole } from '../../hooks/hasuraHooks';
import { gql } from '@apollo/client';
import { GraphQLState } from '../../components/GraphQLState';
import { TranslationPicker } from './Translations';
import { addCircle, close, } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';


export const StatusTranslationField: React.FC<any> = ({translation, type, id, width, fontWeight}: any) => {
  const [event, setEvent] = useState<any>(null)

  const [update] = useMutationWithElevatedRole(gql`
    mutation UpdateDiagnosticStatusType($id: Int!, $_set: diagnostic_status_types_set_input!) {
      update_diagnostic_status_types_by_pk(pk_columns: {id: $id}, _set: $_set) {
        id
      }
    }
  `)
    
  const {t} = useTranslation()
    
  return <>
    <IonPopover event={event} isOpen={!!event} onDidDismiss={() => setEvent(null)} className='wide-popover'>
      <IonToolbar>
        <h4 style={{margin: 5}}>{t("Pick text field for {{type}}", {type})}</h4>
        <IonButton onClick={() => setEvent(null)} slot="end" fill="clear"><IonIcon icon={close} slot="icon-only"/></IonButton>
      </IonToolbar>
      <TranslationPicker
        height={300} onSelect={(translationId: any) => update({variables: {id, _set: {[`${type}_translation_id`]: translationId}}})}
        searchPlaceholder={`Search translated Pick text field for "${type}"`}
      />
      {translation && <IonButton onClick={() => update({variables: {id, _set: {[`${type}_translation_id`]: null}}})}>
        {t("Remove text field (leave empty)")}
      </IonButton>}
    </IonPopover>

    <div
      style={{cursor: "pointer", width: width, border: "1px solid lightgray", borderRadius: 4, padding: 2, display: "flex", justifyContent: "end", flexDirection: "column"}}
      onClick={setEvent}
    >

      <div style={{display: "flex", justifyContent: "center", width: "100%", textAlign: "left", fontWeight}}>
        {translation && <pre style={{font: "unset", margin: 10, maxHeight: 100}}>{translation.en}</pre>}
        {!translation && <>
          <IonIcon icon={addCircle} color="medium" />
        </>}
      </div>

      <div style={{display: "flex", alignItems: "center", justifyContent: "center", fontSize: "small", color: "gray", textTransform: "uppercase"}}>
        <>{type}</>
      </div>
    </div>
  </>
}



export const InputField: React.FC<any> = ({currentValue, id, type, ...props}: any) => {
  const [update] = useMutationWithElevatedRole(gql`
    mutation UpdateDiagnosticStatusType($id: Int!, $_set: diagnostic_status_types_set_input!) {
      update_diagnostic_status_types_by_pk(pk_columns: {id: $id}, _set: $_set) {
        id
      }
    }
  `)
  const [tmpValue, setTmpValue] = useState<string>(currentValue)
  return <>
    <IonInput value={tmpValue} onIonChange={({detail: {value}}: any) => setTmpValue(value)} {...props}/>
    {tmpValue != currentValue && <IonButton onClick={() => update({variables: {
      id, _set: {[type]: tmpValue}
    }})} size="small" slot="end">Save</IonButton>}
  </>
}


export const DiagnosticStatusType: React.FC<any> = ({diagnosticStatusType}: any) => {
  const {id, software_key, title_translation, description_translation, action_translation, consequence_of_ignoring_translation, } = diagnosticStatusType

  return <div style={{width: "100%", display: "flex", padding: 10, flexDirection: "column", gap: 5, borderBottom: "1px solid"}}>
    <div style={{display: "flex", width: "100%", alignItems: "start", gap: 5, flexWrap: "wrap", justifyContent: "space-between"}}>
      <StatusTranslationField id={id} type="title" translation={title_translation} width={500} fontWeight="bold"/>
      <div style={{width: 500, display: "flex", gap: 5, alignItems: "center"}}>
        <InputField type="software_key" id={id} currentValue={software_key} style={{textAlign: "right", fontSize: "small", minWidth: 200, width: "fit-content"}}/>
        <IonBadge color="medium">#{id}</IonBadge>
      </div>
    </div>
    <div style={{display: "flex", width: "100%", alignItems: "stretch", gap: 5, flexWrap: "wrap", justifyContent: "space-between"}}>
      <StatusTranslationField id={id} type="description" translation={description_translation} width={500}/>
      <StatusTranslationField id={id} type="action" translation={action_translation} width={500}/>
      <StatusTranslationField id={id} type="consequence_of_ignoring" translation={consequence_of_ignoring_translation} width={500}/>
    </div>
  </div>
}


export const DiagnosticStatusTypesPage: React.FC<any> = ({}) => {
  const [limit, setLimit] = useState<any>(500)
  const [searchString, setSearchString] = useState("")
  const where = (() => {
    if (searchString?.length < 1) {return {}}  // No filter (searchbar is empty)
    else {return ({software_key: {_regex: searchString}})}
  })()

  const { loading, data, error } = useSubscriptionWithElevatedRole(gql`
    subscription GetDiagnosticStatusTypes($limit: Int!, $where: diagnostic_status_types_bool_exp) {
      diagnostic_status_types(order_by: {software_key: asc}, limit: $limit, where: $where) {
        id 
        action_translation {id en}
        consequence_of_ignoring_translation {id en}
        description_translation {id en}
        title_translation {id en}
        software_key
      }
  }`, {variables: {limit, where}});
  const {t} = useTranslation()
  
  return <IonPage>
    <Toolbar name="Diagnostic status types"/>
    <IonToolbar>
      <div style={{display: "flex"}}>
        <IonItem lines="none" style={{display: "flex", flexGrow: 2}}>
          <IonSearchbar onIonChange={((e: any) => setSearchString(e.detail.value))} style={{padding: 2}}/>
        </IonItem>
      </div>
    </IonToolbar>

    <GraphQLState error={error} loading={loading} />

    <IonContent fullscreen>
      {data?.diagnostic_status_types?.map((d: any) => <DiagnosticStatusType diagnosticStatusType={d} key={d.id}/>)}

      {data?.diagnostic_text_fields?.length === limit && <IonItemDivider>
        Only showing {limit} elements. 
        <IonButton onClick={() => setLimit(null)}>
          Click to show all
        </IonButton>
      </IonItemDivider>}
      {data?.diagnostic_text_fields?.length === 0 && <IonItemDivider>No statuses found</IonItemDivider>}
    </IonContent>
  </IonPage>
};