import Konva from 'konva';

let zoom = 1000;

let callbacksDrag : Map<String, (dx: number, dy: number)=>void> = new Map();
let callbacksZoom : Map<String, (s: number)=>void> = new Map();

export default function useDrag (id : String, updatePosition : (x : number, y: number)=>void, setScale : (s : number)=>void) {
    function onDragStart(evt : Konva.KonvaEventObject<DragEvent>) {
        
    }

    function onDragMove(evt: Konva.KonvaEventObject<DragEvent>) {
        let dx = evt.evt.movementX;
        let dy = evt.evt.movementY;
        callbacksDrag.forEach((v,k)=>{
            if (k===id) return;
            v(dx, dy)
        })
    }

    function onWheel(evt : Konva.KonvaEventObject<WheelEvent>) {
        evt.evt.preventDefault();
        if (evt.evt.deltaY < 0) {
            zoom /= 0.98
        } else {
            zoom *= 0.98
        }
        callbacksZoom.forEach((v,k)=>{
            v(zoom)
        })
    }

    function unsubscribe() {
        callbacksDrag.delete(id)
        callbacksZoom.delete(id)
    }

    callbacksDrag.set(id, updatePosition);
    callbacksZoom.set(id, setScale);

    return {onDragStart, onDragMove, onWheel, unsubscribe}
}