import { IonList, IonButton, IonContent, IonPage, IonItem, IonLabel, IonBadge, IonIcon, IonChip, IonPopover, IonToolbar, IonCard, IonCardHeader, IonCardContent, IonCardSubtitle, IonModal, IonCardTitle, IonCheckbox, IonButtons, IonFab, IonFabButton, IonSkeletonText, IonInput, IonCol, IonRow, IonListHeader, IonTextarea, IonProgressBar, IonTitle, } from '@ionic/react';
import React, { useState } from 'react';
import Toolbar from '../../components/Toolbar'
import { useQuery, gql } from '@apollo/client';
import ace from "brace"
import { arrowUndo, chevronDown, chevronUp, copy, gitCompare, trash } from 'ionicons/icons';
import ParamConfigErrors, { flattenDict, getMissingKeys, mergeDicts } from '../../components/ParamConfigErrors';
import ConfirmButton from '../../components/ConfirmButton';
import getMaxRole from '../../lib/getMaxRole';
import { useMutationWithElevatedRole, useSubscriptionWithElevatedRole } from '../../hooks/hasuraHooks';
import useWindowDimensions from '../../useWindowDimensions';
import { JsonEditor as Editor } from "jsoneditor-react18";
import 'jsoneditor-react18/es/editor.min.css';
const moment = require('moment-twitter');


export const ParamConfigCard: React.FC<any> = ({param_config, selectForComparison, selectedForComparison, paramOptions}) => {
  const [expanded, setExpanded] = useState(false)
  const [edit, setEdit] = useState(false)
  const [data, setData] = useState(param_config.data)
  const [newDescription, setNewDescription] = useState(param_config.description)

  const [updateParamConfig] = useMutationWithElevatedRole(gql`
    mutation UpdateParamConfig($id: Int!, $_set: param_configs_set_input = {}) {
      update_param_configs_by_pk(pk_columns: {id: $id}, _set: $_set) {
        id description data
      }
    }
  `,)
  
  const [updateRules] = useMutationWithElevatedRole(gql`
    mutation UpdateRules($rule_ids: [Int!]!, $new_config_id: Int!) {
      update_param_config_rules(where: {id: {_in: $rule_ids}}, _set: {recommended_param_config_id: $new_config_id}) {
        affected_rows
      }
    }
  `)
 
  const [copyConfig] = useMutationWithElevatedRole(gql`
    mutation CopyConfig($config: param_configs_insert_input!) {
      insert_param_configs_one(object: $config) {
        id description data
      }
    }
  `)
 
  const [createConfigAndArchiveOldConfig] = useMutationWithElevatedRole(gql`
    mutation CreateConfigAndArchiveOldConfig($newConfig: param_configs_insert_input!, $old_config_id: Int!) {
      insert_param_configs_one(object: $newConfig) {
        id
      }
      update_param_configs_by_pk(pk_columns: {id: $old_config_id}, _set: {archived: true}) {
        id
      }
    }
  `)
  const [createConfig] = useMutationWithElevatedRole(gql`
    mutation CreateConfig($newConfig: param_configs_insert_input!) {
      insert_param_configs_one(object: $newConfig) {
        id
      }
    }
  `)
    
  const editConfig = (oldConfig: any, newData: any = null) => {
    // Create a new config every time we edit it
    if (oldConfig.rules.length > 0) {
      // Old config has rules: do not archive it
      createConfig(
        {variables: {newConfig: {description: oldConfig.description, data: newData}, old_config_id: oldConfig.id}}
      )
    }
    else {
      createConfigAndArchiveOldConfig({
        variables: {newConfig: {description: oldConfig.description, data: newData}, old_config_id: oldConfig.id},
      })
    }
  }
    
  const save = () => {
    editConfig(param_config, data)
    setEdit(false)
  }

  const selected = selectedForComparison.includes(param_config.id);
  const {isLargeScreen} = useWindowDimensions()
  
  const headerItem = <>
    <IonItem style={{fontWeight: expanded ? "bold": ""}} color={param_config.archived ? "medium": undefined} onClick={() => setExpanded(!expanded)}>

      {param_config.description}
      {" "}
      (ID: {param_config.id})
      
      {isLargeScreen && <IonChip >{moment(param_config.created_at).twitterShort()}</IonChip>}

      <IonButtons slot="end">
        <IonButton fill="clear" color={selected ? "secondary" : ""} style={{
          border: "3px solid transparent 5px",
          borderColor: selected ? "var(--ion-color-secondary)" : "transparent",
          borderRadius: "5px",
          minHeight: "30px"
        }} onClick={e=>{
          e.stopPropagation();
          if (selected) {
            selectForComparison(selectedForComparison.filter((x : number)=>x!=param_config.id))
          } else {
            if (selectedForComparison.length < 2) {
              selectForComparison([param_config.id, ...selectedForComparison])
            } else {
              selectForComparison([param_config.id]);
            }
            
          }
        }}><IonIcon icon={gitCompare} slot='icon-only'></IonIcon></IonButton>
        <ConfirmButton fill="clear" text={`${param_config.archived ? "Restore" : "Archive"} config #${param_config.id}`}
          onClick={() => {updateParamConfig({variables: {id: param_config.id, _set: {archived: !param_config.archived}}})}}
        ><IonIcon icon={param_config.archived ? arrowUndo : trash} slot="icon-only"/></ConfirmButton>
        <IonButton fill="clear"
          onClick={(e:any) => {
            e.stopPropagation();
            copyConfig({variables: {config: {data: param_config.data, description: param_config.description}}})
        }}><IonIcon icon={copy} slot="icon-only"/></IonButton>
        <IonButton onClick={() => setExpanded(!expanded)} color="secondary" fill="clear">
            <IonIcon icon={expanded ? chevronUp : chevronDown} slot={"icon-only"}/>
        </IonButton>
      </IonButtons>
      <div style={{display: "flex", flexWrap: "wrap"}}>
        {param_config?.rules?.map((rule: any) => <div key={rule.id} slot="end" style={{margin: 5}}>
          <IonBadge color={rule.is_dev ? "danger": "success"}>{rule?.plant_variety?.name_EN}</IonBadge>
          {rule.tags.map(({id, tag}: any) => <IonBadge color="light" key={id}>{tag.name}</IonBadge>)}
        </div>)}
      </div>
    </IonItem>
  </>
  
  const flatDict: any = flattenDict(param_config.data)
  const missingKeys = getMissingKeys(flatDict, paramOptions)
  
  if (expanded) {
    return <IonCard>
        {headerItem}
        <IonCardContent>
          {missingKeys.length > 0 && <ParamConfigErrors param_config={param_config} paramOptions={paramOptions} editConfig={editConfig}/>}
          {!edit && <div style={{maxHeight: 400, overflow: "scroll"}}>
            {Object.keys(flatDict).map((key: string) => <ul key={key}>
              {key}: {JSON.stringify(flatDict[key])}
            </ul>)}
          </div>}
          {edit && <Editor
            value={flatDict}
            onChange={setData}
            ace={ace}
            schema={{
              "$schema": "http://json-schema.org/draft-07/schema#",
              "type": "object",
              "additionalProperties": true,
            }}
            theme="ace/theme/ambiance"
            htmlElementProps={{style: {marginBottom: "200px"}}}
          />}
          <IonItem>
            <IonLabel position="floating">Description</IonLabel>
            <IonTextarea value={newDescription} onIonChange={(e: any) => setNewDescription(e.detail.value)}/>
            {newDescription != param_config.description && <IonButton onClick={() => updateParamConfig({variables: {id: param_config.id, _set: {description: newDescription}}})}
            >Change description</IonButton>}
          </IonItem>
          {!edit && <IonButton slot="end" onClick={() => setEdit(true)} expand={"full"}>Edit config</IonButton>}
          {edit && <IonButton onClick={() => setEdit(false)}>Discard</IonButton>}
          {edit && <IonButton onClick={() => save()}>Save</IonButton>}
          <IonButton fill="outline" expand='block' onClick={() => updateParamConfig({variables: {id: param_config.id, _set: {archived: !param_config.archived}}})}>
            {param_config.archived ? "Restore" : "Archive"}
          </IonButton>
        </IonCardContent>
      </IonCard>
  }
  return headerItem
}


const ParamConfigs: React.FC = () => {
  const [showArchived, setShowArchived] = useState(false)
  const { loading, data} = useSubscriptionWithElevatedRole(gql`
    subscription ParamConfigs ($where: param_configs_bool_exp!) {
        param_configs(order_by: {id: desc}, where: $where) {
          id
          archived
          data
          description
          created_at
          rules(where: {validated: {_eq: true}}) {
            id validated is_dev
            plant_variety {id name_EN}
            tags {id tag {name id}}
          }
        }
    }
  `, {
    variables: {where: showArchived ? {} : {archived: {_eq: false}} },
  });
  const [selectedForComparison, selectForComparison] = useState([]);
  const compare = useQuery(gql`
      query($params_a : Int!, $params_b : Int!) {
        scenarios(where: {_and: [
            {evaluations: {param_config_id : {_eq: $params_a}}},
            {evaluations: {param_config_id : {_eq: $params_b}}},
        ]}) {
            id
            bag { path }
            eval_a : evaluations(where: {param_config_id: {_eq: $params_a}}, order_by: {id: desc}, limit: 1) {
                id
            }
            eval_b : evaluations(where: {param_config_id: {_eq: $params_b}}, order_by: {id: desc}, limit: 1) {
                id
            }
        }
    }`,
    {
      variables: {
        params_a: selectedForComparison[0],
        params_b: selectedForComparison[1]
      },
      skip: selectedForComparison.length != 2,
      context: {
        headers: {
          "x-hasura-role": getMaxRole()
        }
      }
    }
  )
  const [filter, setFilter] = useState("");

  // Get values that are set sorted by param key
  const paramOptions = mergeDicts(data?.param_configs?.map((c: any) => flattenDict(c.data)))

  return (
    <IonPage>
      <Toolbar name="ParamConfigs">
        <IonItem color="transparent">
          <IonInput onKeyUp={(evt : any)=>setFilter(evt.nativeEvent.target.value)} placeholder="Filter by name" />  
          <IonButton slot="end" onClick={() => setShowArchived(!showArchived)}>{showArchived ? "Hide archived" : "Show archived"}</IonButton>
        </IonItem>
      </Toolbar>

      <IonContent fullscreen>
        {loading && <IonProgressBar/>}
        {data && <>
          <IonList>
            {data.param_configs.filter((param_config: any)=>{
              for (let word of filter.split(' ')) {
                if (!param_config.description.includes(word)) {
                  return false
                }
              }
              return true;
            }).map((param_config: any, index: number) => <ParamConfigCard param_config={param_config} key={param_config.id} selectedForComparison={selectedForComparison} selectForComparison={selectForComparison}
              paramOptions={paramOptions}/>)}
          </IonList>
        </>}
      </IonContent>

      <IonModal isOpen={selectedForComparison.length==2} onDidDismiss={_=>selectForComparison([])}>
        {compare.data?.scenarios && <IonCardContent>
          <IonItem>
            <IonButton onClick={_=>{
              document.querySelectorAll('a.param-compare-link').forEach((a : any)=>a.click())
            }}>Open all</IonButton>
          </IonItem>
          {compare.data?.scenarios?.map((scenario : any)=>{
          const id = scenario.id;
          let bagname = scenario.bag.path;
          bagname = bagname.substring(bagname.lastIndexOf("/")+1).replace(/^s3:/,'');
          const eval_ids = [scenario.eval_a[0].id, scenario.eval_b[0].id];
          return <IonItem key={id}>
            <a href={`/evaluation/${eval_ids.join(',')}`} target="_blank" className='param-compare-link'>{bagname} (Scenario {id})</a>
          </IonItem>
        })}
        </IonCardContent>}
      </IonModal>
    </IonPage>
  );
};
export default ParamConfigs;