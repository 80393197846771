import React, {useEffect, useState} from 'react';
import {Line} from 'react-konva';


export const Cropline: React.FC<any> = ({cropline, setInfo, ...props}: any) => {
  const [hover, setHover] = useState(false)
  const text = `Inliers: ${cropline.inliers}` + (cropline.errors.length ? `Error ${JSON.stringify(cropline.errors)}` : "")
  useEffect(() => {
    if (hover) {
        setInfo(text)
    }
    else {
        setInfo(null)
    }
  }, [hover])
  if (Math.abs(cropline.A.x) < 0.001) {
    return null  // Get rid of invalid croplines (would be better if could be checked via flag)
  }
  return <>
      <Line onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}
       points={[cropline.A.x, cropline.A.y, cropline.B.x, cropline.B.y]}
       strokeWidth={hover ? 0.2 : 0.05} stroke={cropline.errors.length ? "#eb445a" : "#058b8c"} {...props} opacity={hover ? 0.2: 0.05}/>
  </>
}