import React, { useState, } from "react";
import {
  IonList,
  IonContent,
  IonPage,
  IonItem,
  IonAccordionGroup,
  IonAccordion,
  IonIcon,
  IonFab,
  IonFabButton,
  IonLabel,
  IonBadge,
  IonInput,
  IonProgressBar,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { add, } from "ionicons/icons";
import Toolbar from "../../components/Toolbar";
import Loading from "../../components/Loading";
import { gql } from "@apollo/client";
import { useSubscriptionWithElevatedRole } from "../../hooks/hasuraHooks";
import { useMutationWithElevatedRole } from "../../hooks/hasuraHooks";
import { ComponentEditorButtonsItem, useComponentEditor } from "../../components/ComponentEditor";
import { useTranslation } from "react-i18next";


export const ProcessGroupContent = ({ processGroup }: any) => {
  const [updateProcess] = useMutationWithElevatedRole(
    gql`
      mutation UpdateProcessGroup($id: Int!, $_set: process_groups_set_input!) {
        update_process_groups_by_pk(pk_columns: { id: $id }, _set: $_set) {
          id
        }
      }
    `,
    {}
  );
  const {save, reset, props, changedFields, tmp, archived, toggleArchived} = useComponentEditor(processGroup, updateProcess)

  return <>
    <ComponentEditorButtonsItem component={processGroup} save={save} reset={reset} archived={archived} toggleArchived={toggleArchived} changedFields={changedFields}/>

    <IonItem>
      <IonLabel position="stacked">Description</IonLabel>
      <IonInput {...props("description")} value={tmp.description} placeholder="Description"/>
    </IonItem>
  </>
}



const ProcessGroup = ({ processGroup }: any) => {
  return (
    <IonAccordionGroup expand="inset">
      <IonAccordion value={processGroup.id}>
        <IonItem slot="header" color="light">

          {processGroup.archived && <IonBadge color="medium" slot="start">archived</IonBadge>}
          <IonLabel>
            {processGroup.description}
          </IonLabel>

        </IonItem>
        <IonList lines="none" slot="content">
          <ProcessGroupContent processGroup={processGroup}/>
        </IonList>
      </IonAccordion>
    </IonAccordionGroup>
  );
};


export const useProcessGroups = () => {
   return useSubscriptionWithElevatedRole(
    gql`
      subscription ProcessGroups {
        process_groups(order_by: {id: asc}) {
          id
          created_at
          updated_at
          description
          archived
        }
      }
    `,
  )
}


export const ProcessGroupSelect = ({multiple = false, ...props}: any) => {
  const {loading, data} = useProcessGroups()
  const {t} = useTranslation()
  if (loading || !data.process_groups) {
    return <IonProgressBar type="indeterminate"/>
  }
  return <IonSelect {...props} multiple={multiple}>
    {data.process_groups.map(({id, description}: any) => <IonSelectOption value={id} key={id}>{description}</IonSelectOption>)}
    {!multiple && <IonSelectOption value={null}>{t("No group")}</IonSelectOption>}
  </IonSelect>
}


const ProcessGroups = () => {
  const {loading, data} = useProcessGroups()

  const [insertProcessGroup] = useMutationWithElevatedRole(
    gql`
      mutation InsertProcessGroup {
        insert_process_groups_one(object: {}) {
          id
        }
      }
    `,
    {}
  );

  return <IonPage>
    <Toolbar name="Process groups" />
    <IonContent fullscreen>
      {loading && <Loading />}
      <IonFab horizontal="end" vertical="bottom" slot="fixed">
        <IonFabButton onClick={() => insertProcessGroup()}>
          <IonIcon icon={add}></IonIcon>
        </IonFabButton>
      </IonFab>
      {data?.process_groups && <IonList>
        {data.process_groups.map((processGroup: any) => <ProcessGroup processGroup={processGroup} key={processGroup.id} />)}
      </IonList>}
    </IonContent>
  </IonPage>
};
export default ProcessGroups;