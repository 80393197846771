import { Circle, Line } from "react-konva"


export const KonvaCroplines: React.FC<any> = ({msg}: any) => {
  const [A, B] = [msg.A, msg.B]
  const yaw = Math.atan2(msg.B.y - msg.A.y, msg.B.x - msg.A.x) + Math.PI / 2.
  const hasErrors = msg?.errors.length > 0
  const inlier_indices = new Set(([] as number[]).concat(...msg.rows?.map((row: any) => {return row.inlier_indices})))
  return <>
    <Line points={[A.x, A.y, B.x, B.y]} stroke={hasErrors ? "red" : "green"} dash={[0.2, 0.3]} dashEnabled={hasErrors} strokeWidth={0.05} opacity={0.3}/>
    {msg.rows.map((row: any) => {
      const A_offseted = {
        x: A.x + Math.cos(yaw) * row.offset,
        y: A.y + Math.sin(yaw) * row.offset,
      }
      const B_offseted = {
        x: B.x + Math.cos(yaw) * row.offset,
        y: B.y + Math.sin(yaw) * row.offset,
      }
      return <Line key={row.name} points={[
        A_offseted.x, A_offseted.y, B_offseted.x, B_offseted.y
      ]} stroke={"black"} strokeWidth={0.005} opacity={0.3}/>
    })}
    {msg.data_points?.map((pt: any, i: number) => {
      return <Circle key={i} radius={inlier_indices.has(i) ? 0.03: 0.01} fill={inlier_indices.has(i) ? "green" : "gray"}
              x={msg.data_points[i].x} y={msg.data_points[i].y} />
    })}
  </>
}