import { IonList, IonButton, IonContent, IonPage, IonItemDivider, IonProgressBar, IonIcon, IonSelect, } from '@ionic/react';
import React, { useState } from 'react';
import Toolbar from '../../components/Toolbar'
import { gql, } from '@apollo/client';
import { useSubscriptionWithElevatedRole } from '../../hooks/hasuraHooks';
import { ComponentGroup, groupComponents } from '../../components/ComponentGroup';
import { IngredientItem } from '../../components/IngredientItem';
import { NewIngredientButton } from '../../components/NewIngredientButton';
import { add } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';


export const useIngredients = (where: any = {}) => useSubscriptionWithElevatedRole(gql`subscription GetAllIngredients($where: ingredients_bool_exp!) {
      ingredients (order_by: {id: asc}, where: $where) {
        id
        
        updated_at
        obsolete

        type
        unit
        group
        name_DE
        name_EN
        name_PL
        active_substance_DE
        active_substance_EN
        active_substance_PL
        default_amount_per_ha
      }
  }`, {variables: {where}});


export const Ingredients: React.FC = () => {
  const [showArchives, setShowArchives] = useState(false)
  const [groupByVariant, setGroupByVariant] = useState(false)
  const where = showArchives ? {} : {obsolete: {_eq: false}}  // Hide obsoletes if showArchives is false

  const {t} = useTranslation()
  const { loading, data, } = useIngredients()
  
  const groupedComponents = groupComponents(data?.ingredients,
    ["id", "updated_at", "obsolete", "active_substance_DE", "active_substance_PL", "active_substance_EN", "name_DE", "name_PL", "name_EN", "default_amount_per_ha", "__typename"])
  return (
    <IonPage>
      <Toolbar name="Ingredients" buttons={<>
        <IonButton fill="solid" onClick={() => setShowArchives(current => !current)}>{showArchives ? 'hide archived': 'show archived'}</IonButton>
        <IonButton fill="solid" onClick={() => setGroupByVariant(current => !current)}>{groupByVariant ? 'do not group': 'group by variant'}</IonButton>
      </>}/>

      <IonContent fullscreen>
        {loading && <IonProgressBar type="indeterminate"/>}

        {groupByVariant && groupedComponents.map(([groupInfo, components]: any) => <ComponentGroup groupDescription={groupInfo} components={components}
          componentName="ingredient" componentNamePlural="ingredients"
          factory={(c: any, index: number) => <IngredientItem ingredient={c} key={index} showArchives={showArchives}/>}
        />)}

        {!groupByVariant && data && <IonList>
          <IonItemDivider>
            {data?.ingredients?.length} ingredients
          </IonItemDivider>
          {data.ingredients.map((ingredient: any, index: number) => <IngredientItem ingredient={ingredient} key={index} showArchives={showArchives}/>)}
        </IonList>}
        <NewIngredientButton expand="block">
          <IonIcon icon={add} slot="start"/>
          {t("Add ingredient")}
        </NewIngredientButton>
      </IonContent>
    </IonPage>
  );
};