import { IonButton, IonLabel, IonTextarea } from '@ionic/react';
import React, {useState} from 'react';
import ReactMarkdown from 'react-markdown'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const EditableField: React.FC<any> = ({value, label = "description", onChange, onIonChange, type="markdown", ...props}) => {
   const [edit, setEdit] = useState<boolean>(false)

   return <>
      <div style={{paddingBottom: "15px"}}>
         <IonLabel position="stacked">{label}
            {(onChange || onIonChange) && <IonButton fill="clear" onClick={() => {setEdit(!edit)}}>{edit ? "preview" : "edit"}</IonButton>}
         </IonLabel>
      </div>
      <div style={{width: "100%"}}>
         {edit && <IonTextarea color="secondary" value={value} placeholder={`No ${label} yet`} onChange={onChange} onIonChange={onIonChange} autoGrow={true}/>}
         {!edit && <div style={{cursor: "pointer"}} onClick={() => onChange && setEdit(true)}>
         {type === "markdown" && <ReactMarkdown >{value || `No ${label}`}</ReactMarkdown>}
         {type === "python" && <SyntaxHighlighter customStyle={{fontSize: "small"}} language="python" style={docco}>{value || `No ${label}`}</SyntaxHighlighter>}
         </div>}
      </div>
   </>
}

export default EditableField;
