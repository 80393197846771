import { IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { refresh } from 'ionicons/icons';

import React from 'react';

const Error: React.FC<any> = ({refetch}) => {
  return <IonFab vertical="bottom" horizontal="center" slot="fixed">
          <IonFabButton onClick={() => refetch()} color="secondary">
            <IonIcon icon={refresh} />
          </IonFabButton>
        </IonFab>
};

export default Error;
