import { createBrowserHistory } from 'history';
import React from 'react';

const history = createBrowserHistory();
export default history;

export const goBackOrGoto = (url) => {
      if (history.length > 0) {
          history.goBack()
      }
      else {
          history.replace(url)
      }
}
