import { IonAlert, IonButton } from '@ionic/react';
import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';

const ConfirmButton: React.FC<any> = ({children, text, onClick, ...props}) => {
    const [show, setShow] = useState(false)
    const { t } = useTranslation()

    return <><IonAlert
          isOpen={show}
          onDidDismiss={() => setShow(false)}
          header={text}
          buttons={[
            {
              text: t('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                  setShow(false)
              }
            },
            {
              text: 'OK',
              handler: () => {
                onClick()
              }
            }
          ]}
        />
        <IonButton onClick={(e: any) => {e.stopPropagation(); setShow(true)}} {...props}>{children}</IonButton>
    </>
};

export default ConfirmButton;
