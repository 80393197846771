import { IonLabel, IonItem, IonContent, IonBadge, IonIcon, IonButton, IonPopover } from '@ionic/react';
import React, {useState} from 'react';
import { close, informationCircle } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { gql, useSubscription } from '@apollo/client';


export const useLastReleasedSoftwareVersion = () => {
  // Return last non-archived released softtware version
  const {data} = useSubscription(gql`
    subscription GetLatestSoftwareVersions {
      software_versions(limit: 1, order_by: {id: desc}, where: {released_at: {_is_null: false}, archived: {_eq: false}}) {
        id released_at
      }
    }`
  )
  return data?.software_versions?.at(0)
}


export const SoftwareVersionBadge: React.FC<any> = ({softwareVersionState, lastSoftwareVersion}: any) => {
  const [event, setEvent] = useState<any>(null)
  const { t } = useTranslation();
  if (!softwareVersionState) {return null}
  if (!softwareVersionState.software_version.released_at) {return null}  // Skip: not released yet
  const color = (() => {
    if (softwareVersionState.dirty) {return "danger"}
    if (softwareVersionState.software_version.id != lastSoftwareVersion?.id) {return "light"}
    return "primary"
  })()
  const isLatestRelease = lastSoftwareVersion && (lastSoftwareVersion.id === softwareVersionState.software_version.id) && !softwareVersionState.dirty
  return <>
    {softwareVersionState && <IonBadge color={color} style={{cursor: "pointer"}} onClick={setEvent}>
      <IonLabel>
        v{softwareVersionState.software_version.released_at.split("T")[0]}{" "}
        {softwareVersionState.dirty && <>*</>}
      </IonLabel>
    </IonBadge>}
 
    <IonPopover isOpen={!!event} onDidDismiss={() => setEvent(null)} className="wide-popover" event={event}>
      <IonContent>
        <IonItem>
          <strong>v{softwareVersionState.software_version.released_at.split("T")[0]}{" "}
          {softwareVersionState.dirty && <> {t("with local changes")}</>}</strong>
          <IonButton slot="end" onClick={() => setEvent(null)}>
            <IonIcon icon={close} slot="icon-only" />
          </IonButton>
        </IonItem>
        {lastSoftwareVersion && !isLatestRelease && <IonItem lines="none">
          <IonIcon slot="start" icon={informationCircle}/>
          {t("New software version available: {{versionName}}", {versionName: `v${lastSoftwareVersion.released_at.split("T")[0]}`})}
        </IonItem>}
        <div style={{ paddingLeft: 20 }}>
          <ReactMarkdown>
            {softwareVersionState.software_version.description}
          </ReactMarkdown>
        </div>
      </IonContent>
    </IonPopover>
  </>
}