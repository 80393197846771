import {
  IonButton,
  IonItem,
  IonLabel,
  IonChip,
  IonAccordion,
  IonCol,
  IonGrid,
  IonRow,
  IonAccordionGroup,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonToggle,
  IonBadge,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import useWindowDimensions from "../useWindowDimensions";
import {
  useMutationWithElevatedRole,
} from "../hooks/hasuraHooks";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { useInsertIngredients } from "./NewIngredientButton";


// Keys to be ignored in form
const ignoreKeys: string[] = ["id", "__typename", "created_at", "updated_at"]


export const IngredientGroupSelect: React.FC<any> = ({ value, selectProps, }: any) => {
  const {t} = useTranslation()
  return <IonSelect value={value} {...selectProps}>
    {["herbicide", "graminicide", "insecticide", "fungicide", "biostimulant", "additive", "other"].map((type: string) => <IonSelectOption value={type} key={type}>
      {t(type)}
    </IonSelectOption>)}
  </IonSelect>
}


export const IngredientTypeSelect: React.FC<any> = ({ value, selectProps, }: any) => {
  const {t} = useTranslation()
  return <IonSelect value={value} {...selectProps}>
    {["EC", "SC", "SE", "SG", "WG", "SL", ""].map((type: string) => <IonSelectOption value={type} key={type}>
      {t(type)}
    </IonSelectOption>)}
  </IonSelect>
}


export const IngredientItem: React.FC<any> = ({ ingredient, showArchives }: any) => {
  const [updateIngredient] = useMutationWithElevatedRole(
    gql`
      mutation UpdateIngredient($id: Int!, $_set: ingredients_set_input = {}) {
        update_ingredients_by_pk(pk_columns: { id: $id }, _set: $_set) {
          id
        }
      }
    `,
    {}
  );

  const [tmpIngredient, setTmpIngredient] = useState({...ingredient})

  const changeableFields = Object.keys(tmpIngredient).filter((k: string) => !ignoreKeys.includes(k))
  const changedFields = changeableFields.filter((k: string) => tmpIngredient[k] !== ingredient[k])

  const reset = () => {
    setTmpIngredient({...ingredient})
  };
  useEffect(reset, [ingredient])

  const { isLargeScreen } = useWindowDimensions();
  const save = () => {
    if (changedFields.length < 1) return
    const _set = Object.fromEntries(changedFields.map((k: string) => ([k, tmpIngredient[k]])))
    console.log(`Save ${JSON.stringify(_set)} for ingredient ${ingredient.id}`)
    updateIngredient({
      variables: {
        id: ingredient.id,
        _set: _set
      },
    });
  };

  const fieldSetter = (fieldName: string) => {
    return ({detail: {value, checked}}: any) => setTmpIngredient((prev: any) => ({...prev, [fieldName]: checked !== undefined ? checked : value}))
  }

  const hasChanged = (fieldName: string) => changedFields.includes(fieldName)

  const props = (fieldName: string) => ({
    onIonChange: fieldSetter(fieldName),
    style: {fontWeight: hasChanged(fieldName) ? "bolder" : undefined},  // Change weight if it has changed
  })

  const [insertIngredients] = useInsertIngredients()

  return (
    <>
      <IonAccordionGroup
        expand="inset"
        style={{ display: ingredient?.obsolete && !showArchives ? "none" : "" }}
      >
        <IonAccordion value={ingredient?.id}>
          <IonItem slot="header" color="light">
            <IonLabel>
              {ingredient.name_DE}
            </IonLabel>
            {isLargeScreen && <>
              {ingredient.type && <IonBadge>{t(ingredient.type)}</IonBadge>}
              {ingredient.group && <IonBadge>{t(ingredient.group)}</IonBadge>}
              {ingredient.unit && <IonBadge>{ingredient.unit}</IonBadge>}
            </>}
          </IonItem>

          <IonGrid slot="content" style={{bottomBorder: "groove"}}>
            
            <IonItem>
              {changedFields.length > 0 && <>
                <IonButton onClick={save} slot="start">
                  Save
                </IonButton>
                <IonButton fill="outline" color="danger" onClick={reset} slot="start">
                  Reset
                </IonButton>
              </>}
              <IonChip slot="end"><IonLabel>{ingredient.updated_at} (last update)</IonLabel></IonChip>
            </IonItem>

            {["name_DE", "name_EN", "name_PL", "active_substance_DE", "active_substance_EN", "active_substance_PL"].map((k: string) => <IonRow>
              <IonCol>
                {t(k.replace("name_", t("Name") + ": ").replace("active_substance_", t("Active substance") + ": "))}
              </IonCol>
              <IonCol>
                <IonInput value={tmpIngredient[k]} {...props(k)} placeholder={k} />
              </IonCol>
            </IonRow>)}

            <IonRow>
              <IonCol>
                Default amount per ha
              </IonCol>
              <IonCol>
                <IonInput type="number" value={tmpIngredient["default_amount_per_ha"]} {...props("default_amount_per_ha")} />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                Group
              </IonCol>
              <IonCol>
                <IngredientGroupSelect value={tmpIngredient.group} selectProps={props("group")}/>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                Type
              </IonCol>
              <IonCol>
                <IngredientTypeSelect value={tmpIngredient.type} selectProps={props("type")}/>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                Unit
              </IonCol>
              <IonCol>
                <IonSelect value={tmpIngredient["unit"]} {...props("unit")}>
                  <IonSelectOption value="L">L</IonSelectOption>
                  <IonSelectOption value="g">g</IonSelectOption>
                  <IonSelectOption value="kg">kg</IonSelectOption>
                </IonSelect>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                Obsolete
              </IonCol>
              <IonCol>
                <IonToggle checked={tmpIngredient.obsolete} {...props("obsolete")}/>
              </IonCol>
            </IonRow>

            <IonButton onClick={() => insertIngredients({variables: {objects: [Object.fromEntries(changeableFields.map((k: string) => ([k, ingredient[k]])))]}})} expand="full">Duplicate</IonButton>

          </IonGrid>
        </IonAccordion>
      </IonAccordionGroup>
    </>
  );
};
