import { IonChip, IonContent, IonIcon, IonLabel, IonPopover } from "@ionic/react";
import { useState } from "react";

export const PopoverChip: React.FC<any> = ({text, children, icon, ...props}: any) => {
  const [event, setEvent] = useState<any>(null)
  return <>
    <IonChip {...props} onClick={(e: any) => {e.stopPropagation(); setEvent(e)}}>
      {icon && <IonIcon icon={icon}/>}
      <IonLabel>{text}</IonLabel>
    </IonChip>
    {/* IonPopover is positioned at location of the event click */}
    <IonPopover onDidDismiss={() => setEvent(null)} event={event} isOpen={!!event}>
      <IonContent>{children}</IonContent></IonPopover>
  </> 
}