import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Tooltip, CircleMarker, LayerGroup, useMap, useMapEvents, Polyline, Marker } from 'react-leaflet'
import { LatLng } from 'leaflet';
import TextPath from 'react-leaflet-textpath';
import { toLatLon, fromLatLon } from "utm"
import './Map.css'
import 'leaflet/dist/leaflet.css';


export function RainBowColor(length: number, maxLength: number, opacity: number = 1.)
{
    var i = (length * 255 / maxLength);
    var r = Math.round(Math.sin(0.024 * i + 0) * 127 + 128);
    var g = Math.round(Math.sin(0.024 * i + 2) * 127 + 128);
    var b = Math.round(Math.sin(0.024 * i + 4) * 127 + 128);
    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
}


const toLatLngConverter = (anchor: LatLng) => {
  const anchorUtm = fromLatLon(anchor.lat, anchor.lng)
  return (point: any): LatLng => {
    const ll = toLatLon(anchorUtm.easting + point.x, anchorUtm.northing + point.y, anchorUtm.zoneNum, anchorUtm.zoneLetter)
    return new LatLng(ll.latitude, ll.longitude)
  }
}

const MapContent = ({paths, toLatLng, trackPlan, robots}: any) => {
  const map = useMap()

  const [zoom, setZoom] = useState(23)
  const [ready, setReady] = useState(false)
  useMapEvents({
    zoom: (event: any) => setZoom(event.target._zoom),
  })

  const polyLineSmoothFactor = 22  // More means more smooth

  // From https://stackoverflow.com/questions/27545098/leaflet-calculating-meters-per-pixel-at-zoom-level
  const metresPerPixel = 40075016.686 * Math.abs(Math.cos(map.getCenter().lat * Math.PI/180)) / Math.pow(2, zoom+8);

  const pathTracks: any = {}
  let firstTrack: any = {}
  trackPlan?.tracks?.slice(trackPlan?.start_position, trackPlan?.stop_position - trackPlan?.start_position + 1).forEach((track: any, trackNumber: number) => {
    if (trackNumber === 0) {firstTrack = track}
    track?.position_path_mappings?.forEach((positionPathMapping: any) => {
      pathTracks[`${positionPathMapping?.path_type}_${positionPathMapping?.path_number}`] = {trackNumber: trackNumber, position: positionPathMapping?.position, reverse: track?.reverse}
    })
  })
  const colorForPath = (path: any, pathTrack: any) => {
    if (pathTrack) {
      return (firstTrack?.reverse === pathTrack?.reverse) ? "var(--ion-color-secondary)": "var(--ion-color-medium)"
    }

    const daysSincePath = path?.currentPathState ? (new Date().getTime() - new Date(path?.currentPathState?.created_at).getTime()) / 1000 / 3600 / 24 : 0
    if (daysSincePath < 5 && (path?.currentPathState?.source === "samerow" || path?.currentPathState?.source === "samepath")) return "maroon"
    return "chocolate"
  }

  const textForPath = (path: any, pathTrack: any) => {
    const pathName = `${path.type} ${path.number}`
    return pathName
  }

  // Required so leaflet can get size of map (HACK)
  // See https://gis.stackexchange.com/questions/348228/map-is-not-visible-at-initialization-using-react-leaflet
  useEffect(() => {
    setTimeout(() => {map.invalidateSize(); setReady(true)}, 100)
  }, [])

  if (!ready) return null  // Wait until container is ready to render content

  return <>
    {paths?.filter((path: any) => !path.ignore)?.map((path: any, i: number) => {
        {
            const pathTrack = pathTracks[`${path?.type}_${path?.number}`]
            const points = path?.points
            if (!points || points?.length < 1) {return}
            const start = toLatLng(points[0])  // position of start point
            const stop = toLatLng(points[points?.length -1])  // position of start point
            const linePositions = points.map((wp: any) => toLatLng(wp))
            const isEvenSowingTrack = path.sowing_track_number % 2 == 0

            return <LayerGroup key={i}>
                <CircleMarker center={start} pathOptions={{color: "green"}} radius={0.01}>
                    {(path.number === 1) && <Tooltip permanent={true} className="row-tooltip" direction="top">{path.type} {path.number}</Tooltip>}
                </CircleMarker>

                <CircleMarker center={firstTrack?.reverse ? stop : start} pathOptions={{color: "green"}} radius={0.0}>
                    {(pathTrack?.trackNumber === 0) && <Tooltip className="track-tooltip" permanent={true} direction="left">First track</Tooltip>}
                </CircleMarker>

                <Polyline positions={linePositions} smoothFactor={polyLineSmoothFactor} lineCap="butt"
                    pathOptions={{weight: 0.45 / metresPerPixel, color: colorForPath(path, pathTrack), opacity: pathTrack ? 1 : 0.5}} >
                </Polyline>

                <TextPath positions={linePositions} text={zoom >= 21 ? textForPath(path, pathTrack): null}
                    pathOptions={{color: isEvenSowingTrack ? "var(--ion-color-primary)": "var(--ion-color-success)", weight: zoom >= 21 ? 2.0 : 0.9, opacity: 1.}} smoothFactor={polyLineSmoothFactor}
                    center offset={5}
                    attributes={{class: "polyline-row-text", fill: "lightgray", style: {"text-transform": "capitalize"}}}
                    >
                    <Tooltip sticky={true} className="tooltip">{path.type} {path.number}</Tooltip>
                </TextPath>

                {robots?.map((robot: any, i: number) => <CircleMarker key={i} center={robot} radius={3}/>)}

                {pathTrack && zoom >= 21 &&
                  <TextPath positions={linePositions}
                      pathOptions={{weight: 0}} smoothFactor={polyLineSmoothFactor}
                      center offset={5}
                      text={pathTrack?.reverse ? " ".repeat(30) + `< ${pathTrack?.trackNumber + 1}` : `${pathTrack?.trackNumber + 1} >` + " ".repeat(30)} repeat
                      attributes={{class: "polyline-track-text", fill: "white", style: {"text-transform": "capitalize"}}}
                  />
                }

            </LayerGroup>
        }})}
  </>
}

const Map: React.FC<any> = ({anchor, paths, robots, ...options}) => {
  if (!anchor) return <div>No anchor</div>
  const points = paths?.length > 0 && paths[0]?.points
  const toLatLng = toLatLngConverter(anchor)
  const center: LatLng = points?.length > 0 ? toLatLng(points[0]) : anchor

  return <MapContainer id="mapid" center={center} zoom={21} scrollWheelZoom={false}>
    <TileLayer
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      maxNativeZoom={19}
      maxZoom={23}
    />
    <MapContent paths={paths} toLatLng={toLatLng} {...options} center={center} robots={robots}/>
  </MapContainer>
}

export default Map
