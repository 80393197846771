import React, { useState, } from "react";
import {
  IonList,
  IonContent,
  IonPage,
  IonItem,
  IonAccordionGroup,
  IonAccordion,
  IonIcon,
  IonFab,
  IonFabButton,
  IonLabel,
  IonInput,
  IonPopover,
  IonDatetime,
  IonProgressBar,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { add, } from "ionicons/icons";
import Toolbar from "../../components/Toolbar";
import Loading from "../../components/Loading";
import { gql } from "@apollo/client";
import { useSubscriptionWithElevatedRole } from "../../hooks/hasuraHooks";
import { useMutationWithElevatedRole } from "../../hooks/hasuraHooks";
import { ComponentEditorButtonsItem, useComponentEditor } from "../../components/ComponentEditor";


const Generator = ({ generator }: any) => {
  const [updateGenerator] = useMutationWithElevatedRole(
    gql`
      mutation UpdateGenerator($id: Int!, $_set: generators_set_input!) {
        update_generators_by_pk(pk_columns: { id: $id }, _set: $_set) {
          id
        }
      }
    `,
    {}
  );
  const [editManufacturedAt, setEditManufacturedAt] = useState(false)
  const {save, reset, props, changedFields, tmp, } = useComponentEditor(generator, updateGenerator)

  return (
    <IonAccordionGroup expand="inset">
      <IonAccordion value={generator.id}>
        <IonItem slot="header" color="light">
          {generator.serial_number} {" "}
          (ID: {generator.id})
          <IonLabel slot="end">
            {generator.description}
          </IonLabel>
        </IonItem>
        <IonList lines="none" slot="content">
          <ComponentEditorButtonsItem component={generator} save={save} reset={reset} changedFields={changedFields}/>

          <IonItem>
            <IonLabel position="stacked">Serial Number</IonLabel>
            <IonInput {...props("serial_number")} value={tmp.serial_number} placeholder="Serial number"/>
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">Model</IonLabel>
            <IonInput {...props("model")} value={tmp.model} />
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">Manufacturer</IonLabel>
            <IonInput {...props("manufacturer")} value={tmp.manufacturer} placeholder="Manufacturer"/>
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">Manufactured at</IonLabel>
            <a style={{cursor: "pointer", marginBottom: 15}} onClick={() => setEditManufacturedAt(true)}>{tmp.manufactured_at?.split("T")[0] || "No date yet"}</a>
            <IonPopover
              isOpen={editManufacturedAt}
              onDidDismiss={() => setEditManufacturedAt(false)}
            >
              <IonDatetime
                value={tmp.manufactured_at?.split(".")[0]}
                presentation="date"
                {...props("manufactured_at")}
              ></IonDatetime>
            </IonPopover>
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">Description</IonLabel>
            <IonInput {...props("description")} value={tmp.description} placeholder="Description"/>
          </IonItem>

        </IonList>
      </IonAccordion>
    </IonAccordionGroup>
  );
};

const Generators = () => {
  const { loading, data } = useSubscriptionWithElevatedRole(
    gql`
      subscription Generators {
        generators {
          id
          updated_at
          description
          serial_number
          model
          manufacturer
          manufactured_at
        }
      }
    `,
  )

  const [addGenerator] = useMutationWithElevatedRole(
    gql`
      mutation InsertGenerator {
        insert_generators_one(object: {}) {
          id
        }
      }
    `,
    {}
  );

  return (
    <IonPage>
      <Toolbar name="Generators" />
      <IonContent fullscreen>
        {loading && <Loading />}
        <IonFab horizontal="end" vertical="bottom" slot="fixed">
          <IonFabButton onClick={() => addGenerator()}>
            <IonIcon icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>
        {data?.generators && <IonList>
          {data.generators.map((generator: any) => <Generator generator={generator} key={generator.id} />)}
        </IonList>}
      </IonContent>
    </IonPage>
  );
};
export default Generators;


export const GeneratorSelect = ({componentId, setComponentId, selectChildren = null}: any) => {
  
  const {loading, data} = useSubscriptionWithElevatedRole(
    gql`
      subscription Generators {
        generators (order_by: {id: asc}) {
          id serial_number
        }
      }
    `, {}
  )

  if (loading || !data?.generators) {
    return <IonProgressBar type="indeterminate"/>
  }

  return <IonSelect value={componentId} onIonChange={({detail: {value}}: any) => setComponentId(value)}>
    {selectChildren}
    {data.generators.map(({id, serial_number}: any) => <IonSelectOption key={id} value={id}>{serial_number} (ID: {id})</IonSelectOption>)}
  </IonSelect>
}