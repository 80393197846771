import React, { useEffect, useState } from "react"
import { IonButton, IonInput, IonItem, IonLabel, IonSearchbar, IonSelect, IonSelectOption, IonToggle } from '@ionic/react';
import { gql, } from "@apollo/client";
import { SearchableSelect } from "./SearchableSelect";
import { useQueryWithElevatedRole } from "../hooks/hasuraHooks";
import { ComplexTagPicker, useTagWhere } from "./TagPicker";


export const useBagFilter = () => {
  return useState<any>({
    name: {},
    archived: {_eq: false},
  })
}


export const useEvaluationFilter = () => {
  return useState<any>({})  // Initially: nothing to filter
}


const CAMERA_FILTER_OPTIONS = ['jai1', 'jai2', 'jai2 (only wideview)', 'jai2 (no wideview)', 'All'] as const
type CameraFilterTuple = typeof CAMERA_FILTER_OPTIONS  // Trick to get type from list
type CameraFilterOption = CameraFilterTuple[number]


export const BagFilter: React.FC<any> = ({setBagFilter, bagFilter, defaultGtMode="All"}: any) => {
    const [camera, setCamera] = useState<CameraFilterOption>("All")
    const [onD1, setOnD1] = useState(false)
    const [gtMode, setGtMode] = useState<"All"|"With groundtruth"|"Without groundtruth">(defaultGtMode)
    const [evalMode, setEvalMode] = useState<"All"|"With evaluations"|"Without evaluations">("All")
    const {tagWhere, ...tagWhereState} = useTagWhere()
    const [minSize, setMinSize] = useState(0)
    const { loading, error, data} = useQueryWithElevatedRole(gql`
      query PlantVarieties {
          plant_varieties {
            id name_EN
          }
      }
    `)
    useEffect(() => {
      if(Object.keys(tagWhere).length === 0) {
        setBagFilter((prev: any) => {
          delete prev["bag_tags"]
          return {...prev}
        })
      }
      else {
        setBagFilter((prev: any) => ({...prev, ...tagWhere}))
      }
    }, [tagWhere])

    useEffect(() => {
      setBagFilter((prev: any) => {
        if (evalMode === "Without evaluations") {
          // Best hack to check that there are no evaluations
          prev._not = {evaluations: {id: {_gte: 0}}}
        }
        else if (prev?._not?.evaluations !== undefined) {
          // Remove potential filters
          delete prev._not.evaluations
          if (Object.keys(prev._not).length === 0) {
            delete prev._not
          }
        }

        if (evalMode === "With evaluations") {
          prev.evaluations = {id: {_gte: 0}}
        }
        else if (prev?.evaluations !== undefined) {
          // Remove potential filters
          delete prev.evaluations
        }
        return {...prev}
      })
    }, [evalMode])

    useEffect(() => {
      setBagFilter((prev: any) => {
        if (gtMode === "Without groundtruth") {
          // Best hack to check that there are no ground_truths
          prev._not = {ground_truths: {id: {_gte: 0}}}
        }
        else if (prev?._not?.ground_truths !== undefined) {
          // Remove potential filters
          delete prev._not.ground_truths
          if (Object.keys(prev._not).length === 0) {
            delete prev._not
          }
        }

        if (gtMode === "With groundtruth") {
          prev.ground_truths = {id: {_gte: 0}}
        }
        else if (prev?.ground_truths !== undefined) {
          // Remove potential filters
          delete prev.ground_truths
        }
        return {...prev}
      })
    }, [gtMode])

    useEffect(() => setBagFilter((prev: any) => {
      if (!onD1 && minSize == 0) {
        // Remove filepath filter completely if neither d1 not min size are set
        if (prev.file_paths) {
          delete prev.file_paths
        }
        return {...prev}
      }

      if (!prev.file_paths) {
        prev.file_paths = {}
      }
      if (onD1) {
        // Must have file_path named d1
        prev.file_paths = {computer_name: {_eq: "d1"}}
      }
      else if (prev.file_paths.computer_name) {
        delete prev.file_paths.computer_name
      }
      if (minSize > 0) {
        prev.file_paths = {size_mb: {_gte: minSize}}
      }
      else if (prev.file_paths.size_mb) {
        delete prev.file_paths.size_mb
      }
      return {...prev}
    }), [onD1, minSize])

    useEffect(() => setBagFilter((prev: any) => {
      if (camera === "All") {
        // Remove all camera-specific filters
        if (prev.aquila_had_camera_type) {
          delete prev.aquila_had_camera_type
        }
        if (prev.aquila_had_wide_view) {
          delete prev.aquila_had_wide_view
        }
      }
      else {
        prev.aquila_had_camera_type = {_eq: camera.split(" ")[0]} // Only set the prefix (jai1 or jai2) without (no wideview) or other suffixes
        // Set wide-view depending on content of camera
        if (camera.includes("no wideview")) {
          prev.aquila_had_wide_view = {_eq: false}
        }
        else if (camera.includes("only wideview")) {
          prev.aquila_had_wide_view = {_eq: true}
        }
        else if (prev.aquila_had_wide_view) {
          delete prev.aquila_had_wide_view
        }
      }
      return {...prev}
    }), [camera])

    return <>
      {data && <IonItem color="transparent" style={{minWidth: 100}}><IonLabel position="stacked">Filter by plant</IonLabel><SearchableSelect value={bagFilter?.plant_variety_id?._in || []} onChange={
        (value: any) => setBagFilter((prev: any) => {
          if (value?.length > 0) {return ({...prev, plant_variety_id: {_in: value}})}
          else {delete prev.plant_variety_id; return prev}
        })
      } options={data.plant_varieties.map((t: any) => [t.id, t.name_EN])} /></IonItem>}

      <IonItem color="transparent">
        <IonLabel position="stacked">Filter by tag</IonLabel>
        <ComplexTagPicker state={tagWhereState} />
      </IonItem>

      <IonItem color="transparent">
        <IonLabel position="stacked">Archived</IonLabel>
        <IonSelect
            value={bagFilter?.archived?._eq !== undefined ? bagFilter?.archived._eq : null}
            onIonChange={(e: any) => setBagFilter((prev: any) => ({...prev, archived: e.detail.value === null ? {} : {_eq: e.detail.value}}))}>
          <IonSelectOption value={false}>Hide archived bags</IonSelectOption>
          <IonSelectOption value={null}>Show archived bags</IonSelectOption>
          <IonSelectOption value={true}>Show only archived bags</IonSelectOption>
        </IonSelect>
      </IonItem>

      <IonItem color="transparent">
        <IonLabel position="stacked">On d1</IonLabel>
        <IonToggle checked={onD1} onIonChange={(e: any) => setOnD1(e.detail.checked)}/>
      </IonItem>

      <IonItem color="transparent">
        <IonLabel position="stacked">Ground truth</IonLabel>
        <IonSelect onIonChange={(e: any) => setGtMode(e.detail.value)} value={gtMode}>
          {["All", "With groundtruth", "Without groundtruth"].map((t: string) => <IonSelectOption key={t} value={t}>{t}</IonSelectOption>)}
        </IonSelect>
      </IonItem>


      <IonItem color="transparent">
        <IonLabel position="stacked">Evaluations</IonLabel>
        <IonSelect onIonChange={(e: any) => setEvalMode(e.detail.value)} value={evalMode}>
          {["All", "With evaluations", "Without evaluations"].map((t: string) => <IonSelectOption key={t} value={t}>{t}</IonSelectOption>)}
        </IonSelect>
      </IonItem>

      <IonItem color="transparent">
        <IonLabel position="stacked">Camera</IonLabel>
        <IonSelect value={camera} onIonChange={(e: any) => setCamera(e.detail.value)}>
          {CAMERA_FILTER_OPTIONS.map((k: string) => <IonSelectOption key={k} value={k}>{k}</IonSelectOption>)}
        </IonSelect>
      </IonItem>
      
      <IonItem color="transparent">
        <IonLabel position="stacked">Min size (MB)</IonLabel>
        <IonInput value={minSize} onIonChange={(e: any) => setMinSize(parseInt(e.detail.value) || 0)} type="number" step="1" min="0"/>
      </IonItem>

      <IonSearchbar style={{maxWidth: 500}} placeholder='Bag name' value={bagFilter.name._regex} debounce={500}
        onIonChange={e => setBagFilter((prev: any) => ({...prev, name: e.detail.value === '' ? {} : {...prev.name, _regex: e.detail.value}}))}/>
    </>
}


export const EvaluationFilter: React.FC<any> = ({setEvaluationFilter, evaluationFilter, children}: any) => {
    const { loading, error, data} = useQueryWithElevatedRole(gql`
      query EvaluationFilter {
          param_configs(order_by: {id: desc}) {
            id description
          }
          evaluator_configs(order_by: {id: desc}, where: {archived: {_eq: false}}) {
            id
            evaluator_name
            data
          }
      }
    `)
    
    // Set a bag filter only if showBagFilter is trur
    const [showBagFilter, setShowBagFilter] = useState(false)
    const [bagFilter, setBagFilter] = useBagFilter()
    const [state, setState] = useState("All")
    const [startIndex, setStartIndex] = useState<number|null>(null)
    const [endIndex, setEndIndex] = useState<number|null>(null)
    const [scoreMode, setScoreMode] = useState<"All"|"Passed"|"Failed"|"Manually set as failed">("All")

    useEffect(() => {
      if (scoreMode === "All") {
        setEvaluationFilter((prev: any) => {
          if (prev.score_passed !== undefined) {
            delete prev.score_passed
          }
          return prev
        })
      }
      else if (scoreMode === "Manually set as failed") {
        setEvaluationFilter((prev: any) => ({...prev, score_passed: {_eq: false}, score_author_name: {_eq: "annotator@farming-revolution.com"}}))
      }
      else {
        setEvaluationFilter((prev: any) => {
          delete prev.score_author_name
          return {...prev, score_passed: {_eq: scoreMode === "Passed"}}
        })
      }
    }, [scoreMode])

    useEffect(() => {
      if (!showBagFilter && evaluationFilter?.bag) {
        delete evaluationFilter.bag
        setEvaluationFilter({...evaluationFilter})
      }
      else if (showBagFilter) {
        setEvaluationFilter((prev: any) => ({...prev, bag: bagFilter}))
      }
    }, [showBagFilter, bagFilter])  // Update if any of those change

    useEffect(() => {
      if (startIndex || endIndex) {
        evaluationFilter.id = {}
      }
      else {
        delete evaluationFilter.id
      }
      if (startIndex) {
        evaluationFilter.id._gte = startIndex
      }
      if (endIndex) {
        evaluationFilter.id._lte = endIndex
      }
      setEvaluationFilter({...evaluationFilter})
    }, [startIndex, endIndex])

    useEffect(() => {
      if (state === "All") {
        delete evaluationFilter.result_data
        delete evaluationFilter.result_error
        delete evaluationFilter.execution_computer_id
      }
      else if (state === "Completed") {
        evaluationFilter.result_data = {_is_null: false}
        evaluationFilter.result_error = {_is_null: true}
        delete evaluationFilter.execution_computer_id
      }
      else if (state === "Failed") {
        delete evaluationFilter.result_data
        evaluationFilter.result_error = {_is_null: false}
        delete evaluationFilter.execution_computer_id
      }
      else {
        evaluationFilter.result_data = {_is_null: true}
        evaluationFilter.result_error = {_is_null: true}
        if (state === "In progress") {
          evaluationFilter.execution_computer_id = {_is_null: false}
        }
        else if (state === "Waiting") {
          evaluationFilter.execution_computer_id = {_is_null: true}
        }
        else {
          console.error(`Invalid state ${state}`)
        }
      }
      setEvaluationFilter({...evaluationFilter})
    }, [state])

    return <>
      <IonItem color="transparent">
        <IonLabel position="stacked">State</IonLabel>
        <IonSelect onIonChange={(e: any) => setState(e.detail.value)} value={state}>
          {["All", "Completed", "Failed", "In progress", "Waiting"].map((type: string) => <IonSelectOption key={type} value={type}>{type}</IonSelectOption>)}
        </IonSelect>
      </IonItem>

      {data && <IonItem color="transparent">
        <IonLabel position="stacked">Param config</IonLabel>
        <SearchableSelect value={evaluationFilter?.param_config_id?._in || []} onChange={
          (value: any) => setEvaluationFilter((prev: any) => {
            if (value?.length > 0) {return ({...prev, param_config_id: {_in: value}})}
            else {delete prev.param_config_id; return prev}
          })
        } options={data.param_configs.map((t: any) => [t.id, `#${t.id} ${t.description}`])} />
      </IonItem>}

      {data && <IonItem color="transparent">
        <IonLabel position="stacked">Evaluator</IonLabel>
        <SearchableSelect value={evaluationFilter?.evaluator_config_id?._in || []} onChange={
          (value: any) => setEvaluationFilter((prev: any) => {
            if (value?.length > 0) {return ({...prev, evaluator_config_id: {_in: value}})}
            else {delete prev.evaluator_config_id; return prev}
          })
        } options={data.evaluator_configs.map((t: any) => [t.id, `${t.evaluator_name} (${JSON.stringify(t.data)})`])} />
      </IonItem>}

      <IonItem color="transparent">
        <IonLabel position="stacked">Score</IonLabel>
        <IonSelect onIonChange={(e: any) => setScoreMode(e.detail.value)} value={scoreMode}>
          {["All", "Passed", "Failed", "Manually set as failed"].map((t: string) => <IonSelectOption key={t} value={t}>{t}</IonSelectOption>)}
        </IonSelect>
      </IonItem>

      <IonItem color="transparent" style={{maxWidth: 110}}>
        <IonLabel position="stacked">Start index</IonLabel>
        <IonInput type="number" value={startIndex} onIonChange={(e: any) => setStartIndex(parseInt(e.detail.value) || null)}/>
      </IonItem>
      <IonItem color="transparent" style={{maxWidth: 110}}>
        <IonLabel position="stacked">End index</IonLabel>
        <IonInput type="number" value={endIndex} onIonChange={(e: any) => setEndIndex(parseInt(e.detail.value) || null)}/>
      </IonItem>

      {children}
      <IonButton size="small" onClick={() => setShowBagFilter(!showBagFilter)}>{showBagFilter ? "- Hide" : "+ Show"} bag filter</IonButton>
      <div style={{display: "flex", width: "100%"}}>
        {showBagFilter && <BagFilter bagFilter={bagFilter} setBagFilter={setBagFilter} />}
      </div>
    </>
}

