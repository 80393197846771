import { IonItem, IonBadge, IonCard, IonCardContent, IonCheckbox, } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { SearchableSelect } from './SearchableSelect';
import { useTranslation } from 'react-i18next';



export const CropSelector: React.FC<any> = ({rules_by_crop, setCropId, cropId, setDefaultCropId = true, skipCropsWithNoRule = true, ...props}: any) => {
  const { t, i18n } = useTranslation()
  const langKey = `name_${i18n.language.split("-")[0]?.toUpperCase()}`

  // Crops with at least one rule
  const filtered_rules_by_crop = rules_by_crop?.filter(({param_config_rules}: any) => skipCropsWithNoRule ? param_config_rules?.length > 0 : true) 
  useEffect(() => {
    if (!cropId && setDefaultCropId && filtered_rules_by_crop.length > 0) {
      setCropId(filtered_rules_by_crop[0].id)
    }
  }, [filtered_rules_by_crop])
  if (!filtered_rules_by_crop) {return <IonCard color="danger"><IonCardContent>No crop info.</IonCardContent></IonCard>}
  return <IonCard {...props}><IonCardContent>
    <SearchableSelect value={cropId} onChange={setCropId} name="Crop" closeOnChange={true}
      options={filtered_rules_by_crop?.map((crop: any) => [crop.id, crop[langKey] || crop["name_LA"]])}/>
  </IonCardContent></IonCard>
}


export const RuleSelector: React.FC<any> = ({rules_by_crop, setRule, cropId}: any) => {
  // Wraps rule selector simple and sets default rule and crop (handled by param_server node on robot)
  const [ruleId, setRuleId] = useState(undefined)
  useEffect(() => {
    setRuleId(rules_by_crop?.find(({id}: any) => id === cropId)?.param_config_rules[0]?.id)  // Set first rule
  }, [cropId])
  useEffect(() => {
    if (cropId && ruleId) {
      setRule(rules_by_crop?.find(({id}: any) => id === cropId)?.param_config_rules?.find(({id}: any) => id === ruleId))
    }
  }, [ruleId])
  return <RuleSelectorSimple rules_by_crop={rules_by_crop} ruleId={ruleId} setRuleId={setRuleId} cropId={cropId}/>
}


export const RuleSelectorSimple: React.FC<any> = ({rules_by_crop, ruleId, setRuleId, cropId, developer}: any) => {
  const { t, i18n } = useTranslation()
  const rules = rules_by_crop
    ?.find(({id}: any) => id === cropId)?.param_config_rules  // Find available rules for this crop
    ?.filter((rule: any) => (developer || !rule.is_dev))  // Remove is_dev rules unless we are a developer
  if (rules?.length <= 1) return null  // No need to show options (only 1 option)
  const ruleKey = (rule: any) => {
    return rule?.tags?.length ? rule?.tags[0]?.tag?.name : ""
  }
  return <>
    {rules?.sort((a:any, b: any) => ruleKey(a)?.localeCompare(ruleKey(b)))?.map(({tags, id, recommended_param_config, description}: any) => <IonItem onClick={() => setRuleId(id)} style={{marginLeft: "-10px"}}>
      <IonCheckbox slot="start" checked={id === ruleId} style={{marginRight: "8px"}}/>
      {description ? <>{description}</> : <>
        {tags.length ? tags.map(({tag}: any) => <IonBadge style={{textTransform: "capitalize"}}>{tag.name}</IonBadge>) : <IonBadge>{t("Default")}</IonBadge>}
      </>}
      {developer && <>#{recommended_param_config?.id}</>}
    </IonItem>)}
  </>
}