import React, { useState, } from "react";
import {
  IonList,
  IonContent,
  IonPage,
  IonItem,
  IonAccordionGroup,
  IonAccordion,
  IonIcon,
  IonLabel,
  IonBadge,
  IonButton,
} from "@ionic/react";
import { add, } from "ionicons/icons";
import Toolbar from "../../components/Toolbar";
import Loading from "../../components/Loading";
import { gql } from "@apollo/client";
import { useSubscriptionWithElevatedRole } from "../../hooks/hasuraHooks";
import { useMutationWithElevatedRole } from "../../hooks/hasuraHooks";
import { ProcessContent } from "./Checklist";
import { useTranslation } from "react-i18next";
import { ComponentTypeSelect } from "../../components/ComponentIdSelect";


export const shortenComponentType = (componentType: string) => componentType.replace("generator", "GEN").replace("taurus", "GT").replace("talpa", "TLP").replace("aquila", "AQL").replace("sprayer", "SPR")


const Process = ({ process }: any) => {
  return (
    <IonAccordionGroup expand="inset">
      <IonAccordion value={process.id}>
        <IonItem slot="header" color="light">

          {process.archived && <IonBadge color="medium" slot="start">archived</IonBadge>}
          <IonLabel>
            {process.description}
          </IonLabel>

          {process.component_type && <IonBadge slot="end">
            {process.filter_min_component_id !== null && <>{process.filter_min_component_id } ≤ </>}
            {shortenComponentType(process.component_type)}
            {process.filter_max_component_id !== null && <> ≤ {process.filter_max_component_id }</>}
          </IonBadge>}
        
        </IonItem>
        <IonList lines="none" slot="content">
          <ProcessContent process={process}/>
        </IonList>
      </IonAccordion>
    </IonAccordionGroup>
  );
};

const Processes = () => {
  const { data } = useSubscriptionWithElevatedRole(
    gql`
      subscription Processes {
        processes(order_by: {id: asc, component_type: asc, process_group_id: asc}) {
          id
          created_at
          updated_at

          description

          component_type

          interval_s
          duration_s
          remote
          archived

          process_group_id

          filter_min_component_id
          filter_max_component_id
        }
      }
    `,
  )

  const [addProcess] = useMutationWithElevatedRole(
    gql`
      mutation InsertProcess($object: processes_insert_input!) {
        insert_processes_one(object: $object) {
          id
        }
      }
    `,
    {}
  );

  const {t} = useTranslation()

  const [componentForNewProcess, setComponentForNewProcess] = useState("aquila")

  return <IonPage>
    <Toolbar name="Processes" />
    <div style={{display: "flex", width: "100%", justifyContent: "end"}}>
      <IonItem lines="none">
        <ComponentTypeSelect value={componentForNewProcess} onIonChange={({detail: {value}}: any) => setComponentForNewProcess(value)} slot="start"/>
      </IonItem>
      <IonItem lines="none">
        <IonButton
            onClick={() => addProcess({variables: {object: {component_type: componentForNewProcess}}})}
            fill="clear" disabled={componentForNewProcess.length < 1}
        >
          <IonIcon slot="start" icon={add}/>
          {t("Add process for {{ componentForNewProcess }}", {componentForNewProcess})}
        </IonButton>
      </IonItem>
    </div>
    <IonContent fullscreen>
      {data?.processes && <IonList>
        {data.processes.map((process: any) => <Process process={process} key={process.id} />)}
      </IonList>}
    </IonContent>
  </IonPage>
};
export default Processes;