import React, { useEffect, useRef, useState } from 'react';
import {IonButton, IonIcon, IonBadge, IonCard, IonButtons, IonPopover, IonItem, IonList, IonLabel } from '@ionic/react';
import {Line, Arrow, Text, Circle, Group, Rect, } from 'react-konva';
import {Quaternion, Type, Action, color_to_rgba} from '../utils/MarkerArray';

import {RosHandleContext, useParam, useTopic} from '../hooks/rosHooks';
import { arrowUp, arrowUpCircleOutline, caretDown, compass, } from 'ionicons/icons';
import {TaurusControllerInfo} from './TaurusControllerInfo';
import GpsDiagButton from './GpsDiagButton';
import { DeveloperModeContext, useDeveloper } from '../hooks/useDeveloperMode';
import { KonvaHtml } from './KonvaHtml';
import { useViewConfig } from './ViewConfig';
import { Axle, RobotArrow, RobotConfigs } from './KonvaRobot';
import { KonvaCroplines } from './KonvaCroplines';

import { Point, Vector, } from 'ts-2d-geometry'
import { t } from 'i18next';


const POSITIONING_MODES = [
  {key: "north-up", icon: <IonIcon icon={compass} slot="start" style={{transform: "rotate(-45deg)"}}/>, text: "North pointing up"},
  {key: "robot-up", icon: <IonIcon icon={arrowUp} slot="start"/>, text: "Center on robot"},
  {key: "follow-robot-up", icon: <IonIcon icon={arrowUpCircleOutline} slot="start"/>, text: "Keep centered on robot"},
]


function quaternion_to_yaw(q: Quaternion) : number {
    return -Math.PI - Math.atan2(2*(q.z*q.w), 1-2*(q.w*q.w))
}


export const OriginFrame: React.FC<any> = ({size = 1}) => {
  return <>
    <Arrow points={[0, 0, size, 0.]} stroke="black" strokeWidth={0.05} pointerLength={0.1} pointerWidth={0.1} />
    <Text x={1.1 * size} y={0.1 * size} scaleY={-1} text={"x"} fontSize={0.2 * size } />
    <Arrow points={[0, 0, 0, size]} stroke="black" strokeWidth={0.05} pointerLength={0.1} pointerWidth={0.1} />
    <Text x={0} y={1.3 * size} text={"y"} scaleY={-1} fontSize={0.2 * size} />
  </>
}


export const PositioningButtons: React.FC<any> = ({show, center, followRobot, setFollowRobot}) => {  // memo: memoized (not update from parent field section because has no parameters)
  // Buttons to position the map correctly
  const current_robot_pose = useTopic("/current_robot_pose", "geometry_msgs/PoseStamped")?.pose  // this has a quite high frequency
  const [initiallyCentered, setInitiallyCentered] = useState(false)
  const [modeKey, setModeKey] = useState<string>("north-up")

  useEffect(() => {
    if (!current_robot_pose) return
    if (!followRobot && initiallyCentered) return  // no action to be taken
    const yaw = quaternion_to_yaw(current_robot_pose.orientation)
    const offsetFromBaseLink = 1.5  // We want to center around the central part of the robot (behind base link)
    const pos = {
      yaw: yaw,
      x: current_robot_pose.position.x - Math.cos(yaw) * offsetFromBaseLink,
      y: current_robot_pose.position.y - Math.sin(yaw) * offsetFromBaseLink,
    }
    if (!initiallyCentered) {
      if (modeKey === "north-up") {
        if (current_robot_pose) {
          center(10, current_robot_pose.position.x, current_robot_pose.position.y)
        }
        else {
          center(10, 0, 0)  // No robot pose: center around 0
        }
      }
      else {
        center(70, pos.x, pos.y, pos.yaw - Math.PI / 2.  /* -90 to have the robot going upward */)
      }
      setInitiallyCentered(true)
    }
    if (modeKey === "follow-robot-up") {
      center(70, pos.x, pos.y, pos.yaw - Math.PI / 2.  /* -90 to have the robot going upward */)
    }
  }, [current_robot_pose, followRobot, initiallyCentered])

  useEffect(() => {
    if (modeKey === "follow-robot-up") {
      setFollowRobot(true)
    }
    else {
      setFollowRobot(false)
    }
    setInitiallyCentered(false)
  }, [modeKey])

  if (!show) {return null}
  return <>
    <PositionModePicker modeKey={modeKey} setModeKey={setModeKey}/>
  </>
}


export const PositionModePicker: React.FC<any> = ({modeKey, setModeKey}: any) => {
  const [event, setEvent] = useState<any>(null)
  const currentMode = POSITIONING_MODES.find(({key}: any) => key === modeKey)
  return <>
    <IonButton fill="outline" onClick={setEvent}>
      {currentMode?.icon}
      <IonIcon slot="end" icon={caretDown}/>
    </IonButton>
    <IonPopover event={event} isOpen={!!event} onDidDismiss={() => setEvent(null)} className='wide-popover'>
      <IonList>
        {POSITIONING_MODES.map(({key, icon, text}: any) => <IonItem
            onClick={() => setModeKey(key)} lines="none" color={(modeKey === key) ? "secondary" : undefined} detail={modeKey !== key}
          >
          {icon}
          <IonLabel>{t(text)}</IonLabel>
        </IonItem>)}
      </IonList>
    </IonPopover>
  </>
}


export const CroplineMatcher: React.FC<any> = ({topic }: any) => {
  const croplineMatcherInfo = useTopic(topic, "weeding_cropline_estimation/CropRowModelMatcherInfo")
  if (!croplineMatcherInfo) return null
  return <KonvaCroplines msg={croplineMatcherInfo}/>
}


export const KonvaPlannerNode: React.FC<any> = ({node, isExpanded}: any) => {
  const [hover, setHover] = useState(false)
  const [pin, setPin] = useState(false)
  const [more, setMore] = useState(false)
  const timer = useRef<any>(setTimeout(() => {}, 0))  // Default: timer that does nothing
  const color = isExpanded ? "black" : "darkgray"
  const p1 = new Point(node.pose.x, node.pose.y)
  const p2 = p1.plus(new Vector(Math.cos(node.pose.theta), Math.sin(node.pose.theta)))
  return <>
    <StandardArrow p1={p1} p2={p2} size={100} fill={color}
      onMouseOver={() => {clearTimeout(timer.current); /* clear timer for last onmouseout */ setHover(true)}}
      onMouseOut={() => timer.current = setTimeout(() => setHover(false), 5000) /* close show after 5s */}
    />
    {(hover || pin) && <KonvaHtml x={p1.x} y={p1.y}>
      <IonCard style={{maxWidth: 300}}>
        <IonBadge>
          #{node.id}-{isExpanded ? "Expanded" : "Reachable"}
          {more && <> | Sum: {(node.cost + node.heuristic).toFixed(1)} | last node: #{node.last_planner_node_id}</>}
        </IonBadge>
        <IonBadge color="medium" style={{whiteSpace: "pre-wrap"}}>
          Cost: {node.cost.toFixed(1)} 
          {more && <>{" ("}
            last cost: {node.cost_of_last_node.toFixed(1)}
            {node.transition_to_track_node.on_track_length_m > 0 && <>, on track length: {node.transition_to_track_node.on_track_length_m.toFixed(1)}m, </>}
            {node.transition_to_track_node.on_track_prolongation_length_m > 0 && <>, on track prolongation length: {node.transition_to_track_node.on_track_prolongation_length_m.toFixed(1)}m, </>}
            {node.transition_to_track_node.on_start_track_length_m > 0 && <>, on start track length: {node.transition_to_track_node.on_start_track_length_m.toFixed(1)}m, </>}
            {node.transition_to_track_node.curve_length_m > 0 && <>, curve length: {node.transition_to_track_node.curve_length_m.toFixed(1)}m</>}
          {")"}</>}
        </IonBadge>
        <IonBadge color="medium" style={{whiteSpace: "pre-wrap"}}>
          Heuristic: {node.heuristic.toFixed(1)}
          {more && <> (
            {(node.yaw_difference / Math.PI * 180).toFixed(0)}deg → {node.yaw_heuristic.toFixed(1)} {" | "}
            {node.on_goal_track ? "is on goal track" : "not on goal track"} → {node.on_goal_track_heuristic.toFixed(1)} {" | "}
            {node.distance_to_goal.toFixed(0)}m to goal → {node.distance_to_goal_heuristic.toFixed(1)}
          )</>}
        </IonBadge>
        <IonButtons>
          <IonButton size="small" onClick={() => setPin(!pin)}>{pin ? "unpin" : "pin"}</IonButton>
          <IonButton size="small" onClick={() => setMore(!more)}>{more ? "-" : "+"}</IonButton>
        </IonButtons>
      </IonCard>
    </KonvaHtml>}
  </>
}


export const KonvaPlannerState: React.FC<any> = ({}: any) => {
  const plannerState = useTopic("/planner_state", "weeding_maneuver_planner/PlannerState")
  if (!plannerState) return null
  return <>
    {plannerState.reachable_nodes.map((node: any, i: number) => <KonvaPlannerNode node={node} key={`expanded${i}`}/>)}
    {plannerState.expanded_nodes.map((node: any, i: number) => <KonvaPlannerNode node={node} key={`expanded${i}`} isExpanded/>)}
  </>
}


export const KonvaEstimatedTrackTrend: React.FC<any> = ({msg}: any) => {
  const fitted_pts = [].concat(...msg.fitted_points.map((ptmsg: any) => {
      return [ptmsg.x, ptmsg.y]
  }))
  return <>
    <Line key="track_trend_fitted" stroke="blue" strokeWidth={0.03} opacity={0.3} points={fitted_pts}/>
    </>
}

export const EstimatedTrackTrend: React.FC<any> = ({topic}: any) => {
  const trackTrend = useTopic(topic, "weeding_track_navigation/EstimatedTrackTrend")
  if (!trackTrend) return null
  return <KonvaEstimatedTrackTrend msg={trackTrend}/>
}


export const KonvaMarkers = ({topicName, topicType}: any) => {
  const msg = useTopic(topicName, topicType)
  if (!msg) return null
  return msg?.markers?.map((m: any, i: number) => <KonvaMarker m={m} scale={1} key={i}/>)
}


export const Geofence = ({}: any) => {
  const msg = useTopic("/markers/geofence_candidate", "visualization_msgs/MarkerArray")
  const {settings} = useViewConfig()
  if (!msg) return null
  const candidate = msg.markers[1]
  const validated = msg.markers[2]
  // Always show background of geofence
  return <>
    {settings.showGeofenceCandidate && candidate.color.r === 1 && <Line
      points={candidate.points.flatMap((p: any) => [p.x, p.y])} stroke={"black"} strokeWidth={0.3} dash={[1, 1]}
      closed={true}
    />}

    {validated.color.b === 1 && <Line
      points={validated.points.flatMap((p: any) => [p.x, p.y])} stroke={"red"} strokeWidth={0.3}
      opacity={1.0}
      closed={true}
    />}
  </>
}


export const StandardArrow: React.FC<any> = ({p1, p2, size=1.5, ...props}: any) => {
  if (!p1 || !p2) {return null}
  return <Arrow
    pointerLength={1.1} pointerWidth={1.1} points={[p1.x, p1.y, p2.x, p2.y]} {...props}
  />
}



export const DebugPath: React.FC<any> = ({}: any) => {
  const {developer} = useDeveloper()
  const debugPath = useTopic("/debug_path", "weeding_field_manager/Path")
  if (!developer) return null
  return <>
    {debugPath && <RosPath path={debugPath} color="black"/>}
  </>
}


export const AllPaths: React.FC<any> = ({}: any) => {
  const paths = useTopic("/selected_paths", "weeding_field_manager/Paths")?.paths
  if (!paths) {return null}
  return paths?.map((path: any) => <RosPath path={path} color={"rgba(0,250,0,0.5)"} showPoints={true}/>)
}


export const RosPath: React.FC<any> = ({path, color, showPoints=false, simplified=false, strokeWidth=0.1}: any) => {
  const {developer} = useDeveloper()
  if (!path?.points || path?.points?.length < 2) {
    return null
  }
  const points = simplified ? [path.points[0], path.points[path.points.length - 1]] : path.points
  const button = <IonButton fill="clear" color="success" size="small" onClick={() => console.log(path)}>
    {path.type}
    {path.number > 0 && <>{path.number}</>}
  </IonButton>
  return <>
    <KonvaHtmlRos x={points[0].x} y={points[0].y}>{button}</KonvaHtmlRos>
    <KonvaHtmlRos x={points[points.length - 1].x} y={points[points.length - 1].y}>{button}</KonvaHtmlRos>
    {points.length > 1 && <StandardArrow p1={points[0]} p2={points[1]} fill={color}/>}
    {points.length > 2 && <StandardArrow p1={points[points.length - 2]} p2={points[points.length - 1]} fill={color}/>}
    <Line points={points.flatMap((p: any) => [p.x, p.y])} stroke={color} strokeWidth={strokeWidth} onMouseOver={() => console.log(path)}/>
    {showPoints && points.map((p: any, i: number) => <Circle key={i} radius={0.05} fill="black" x={p.x} y={p.y} opacity={0.5} />)}
  </>
}


export const useRobotFrontAxlePosition = () => {
  const current_robot_pose = useTopic("/current_robot_pose", "geometry_msgs/PoseStamped")  // this has a quite high frequency
  const baseLink = current_robot_pose?.pose?.position  // Is at front axle
  if (!baseLink) {return null}
  const robot_yaw = quaternion_to_yaw(current_robot_pose.pose.orientation)
  return {
    x: baseLink.x,
    y: baseLink.y,
    yaw: robot_yaw,
  }
}


export const useRobotRearAxlePosition = () => {
  const front = useRobotFrontAxlePosition()
  const {value: wheelBase} = useParam("/wheel_base")
  if (!front) {return null}
  return {
    x: front.x - Math.cos(front.yaw) * wheelBase,
    y: front.y - Math.sin(front.yaw) * wheelBase,
    yaw: front.yaw,
  }
}


export const Robot: React.FC<any> = ({settings, fixed, showPopover, setShowPopover}) => {
  const {value: wheelBase} = useParam("/wheel_base")
  const {value: trackWidth} = useParam("/track_width")
  const {value: robotConfig} = useParam("/robot_config")
  const lifterStates = useTopic("/lifter_states", "weeding_implement_manager/LifterStates")

  const pos = useRobotFrontAxlePosition()
  const steeringCmd = useTopic("/all_wheel_steering_cmd_throttled", "weeding_navigator/AllWheelSteeringCommand")
  if (!pos) return null
  const {x, y, yaw} = pos

  return <>
    <Group x={x} y={y} rotation={yaw * 180 / Math.PI}>
      <RobotArrow x={-wheelBase / 2.}/>
      {settings?.showDiagnostics && <GpsDiagButton wheelBase={wheelBase}/>}
      <Axle trackWidth={trackWidth} x={0} steeringAngle={steeringCmd?.front_steering_angle}
        leftSpeed={steeringCmd?.front_left_wheel_speed} rightSpeed={steeringCmd?.front_right_wheel_speed}/>
      <RobotConfigs x={-wheelBase} robotConfig={robotConfig} lifterStates={lifterStates} trackWidth={trackWidth} showPopover={showPopover} setShowPopover={setShowPopover}/>
      <Axle trackWidth={trackWidth} x={-wheelBase} steeringAngle={steeringCmd?.rear_steering_angle}
        leftSpeed={steeringCmd?.rear_left_wheel_speed} rightSpeed={steeringCmd?.rear_right_wheel_speed}/>
      {settings?.showRowFollowing && <>
        <EstimatedTrackTrend topic="/main/cropline_transformer/estimated_track_trend"/>
        <CroplineMatcher topic="/main/cropline_transformer/info"/>
      </>}
    </Group>
    <TaurusControllerInfo wheelBase={wheelBase} fixed={fixed}/>
  </>
}


export const KonvaHtmlRos: React.FC<any> = ({...props}: any) => <KonvaHtml contexts={[DeveloperModeContext, RosHandleContext]} {...props}/>


export const KonvaMarker: React.FC<any> = ({m, scale, ...props}) => {
  // all our markers are published in every message, so we can probably ignore rviz's state management concept
  if (m.action !== Action.ADD) {
    return null
  }

  if (m.type == Type.LINE_STRIP) {
    return <Line points={m.points.flatMap((p: any) => [p.x, p.y])} stroke={color_to_rgba(m.color)} strokeWidth={m.scale.x} key={`${m.id}-${m.ns}`} {...props}/>
  }

  if (m.type == Type.SPHERE_LIST) {
    return <>
      {m.points.map((p: any, i: number) => <Circle 
        radius={(m.scale.x || 0.1) / 2.} key={i}
        x={p.x} y={p.y} 
        fill={color_to_rgba(m.color)} opacity={m.color.a || 1.0}
        {...props}
      />)}
    </>
  }

  if (m.type == Type.CUBE_LIST) {
    const height = m.scale.y || 0.1
    const width = m.scale.x || 0.1
    return <>
      {m.points.map((p: any, i: number) => <Rect
        height={height} width={width} key={i}
        x={p.x - width / 2.} y={p.y - height / 2.}
        fill={color_to_rgba(m.color)} opacity={m.color.a || 1.0}
        {...props}
      />)}
    </>
  }

  if (m.type == Type.ARROW) {
    if (m.points.length < 2) {
      return null
    }
    const color = color_to_rgba(m.color);
    return <Arrow points={m.points.flatMap((p: any) => [p.x, p.y])} stroke={color} fill={color} strokeWidth={m.scale.x} key={`${m.id}-${m.ns}`} pointerLength={m.scale.z} pointerWidth={m.scale.y} {...props}/>
  }

  if (m.type == Type.TEXT_VIEW_FACING) {
    return <Text 
      x={m.pose.position.x-5 * scale}
      y={m.pose.position.y-5 * scale}
      width={10*scale}
      height={10*scale}
      text={m.text}
      fontSize={scale * 0.03 * m.scale.z}
      align="center" verticalAlign="middle"
      fill={color_to_rgba(m.color)} />
  }
  // no other marker type visualizations for now
  return null
}
