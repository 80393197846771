/*
Fix object to use it directly in hasura insertion mutation, e.g.:
- for objects: use <object_key>_id with an int to create the proper relationship
- remove elements that will change for insert (s.a. id and created at)
- for arrays: add the data key needed for insertion

Example
Input: {"planned_ha":1.4,"planned_herbicide_savings":0.7635700000000001,"planned_volume_L":66.20039999999997,"volume_after_filling_L":66.20039999999997, "simulation_name":"configurator",
"ingredients":[
    {"amount":0.33100199999999985,"ingredient":{"id":28,"unit":"L","default_amount_per_ha":null,"name_DE":"Grasser 100","name_PL":null,"name_EN":null,"__typename":"ingredients"}},
    {"amount":0.6620039999999997,"ingredient":{"id":33,"unit":"L","default_amount_per_ha":null,"name_DE":"Kantor","name_PL":null,"name_EN":null,"__typename":"ingredients"}}],
"taurus_id":38}

Output: {"planned_ha":1.4,"planned_herbicide_savings":0.7635700000000001,"planned_volume_L":66.20039999999997,"volume_after_filling_L":66.20039999999997,"simulation_name":"configurator",
"ingredients":{"data":[
    {"amount":0.33100199999999985,"ingredient_id":28},
    {"amount":0.6620039999999997,"ingredient_id":33}]},
"taurus_id":38}
*/


export const fixObjectForHasuraMany2ManyInsert = (data: any) => {
    return Object.fromEntries(Object.entries(data)
        .filter(([k, v]: any) => !["__typename", "created_at", "updated_at", "id"].includes(k))  // Remove keys that need to be new with insertion
        .map(([k, v]: any): any => {
            if (Array.isArray(v)) {
                return [k, {data: v.map(fixObjectForHasuraMany2ManyInsert)}]  // Add data key (Hasura GraphQL convention)
            }
            else if (v && typeof v === 'object') {
                return [k + "_id", v?.id]
            }
            else {
                return [k, v]
            }
        })
    )
}