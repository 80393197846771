import { IonButton, IonIcon, IonInput, IonItemDivider } from '@ionic/react';
import { caretBack, caretForward } from 'ionicons/icons';
import React, {useState} from 'react';


export const usePagination = (initialLimit = 10) => {
    const [state, setState] = useState({
        limit: initialLimit,
        offset: 0,
    })
    return {state, setState}
}


export const Pagination: React.FC<any> = ({state, setState, itemType, numItems, totalItems}: any) => {
    const page = Math.round(state.offset / state.limit) + 1
    const setPage = (newPage: number) => setState((prev: any) => ({...prev, offset: (newPage - 1) * state.limit}))
    return <IonItemDivider>
        {page > 1 && <IonButton fill="outline" onClick={() => setPage(page - 1)} slot="start"><IonIcon icon={caretBack}/>{page - 1}</IonButton>}
        <div><IonInput style={{textAlign: "end"}}
            value={state.limit} onIonChange={(e: any) => setState((prev: any) => ({...prev, limit: parseInt(e.detail.value)}))} debounce={500}
        /></div>
        {numItems == state.limit && <>  {/* Only increase page or limit if limit is not yet reached */}
            <IonButton size="small" fill="clear" onClick={() => setState((prev: any) => ({...prev, limit: prev.limit * 2}))} slot="end">Show more</IonButton>
            <IonButton fill="outline" onClick={() => setPage(page + 1)} slot="end">{page + 1}<IonIcon icon={caretForward}/></IonButton>
        </>}
        <pre> </pre>{itemType}
        {totalItems > 0 && <> / {totalItems}</>}
    </IonItemDivider>
}