import React, { useRef, useEffect } from "react";

const RangeMarkers = ({
  steps,
  width,
  min,
  max,
}: {
  steps: number;
  width: number;
  min: number;
  max: number;
}) => {
  const range = max - min;
  const markers = [];
  for (let i = 0; i <= steps; i++) {
    const value = min + (i / steps) * range;
    markers.push(
      <div
        key={i}
        style={{
          position: "relative",
          left: `${(value / width) * 100}%`,
          fontSize: 12,
        }}
      >
        {value.toFixed(2)}
      </div>
    );
  }
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {markers}
    </div>
  );
};

const ColorMap = ({ minValue, maxValue }: any) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const colorMapWidth = 500;


  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext("2d");
      if (context) {
        const gradient = context.createLinearGradient(0, 0, canvas.width, 0);
        gradient.addColorStop(0, "red");
        gradient.addColorStop(0.17, "orange");
        gradient.addColorStop(0.34, "yellow");
        gradient.addColorStop(0.51, "green");
        gradient.addColorStop(0.68, "blue");
        gradient.addColorStop(0.85, "indigo");
        gradient.addColorStop(1, "violet");

        context.fillStyle = gradient;
        context.fillRect(0, 0, canvas.width, canvas.height);

      }
    }
  }, [minValue, maxValue]);

  return (
    <div style={{ padding: "0  20px", textAlign: "center" }}>
      <p>Range of Height Map</p>
      <canvas ref={canvasRef} width={colorMapWidth} height={70} />
      <RangeMarkers
        steps={5}
        width={colorMapWidth}
        min={minValue}
        max={maxValue}
      />
    </div>
  );
};

export default ColorMap;
