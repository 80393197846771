import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonItem, IonLabel, IonLoading, IonProgressBar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import Image from '../../components/Image';
import { AccessType, getAccessType, useCallService, useParam, useTopic } from '../../hooks/rosHooks';
import { useTranslation } from 'react-i18next';

const Error: React.FC<any> = (msg : String) => {
    return <IonCard style={{"height": "100%"}}>
            <IonCardContent>
                <div style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    color: "black",
                    fontSize: "120%"
                }}>
                    { msg }
                </div>
            </IonCardContent>
        </IonCard>
}

function prettyRowName(row : string, num_rows : Number) : string {
    if (num_rows == 1) {
        return "row"
    } else if (num_rows == 2) {
        return row == "rowI"?"left":"right"
    } else if (num_rows == 3) {
        return ({"rowI": "left", "rowII": "center", "rowIII": "right"}[row]) as string
    } else if (num_rows == 4) {
        return ({"rowI":"outer left", "rowII":"inner left", "rowIII":"inner right", "rowIV":"outer right"}[row]) as string
    } else if (num_rows == 5) {
        return {"rowI":"outer left", "rowII":"inner left", "rowIII":"center", "rowIV":"inner right", "rowV":"outer right"}[row] as string
    } else if (num_rows == 6) {
        return {"rowI":"outer left", "rowII":"left", "rowIII":"inner left", "rowIV":"inner right", "rowV":"right", "rowVI": "outer right"}[row] as string
    }
    return row
}

const MIN_OBSERVATIONS = 5;

enum CalibrationViewState {
    Overview,
    TalpaSelected
}

enum SideOfRow {
    Left = "left",
    Right = "right"
}

interface CalibrationPoint {
    talpa_id: number
    side: 'left'|'right'
    talpa_angle: number
    marker_x: number
    marker_y: number
    marker_angle: number
    id: number
}

interface StringMsg {
    data: string
}

// <json structure of /talpa_calibration_wizard_state topic>
export interface TalpaCalibrationWizardState {
    recorded_data:    RecordedDatum[];
    talpa_angles:     { [key: string]: number };
    marker_transform: ([number, number, number, string] | null);
    desired_depths: { [key: string]: number };
    unhomed_talpas: string[];
}

export interface RecordedDatum {
    talpa_id:             number;
    side:                 'left' | 'right';
    talpa_angle:          number;
    marker_x:             number;
    marker_y:             number;
    marker_angle:         number;
    raw_marker_transform: RawMarkerTransform;
    id:                   number;
}

export interface RawMarkerTransform {
    header:         Header;
    child_frame_id: string;
    transform:      Transform;
}

export interface Header {
    seq:      number;
    stamp:    Stamp;
    frame_id: string;
}

export interface Stamp {
    secs:  number;
    nsecs: number;
}

export interface Transform {
    translation: Vec3D;
    rotation:    Quaternion;
}

export interface Vec3D {
    x: number;
    y: number;
    z: number;
}

export interface Quaternion {
    x: number;
    y: number;
    z: number;
    w: number;
}

// </json structure of /talpa_calibration_wizard_state topic>

function deg2rad(deg : number) : number {
    return Math.PI * deg / 180.0
}

function rad2deg(rad : number) : number {
    return 180 * rad / Math.PI
}

class Channel<T> {
    subscribers : Map<string, (arg : T) => void>;

    constructor() {
        this.subscribers = new Map();
    }

    publish(data: T) {
        for (const [_name, cb] of this.subscribers.entries()) {
            cb(data);
        }
    }

    subscribe(name : string, cb : (arg : T) => void) {
        this.subscribers.set(name, cb);
    }

    unsubscribe(name : string) {
        this.subscribers.delete(name);
    }
}

const AdjustHeightTalpaRow : React.FC<{row_name : string, current_desired_depth : number, set_desired_depth : Function, set_all_channel : Channel<number>}> = (props) => {
    const [desired_depth_gui, set_desired_depth_gui] = useState(props.current_desired_depth);
    const position_ok = Math.abs(desired_depth_gui - props.current_desired_depth) < 0.0001;
    const { t } = useTranslation();

    function set_desired_depth(new_depth: number) {
        set_desired_depth_gui(new_depth);
        props.set_desired_depth(props.row_name, new_depth);
    }

    useEffect(()=> {
        props.set_all_channel.subscribe(props.row_name, set_desired_depth);
        return ()=>props.set_all_channel.unsubscribe(props.row_name);
    });

    return <tr style={{lineHeight: '4em', verticalAlign: 'middle'}}>
        <td>{props.row_name}</td>
        <td><IonButton onClick={_=>set_desired_depth(desired_depth_gui+0.01)}>↑</IonButton></td>
        <td style={{backgroundColor: position_ok?'white':'lightyellow'}}>{(desired_depth_gui*100).toFixed(0)} cm</td>
        <td><IonButton onClick={_=>set_desired_depth(desired_depth_gui-0.01)}>↓</IonButton></td>
        <td><IonButton onClick={_=>props.set_all_channel.publish(desired_depth_gui)}>{t("all")}</IonButton></td>
    </tr>
}

const AdjustHeight : React.FC<{talpa_calibration_json : undefined | string}> = ({talpa_calibration_json}) => {
    const callService = useCallService();
    const [set_all_channel] = useState(()=>new Channel<number>());
    if (talpa_calibration_json === undefined) {
        return <p>loading...</p>
    }
    const state: TalpaCalibrationWizardState = JSON.parse(talpa_calibration_json);

    function set_desired_depth(row_name : string, desired_depth : number) {
        callService("/talpa_calibration/set_desired_depth", "talpa_calibration_wizard/AdjustDesiredDepth", {row_name, desired_depth}, (res)=>{!res.success && alert('error setting depth')})
    }

    return <div style={{minHeight: '100%', padding: '1em'}}>
        <h1>Talpa height adjustment</h1>
        {Object.entries(state.desired_depths).length > 0 && <>
            <p>Adjust talpa depths so that the hoes are just above the ground:</p>
            <table style={{width: '100%'}}>
                {Object.entries(state.desired_depths).map(([row_name, current_desired_depth])=><AdjustHeightTalpaRow row_name={row_name} current_desired_depth={current_desired_depth} set_desired_depth={set_desired_depth} set_all_channel={set_all_channel} key={row_name}/>)}
            </table>
        </>}
        
        <p>Press E-Stop to start calibrating.</p>
    </div>
}

const TalpaCalibrationSection: React.FC<any> = ({}) => {

    const {value: rows_param} = useParam("/rows");
    const num_rows : number = rows_param === undefined ? 0 : Object.entries(rows_param).length;
    const estop = useTopic("/estop", "std_msgs/Bool");
    const { t } = useTranslation()
    
    const [viewState, setViewState] = useState<CalibrationViewState>(CalibrationViewState.Overview);

    const [selectedTalpa, setSelectedTalpa] = useState({talpa_id: 0, row: "rowI", side: SideOfRow.Left, hardwareVersion: 4});

    const wizard_state_json : (StringMsg | undefined) = useTopic("/talpa_calibration_wizard_state", "std_msgs/String");

    const callService = useCallService();

    function addCalibrationPoint() {
        callService("/add_talpa_calibration_point", "talpa_calibration_wizard/AddTalpaCalibrationPoint", {talpa_id: selectedTalpa.talpa_id, side: selectedTalpa.side==SideOfRow.Left?'left':'right'})
    }

    function deleteCalibrationPoint(id : number) {
        callService("/delete_talpa_calibration_point", "talpa_calibration_wizard/DeleteTalpaCalibrationPoint", { id })
    }

    function saveCalibrationData() {
        callService("/talpa_calibration_wizard_save", "std_srvs/Trigger", {}, res=>alert((res["success"]?'✅ ':'❌ ') + res["message"]))
    }

    const set_mode_name = getAccessType()===AccessType.RemoteHttps?"/main/drive_mode_switch/set_mode_remote":"/main/drive_mode_switch/set_mode_local";
    const [loadingCalibrationMode, setLoadingCalibrationMode] = useState(false);
    function startTalpaCalibrationMode() {
        setLoadingCalibrationMode(true);
        callService(set_mode_name, "weeding_drive_mode_switch/SetMode", {mode: "calibrate_talpas"})
    }

    if (estop === undefined || rows_param === undefined) {
        return Error("Loading...")
    }

    if (wizard_state_json === undefined) {
        return <div style={{height: '100%', padding: '0 1em'}}>
            <h1>{t('Talpa Calibration')}</h1>
            {loadingCalibrationMode && "loading..." ||
            <IonButton onClick={_=>{
                startTalpaCalibrationMode()
            }}>{t('Enter calibration mode')}</IonButton>
            }
        </div>
    }

    if (estop.data !== true) {
        return <AdjustHeight talpa_calibration_json={wizard_state_json.data} />
    }

    let cam_ns = "cama";
    try {
        cam_ns = rows_param[selectedTalpa.row]["aquila"]["namespace"];
    } catch {
        console.error(`no camera for ${selectedTalpa.row}`)
    }

    const wizard_state : TalpaCalibrationWizardState = JSON.parse(wizard_state_json.data);
    Object.assign(window, {wizard_state})

    const recorded_data : CalibrationPoint[] = wizard_state.recorded_data;
    const talpa_angles : Map<string, number> = new Map(Object.entries(wizard_state.talpa_angles));
    const current_talpa_angle = talpa_angles.get(`${selectedTalpa.talpa_id}_${selectedTalpa.side}`);
    let current_talpa_angle_formatted = current_talpa_angle !== undefined ? rad2deg(current_talpa_angle).toFixed(1) + ' °' : '(no data from talpa)'
    if (current_talpa_angle_formatted.length < 6) {
        current_talpa_angle_formatted = `\xa0${current_talpa_angle_formatted}`;
    }

    const marker_transform = wizard_state.marker_transform
    const marker_ok = !!marker_transform && (marker_transform[3] === `${cam_ns}_jai_optical_frame`);
    const cannot_add_calibration_point = !marker_ok || current_talpa_angle === undefined || wizard_state.unhomed_talpas.length > 0;

    if (viewState == CalibrationViewState.TalpaSelected) {
        const progress = wizard_state.recorded_data.filter(p=>((p.talpa_id === selectedTalpa.talpa_id) && (p.side === selectedTalpa.side))).length;
        const progress_frac = Math.min(1.0, progress / MIN_OBSERVATIONS);
        const smallScreen = window.visualViewport!.width<500;
        return <IonCard style={{height: "100%", overflowY: "scroll"}}>
            <IonCardHeader>
                <IonCardTitle style={{textAlign: "center"}}>Calibrating TLP {selectedTalpa.talpa_id} ({selectedTalpa.side})</IonCardTitle>
                <IonCardSubtitle style={{cursor: "pointer"}} onClick={_evt=>setViewState(CalibrationViewState.Overview)}>← back to overview</IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
                <div style={{display: "flex", flexDirection: smallScreen?"column-reverse":"row"}}>
                    <div><Image showUi={false} presetTopic={`/${cam_ns}/aruco_detection`} /></div>
                    <div style={{paddingLeft: "1em", paddingRight: "1em", width: smallScreen?"100%":"50%"}}>
                        <p>{marker_ok?"✅ marker detected":"❌ no marker"}</p>
                        <p>{wizard_state.unhomed_talpas.length===0?"✅ all talpas are homed":`❌ unhomed talpas: ${wizard_state.unhomed_talpas.join(', ')}. Please switch to manual mode, move talpas up and wait for homing.`}</p>
                        <hr style={{borderTop: "1px solid #ccc"}}/>
                        <p>Please adjust the talpa's arm so that the tip of the in-row tool hovers above the {(selectedTalpa.hardwareVersion >= 7)?("-61 cm"):(selectedTalpa.side==SideOfRow.Left?"-104 cm":"-81 cm")} point.</p>
                        <p>At least four calibration points are needed for each talpa. Please vary the position of the calibration board for each recorded calibration point.</p>
                    </div>
                </div>
                <h2 style={{marginTop: "1em"}}>recorded calibration points:</h2>
                <div style={{marginBottom: "1em", display: 'flex', alignItems: "center"}}>
                    <span style={{whiteSpace: 'nowrap', paddingRight: "1em"}}>progress: {progress}/{MIN_OBSERVATIONS}</span>
                    <IonProgressBar value={progress_frac} />
                </div>
                <table style={{width: "100%"}}>
                    <thead>
                        <tr style={{textAlign: "left"}}>
                            <th style={{width: 0, whiteSpace: "nowrap", paddingRight: "1em"}}>talpa angle</th>
                            <th>marker x/y/&phi;</th>
                            <th></th></tr>
                    </thead>
                    <tbody>
                        <tr style={{borderTop: "1px solid", borderTopColor: "#444", height: "3em"}}>
                            <td style={{fontFamily: "monospace"}}>
                                {current_talpa_angle_formatted}
                            </td>
                            <td style={{fontFamily: "monospace"}}>{marker_transform === null ? "(no marker)" : `${(100*marker_transform[0]).toFixed(1)} cm / ${(100*marker_transform[1]).toFixed(1)} cm / ${rad2deg(marker_transform[2]).toFixed(1)} °`}</td>
                            <td style={{textAlign: "right"}}>
                                <IonButton disabled={(cannot_add_calibration_point)} onClick={addCalibrationPoint}>add calibration point</IonButton>
                            </td>
                        </tr>

                        {recorded_data.filter(e=>e.talpa_id==selectedTalpa.talpa_id && e.side==selectedTalpa.side).reverse().map((e, idx)=>
                        <tr key={e.id} style={{borderTop: "1px solid", borderTopColor: idx==0?"#444":"#ccc", height: "3em"}}>
                            <td>{rad2deg(e.talpa_angle).toFixed(2)} °</td>
                            <td>{(e.marker_x*100).toFixed(1)} cm / {(e.marker_y*100).toFixed(1)} cm / {rad2deg(e.marker_angle).toFixed(2)} °</td>
                            <td style={{textAlign: "right"}}>
                                <span
                                onClick={_evt=>deleteCalibrationPoint(e.id)}
                                style={{
                                    color: "white",
                                    fontWeight: "bolder",
                                    background: "#eb445a",
                                    width: "2em",
                                    height: "2em",
                                    borderRadius: "1em",
                                    display: "inline-flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    boxShadow: "0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12)"
                                }}>×</span>
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </IonCardContent>
        </IonCard>
    } else if (viewState == CalibrationViewState.Overview) {
        return <IonCard style={{height: "100%", overflowY: "scroll"}}>
            <IonCardHeader>
                <IonCardTitle style={{textAlign: "center"}}>{t("Select Talpa to calibrate:")}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <table style={{width: "100%"}}>
                    <thead style={{fontWeight: "bold", color: "black", height: "3em", borderBottom: "1px solid black", textAlign: "left"}}>
                        <tr>
                            <th>row</th>
                            <th>talpa</th>
                            <th>progress</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows_param && Object.entries(rows_param).map(e=>{
                            const row : string = e[0];
                            interface TalpaToCalibrate {
                                id : number,
                                side : SideOfRow,
                                hardware_version : number
                            }
                            let talpas : TalpaToCalibrate[] = [];

                            if (Object.hasOwn((e[1] as any), "left")) {
                                talpas.push({id: (e[1] as any).left.id as number, side: SideOfRow.Left, hardware_version: (e[1] as any).left.hardware_version_id})
                            }
                            if (Object.hasOwn((e[1] as any), "right")) {
                                talpas.push({id: (e[1] as any).right.id as number, side: SideOfRow.Right, hardware_version: (e[1] as any).right.hardware_version_id})
                            }
                            if (Object.hasOwn((e[1] as any), "both")) {
                                talpas.push({id: (e[1] as any).both.id as number, side: SideOfRow.Left, hardware_version: (e[1] as any).both.hardware_version_id})
                                talpas.push({id: (e[1] as any).both.id as number, side: SideOfRow.Right, hardware_version: (e[1] as any).both.hardware_version_id})
                            }
                            
                            return <>
                                {talpas.map((tlp, idx)=>{
                                    const progress = wizard_state.recorded_data.filter(p=>((p.talpa_id === tlp.id) && (p.side === tlp.side))).length;
                                    return <tr 
                                        style={{cursor: "pointer", height: "3em", verticalAlign: "center", borderTop: "1px solid", borderTopColor: idx?"#ccc":"#444"}}
                                        onClick={_=>{
                                            setSelectedTalpa({talpa_id: tlp.id, row, side: tlp.side, hardwareVersion: tlp.hardware_version})
                                            setViewState(CalibrationViewState.TalpaSelected)
                                        }}
                                        >
                                        <td>{prettyRowName(row as string, num_rows)}</td>
                                        <td>TLP {tlp.id} {tlp.side==SideOfRow.Left?'↱ left':'↰ right'}</td>
                                        <td>{progress}/{MIN_OBSERVATIONS} <IonProgressBar value={Math.min(1.0, progress / MIN_OBSERVATIONS)} /></td>
                                    </tr>
                                    }
                                )}
                            </>
                        })}
                    </tbody>
                </table>
                <IonButton onClick={saveCalibrationData}>{t("Save calibration data to disk")}</IonButton>
            </IonCardContent>
        </IonCard>
    }

    throw Error("invalid calibration view state")
    
};

export default TalpaCalibrationSection;