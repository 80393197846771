import { IonCardHeader, IonCardTitle, IonCard, IonCardContent, IonItem, IonSegment, IonSegmentButton, IonLabel, IonButton, IonIcon, IonList, IonSpinner, IonCheckbox, IonRange, IonProgressBar, IonRow } from '@ionic/react';
import React, {useEffect, useState} from 'react';
import {Stage, Layer, Line, Arrow, Text, Image, Circle, Label, Tag} from 'react-konva';
import { close, eye, sparkles } from 'ionicons/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import getMaxRole from '../lib/getMaxRole';
import { useMutationWithElevatedRole } from '../hooks/hasuraHooks';



export const AddRuleButton: React.FC<any> = ({param_config_id, tag_ids, plant_variety_id}: any) => {
  const [insertRule] = useMutationWithElevatedRole(gql`
mutation InsertRule($object: param_config_rules_insert_input!) {
  insert_param_config_rules_one(object: $object) {
    id
  }
}
  `, {variables: {"object": {
    "recommended_param_config_id": param_config_id, "plant_variety_id": plant_variety_id, "tags": {"data": tag_ids.map((id: number) => ({"tag_id": id}))},
  }},
  context: {
    headers: {
      "x-hasura-role": getMaxRole()
    }
  }
})
  return <IonButton fill="clear" onClick={() => insertRule()}><IonIcon icon={sparkles} slot="start"/>Add rule</IonButton>
}