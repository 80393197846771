import { IonIcon, IonButton } from '@ionic/react';
import React from 'react';
import { pencil } from 'ionicons/icons';

const EditButton: React.FC<any> = ({...props}) => {
    props.fill = props.fill || "clear"

    return <IonButton {...props}>
        <IonIcon icon={pencil} slot="start"/>
        edit
    </IonButton>
};

export default EditButton;
