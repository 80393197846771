import { IonBadge, IonButton, IonCard, IonCardContent, IonChip, IonContent, IonIcon, IonItem, IonModal, IonSegment, IonSegmentButton, IonSelect, IonSelectOption } from '@ionic/react';
import { chevronDown, chevronUp } from 'ionicons/icons';
import React, { useState } from 'react';
import { StatusContent, StatusDetails } from './Status';
import useWindowDimensions from '../useWindowDimensions';


// Filter utils
export const statusPrios: any = {
  "ERROR": 0,
  "STALE": 1,
  "WARN": 2,
  "TRANSITIONING": 3,
  "OK": 4,
}
export const statusCompValue = (status: any) => statusPrios[status.level] + (status.disabled ? 10 : 0)


export const StatusEventItem: React.FC<any> = ({event, collapsable = true, ...props}) => {
  const [expanded, setExpanded] = useState(false)
  const {isLargeScreen} = useWindowDimensions()
  if (!event?.diagnostics?.statuses) {return null}
  const sortedStatuses = event.diagnostics.statuses.sort((a: any, b: any) => statusCompValue(a) - statusCompValue(b))
  const firstStatus = sortedStatuses[0]
  const errorCount = sortedStatuses.filter((status: any) => statusPrios[status.level] < 2 && !status.disabled).length
  const warnCount = sortedStatuses.filter((status: any) => statusPrios[status.level] == 2 && !status.disabled).length

  const headerItem = <IonItem lines={"full"} onClick={() => setExpanded(!expanded)} {...props}>
    {isLargeScreen && firstStatus.level !== "OK" && <IonItem>
      <StatusContent status={firstStatus}/>
      {errorCount > 1 && <IonBadge color="danger">+ {errorCount - 1} errors</IonBadge>}
      {warnCount > 1 && <IonBadge color="warning">+ {warnCount - 1} warnings</IonBadge>}
    </IonItem>}
  </IonItem>
 
  if (!expanded && collapsable) {
    return headerItem
  }
  else {
    return <IonCard style={{margin: "15px"}}>
      {headerItem}
      <IonCardContent>
        <StatusList statuses={sortedStatuses}/>
      </IonCardContent>
    </IonCard>
  }
}



export const StatusList: React.FC<any> = ({statuses}) => {
  const [mode, setMode] = useState<"errors and warnings"|"all"|"disabled">("errors and warnings")
  
  const filteredStatuses = statuses.filter((status: any) => {
    if (mode === "errors and warnings") return ["ERROR", "STALE", "WARN"].includes(status.level) && !status.disabled
    if (mode === "all") return !status.disabled
    if (mode === "disabled") return true
  })
  return <>
    <IonItem>
      <IonSelect value={mode} onIonChange={(e: any) => setMode(e.detail.value)}>
        {["errors and warnings", "all", "disabled"].map((m: string) => <IonSelectOption value={m}>Show {m}</IonSelectOption>)}
      </IonSelect>
    </IonItem>
    {
      filteredStatuses.map((status: any, i: number) => <StatusItem status={status} key={i}/>)
    }
  </>
}


export const StatusItem: React.FC<any> = ({status}) => {
  const [expanded, setExpanded] = useState(false)
  const item = <IonItem onClick={() => setExpanded(true)}>
    <StatusContent status={status}/>
  </IonItem>
  return <>
    {item}
    <IonModal isOpen={expanded} onDidDismiss={() => setExpanded(false)} className="fullscreen">
      <StatusDetails status={status} itemContent={item} onClose={() => setExpanded(false)}/>
    </IonModal>
  </>
}


export const DiagnosticMsgItem: React.FC<any> = ({msg, ...props}) => {
  const [expanded, setExpanded] = useState(false)
  const sortedStatuses = msg.statuses.sort((a: any, b: any) => statusCompValue(a) - statusCompValue(b))
  const firstStatus = sortedStatuses[0]
  const errorCount = sortedStatuses.filter((status: any) => statusPrios[status.level] < 2 && !status.disabled).length
  const warnCount = sortedStatuses.filter((status: any) => statusPrios[status.level] == 2 && !status.disabled).length
  const {isLargeScreen} = useWindowDimensions()

  const headerItem = <IonItem lines={"full"} onClick={() => setExpanded(!expanded)} {...props}>
    {msg?.stamp?.secs && <IonChip>{new Date(msg.stamp.secs * 1000).toISOString()}</IonChip>}
    {isLargeScreen && firstStatus.level !== "OK" && <IonItem>
      <StatusContent status={firstStatus}/>
      {errorCount > 1 && <IonBadge color="danger">+ {errorCount - 1} errors</IonBadge>}
      {warnCount > 1 && <IonBadge color="warning">+ {warnCount - 1} warnings</IonBadge>}
    </IonItem>}
    {isLargeScreen && <IonButton onClick={() => setExpanded(!expanded)} fill="clear" slot="end">
      <IonIcon icon={expanded ? chevronUp : chevronDown } slot={"icon-only"}/>
    </IonButton>}
  </IonItem>
 
  if (!expanded) {
    return headerItem
  }
  else {
    return <IonCard style={{margin: "15px"}}>
      {headerItem}
      <IonCardContent>
        <StatusList statuses={sortedStatuses}/>
      </IonCardContent>
    </IonCard>
  }
}