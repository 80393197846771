import { IonBadge, IonButton, IonCard, IonCardContent, IonContent, IonDatetime, IonIcon, IonItem, IonLabel, IonList, IonModal, IonPopover, IonToggle, } from '@ionic/react';
import { ban, checkmarkCircle, close, list, reloadCircle, } from 'ionicons/icons';
import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useOverrideParams, } from '../hooks/rosHooks';
import { useArea } from '../hooks/useArea';
import { useFieldConfig } from '../hooks/useFieldConfig';
import { useSharedTopic } from '../hooks/useSharedTopic';
import { BackButton } from './BackButton';
import { ErrorBadge } from './ErrorBadge';
import { ParamSetter } from './OvalParams';
const moment = require('moment');


export const PathInfo: React.FC<any> = ({path, color, name}) => {
  const {t, i18n} = useTranslation()
  return <>
    <IonBadge style={{background: color}}>
      {name}
    </IonBadge>
    <IonBadge color={path.number % 2 === 0 ? "dark": undefined}>{t(`${path.type}`)} {path.number > 0 && path.number}</IonBadge>
    {path.created_at && <IonBadge>{moment(path.created_at).lang(i18n.language).fromNow()}</IonBadge>}
  </>
}


export const usePathWidthInfo = () =>{
  const { globalConfig: config } = useFieldConfig()
  if (!config) {
    return null
  }
  if (config.has_variable_interrow_distance) {
    return {width: config.interbed_distance, type: "bed"}
  }
  else {
    return {width: config.interrow_distance, type: "row"}
  }
}

export const usePathPlanner = () => {
  const state = useSharedTopic("/path_planner_state", "weeding_field_manager/PathPlannerState")
  if (!state) {return null}
  if (state.error_json.length > 0) {
    return {errorJson: state.error_json}
  }
  const nDonePaths = state.paths.filter((p: any) => !["new", "last treatment too old", "skipped"].includes(p.source)).length
  const nTodoPaths = state.paths.filter((p: any) => !["skipped"].includes(p.source)).length
  return {
    state, nDonePaths,
    allPathsDone: nDonePaths > 0 && nDonePaths === nTodoPaths,
  }
}


export const PathPlanner: React.FC<any> = ({}) => {
  const pathPlanner = usePathPlanner()
  const [edit, setEdit] = useState(false)
  const {t} = useTranslation()
  if (!pathPlanner) {return null}
  if (pathPlanner.errorJson) return <IonItem><ErrorBadge errorJson={pathPlanner.errorJson}/></IonItem>
  return <>
    <IonButton fill="clear" onClick={() => setEdit(true)}>
      <IonIcon icon={list} slot="start"/>
      {t("Treatment planner")}
    </IonButton>
    {pathPlanner.allPathsDone && <TreatAllPathsAgainButton/>}
    <IonModal isOpen={edit} onDidDismiss={() => setEdit(false)}>
      <PathPlannerContent state={pathPlanner.state} />
      <div style={{display: "flex"}}>
        <BackButton onClick={() => setEdit(false)}/>
      </div>
    </IonModal>
  </>
}


export const Source: React.FC<any> = ({source, ...props}: any) => {
  const { t } = useTranslation();
  if (source === "last treatment too old") return <IonIcon icon={reloadCircle} {...props}/>
  if (source === "error") return <IonBadge color="danger" {...props}>Error</IonBadge>
  if (source === "skipped") return <IonIcon color="danger" icon={ban} {...props}/>
  if (source === "new") return <IonBadge color="light" {...props}>{t("new")}</IonBadge>
  if (source === "treated recently") return <IonIcon icon={checkmarkCircle} {...props}/>
  return null
}


export const TreatAllPathsAgainButton: React.FC<any> = ({...props}: any) => {
  const {setParams} = useOverrideParams()
  const { t } = useTranslation();
  const now  = "date:" + (new Date()).toISOString().replace("Z", "000+00:00")  // Show with timezone (Z => +00:00, consistent with Hasura / python datetime)
  return <IonButton {...props} onClick={(e: any) => {e.stopPropagation(); setParams({"/path_planner/cutoff_date": now})}}>
    {t("Treat all paths again")}
  </IonButton>
}


export const PathPlannerContent: React.FC<any> = ({state}: any) => {
  // The date: prefix is used to regard this as a string (ros param server otherwise gets into bug considering the parameter as a date)
  const now  = "date:" + (new Date()).toISOString().replace("Z", "000+00:00")  // Show with timezone (Z => +00:00, consistent with Hasura / python datetime)
  const {setParams, resultingParams } = useOverrideParams()
  const { t } = useTranslation();

  const areas = useSharedTopic("/area_polygons", "weeding_field_manager/AreaPolygons")?.areas
  const mergedArea_ha = (areas?.find(({area_id}: any) => area_id === 0)?.area_m2 || 0.0) / 1e4

  return <IonContent>
    <IonList>
     
      <IonCard style={{border: "solid 1px"}}><IonCardContent>
        <ParamSetter
            paramKey={"/path_planner/cutoff_date"}
            defaultValue={now} resultingParams={resultingParams} setParams={setParams}
            component={({tempValue, setParam}: any) => <IonItem>
              <IonLabel position="floating">{t("Start of treatment")}</IonLabel>
              <IonDatetime value={tempValue?.replace("date:", "")} presentation="date-time" onIonChange={(e: any) => setParam("date:" + e.detail.value)}/>
            </IonItem>}
        />
        <TreatAllPathsAgainButton expand="block"/>
        <IonItem lines="none">
          <IonLabel>{t("Restart at end")}</IonLabel>
          <ParamSetter
              paramKey={"/path_planner/restart_at_end"}
              defaultValue={false} resultingParams={resultingParams} setParams={setParams}
              component={({tempValue, setParam}: any) => <IonToggle
                checked={tempValue} onIonChange={(e: any) => setParam(e.detail.checked)}
              />}
          />
        </IonItem>
      </IonCardContent></IonCard>
      
      <IonItem>
        <FieldProgress paths={state.paths} areaHa={mergedArea_ha} />
      </IonItem>
      <ParamSetter
          paramKey={"/path_planner/config_json"}
          defaultValue={"{}"} resultingParams={resultingParams} setParams={setParams}
          component={({tempValue, setParam}: any) => <AreasConfig
            configJson={tempValue} setConfigJson={setParam} allPaths={state.paths}
            checked={tempValue} onIonChange={(e: any) => setParam(e.detail.checked)}
          />}
      />
    </IonList>
  </IonContent>
}


const parseJsonDictSafe = (jsonString: string) => {
  // Catch if error in json and return empty dict
  try {
    return JSON.parse(jsonString)
  }
  catch {
    return {}
  }
}

export const AreasConfig: React.FC<any> = ({allPaths, configJson, setConfigJson}: any) => {
  // Parameter on ROS param server is a json string => parse it
  const config = parseJsonDictSafe(configJson)
  const setConfig = (newConfig: any) => setConfigJson(JSON.stringify(newConfig))
  // Group paths by area
  const groupedPaths = Object.entries(allPaths.reduce((r: any, el: any) => {
    r[el.area_id] = [...(r[el.area_id] || []), el]  // Add to group
    return r
  }, {})).map(([id, paths]) => [parseInt(id), paths])  // string (because object keys are string) => int for area id
  return <>
    {groupedPaths.map(([area_id, paths]: any) => <AreaPaths key={area_id} areaId={area_id} paths={paths}
      areaConfig={config[area_id] || {}} setAreaConfig={(newConfig: any) => setConfig({...config, [area_id]: newConfig})} />)}
  </>
}


export const averageLength = (paths: any) => {
  // Only use non-zero length paths (paths to be taught-in are skipped)
  const pathsWithLength = paths.filter((p: any) => p.length_m > 0)
  if (pathsWithLength.length == 0) {return 0}  // No paths yet thus no average length
  return pathsWithLength.reduce((total:number, currVal:any) => total + currVal.length_m, 0) / pathsWithLength.length
}


export const FieldProgress: React.FC<any> = ({paths, showPathInfo=false, areaHa}: any) => {
  const pathWidthInfo = usePathWidthInfo()
  const { t } = useTranslation();

  if (!paths) {return null}

  // Non skipped paths: for full sum
  const todoPaths = paths.filter((p: any) => !["skipped"].includes(p.source))
  // Paths that have already been done for this run
  const donePaths = todoPaths.filter((p: any) => !["new", "last treatment too old"].includes(p.source))
  if (!pathWidthInfo) { return null }
  
  if (!areaHa) {
    // Unknown length (e.g. teach-in). Progress is 0 %
    return <>0 % - 0 / {todoPaths.length} {t(pathWidthInfo.type + "s")}</>
  }
  return <>
    {((donePaths.length) / (todoPaths.length) * 100).toFixed(1)} %
    {" - "}
    {(donePaths.length / todoPaths.length * areaHa).toFixed(3)} ha
    /{" "}
    {areaHa.toFixed(3)} ha
    {showPathInfo && <>
      {" - "}
      {donePaths.length} / {todoPaths.length} {t(pathWidthInfo.type + "s")}
    </>}
  </>
}


export const AreaPaths: React.FC<any> = ({areaId, paths, areaConfig, setAreaConfig}: any) => {
  const {name, colorTransparent: color} = useArea(areaId)
  const [showAllPaths, setShowAllPaths] = useState(false)
  const { t } = useTranslation();
  const areas = useSharedTopic("/area_polygons", "weeding_field_manager/AreaPolygons")?.areas
  const area_ha = (areas?.find(({area_id}: any) => area_id === areaId)?.area_m2 || 0.0) / 1e4

  return <IonCard style={{border: `solid 1px ${color}`}}>
    <IonItem lines="none">
      <IonBadge style={{background: color}} slot="start">{name}</IonBadge>
      <IonBadge>
        <FieldProgress paths={paths} areaHa={area_ha}/>
      </IonBadge>
      {areaConfig?.first_row && <IonButton onClick={() => setAreaConfig({...areaConfig, first_row: null})}>
        {t("first row")}: {areaConfig.first_row}
        <IonIcon icon={close} slot="end"/>
      </IonButton>}
      {areaConfig?.last_row && <IonButton onClick={() => setAreaConfig({...areaConfig, last_row: null})}>
        {t("last row")}: {areaConfig.last_row}
        <IonIcon icon={close} slot="end"/>
      </IonButton>}
      <IonToggle slot="end" checked={!areaConfig.skip} onIonChange={(e: any) => setAreaConfig({...areaConfig, skip: !e.detail.checked})}/>
    </IonItem>
    {!areaConfig?.skip && <IonCardContent style={{maxHeight: 300, overflow: "auto"}}>
      <IonList>
        {paths.filter((_: any, i: number) => i < 40 || showAllPaths).map((path: any, i: number) => <Path path={path} key={i} areaConfig={areaConfig} setAreaConfig={setAreaConfig}/>)}
        {!showAllPaths && <IonButton fill="clear" expand="block" onClick={() => setShowAllPaths(true)}>{t("Show all paths")}</IonButton>}
      </IonList>
    </IonCardContent>}
  </IonCard>
}


export const Path: React.FC<any> = ({path, areaConfig, setAreaConfig}: any) => {
  const [event, setEvent] = useState<any>(null)
  const pathName = `${path.type}_${path.number}`
  const skipped = areaConfig?.skip_path_names?.includes(pathName)
  const {colorTransparent: color, name} = useArea(path.area_id)
  const { t, i18n } = useTranslation();
  const toggleSkipped = () => {
    if (skipped) {
      setAreaConfig({...areaConfig, skip_path_names: areaConfig.skip_path_names.filter((pn: string) => pn !== pathName)})
    }
    else {
      setAreaConfig({...areaConfig, skip_path_names: [...(areaConfig?.skip_path_names || []), pathName]})
    }
    setEvent(null)
  }
  return <>
    <IonPopover event={event} isOpen={!!event} onDidDismiss={() => setEvent(null)}>
      <IonButton onClick={toggleSkipped}>{skipped ? t("Un-skip") : t("Skip")}</IonButton>
      <IonButton fill="clear" onClick={() => {setAreaConfig({...areaConfig, first_row: path.number}); setEvent(null)}}>{t("Set as first row")}</IonButton>
      <IonButton fill="clear" onClick={() => {setAreaConfig({...areaConfig, last_row: path.number}); setEvent(null)}}>{t("Set as last row")}</IonButton>
      <IonButton fill="clear" onClick={() => setEvent(null)}>{t("Cancel")}</IonButton>
    </IonPopover>
    <IonItem color={["new", "last treatment too old"].includes(path.source) ? undefined : "medium"} onClick={setEvent}>
      <PathInfo path={path} areaName={name} areaColor={color}/>
      {path.treated_left_side_at.length > 0 && <IonBadge color="secondary">{moment(path.treated_left_side_at).lang(i18n.language).fromNow()}</IonBadge>}
      {skipped && <IonBadge color="danger" slot="end">{t("skipped")}</IonBadge>}
      {path.number < areaConfig?.first_row && <IonBadge slot="end" color="danger">{t("before row")} {areaConfig.first_row}</IonBadge>}
      {path.number > (areaConfig?.last_row || Infinity) && <IonBadge slot="end" color="danger">{t("after row")} {areaConfig.last_row}</IonBadge>}
      <Source source={path.source} slot="end"/>
    </IonItem>
  </>
}
