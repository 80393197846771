import { IonList, IonContent, IonPage, IonItemDivider, IonItem, IonBadge, IonButton, IonIcon, IonLabel, } from '@ionic/react';
import React, { } from 'react';
import { gql, } from '@apollo/client';
import { useMutationWithElevatedRole, useSubscriptionWithElevatedRole } from '../../hooks/hasuraHooks';
import { GraphQLState } from '../../components/GraphQLState';
import moment from 'moment';
import { trash } from 'ionicons/icons';
import Toolbar from '../../components/Toolbar';


export const useCopyOps = (where: any = {}) => useSubscriptionWithElevatedRole(gql`subscription GetFileCopyOperations($where: file_copy_operations_bool_exp!) {
      file_copy_operations (order_by: {id: desc}, where: $where) {
        id
        created_at
        file_path {
          computer_name
          path
        }
      }
  }`, {variables: {where}});


export const FileCopyOperations: React.FC = () => {
  const { loading, data, error} = useCopyOps()
  const [deleteCopyOp] = useMutationWithElevatedRole(gql`
    mutation DeleteFileCopyOperation($id: Int!) {
      delete_file_copy_operations_by_pk(id: $id) {id}
    }
  `, {})
  
  return (
    <IonPage>
      <Toolbar name="File copy operations"/>
      <IonContent fullscreen>
        <GraphQLState error={error} loading={loading}/>
        
        {data && <IonList>
          <IonItemDivider>{data?.file_copy_operations?.length} copy operations</IonItemDivider>
          {data?.file_copy_operations?.map(({id, created_at, file_path}: any) => <IonItem key={id} lines="none">
            <IonBadge slot="start">
              {moment(created_at).fromNow()}
            </IonBadge>
            <IonLabel>from {file_path.computer_name}</IonLabel>
            <IonLabel style={{textOverflow: "unset", overflowX: "auto"}}>
              {file_path.path}
            </IonLabel>
            <IonButton slot="end" fill="clear" onClick={() => deleteCopyOp({variables: {id}})}><IonIcon slot="icon-only" icon={trash}/></IonButton>
          </IonItem>)}
        </IonList>}
      </IonContent>
    </IonPage>
  );
};