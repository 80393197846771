import { IonList, IonContent, IonPage, IonButton, } from '@ionic/react';
import React, { useState } from 'react';
import Toolbar from '../../components/Toolbar'
import { gql, useSubscription, } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { GraphQLState } from '../../components/GraphQLState';
import { Replenishment } from '../../components/Replenishment';
import { ReferenceSprayerAreaInfo } from '../../components/ReferenceSprayingArea';
import { SprayerConfigurator } from '../../components/SprayerConfigurator';


export const Replenishments: React.FC = () => {
  const [showArchived, setShowArchived] = useState(false)
  const where = showArchived ? {} : {archived: {_eq: false}}  // Hide archived if showArchives is false
  const {data, loading, error} = useSubscription(gql`subscription GetReplenishments($where: replenishments_bool_exp!) {
      replenishments (order_by: {id: desc}, where: $where) {
        id
        created_at updated_at
        taurus_id
        robot_config_id
        simulation_name
        archived

        planned_ha
        planned_herbicide_savings
        planned_volume_L
        volume_after_filling_L
        clearwater_volume_after_filling_L

        ingredients {
          id
          amount
          ingredient {
            id name_DE unit
          }
        }

        previous_replenishment_id
        previous_replenishment_amount_L
      }
  }`, {variables: {where}})
  const {t} = useTranslation()
  return <IonPage>
    <Toolbar name={t("Replenishments")} buttons={<>
      <IonButton fill="solid" onClick={() => setShowArchived(current => !current)}>{showArchived ? 'hide archived': 'show archived'}</IonButton>
    </>}/>

    <IonContent fullscreen>
      <GraphQLState error={error} loading={loading}/>
      <ReferenceSprayerAreaInfo/>
      <SprayerConfigurator/>
      {data && <IonList>
        {data.replenishments.map((replenishment: any) => <Replenishment key={replenishment.id} replenishment={replenishment}/>)}
      </IonList>}
    </IonContent>
  </IonPage>
};