import React from 'react';
import ReactDOM from 'react-dom';
import ControlApp from './control-app/App';
import DashboardApp from './dashboard-app/App';
import './types.d.ts'
import './utils/i18n'
import { setupIonicReact } from '@ionic/react';
import * as serviceWorkerRegistration from './dashboard-app/serviceWorkerRegistration';

const APP_ENV = process.env.REACT_APP_ENV || 'control-app';

console.log(`Starting app with APP_ENV: ${APP_ENV}`);

if (APP_ENV === 'control-app') {
  setupIonicReact({
    mode: 'md',
  });
} else if (APP_ENV === 'dashboard-app') {
  setupIonicReact({});
} else {
  console.error(`Unknown APP_ENV: ${APP_ENV}`);
}

if (APP_ENV === 'control-app') {
  ReactDOM.render(
    <React.StrictMode>
      <React.Suspense fallback={<div>Loading...</div>}>
        <ControlApp />
      </React.Suspense>
    </React.StrictMode>,
    document.getElementById('root')
  );
} else if (APP_ENV === 'dashboard-app') {

  ReactDOM.render(
    <React.StrictMode>
      <DashboardApp />
    </React.StrictMode>,
    document.getElementById('root')
  );

  // Register service worker for dashboard-app
  console.log('Registering service worker');
  serviceWorkerRegistration.register({
    onSuccess: () => console.log('Service worker running'),
    onUpdate: () => {
      console.log('Service worker updated');
      // window.location.reload();
    },
  });
  console.log('Registered service worker?');
} else {
  console.error(`Unknown APP_ENV: ${APP_ENV}`);
}