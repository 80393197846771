import { IonList, IonButton, IonContent, IonPage, IonItemDivider, IonProgressBar, } from '@ionic/react';
import React, { useState } from 'react';
import Toolbar from '../../components/Toolbar'
import { gql, } from '@apollo/client';
import { useSubscriptionWithElevatedRole } from '../../hooks/hasuraHooks';
import { ComponentGroup, groupComponents } from '../../components/ComponentGroup';
import { taurusFieldColors, TaurusItem } from '../../components/TaurusItem';


export const Tauruses: React.FC = () => {
  const [showArchives, setShowArchives] = useState(false)
  const [groupByVariant, setGroupByVariant] = useState(false)
  const where = showArchives ? {} : {obsolete: {_eq: false}}  // Hide obsoletes if showArchives is false

  const { loading, data } = useSubscriptionWithElevatedRole(gql`subscription GetAllTauruses($where: tauruses_bool_exp!) {
      tauruses (order_by: {id: asc}, where: $where) {
        id
        updated_at
        obsolete
        steerer_type
        implement_beam
        connector_plug
        battery_communication
        sprayer_version
        generator_pcb_version
        taurus_pcb_version
        safety_pcb_version
        light_pcb_version
        axle_pcb_version
        sprayer_pcb_version
        can0_interface_type
        can0_baudrate
        can1_interface_type
        can1_baudrate
        chassis_version
        master_light_trigger_sync
        drive_transmission
        control_panel_type
        gps_antenna_type

        calibrations(order_by: {created_at: desc}, limit: 1) {
          created_at
          data
        }

        taurus_configs(order_by: {created_at: desc}, limit: 1) {
          id
          robot_config {
            id
          }
        }
      }
  }`, {variables: {where}});
  const groupedComponents = groupComponents(data?.tauruses,
    ["id", "updated_at", "calibrations", "taurus_configs", "obsolete", "__typename"])
  return (
    <IonPage>
      <Toolbar name="Tauruses" buttons={<>
        <IonButton fill="solid" onClick={() => setShowArchives(current => !current)}>{showArchives ? 'hide archived': 'show archived'}</IonButton>
        <IonButton fill="solid" onClick={() => setGroupByVariant(current => !current)}>{groupByVariant ? 'do not group': 'group by variant'}</IonButton>
      </>}/>

      <IonContent fullscreen>
        {loading && <IonProgressBar type="indeterminate"/>}

        {groupByVariant && groupedComponents.map(([groupInfo, components]: any) => <ComponentGroup groupDescription={groupInfo} components={components}
          componentName="Taurus" componentNamePlural="Tauruses" valueColors={taurusFieldColors}
          factory={(c: any, index: number) => <TaurusItem taurus={c} key={index} showArchives={showArchives}/>}
        />)}

        {!groupByVariant && data && <IonList>
          <IonItemDivider>
            {data?.tauruses?.length} Tauruses
          </IonItemDivider>
          {data.tauruses.map((taurus: any, index: number) => <TaurusItem taurus={taurus} key={index} showArchives={showArchives}/>)}
        </IonList>}
      </IonContent>
    </IonPage>
  );
};