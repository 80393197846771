import { gql, useQuery } from '@apollo/client';
import { IonCard, IonCardSubtitle, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import React, {useState} from 'react';
import { Line } from 'react-konva';


export const useSpotSpraySim = () => {
  const { data } = useQuery(gql`query{
      sprayer_nozzles(order_by: {id: asc}) {
        id
        description
        spray_pattern
        spray_pattern_height_m
      }
  }`)

  const [config, setConfig] = useState({
    nozzleId: 1,
    height: 0.17,
    robotLateralOffset: 0.0,  // Simulate bad robot position
    nSpots: 2,
    interspotDistance: 0.01,
  })
  return {
    config, setConfig,
    nozzle: data?.sprayer_nozzles?.find((nozzle: any) => nozzle.id === config.nozzleId),
    sprayerNozzles: data?.sprayer_nozzles,  // Note: can be undefined while loading
  }
}


export const InputItem: React.FC<any> = ({paramName, setConfig, config, min="0.01", max="0.3", step="0.001"}: any) => {
    return <IonItem style={{maxWidth: "150px"}}>
        <IonLabel position="stacked">{paramName}</IonLabel>
        <IonInput debounce={500} type="number" min={min} max={max} step={step} value={config[paramName]} onIonChange={(e: any) => setConfig((prev: any) => ({...prev, [paramName]: parseFloat(e.detail.value)}))}/>
    </IonItem>
}


export const SpotSprayConfig: React.FC<any> = ({config, setConfig, sprayerNozzles}: any) => {
  return <IonCard>
    <IonCardSubtitle>Nozzle</IonCardSubtitle>
    {sprayerNozzles && <IonItem>
      <IonSelect value={config.nozzleId} onIonChange={(e: any) => setConfig((prev: any) => ({...prev, nozzleId: e.detail.value}))}>
        {sprayerNozzles.map((nozzle: any) => <IonSelectOption value={nozzle.id}>{nozzle.description}</IonSelectOption>)}
      </IonSelect>
    </IonItem>}
    <IonCardSubtitle>Sprayer configuration</IonCardSubtitle>
    <div style={{display: "flex"}}>
        <InputItem paramName={"height"} setConfig={setConfig} config={config}/>
        <InputItem paramName={"nSpots"} setConfig={setConfig} config={config} min="0" max="10" step="1"/>
        <InputItem paramName={"interspotDistance"} setConfig={setConfig} config={config}/>
    </div>

    <IonCardSubtitle>Simulation</IonCardSubtitle>
    <div style={{display: "flex"}}>
        <InputItem paramName={"robotLateralOffset"} setConfig={setConfig} config={config}/>
    </div>
  </IonCard>
}


export const Spot: React.FC<any> = ({config, stem, nozzle, yOffset=0}: any) => {
    // Get offsets of spots. E.g. [-0.5, 0.5] for 2 spots or [-1, 0, 1] for 3 spots
    const centerOffset = (config.nSpots - 1) / 2.  // Index offset of center of list
    const offsets = [...new Array(config.nSpots)].map((_: any, i: number) => (i - centerOffset))

    return <>{offsets.map((offset: number) => <SingleSpot nozzle={nozzle}
       x={stem[0] + offset * config.interspotDistance} y={yOffset + config.robotLateralOffset} height={config.height}
    />)}</>
}


export const SingleSpot: React.FC<any> = ({nozzle, height, x, y}: any) => {
  if (!nozzle) {return null}

  const scale = height / nozzle.spray_pattern_height_m
  const offsetedPoints = nozzle.spray_pattern.map(([_x, _y]: any) => [_x * scale + x, _y * scale + y])
  return <Line points={offsetedPoints.flat()} closed={true}
    fill="rgba(255,0,0,0.3)"
  />
}