export interface TalpaConfig {
    blade_turning_angle_pointing_backwards_deg: number;
    connected_aquila_id:                        number;
    created_at:                                 Date;
    disabled:                                   boolean;
    offset_y:                                   number;
    row_name:                                   string;
    talpa:                                      Talpa;
    talpa_tool_id:                              number;
}

export enum SideOfRow {
    Left = "left",
    Right = "right",
    Both = "both"
}

export interface Talpa {
    baud_rate:                                  number;
    blade_turning_angle_pointing_backwards_deg: number;
    calibrations:                               HoesCalibration[];
    can_id_chopper:                             number;
    can_id_lifter:                              number;
    can_id_swivel:                              number;
    can_id_swivel_left:                         number;
    can_id_swivel_right:                        number;
    controller_chopper:                         string;
    controller_swivel:                          string;
    end_of_line_test_passed:                    boolean;
    hardware_version:                           HardwareVersion;
    hardware_version_id:                        number;
    id:                                         number;
    left_motor_polarity_reversed:               boolean;
    lifter_motor_address:                       number;
    right_motor_polarity_reversed:              boolean;
    side_of_row:                                SideOfRow;
    sprayer_calibrations:                       SprayerCalibration[];
    talpa_tool_id:                              number;
}

export interface HoesCalibration {
    created_at: Date;
    data:       TwoSidedTalpaCalibrationData;
}

export interface TwoSidedTalpaCalibrationData {
    left:  TalpaCalibrationData;
    right: TalpaCalibrationData;
}

export interface TalpaCalibrationData {
    calib_offset_x_m:     number;
    calib_offset_y_m:     number;
    calib_offset_yaw_rad: number;
}

export interface HardwareVersion {
    centered_on_row: boolean;
    has_arm:         boolean;
    has_chopper:     boolean;
    has_lifter:      boolean;
}


export interface SprayerCalibration {
    id:                  number;
    nozzle_angle_x_deg:  number;
    nozzle_angle_y_deg:  number;
    opening_duration_ms: number[];
    pressure_bar:        number[];
    spot_mass_mg:        Array<number[]>;
}

export interface AquilaConfig {
    aquila:     Aquila;
    created_at: Date;
    offset_y:   number;
    standalone: boolean;
}

export interface Aquila {
    camera_mac:                  string;
    camera_type:                 string;
    computer:                    Computer;
    connector_plug:              string;
    disable_lidar:               boolean;
    electronics_version:         string;
    end_of_line_test_passed:     boolean;
    ethernet_interface_to_robot: string;
    has_central_trigger:         boolean;
    has_lidar:                   boolean;
    has_wide_view:               boolean;
    housing:                     string;
    id:                          number;
    light_controller_firmware:   string;
    mount:                       string;
    updated_at:                  Date;
}

export interface Computer {
    eth0_mac:      string;
    hardware_type: string;
    ram_gb:        number;
}