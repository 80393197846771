import React, { useContext, useEffect, } from 'react';
import { Circle, Layer, Stage } from 'react-konva';  // LineKonva because Line already used in t2-2d-geometry
import { LineSegment, Point, Vector, Line, } from 'ts-2d-geometry'
import { FieldEditorContext, useGetArea, Extremity, Side, } from './useFieldEditorData';
import { AreaKonva } from './AreaKonva';
import { getBoundaries } from './MiniatureField';
import { KonvaStage, useKonvaStageProps } from './KonvaStage';


// This is where the computation of the actual corner points is happening
const fixPoints = (fieldConfig: any, points: any = {}) => {
  // Handle the computation of all corner points in the schema
  const {areas, interrow_distance, interbed_distance, number_of_bed_rows} = fieldConfig
  const getArea = (area_id: string) => areas.find(({id}: any) => id === area_id)

  const initialPoints = points

  // Convert all {x, y} objects to Point(x, y). This is required after the JSON import
  Object.keys(initialPoints).forEach((k: string) => Object.keys(initialPoints[k]).forEach((pk: string) => {
    const p = initialPoints[k][pk].point
    initialPoints[k][pk].point = new Point(p.x, p.y)
  }))

  // To set random point
  const randomCoordinate = () => (2 * Math.random() - 1.) * 20.
  const randomPoint = () => new Point(randomCoordinate(), randomCoordinate())

  // Ensure points for all areas are defined
  areas.forEach(({id}: any) => {
    if (!points[id]) {
      points[id] = {
        start_left: {point: randomPoint(), side: "left", extremity: "start", area_id: id},
        start_right: {point: randomPoint(), side: "right", extremity: "start", area_id: id},
        end_left: {point: randomPoint(), side: "left", extremity: "end", area_id: id},
        end_right: {point: randomPoint(), side: "right", extremity: "end", area_id: id},
      }
    }
  })
  // Ensure no additional areas are defined
  const areaIds = new Set(areas.map(({id}: any) => id))
  Object.keys(points).filter((area_id: string) => !areaIds.has(parseInt(area_id))).forEach((superfluous_area_id: string) => {
    delete points[superfluous_area_id]
  })

  // Decorate point info
  areas.forEach((area: any) => {
    // Ensure no errors are set
    Object.keys(points[area.id]).map((k: string) => {
      points[area.id][k].error = null
      points[area.id][k].type = null
      if (area.def.type === "rows") {
        if (k.endsWith("left")) {
          points[area.id][k].type = "support"
        }
        else {
          points[area.id][k].type = "secondary-support"
        }
      }
      if (area.def.type === "side-track") {
        if (k.endsWith(area.def.defined_side)) {
          points[area.id][k].type = "support"
        }
      }
    })
  })


  const getDirVect = (area_id: string) => {
      // Get direction vector of an area
      const area = getArea(area_id)
      if (!area) {
        throw new Error(`No area A${area_id}`)
      }
      if (area.def.type === "rows") {
        const start_left = points[area_id]["start_left"]
        const end_left = points[area_id]["end_left"]
        return end_left.point.minus(start_left.point).normed()
      }
      if (area.def.type.startsWith("extremity") || area.def.type === "side-track") {
        const start = points[area_id]["start_" + area.def.defined_side].point
        const end = points[area_id]["end_" + area.def.defined_side].point
        return end.minus(start).normed()
      }
      throw new Error(`Invalid def type ${area.def.type}`)
  }

  const getSideLine = (area_id: string, side: Side) => {
    const dirVect = getDirVect(area_id)
    const area = getArea(area_id)
    if (area.def.type === "side-track" || area.def.type.startsWith("extremity")) {
      if (side === area.def.defined_side) {
        // Use defined point
        return new Line(points[area_id][`start_${side}`].point, dirVect)
      }
      else {
        const isLeft = area.def.defined_side === "left"
        const extremityVect = getDirVect(area.id).clockwisePerpendicular().scale(area.def.safe_space_m * (isLeft ? 1. : -1.))
        const pointOnDefinedSide = points[area_id][`start_${area.def.defined_side}`].point
        const pointOnThisSide = pointOnDefinedSide.plus(extremityVect)
        return new Line(pointOnThisSide, dirVect)
      }
    }
    if (area.def.type === "rows") {
      // For rows the left side is always defined
      if (side === "left") {
        return new Line(points[area_id][`start_left`].point, dirVect)
      }
      else {
        const extremityVect = dirVect.normed().clockwisePerpendicular().scale(getAreaWidth(area))
        const pointOnRightSide = points[area_id][`start_left`].point.plus(extremityVect)
        return new Line(pointOnRightSide, dirVect)
      }
    }
  }

  const getAreaWidth = (area: any) => {
    return 1. * interrow_distance * (number_of_bed_rows * area.def.beds - 1) + interbed_distance * (area.def.beds - 1)
  }

  const fix = (iteration = 0) => {
    if (iteration > 0) {
      console.log(`Fixed points ${iteration} times`)
    }
    if (iteration > 5) {
      console.error("Needed more than 5 iterations")
      return points
    }
    let hasChange = false
    const setPoint = (corner: any, point: Point) => {
      if (corner.point.distanceSquare(point) > 0.000001) {
        corner.point = point
        hasChange = true
      }
    }
    // Decorate point info
    areas.forEach((area: any) => {
      const area_id = area.id  // FIXME:use area.id
      // Fix point positions
      if (area.def.type === "side-track") {
        const isLeft = area.def.defined_side === "left"
        const otherSide = isLeft ? "right": "left"
        const startPoint = points[area_id][`start_${area.def.defined_side}`]
        const endPoint = points[area_id][`end_${area.def.defined_side}`]
        const extremityVect = getDirVect(area.id).clockwisePerpendicular().scale(area.def.safe_space_m * (isLeft ? 1. : -1.))
        // Ensure that other side is offsetted from defined side
        setPoint(points[area_id][`start_${otherSide}`], startPoint.point.plus(extremityVect))
        setPoint(points[area_id][`end_${otherSide}`], endPoint.point.plus(extremityVect))
      }
      if (area.def.type === "rows") {
        const start_left = points[area_id]["start_left"]
        const dirVect = getDirVect(area_id)
        if (!dirVect) {return}
        const extremityVect = dirVect.normed().clockwisePerpendicular().scale(getAreaWidth(area))
        const pointOnRightSide = start_left.point.plus(extremityVect)
        const lineOnRightSide = new Line(pointOnRightSide, dirVect);

        // Project right points on line
        ["start_right", "end_right"].forEach((key: string) => {
          const p = points[area.id][key]
          setPoint(p, lineOnRightSide.project(p.point))
        })
      }
      else if (area.def.type.startsWith("extremity")) {
        const parentCorners: any = points[area.def.area_id]
        const dirVect = getDirVect(area_id)
        if (!parentCorners || !dirVect) {return}
        const isLeft = area.def.defined_side === "left"

        const startOnLeft = (area.def.defined_side === "left") === (area.def.extremity === "start")

        // Compute fixed point
        const mainPointParent = `${area.def.extremity}_left`  // Always pick left point because it is the defined_side for rows
        const mainPointChild = `${startOnLeft ? "start": "end"}_${area.def.defined_side}`
        setPoint(points[area.id][mainPointChild], points[area.def.area_id][mainPointParent].point)

        // Compute secondary point (can be orthogonal is straight extremity or depending on parent area if slanted)
        const secondaryPointChild = `${startOnLeft ? "end": "start"}_${area.def.defined_side}`
        if (area.def.type.includes("straight")) {
          const parentArea = getArea(area.def.area_id)
          const parentAreaWidth = 1. * interrow_distance * (number_of_bed_rows * parentArea.def.beds - 1) + interbed_distance * (parentArea.def.beds - 1)
          const parentOrthVect = getDirVect(parentArea.id).clockwisePerpendicular().scale(getAreaWidth(parentArea))
          const secondaryPoint = points[area.def.area_id][mainPointParent].point.plus(parentOrthVect)
          setPoint(points[area.id][secondaryPointChild], secondaryPoint)
        }
        else {
          const secondaryPointParent = `${area.def.extremity}_right`  // Always pick left point because it is the defined_side for rows
          setPoint(points[area.id][secondaryPointChild], points[area.def.area_id][secondaryPointParent].point)
        }

        // Now compute the non defined points
        const extremityVect = dirVect.clockwisePerpendicular().scale(area.def.safe_space_m * (isLeft ? 1. : -1.));
        const otherSide: Side = area.def.defined_side === "left" ? "right": "left";
        (["start", "end"] as Extremity[]).forEach((extremity: Extremity) => {
          setPoint(points[area.id][`${extremity}_${otherSide}`], points[area.id][`${extremity}_${area.def.defined_side}`].point.plus(extremityVect))
        })
      }
    })

    if (hasChange) {
      fix(iteration + 1)  // Fix again
      return
    }

    areas.forEach((area: any) => {
      // prolong at the end
      Object.keys(points[area.id]).map((k: string) => {
        const corner: any = points[area.id][k]
        const line = getSideLine(corner.area_id, corner.side)  // Get line through corner point
        //const line = new Line(corner.point, getDirVect(corner.area_id))
        const limits = area[corner.extremity]
        if (limits.length < 1 || !line) {
          corner.point = corner.point
          return
        }
        const limit = limits[0]
        const limitStart = points[limit.area_id]["start_" + limit.side]
        const limitEnd = points[limit.area_id]["end_" + limit.side]
        const lineSegment = new LineSegment(limitStart.point, limitEnd.point)
        const i = lineSegment.intersectForLine(line)
        if (i.nonEmpty()) {
          setPoint(corner, i.get())
        }
        else {
          console.log("No intersection")
          corner.point = corner.point
          corner.error = `No intersection`  // FIXME: add more info
        }
        corner.point = corner.point
      })

    })
    if (hasChange) {
      fix(iteration + 1)  // Fix again
    }
  }

  // Enforce constraints
  fix()
  return points
}



export const PointsKonva: React.FC<any> = ({start_left, start_right, end_left, end_right, update, getAreaColor, scale}: any) => {
  const points = [start_left, end_left, end_right, start_right]
  return <>
    {points.filter(({error, type}: any) => error || type).map((corner: any, i: number) => <Circle x={corner.point.x} y={corner.point.y}
      key={i} _useStrictMode
      radius={20. / scale} fill={corner.error ? "red": getAreaColor(corner.area_id)}
      stroke={corner.type === "support" ? getAreaColor(corner.area_id): undefined} strokeWidth={15. / scale}
      onDragEnd={(e: any) => {
        corner.point = new Point(e.target.attrs.x, e.target.attrs.y)
        update()
      }}
      draggable={corner.type}
    />)}
  </>
}


export const FieldSchema: React.FC<any> = ({editable = true}: any) => {
  const context = useContext(FieldEditorContext)
  const {selectedObject, setSelectedObject, areas, interrow_distance, number_of_bed_rows, setPoints, points: initialPoints} = context
  const {stageProps, center}: any = useKonvaStageProps({onChange: () => setSelectedObject(null)})
  const scale = stageProps.scale
  const {getAreaColor} = useGetArea(areas)

  useEffect(() => {
    if (!initialPoints) {
      return
    }
    // Center in middle of field
    const boundaries = getBoundaries(initialPoints)
    const x = (boundaries.xMin + boundaries.xMax) / 2.
    const y = (boundaries.yMin + boundaries.yMax) / 2.
    const mapWidth = boundaries.xMax - boundaries.xMin
    const mapHeight = boundaries.yMax - boundaries.yMin
    const width = window.visualViewport?.width
    const height = window.visualViewport?.height
    if (width && height) {
      const scale = Math.min(width / mapWidth, height / mapHeight) * 0.5
      center(scale, x, y)
    }
  }, [])

  // Ensure non-zero (breaks some computations)
  if (number_of_bed_rows < 1 || interrow_distance < 0.01 || !areas) {
    return null
  }
  const points = fixPoints(context.data.fieldConfig, initialPoints)
  return <KonvaStage stageProps={stageProps}>
    {areas.map((area: any) => <AreaKonva key={area.id} area={area} {...points[area.id]} getAreaColor={getAreaColor}
      onClick={() => setSelectedObject({area_id: area.id})}
      selected={selectedObject?.area_id === area.id}
    />)}
    {/* Show points last to ensure that they are shown on top (no z-index in konva)*/}
    {editable && areas.filter(({id}: any) => id === selectedObject?.area_id).map((area: any) => <PointsKonva scale={scale}
      key={area.id} area={area} {...points[area.id]} update={() => setPoints({...points})} getAreaColor={getAreaColor}/>)}
  </KonvaStage>
}