import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Message, Ros, Service, ServiceRequest, Topic, ActionClient, Goal } from 'roslib';
import { useSharedTopic } from './useSharedTopic';


// Context shared across connected app
export const RosHandleContext = React.createContext<Ros|undefined>(undefined)


// Cannot be class method unfortunately because it is a hook
export const useTopic = <T = any,>(topicName:string, topicType: string, checkFunction: ((msg : T)=>any)|null = null) => {
  const ros = useContext(RosHandleContext)
  const [value, setValue] = useState<T | undefined>(undefined)

  useEffect(() => {
    // This is a wrapper so we can see in the browser's profiler which message's callback
    // is making our app slow. For example, the function name will be something like
    // "callback___main__sprayer_manager__full_state", which corresponds to the topic
    // "/main/sprayer_manager/full_state".
    const fn_name = `callback__${topicName.replaceAll('/', '__')}`;
    const cb_wrapper : (message : T, setValue : React.Dispatch<React.SetStateAction<T | undefined>>)=>void = new Function(`
      function ${fn_name} (message, callback) {
        return callback(message)
      }
      return ${fn_name}
    `)();
    if (ros) {
        const topic = new Topic({ros: ros, name: topicName, messageType: topicType})
        let lastMsg: any = {current: null};  // Keep track of last message to avoid unnecessary updates if message don't change
        topic.subscribe((msg: any) => {
          // Allow limiting updates if msg didn't change (use checkFunction for comparison)
          if (!checkFunction || lastMsg.current === null || checkFunction(msg) !== checkFunction(lastMsg.current)) {
            lastMsg.current = msg
            cb_wrapper(msg, setValue)
          }
        })
        return () => {
          topic.unsubscribe()
        }
    }
    else {
      console.error(`Called useTopic without ros for ${topicName}: this should not happen`)
    }
  }, [ros])
  return value
}

export function useCallService<Req=any, Res=any>() {
    const ros = useContext(RosHandleContext)
    return (serviceName: string, serviceType: string, requestValues: Req, resultCb: (result: Res) => void = (result: Res) => {}) => {
      if (!ros) {
        console.log("error useCallService: no ROS");
        return
      }
      const service = new Service({ros: ros, name: serviceName, serviceType: serviceType})
      service.callService(new ServiceRequest(requestValues), (result: Res) => {
        console.log(`service ${serviceName} : ${serviceType} responded: ${JSON.stringify(result)}`);
        let fn_name = `callback_srv__${serviceName.replaceAll('/', '__')}`;
        const cb_wrapper : (message : Res, cb : (result : Res)=>void)=>void = new Function(`
          function ${fn_name} (message, callback) {
            return callback(message)
          }
          return ${fn_name}
        `)();
        return cb_wrapper(result, resultCb)
      })
    }
}

export interface Time {
  secs: number,
  nsecs: number
}

const BILLION = BigInt(1e9);
const MILLION = BigInt(1e6);

function to_nsec(t : Time) : bigint {
  return BILLION * BigInt(t.secs) + BigInt(t.nsecs)
}

function from_nsec(ns : bigint) : Time {
  return {
    secs : Number(ns / BILLION),
    nsecs: Number(ns % BILLION)
  }
}

interface GetTimeResponse {
  time : Time,
  is_simulation : boolean
}

enum TimeSyncState {
  Uninitialized,
  Synchronized,
  Simulation
}

export function useRosClock() {
  const sync_state = useRef({ros_base_time_ns: BigInt(0), time_sync_state: TimeSyncState.Uninitialized, js_base_time_ns : BigInt(0)});
  const callService = useCallService();

  async function init () {
    const start_ms = BigInt(Date.now());
    const res : GetTimeResponse = await new Promise(res=>callService("/get_time", "weeding_monitoring/GetTime", {}, res));
    const end_ms = BigInt(Date.now());
    if (res.is_simulation) {
      sync_state.current.time_sync_state = TimeSyncState.Simulation;
      return
    }
    const rtt_ms = end_ms - start_ms;
    const mid_trip_ns = MILLION * (start_ms + (rtt_ms / BigInt(2)));
    const ros_base_time : Time = res.time;
    const ros_base_time_ns = to_nsec(ros_base_time);
    sync_state.current.ros_base_time_ns = ros_base_time_ns;
    sync_state.current.js_base_time_ns = mid_trip_ns;
    sync_state.current.time_sync_state = TimeSyncState.Synchronized;
  }

  useEffect(()=>{
    // for debugging
    init();
  }, [])

  function now() : Time | undefined {
    if (sync_state.current.time_sync_state === TimeSyncState.Simulation) {
      return undefined;
    } else if (sync_state.current.time_sync_state === TimeSyncState.Uninitialized) {
      // We don't have sync (yet); If we returned `undefined` here, the rosbridge would
      // silently replace that with its own `ros.Time.now()`. However, in case something
      // goes wrong with the synchronization process, and we never reach the synchronized
      // state, this would happen for all messages, and all messages would be considered
      // sufficiently recent by the robot, no matter when we actually sent them. This
      // would be wrong and dangerous. Instead, we return a stamp that's always out of 
      // date, so our message will be ignored by any node that cares about the timestamp.
      // This likely means that our action will not be executed, but that's safer than
      // silently doing the wrong thing.
      return {
        secs: 1, // use 1 in case 0 is some special
        nsecs: 1 // case that gets ignored later on
      }
    }
    const now_js_ns = BigInt(Date.now()) * MILLION;
    const elapsed_js_ns = now_js_ns - sync_state.current.js_base_time_ns;
    const elapsed_ros_ns = elapsed_js_ns; // assume clocks run at the same speed
    return from_nsec(elapsed_ros_ns + sync_state.current.ros_base_time_ns);
  }

  // call init() again and run callback when done
  function reinitialize(cb: (value: void)=>void) {
    sync_state.current.time_sync_state = TimeSyncState.Uninitialized;
    init().then(cb)
  }

  return { now, reinitialize }
}

export function usePublisher<T=any>(topicName: string, topicType: string) {
    
    const ros = useContext(RosHandleContext)

    if (ros !== undefined) {
      let rosobj = ros as Object;
      let rosany = ros as any;
      if (!rosobj.hasOwnProperty("topics")) {
        rosany["topics"] = new Map();
      }
      let rostopicmap = rosany["topics"] as Map<string, Topic>;
      if (!rostopicmap.has(topicName)) {
        console.log(`initializing ${topicName}`)
        rostopicmap.set(topicName, new Topic({ros: ros, name: topicName, messageType: topicType}))
        console.log({rostopicmap})
      }
    }

    function fn(requestValues: T) {
      if (!ros) {
        console.log("oh no, no ros for topic " + topicName);
        return
      }
      let topic = ((ros as any)["topics"] as Map<string, Topic>).get(topicName);
      topic!.publish(new Message(requestValues))
    }

    return fn
}

export function useActionClientWrapper(serverName: string, actionName: string){
  const ros = useContext(RosHandleContext)
  console.log("useActionClientWrapper")
  console.log(ros)
  if (!ros) {
    throw new Error("ros is undefined")
  }
  return new ActionClientWrapper(serverName, actionName, ros)
}

export class ActionClientWrapper {
  
  client: ActionClient;
  goal: Goal | null = null;
  constructor(serverName: string, actionName: string, ros: Ros) {
      this.client =  new ActionClient({ros: ros, serverName: serverName, actionName: actionName})
      console.log(`initializing ${actionName}`)
  }

  send_goal(name: string) {
    console.log("sending goal")
    this.goal = new Goal({
      actionClient : this.client,
      goalMessage : {preset: name}
    })
    this.goal.send()
  }

  cancel_goal() {
    this.goal?.cancel()
  }
}

export function useAllParams() {
    const ros = useContext(RosHandleContext)
    const [allParams, setAllParams] = useState<string[]>([])
    const refresh = () => {
      ros?.getParams(setAllParams, (e: any) => console.error(e))
    }
    useEffect(refresh, [])  // Initially get params
    return {
      allParams,
      refresh,
    }
}


export function useParam<T=any>(paramName: string) {
    const [value, setValue] = useState<T | undefined>(undefined)
    const ros = useContext(RosHandleContext)
    const refresh = () => {
      if (ros) {
        const get_param_service = getGetParamService();
        const service = new Service({
          ros: ros,
          name: get_param_service,
          serviceType: "weeding_param/GetParam"
        });
        service.callService(
          new ServiceRequest({
            key: paramName
          }),
          (result: any) => {
            try {
              let parsed = JSON.parse(result.json_value)
              setValue(parsed)
            } catch (e) {
              console.error(`Error parsing JSON (length: ${result.json_value.length}, param: '${paramName}'):\n${result.json_value}\nsetting variable to undefined.`)
              console.error(e);
              setValue(undefined);
            }
          }
        );
      }
    }
    useEffect(refresh, [])
    return {
      value,
      refresh,
    }
}


export const useUpdateSupervision = () => {
  // Continue ignoring errors / supervising the robot
  const publisher = usePublisher("/ignore_commands", "weeding_pause_server/IgnoreCommands")
  const pauseState = useTopic("/main/pause_server/pause_state", "weeding_pause_server/PauseState")
  return () => {
      const activeIgnoredPauseEnforcers = pauseState?.pause_enforcers?.filter((p: any) => p.active && p.ignore_info.ignored && p.ignore_info.time_until_supervision_check_s > 0)
      publisher({ignore_commands: activeIgnoredPauseEnforcers.map(({name}: any) => ({pause_enforcer_to_ignore: name, supervisor_username: "mobile user"}))})
  }
}

export const useOverrideParams = () => {
  // Hook for handling of override params
  const param_service = getOverrideParamService();
  const callService = useCallService();
  const setParams = useCallback((paramsDict: any) => {
    callService(param_service, "weeding_param/OverrideParams", {update_override_json: JSON.stringify(paramsDict)})
  }, [callService])
  const deleteParams = useCallback((deleteKeys: any) => {
    callService(param_service, "weeding_param/OverrideParams", {delete_keys_json: JSON.stringify(deleteKeys)})
  }, [callService])
  const state = useSharedTopic("/main/param_server/state", "fr_param_msgs/Parameters")
  const error = state ? (state.error.length > 0 ? state.error : null) : "No param info received"
  const resultingParams: any = state?.resulting_config_json ? JSON.parse(state?.resulting_config_json) : null
  const overrideParams: any = state?.override_config_json ? JSON.parse(state?.override_config_json) : null
  return {
    setParams,  // Set override params
    deleteParams,  // Delete override params
    state,  // Full state of param server
    error,
    resultingParams,  // Dictionary with set parameters (param config + overrides)
    overrideParams,
  }
}

export enum AccessType {
  LocalHttp,
  LocalHttps,
  RemoteHttps,
  VPN,
  AppDevServer
}

export function getAccessTypeName(accessType: AccessType) : string {
  if (accessType === AccessType.LocalHttp) {
    return "local access, unauthenticated"
  }
  if (accessType === AccessType.LocalHttps) {
    return "local access, authenticated"
  }
  if (accessType === AccessType.RemoteHttps) {
    return "remote access"
  }
  if (accessType === AccessType.AppDevServer) {
    return "development server"
  }
  if (accessType === AccessType.VPN) {
    return "vpn access"
  }
  throw new Error("This code should be unreachable.")
}

export function getAccessType() : AccessType {
  if (window.location.hostname.startsWith("10.100")) {
    return AccessType.VPN;
  }
  if (window.location.hostname === "localhost") {
    return AccessType.AppDevServer;
  }
  if (window.location.protocol === "http:") {
    // http, but not over VPN, must be local
    return AccessType.LocalHttp;
  }

  // https, but which one? use the cookie set by the websocket reverse proxy connection!
  if (
    document.cookie.split("; ")
    .find((row) => row.startsWith("remote_access="))
    ?.split("=")[1]
    === "true"
  ) {
    return AccessType.RemoteHttps
  }
  return AccessType.LocalHttps
}

export function getOverrideParamService() : string {
  return (getAccessType() === AccessType.RemoteHttps)
  ?"/main/param_server/override_params_remote"
  :"/main/param_server/override_params_local";
}

export function getGetParamService() : string {
  return (getAccessType() === AccessType.RemoteHttps)
  ?"/main/param_server/get_param_remote"
  :"/main/param_server/get_param_local";
}