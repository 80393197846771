import { Line, } from 'react-konva';  // LineKonva because Line already used in t2-2d-geometry


export const AreaKonva: React.FC<any> = ({area, selected = false, strokeWidth = 0.8, getAreaColor, onClick, start_left, start_right, end_left, end_right}: any) => {
  const points = [start_left, end_left, end_right, start_right]
  let definedSide = null  // Side to be highlighted
  if (area.def.type === "rows") {
    definedSide = [start_left, end_left]
  }
  else if (area.def.type === "side-track") {
    definedSide = area.def.defined_side === "left" ? [start_left, end_left] : [start_right, end_right]
  }
  return <Line points={points.flatMap((p: any) => [p.point.x, p.point.y])} stroke={getAreaColor(area.id, 1.0)}
    fill={selected ? getAreaColor(area.id): undefined} strokeWidth={strokeWidth} closed={true}
    onClick={onClick}
    onTap={onClick}
  />
}