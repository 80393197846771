import React, { useState, useEffect } from "react";
import {
  IonList,
  IonButton,
  IonContent,
  IonPage,
  IonItem,
  IonLabel,
  IonChip,
  IonGrid,
  IonCol,
  IonRow,
  IonAccordionGroup,
  IonAccordion,
  IonInput,
  IonDatetime,
  IonIcon,
  IonPopover,
  IonFab,
  IonFabButton,
  IonBadge,
} from "@ionic/react";
import { add, calendarNumberOutline, openOutline } from "ionicons/icons";
import Toolbar from "../../components/Toolbar";
import Loading from "../../components/Loading";
import { gql } from "@apollo/client";
import { useSubscriptionWithElevatedRole } from "../../hooks/hasuraHooks";
import { useMutationWithElevatedRole } from "../../hooks/hasuraHooks";
import useWindowDimensions from "../../useWindowDimensions";

const ignoreKeys = [
  "updated_at",
  "taurusbox_configs_on_slot1",
  "taurusbox_configs_on_slot2",
];

const SimDetails = ({ sim_card }: any) => {
  const { isLargeScreen } = useWindowDimensions();

  const [tmpSimcard, setTmpSimcard] = useState({ ...sim_card });
  const changedFields = Object.keys(tmpSimcard).filter(
    (k: string) => !ignoreKeys.includes(k) && tmpSimcard[k] !== sim_card[k]
  );

  const [updateSIMCard] = useMutationWithElevatedRole(
    gql`
      mutation UpdateSIMCard($id: Int!, $_set: sim_cards_set_input!) {
        update_sim_cards_by_pk(pk_columns: { id: $id }, _set: $_set) {
          id
        }
      }
    `,
    {}
  );

  const reset = () => {
    setTmpSimcard({ ...sim_card });
  };

  const [showExpiryCalendar, setShowExpiryCalendar] = useState(false);
  const [showOrderCalendar, setShowOrderCalendar] = useState(false);

  // To convert string to number for number field
  const checkType = (fieldName: any, value: any) => {
    if (value == "") {
      return null;
    } else if (typeof sim_card[fieldName] == "number") {
      return value? parseInt(value): null;
    } else return value;
  };

  useEffect(reset, [sim_card]);

  const fieldSetter = (fieldName: string) => {
    return ({ detail: { value, checked } }: any) => {
      setTmpSimcard((prev: any) => ({
        ...prev,
        [fieldName]: checkType(fieldName, value),
      }));
    };
  };

  const hasChanged = (fieldName: string) => {
    return changedFields.includes(fieldName);
  };

  const props = (fieldName: string, style?: any) => ({
    onIonChange: fieldSetter(fieldName),
    style: { fontWeight: hasChanged(fieldName) ? "bolder" : undefined, ...style},
  });

  const save = () => {
    if (changedFields.length < 1) return;
    const _set = Object.fromEntries(
      changedFields.map((k: string) => [k, tmpSimcard[k]])
    );
    updateSIMCard({
      variables: {
        id: sim_card.id,
        _set: _set
      },
    });
  };
  const contentItem = (
    <IonGrid slot="content" style={{ bottomBorder: "groove" }}>
      <IonItem>
        {changedFields.length > 0 && (
          <>
            <IonButton onClick={save} slot="start">
              Save
            </IonButton>
            <IonButton
              fill="outline"
              color="danger"
              onClick={reset}
              slot="start"
            >
              Reset
            </IonButton>
          </>
        )}
        <IonChip slot="end">
          <IonLabel>{sim_card.updated_at} (last update)</IonLabel>
        </IonChip>
      </IonItem>

      <IonRow>
        <IonCol>
          <IonLabel>Card Number:</IonLabel>
        </IonCol>
        <IonCol>
          <IonInput
            autocomplete="cc-number"
            maxlength={27}
            value={tmpSimcard.card_number}
            {...props("card_number")}
            placeholder="Edit card number"
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonLabel>Expires At:</IonLabel>
        </IonCol>
        <IonCol
          style={{
            display: "flex",
            alignItems: "baseline",
          }}
          onClick={() => setShowExpiryCalendar(true)}
        >
          <IonInput
            value={tmpSimcard.expires_at?.split("T")[0] || ""}
            placeholder="Select expiry date"
            readonly
          />
          <IonIcon icon={calendarNumberOutline} />
          <IonPopover
            isOpen={showExpiryCalendar}
            onDidDismiss={() => setShowExpiryCalendar(false)}
          >
            <IonDatetime
              value={tmpSimcard.expires_at?.split(".")[0]}
              presentation="date"
              {...props("expires_at")}
            ></IonDatetime>
          </IonPopover>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonLabel>Ordered At:</IonLabel>
        </IonCol>
        <IonCol
          style={{
            display: "flex",
            alignItems: "baseline",
          }}
          onClick={() => setShowOrderCalendar(true)}
        >
          <IonInput
            value={tmpSimcard.ordered_at?.split("T")[0] || ""}
            placeholder="Select ordered date"
            readonly
          />
          <IonIcon icon={calendarNumberOutline} />
          <IonPopover
            isOpen={showOrderCalendar}
            onDidDismiss={() => setShowOrderCalendar(false)}
          >
            <IonDatetime
              value={tmpSimcard.ordered_at?.split(".")[0]}
              presentation="date"
              {...props("ordered_at")}
            ></IonDatetime>
          </IonPopover>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonLabel>Phone Number:</IonLabel>
        </IonCol>
        <IonCol>
          <IonInput
            value={tmpSimcard.phone_number}
            {...props("phone_number")}
            placeholder="Add phone number"
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonLabel>URL:</IonLabel>
        </IonCol>
        <IonCol>
          <IonInput
            value={tmpSimcard.url}
            {...props("url", { textTransform: "lowercase" })}
            placeholder="add URL"
          />
          <IonIcon icon={openOutline} onClick={(e) => {e.stopPropagation();window.open(`${tmpSimcard?.url}`, '_blank')}}/>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonLabel>Pin:</IonLabel>
        </IonCol>
        <IonCol>
          <IonInput
            value={tmpSimcard.pin}
            {...props("pin")}
            placeholder="Edit pin"
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonLabel>PUK:</IonLabel>
        </IonCol>
        <IonCol>
          <IonInput
            value={tmpSimcard.puk}
            {...props("puk")}
            placeholder="Edit puk"
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonLabel>Supplier:</IonLabel>
        </IonCol>
        <IonCol>
          <IonInput
            value={tmpSimcard.supplier}
            {...props("supplier")}
            placeholder="Change supplier"
          />
        </IonCol>
      </IonRow>
      
      <IonRow>
        <IonCol>
          <IonLabel>Description:</IonLabel>
        </IonCol>
        <IonCol>
          <IonInput value={tmpSimcard.description} {...props("description")} placeholder="Add description"/>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <IonLabel>Taurusbox config on slot 1:</IonLabel>
        </IonCol>
        <IonCol>
          {tmpSimcard.taurusbox_configs_on_slot1?.map(
            (config: any, i: number) => {
              return (
                <div key={i}>
                  <strong>
                    GT {config.robot_config?.taurus_configs[0]?.taurus_id}
                  </strong>
                </div>
              );
            }
          )}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonLabel>Taurusbox config on slot 2:</IonLabel>
        </IonCol>
        <IonCol>
          {tmpSimcard.taurusbox_configs_on_slot2?.map(
            (config: any, i: number) => {
              return (
                <div key={i}>
                  <strong>
                    GT {config.robot_config?.taurus_configs[0]?.taurus_id}
                  </strong>
                </div>
              );
            }
          )}
        </IonCol>
      </IonRow>
    </IonGrid>
  );

  return (
    <IonAccordionGroup expand="inset">
      <IonAccordion value={sim_card?.id}>
        <IonItem slot="header" color="light">
          {sim_card?.card_number}
          {sim_card?.description && <IonBadge slot="end" color="medium">{sim_card.description}</IonBadge>}
          {sim_card?.taurusbox_configs_on_slot1?.map(({robot_config: {taurus_configs}}: any) => taurus_configs.map(({taurus_id}: any) => <IonBadge color="secondary" slot="end">GT{taurus_id}</IonBadge>))}
          {sim_card?.taurusbox_configs_on_slot2?.map(({robot_config: {taurus_configs}}: any) => taurus_configs.map(({taurus_id}: any) => <IonBadge color="secondary" slot="end">GT{taurus_id}</IonBadge>))}
          <IonBadge slot="end">
            {sim_card?.supplier}
          </IonBadge>
        </IonItem>
        <IonList lines="none" className="ion-padding" slot="content">
          {contentItem}
        </IonList>
      </IonAccordion>
    </IonAccordionGroup>
  );
};

const SimCards = () => {
  const { loading, data } = useSubscriptionWithElevatedRole(
    gql`
      subscription SIMCards {
        sim_cards(order_by: {id: asc}) {
          id
          card_number
          phone_number
          supplier
          pin
          puk
          url
          expires_at
          ordered_at
          updated_at
          description
          taurusbox_configs_on_slot1 {
            robot_config {
              taurus_configs {
                taurus_id
              }
            }
          }
          taurusbox_configs_on_slot2 {
            robot_config {
              taurus_configs {
                taurus_id
              }
            }
          }
        }
      }
    `,
    {
      variables: {},
    }
  );

  const [addSimcard] = useMutationWithElevatedRole(
    gql`
      mutation InsertSIMCard {
        insert_sim_cards_one(object: {}) {
          id
        }
      }
    `,
    {}
  );

  const { sim_cards } = data || [];

  return (
    <IonPage>
      <Toolbar name="Sim Cards" />
      <IonContent fullscreen>
        {loading && <Loading />}
        <IonFab horizontal="end" vertical="bottom" slot="fixed">
          <IonFabButton onClick={() => addSimcard()}>
            <IonIcon icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>
        {sim_cards && (
          <IonList>
            {sim_cards.map((sim_card: any) => {
              return <SimDetails sim_card={sim_card} key={sim_card.id} />;
            })}
          </IonList>
        )}
      </IonContent>
    </IonPage>
  );
};
export default SimCards;
