import React from 'react';
import { IonApp, IonSplitPane } from '@ionic/react';
import { useQuery, gql } from '@apollo/client';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../theme/variables.css';

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';

import Login from './pages/Login'


const UnauthenticatedApp: React.FC<any> = ({setToken, graphqlUri}) => {
  return (
    <IonApp>
         <IonSplitPane contentId="main">
            <Login setToken={setToken} graphqlUri={graphqlUri}></Login>
          </IonSplitPane>
    </IonApp>
  );
};

export default UnauthenticatedApp;
