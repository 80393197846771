import { IonList, IonButton, IonContent, IonItem, IonLabel, IonBadge, IonIcon, IonPopover, IonCard, IonCardHeader, IonCardTitle, IonCheckbox, IonButtons, IonSkeletonText, IonChip, IonProgressBar, IonToolbar, IonInput } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { alertCircle, checkmarkCircle, chevronDown, chevronUp, close, copy, expand, hourglass, syncCircle, } from 'ionicons/icons';
import { ShowEvaluation } from '../components/ShowEvaluation';
import useWindowDimensions from '../useWindowDimensions';
import ParamConfigButton from './ParamConfigButton';
import { BagBadge, BagTags } from '../components/BagItem';
import getMaxRole from '../lib/getMaxRole';
import { useMutationWithElevatedRole, useQueryWithElevatedRole, useSubscriptionWithElevatedRole } from '../hooks/hasuraHooks';
import { SearchableSelect } from './SearchableSelect';
import { EvaluationScore } from '../components/EvaluationScore';
const moment = require('moment-twitter');


export const EVALUATION_FRAGMENT = gql`
  fragment EvaluationFieldsWithoutBase on evaluations {
    id
    bag {
      aquila_had_camera_type
      id archived started_recording_at path taurus_id type plant_variety {id name_EN} bag_tags {id tag{id name} } aquila {id camera_type camera_is_flipped}
      ground_truths(where: {archived: {_eq: false}}, order_by: {id: desc}) {id evaluator_config_id data}
    }
    bag_id
    param_config {id description data}
    param_config_id
    evaluator_config {evaluator_name id data}
    evaluator_config_id
    result_data
    result_error
    execution_computer {id hostname}
    created_at updated_at
    score_passed
    score_author_name
    score_data
    score_details
    score_ground_truth_id
  }
  fragment EvaluationFields on evaluations {
    ...EvaluationFieldsWithoutBase
    base_evaluation {
      id
      ...EvaluationFieldsWithoutBase
    }
  }
`;


export const fixEvaluationsRegardingGroundTruth = (evaluations: any) => evaluations.map((evaluation: any) => ({
  // It is not possible to only get the evaluation that has the correct evaluator_config_id easily via hasura
  // The incorrect groundtruth is thus filtered out here
  ...evaluation,
  bag: {
    ...evaluation.bag,
    ground_truths: evaluation.bag.ground_truths.filter((gt: any) => gt.evaluator_config_id === evaluation.evaluator_config_id).splice(0, 1)
  }
}))


const EvaluatorConfigButton: React.FC<any> = ({evaluator_config, ...props}: any) => {
  const [show, setShow] = useState(false)
  if (evaluator_config?.id === 7) {
    return null  // default evaluator (treatment): no need to show anything
  }
  return <>
    <IonButton {...props} onClick={(e: any) => {e.stopPropagation(); setShow(true)}} fill="outline" color="secondary">
      {evaluator_config.evaluator_name}
    </IonButton>
    <IonPopover onDidDismiss={() => setShow(false)} isOpen={show}><IonContent>
      <h1>Evaluator {evaluator_config.name} (ID: #{evaluator_config.id})</h1>
      <p>
        <code>{JSON.stringify(evaluator_config.data)}</code>
      </p>
    </IonContent></IonPopover>
  </> 
}


export const useUpdateEvaluation = () => useMutationWithElevatedRole(gql`
  ${EVALUATION_FRAGMENT}
  mutation UpdateEvaluation($id: Int!, $_set: evaluations_set_input!) {
    update_evaluations_by_pk(pk_columns: {id: $id}, _set: $_set) {
      ...EvaluationFields
    }
  }
`)


const EvaluationState: React.FC<any> = ({evaluation}: any) => {
  const [updateEvaluation] = useUpdateEvaluation()
  const [event, setEvent] = useState<any>(null)
  const [error, setError] = useState("No image")
  // Only state with results is editable
  const iconProps = {onClick: setEvent, style: {cursor: "pointer"}}
  return <>
    <EvaluationStateIcon evaluation={evaluation} {...iconProps}/>
    {evaluation.result_data && !evaluation.result_error && <EvaluationScore evaluation={evaluation}/>}
    <IonPopover isOpen={!!event} event={event} onDidDismiss={() => setEvent(null)}>
      {evaluation.result_data && <>
        {!evaluation.result_error && <IonItem>
          <IonLabel position="stacked">Error message</IonLabel>
          <IonInput placeholder="Error" value={error}/>
        </IonItem>}
        <IonButton expand="full" onClick={() => updateEvaluation({variables: {id: evaluation.id, _set: {result_error: evaluation.result_error ? null : error}}})}>
          Set as {evaluation.result_error ? "completed": "failed"}
        </IonButton>
      </>}
      {evaluation.result_error && <code>{evaluation.result_error}</code>}
      {!evaluation.result_error && !evaluation.result_data && <IonButton onClick={() => updateEvaluation({variables: {id: evaluation.id, _set: {result_error: "Canceled"}}})}
        expand="full" color="danger">Cancel planned evaluation</IonButton>}
    </IonPopover>
  </>
}


const EvaluationStateIcon: React.FC<any> = ({evaluation, ...props}: any) => {
  if (evaluation.result_error) return <IonIcon icon={alertCircle} color="danger" {...props}/>
  if (evaluation.result_data) return <IonIcon icon={checkmarkCircle} color="secondary" {...props}/>
  if (evaluation.execution_computer) return <IonIcon icon={syncCircle} color="secondary" {...props}/>
  return <IonIcon icon={hourglass} {...props}/>
}


export const EvaluationItem: React.FC<any> = ({evaluation, setAddEvaluation, showBag = true, forceExpand=false, children}: any) => {
  const [expanded, setExpanded] = useState(false)
  useEffect(() => setExpanded(forceExpand), [forceExpand])  // Change expanded state when forceExpanded changes
  const {isLargeScreen} = useWindowDimensions()

  const buttons = <>
    {/* Open in new tab: easier to compare evals and share uri */}
    {setAddEvaluation && <IonButton fill="clear" onClick={(e:any) => {e.stopPropagation(); setAddEvaluation(evaluation)}} slot="end"><IonIcon icon={copy} slot="icon-only"/></IonButton>}
    <IonButton onClick={(e: any) => {e.stopPropagation(); window.open(`/evaluation/${evaluation.id}`)}} ><IonIcon slot="icon-only" icon={expand}/></IonButton>
    {isLargeScreen && <IonButton slot="end" onClick={() => setExpanded(!expanded)} fill="clear">
        <IonIcon icon={expanded ? chevronUp : chevronDown} slot={"icon-only"}/>
    </IonButton>}
  </>

  const headerItem = <>
    <IonItem lines={"full"} color={evaluation?.obsolete ? "light" : ""} style={{textDecoration: evaluation?.obsolete ? "line-through" : ""}}>
      {/* the status of the evaluation is shown on the left side */}
      <IonBadge>{evaluation.id}</IonBadge>
      <EvaluationState evaluation={evaluation}/>
      {showBag && <BagBadge bag={evaluation.bag}/>}
      <IonLabel style={{whiteSpace: "pre-wrap", cursor: "pointer"}} className="clickable"
        onClick={
          /* Only change expanded when event comes from this ion-label (avoid changing state when children trigger events) */
          (e: any) => {e.target.localName === "ion-label" && e.target.className.includes("clickable") && setExpanded(!expanded)}
        }
      >
        <EvaluatorConfigButton evaluator_config={evaluation.evaluator_config}/>
        <ParamConfigButton param_config={evaluation.param_config}/>
        {evaluation?.execution_computer && isLargeScreen && <IonChip>{evaluation.execution_computer.hostname || evaluation.execution_computer.id}</IonChip>}
      </IonLabel>
      
      {isLargeScreen && <BagTags bag={evaluation?.bag}
        showTagPicker={expanded /* only show if expanded: no information about tags can be inferred if collapsed */}
      />}
      {isLargeScreen && <IonChip >{moment(evaluation.updated_at).twitterShort()}</IonChip>}
      <IonButtons slot="end">{buttons}</IonButtons>
      {children}
    </IonItem>
  </>
  if (!expanded) {
    return headerItem
  }
  else {
    return <IonCard style={{margin: "15px"}}>
        {headerItem}
        <ShowEvaluation evaluation={evaluation} height={300} AddEvaluation={AddEvaluation} EvaluationItemFromId={EvaluationItemFromId} />
    </IonCard>
  }
}


export const EvaluationItemFromId: React.FC<any> = ({evaluation_id, ...props}: any) => {
  const { loading, error, data} = useSubscriptionWithElevatedRole(gql`
    ${EVALUATION_FRAGMENT}
    subscription GetEvaluation($id: Int!) {
      evaluations_by_pk(id: $id) {
        ...EvaluationFields
      }
    }
  `, {variables: {id: evaluation_id}, context: {headers: {"x-hasura-role": getMaxRole()}}});
  const evaluation = data?.evaluations_by_pk 
  return <>
    {loading && <IonSkeletonText/>}
    {evaluation && <EvaluationItem evaluation={evaluation} {...props} />}
  </>
}


export const useInsertEvaluations = () => useMutationWithElevatedRole(gql`
  ${EVALUATION_FRAGMENT}
  mutation InsertEvaluations($objects: [evaluations_insert_input!] = []) {
    insert_evaluations(objects: $objects) {
      returning {
        ...EvaluationFields
      }
    }
  }
`)


export const AddEvaluation: React.FC<any> = ({initialEvaluatorIds, initialParamConfigIds, onClose, bags}: any) => {
  const { loading, data } = useQueryWithElevatedRole(gql`query{
    evaluator_configs(order_by: {id: desc}, where: {archived: {_eq: false}}) {
      id
      evaluator_name
      data
    }

    param_configs(order_by: {id: desc}, where: {archived: {_eq: false}}) {
      id
      data
      description
    }
  }`)

  const [createEvaluations] = useInsertEvaluations()
  const [paramConfigIds, setParamConfigIds] = useState(initialParamConfigIds || [])
  const [evaluatorIds, setEvaluatorIds] = useState(initialEvaluatorIds || [])
  
  if (data) {
    // Remove param configs that are not available (e.g. archived)
    const availableParamConfigIds = new Set(data.param_configs.map(({id}: any) => id))
    const idsToBeRemoved = paramConfigIds?.filter((id: number) => !availableParamConfigIds.has(id))
    if (idsToBeRemoved.length > 0) {
      setParamConfigIds(paramConfigIds?.filter((id: number) => !idsToBeRemoved.includes(id)))
    }
  }
  
  // Generate evaluations based on picked bag, param_config and evaluator
  const evaluations = bags.map((bag: any) => paramConfigIds?.map((param_config_id: number) => evaluatorIds?.map((evaluator_config_id: number) => ({
    bag_id: bag.id, param_config_id, evaluator_config_id
  }))).flat()).flat()

  return <>
    <div style={{display: "flex"}}>
      <IonButton style={{flexGrow: 1}} color="secondary" disabled={evaluations.length < 1} onClick={() => createEvaluations({variables: {objects: evaluations}}).then(() => onClose())}>
        Create Evaluations ({evaluations.length})
      </IonButton>
      <IonButton slot="end" onClick={onClose}><IonIcon icon={close} slot="icon-only"/></IonButton>
    </div>
    <IonContent>
      {loading && <IonProgressBar type="indeterminate"/>}
      {data && <>
        <IonCard>
          <IonCardHeader><IonCardTitle>Parameters ({paramConfigIds?.length})</IonCardTitle></IonCardHeader>
          <SearchableSelect name="Param configs" value={paramConfigIds} multiple={true} onChange={(value: any) => setParamConfigIds(value)}
            options={data?.param_configs?.map((t: any) => [t.id, `#${t.id} ${t.description}`])} />
        </IonCard>

        <IonCard >
            <IonCardHeader><IonCardTitle>Evaluators ({evaluatorIds?.length})</IonCardTitle></IonCardHeader>
            <IonList  style={{maxHeight: 150, overflowY: "scroll"}}>
              {data?.evaluator_configs?.map((evaluator_config: any) => <IonItem key={evaluator_config.id}
                onClick={() => setEvaluatorIds((prev: any) => evaluatorIds?.includes(evaluator_config?.id) ? prev.filter((id: number) => id !== evaluator_config.id) : [...prev, evaluator_config.id])}>
                <IonCheckbox checked={evaluatorIds?.includes(evaluator_config?.id) } slot="start"/>
                <div>
                  <IonBadge>{evaluator_config.evaluator_name}</IonBadge>
                  <IonBadge>{JSON.stringify(evaluator_config.data)}</IonBadge>
                </div>
              </IonItem>)}
            </IonList>
        </IonCard>

        <IonCard style={{maxHeight: 250, overflowY: "scroll"}}>
            <IonCardHeader><IonCardTitle>Bags ({bags.length})</IonCardTitle></IonCardHeader>
            <IonList>
                {bags?.map((bag: any) => <IonItem key={bag.id}>
                    {bag.name && <IonLabel>{bag.name}</IonLabel>}
                    <BagBadge bag={bag}/>
                </IonItem>)}
            </IonList>
        </IonCard>

      </>}
    </IonContent>
  </>
}
