import { IonIcon, IonItem } from '@ionic/react';
import { informationCircle } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';


export const referenceVolumePerHa = 200  // For broad application of pesticides


export const ReferenceSprayerAreaInfo: React.FC<{}> = ({}) => {
  const {t} = useTranslation()
  return <IonItem lines="none">
    <IonIcon icon={informationCircle} slot="start"/>
    {t("ha*: comparison value for broadcast spraying with volume of {{ referenceVolumePerHa }} L / ha", {referenceVolumePerHa})}
  </IonItem>
}