import { IonList, IonContent, IonPage, } from '@ionic/react';
import React, { useState } from 'react';
import Toolbar from '../../components/Toolbar'
import { useQuery, useMutation, gql } from '@apollo/client';
import Loading from '../../components/Loading'
import { TalpaItem } from '../../components/TalpaItem';
import { TALPA_FRAGMENT } from '../../hooks/useRobot';


const Talpas: React.FC = () => {

  const { loading, data } = useQuery(gql`
    ${TALPA_FRAGMENT}
    query{
      talpas (order_by: {id: asc}) {
        ...TalpaParts

        talpa_configs(order_by: {created_at: desc}, limit: 1) {
          robot_config {
            taurus_configs(order_by: {id: desc}, limit: 1) {
              taurus{
                id
              }
            }
            id
          }
        }

      }
    }
  `, {pollInterval: 300});
  return (
    <IonPage>
      <Toolbar name="Talpas"/>
      <IonContent fullscreen>
         {loading && <Loading/>}
         {data && <IonList>
             {data.talpas.map((talpa: any, index: number) => <TalpaItem talpa={talpa} key={index}/>)}
        </IonList>}
      </IonContent>
    </IonPage>
  );
};

export default Talpas;
