import { IonButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonModal, IonTitle, IonToolbar } from "@ionic/react"
import { useEffect, useRef, useState } from "react"
import { useRosClock, Time, useCallService } from "../hooks/rosHooks";

import uPlot from 'uplot';
import 'uplot/dist/uPlot.min.css';
import {wheelZoomPlugin} from '../utils/uplot/wheelZoomPlugin';

const COLOR_PALETTE = ["#f34213","#e6af2e","#474b24","#a4031f","#283044","#177e89"];

interface Duration {
  secs: number,
  nsecs: number,
}

interface GetHistoryReq {
  start: Time,
  end: Time,
  dt: Duration
}

interface GetHistoryRes {
  ts: Time[],
  row_names: string[],
  cumulative_masses_per_row_mg: number[],
  cumulative_num_spots_per_row: number[],
  pressures_bar: number[],
  filling_level_l: number[],
}

interface Service<Req, Res> {
  req: Req,
  res: Res
}

function ts_to_time(t: number): Time {
  const secs = Math.floor(t);
  const nsecs: number = Math.round((t - secs) * 1e9);
  return {
    secs, nsecs
  }
}

function time_to_ts(t: Time): number {
  return t.secs + t.nsecs * 1e-9
}

function time_to_date(t: Time) : Date {
  const stamp = Math.round(time_to_ts(t)*1000);
  return new Date(stamp)
}

interface Plot {
  options: uPlot.Options,
  data: uPlot.AlignedData
}

function input_to_time(e : HTMLInputElement) : Time | null {
  let d = e.valueAsDate;
  if (d === null) {
    return null
  }
  d = new Date(d.getTime() + 60000 * d.getTimezoneOffset());
  return ts_to_time(d.getTime())
}

interface uPlotExt extends uPlot {
  updateSyncees? : Function
}

const INITIAL_OPTS : uPlot.Options = {
  height: 200,
  width: 0,
  series: [],
  cursor: {
    sync: {
      key: "herbicide-usage-plot",
      setSeries: false,
    },
    drag: {
      x: true,
      y: false
    }
  },
  plugins: [
    wheelZoomPlugin({factor: 0.75}) as any
  ],
  scales: { x: { time: true }, y: {auto: true} },
  
};

const TEST_MODE = false;
const TEST_START : Time = {secs: 1718295851, nsecs: 0};
const TEST_END : Time = {secs: 1718295951, nsecs: 0};

export const SprayerHerbicideUsage: React.FC<{}> = ({ }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { now, reinitialize } = useRosClock();

  const [t_end, set_t_end] = useState<Time | undefined>(undefined);
  const [t_start, set_t_start] = useState<Time | undefined>(undefined);

  const [timezone_ms, _] = useState(()=>{
    const d = new Date();
    return  - d.getTimezoneOffset() * 60000
  });

  const queried_range = useRef({
    t_start: TEST_START,
    t_end: TEST_END,
    timeout: -1
  });

  // initialize time range in view
  let t_now = now();
  if (TEST_MODE && t_end === undefined) {
    set_t_start(TEST_START);
    set_t_end(TEST_END);
  } else if (t_end === undefined && t_now !== undefined && t_now.secs !== 1) {
    // console.log({ t_now })
    set_t_end(t_now);
    set_t_start({ secs: t_now.secs - 10 * 60, nsecs: 0 })
  }

  const input_t_start = useRef<null | HTMLInputElement>(null);
  const input_t_end = useRef<null | HTMLInputElement>(null);
 
  const callService = useCallService<GetHistoryReq, GetHistoryRes>();
  const container = useRef<null|HTMLDivElement>(null);
  const [attached, set_attached] = useState(false);
  const [plots, update_plots] = useState(()=>{
    const html_tank = document.createElement("div");
    const html_spots_num = document.createElement("div");
    const html_spots_mass = document.createElement("div");  
    const plots = {
      uplot : {
        tank : new uPlot({
            ...INITIAL_OPTS,
            series: [{
              label: "Date"
            },
            {
              label: "pressure (bar)",
              points: { show: false },
              stroke: "black",
              scale: "bar"
            },
            {
              label: "filling level (l)",
              points: { show: false },
              stroke: "blue",
              scale: "l"
            }],
            scales: {
              "bar": {
                range: {
                  min: {
                    hard: 0.0,
                    soft: 0.0,
                    mode: 2
                  },
                  max: {
                    pad: 0.05
                  }
                }
              },
              "l": {
                range: {
                  min: {
                    hard: 0.0,
                    soft: 0.0,
                    mode: 2
                  },
                  max: {
                    pad: 0.05
                  }
                }
              }
            },
            title: "tank",
            axes: [
              {},
              {
                scale: "bar",
                side: 3,
                label: "pressure (bar)"
              },
              {
                scale: "l",
                side: 1,
                label: "filling level (l)"
              }
            ]
          },
          undefined,
          html_tank
        ),
        spots_num : new uPlot({
          ...INITIAL_OPTS,
          series: [
            {
              label: "Date"
            },
          ],
          title: "number of spots"
        }, undefined, html_spots_num),
        spots_mass : new uPlot({
            ...INITIAL_OPTS,
            series: [
              {
                label: "Date"
              },
            ],
            title: "spot masses (mg)"
          }, undefined, html_spots_mass)
      },
      html: {
        tank: html_tank,
        spots_num: html_spots_num,
        spots_mass : html_spots_mass
      },
      sync: uPlot.sync("herbicide-usage-plot"),
      updateData : (t_start : Time, t_end : Time)=>{
        let dt = ts_to_time((time_to_ts(t_end) - time_to_ts(t_start)) / 200);
        let req = {
          start: t_start,
          end: t_end,
          dt: dt
        };
        callService("/herbicide_usage_stats/get_pressure_history", "sprayer/GetHistory", req, (res) => {
          const ts = (res.ts.map(({ secs, nsecs }) => secs + nsecs * 1e-9));

          queried_range.current.t_start = t_start;
          queried_range.current.t_end = t_end;
          
          const pressures = res.pressures_bar.map(p=>p===null?NaN:p);
          const filling_levels = res.filling_level_l.map(f=>f===null?NaN:f);
          
          const row_names = res.row_names;
          const mass_data_per_row = new Map<string, number[]>();
          const num_spots_per_row = new Map<string, number[]>();
          let idx_start = 0;
          for (const name of row_names) {
            const idx_end = idx_start + res.ts.length;
            mass_data_per_row.set(name, res.cumulative_masses_per_row_mg.slice(idx_start, idx_end).map((e, idx)=>res.pressures_bar[idx]===null?NaN:e));
            num_spots_per_row.set(name, res.cumulative_num_spots_per_row.slice(idx_start, idx_end).map((e, idx)=>res.pressures_bar[idx]===null?NaN:e));
            idx_start = idx_end
          }
          row_names.sort();

          plots.uplot.tank.setData(
            [
              new Float64Array(ts),
              new Float64Array(pressures),
              new Float64Array(filling_levels)
            ],
            true
          );
          
          for (const plot of [plots.uplot.spots_mass, plots.uplot.spots_num]) {
            if (plot.series.length === 1) {
              let idx = 0;
              for (const row_name of row_names) {
                plot.addSeries({
                  label: row_name,
                  points: {show: false}, 
                  stroke: COLOR_PALETTE[idx % COLOR_PALETTE.length],
                })
                idx += 1;
              }
            }
          }

          plots.uplot.spots_mass.setData([
            new Float64Array(ts),
            ...(row_names.map(name=>{
              return new Float64Array(mass_data_per_row.get(name)!.map(mg=>0.001*mg))
            })//.filter(a=>a.length==ts.length)
            )
          ],
          true);

          plots.uplot.spots_num.setData([
            new Float64Array(ts),
            ...(row_names.map(name=>{
              return new Float64Array(num_spots_per_row.get(name)!)
            })//.filter(a=>a.length==ts.length)
            )
          ],
          true);
        });    
      }
    };

    // add event handler hook so that the plots update their field of view in sync
    for (const [name, plot] of Object.entries(plots.uplot)) {
      plots.sync.sub(plot);
      (plot as any).updateSyncees = (key : string, limits : {min : number, max: number})=>{
        for (const [name, plot] of Object.entries(plots.uplot)) {
          //console.log("updating", name);
          plot.setScale(key, limits)
        }
        set_t_start(ts_to_time(limits.min));
        set_t_end(ts_to_time(limits.max));
      }
    }
    (window as any).plots = plots;
    (window as any).updateData = ()=>{
      if (TEST_MODE) {
        plots.updateData(TEST_START, TEST_END)
      } else {
        plots.updateData(
          input_to_time(input_t_start.current!)!,
          input_to_time(input_t_end.current!)!
        )
      }
    };
    return plots
  });

  // <elaborate logic to determine the width of our popup, because uPlot needs this as an input>
  const [content_width, set_content_width] = useState(0);
  const ion_content_ref = useRef<null | HTMLIonContentElement>(null);
  
  function check_content_width() {
    if (ion_content_ref.current === null) {
      window.setTimeout(check_content_width, 100);
      return;
    }
    const new_width = ion_content_ref.current.getBoundingClientRect().width;
    if (new_width !== 0) {
      //console.log("set_content_width");
      set_content_width(new_width);
      for (const [_name, plot] of Object.entries(plots.uplot)) {
        plot.setSize({width : new_width, height: plot.height});
      }
    }
  }
  if (content_width === 0) {
    check_content_width()
  }
  // </ … >

  const init = useRef<undefined|number>(undefined);

  function initialize() {
    if (!attached && container.current!==null && content_width !== 0) {
      window.clearInterval(init.current!);
      container.current.appendChild(plots.html.tank);
      container.current.appendChild(plots.html.spots_num);
      container.current.appendChild(plots.html.spots_mass);
      for (let [_name, plot] of Object.entries(plots.uplot)) {
        plot.setSize({height: 200, width: content_width - 10});
      }
      set_attached(true);
    }
  }
  init.current = window.setInterval(()=>{
    initialize()
  }, 100);

  if (input_t_start.current !== null && t_start !== undefined) input_t_start.current.valueAsNumber = timezone_ms + 1000*time_to_ts(t_start);
  if (input_t_end.current !== null && t_end !== undefined) input_t_end.current.valueAsNumber = timezone_ms + 1000*time_to_ts(t_end);

  // Check whether the time range we queried differs from the one that is shown now. If yes,
  // send a new request to the backend for new data
  if (
    // only query if time range is initialized
    t_start !== undefined
    && t_end !== undefined
    // check time interval
    && (
      // selected time interval starts before queried data (gap at beginning)
      time_to_ts(queried_range.current.t_start) > time_to_ts(t_start)
      // selected time interval ends after queried data ends (gap at the end)
      || (time_to_ts(t_end) > time_to_ts(queried_range.current.t_end))
      // the interval is in range, but we have zoomed in and want to display a better sampling rate
      || (
        (time_to_ts(t_end) - time_to_ts(t_start)) / (time_to_ts(queried_range.current.t_end) - time_to_ts(queried_range.current.t_start)) < 0.8
      )
    )
  ) { 
    // re-query
    window.clearTimeout(queried_range.current.timeout);
    queried_range.current.timeout = window.setTimeout(()=>{
      plots.updateData(t_start, t_end);
    }, 100)
  }


  return <IonContent className="ion-padding" ref={ion_content_ref}>
        <IonItem>
          <input type="datetime-local" ref={input_t_start}
            defaultValue={((()=>{
              let d = new Date();
              d = new Date(d.getTime() - 3600000 - 60000 * d.getTimezoneOffset());
              return d.toISOString().slice(0,16)
            })())}
            onChange={evt=>{
              const t = ts_to_time((evt.target.valueAsNumber - timezone_ms) / 1000);
              set_t_start(t)
            }}
          />
          <input type="datetime-local" ref={input_t_end}
            defaultValue={((()=>{
              let d = new Date();
              d = new Date(d.getTime() - 60000 * d.getTimezoneOffset());
              return d.toISOString().slice(0,16)
            })())}
            onChange={evt=>{
              const t = ts_to_time((evt.target.valueAsNumber - timezone_ms) / 1000);
              set_t_end(t)
            }}
          />
        </IonItem>
        <div ref={container} className="uplotContainer" />
      </IonContent>
}