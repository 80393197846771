import { IonList, IonButton, IonContent, IonPage, IonItem, IonLabel, IonBadge, IonIcon, IonChip, IonPopover, IonToolbar, IonCard, IonCardHeader, IonCardContent, IonCardSubtitle, } from '@ionic/react';
import React, { useState } from 'react';
import Toolbar from '../../components/Toolbar'
import { useQuery, useMutation, gql } from '@apollo/client';
import Loading from '../../components/Loading'
import { checkmark, close } from 'ionicons/icons';
import useWindowDimensions from '../../useWindowDimensions';
import history from '../../history'
import getMaxRole from '../../lib/getMaxRole';
import { PopoverChip } from '../../components/PopoverChip';


const NTRIPAccounts: React.FC = () => {
  const onClick = (e:any, robotConfigId: number) => {
      e.stopPropagation()
      history.push(`/robot/${robotConfigId}`)
  }

  const ntripData = useQuery(gql`query{
    ntrip_accounts(order_by: {id: asc}, where: {is_active: {_eq: true}}) {
      id
      url
      user
      password
      comment
    }
  }`, {
    context: {
      headers: {
        "x-hasura-role": "farming-revolution-staff"
      }
    }
  });

  const taurusConfigData = useQuery(gql`query{
  tauruses(order_by: {id: asc}) {
    id
    taurus_configs(order_by: {id: desc}, limit: 1) {
      robot_config {
        taurusbox_configs {
          ntrip_account_id
        }
      }
    }
  }
}`, {
    context: {
      headers: {
        "x-hasura-role": "farming-revolution-staff"
      }
    }
  })

  // get map of ntrip id -> taurus id
  const ntripToTaurusMapping = Object.fromEntries(taurusConfigData.loading ? [] : taurusConfigData.data.tauruses.map((x: any) => [x.taurus_configs[0]?.robot_config.taurusbox_configs[0].ntrip_account_id, x.id]))

  return (
    <IonPage>
      <Toolbar name="NTRIP Accounts"/>
      <IonContent fullscreen>
         {ntripData.loading && <Loading/>}
         {ntripData.data && <IonList>
             {ntripData.data.ntrip_accounts.map((ntrip_account: any) => {
                return <IonItem>
                    <IonLabel>
                      <IonBadge>{ntrip_account?.id}</IonBadge>
                      <PopoverChip text={ntrip_account?.url} icon={checkmark}>
                        <IonToolbar>
                          <h1>
                            {ntrip_account?.url}
                          </h1>
                        </IonToolbar>
                        <IonItem><IonLabel>User</IonLabel>{ntrip_account?.user}</IonItem>
                        <IonItem><IonLabel>Password</IonLabel>{ntrip_account?.password}</IonItem>
                      </PopoverChip>
                    </IonLabel>
                    {ntrip_account?.comment && <IonLabel>{ntrip_account?.comment}</IonLabel>}
                    {ntripToTaurusMapping[ntrip_account.id] &&
                        <IonChip outline color="secondary">GT{ntripToTaurusMapping[ntrip_account.id]}</IonChip>}
                </IonItem>
              }
             )}
        </IonList>}
      </IonContent>
    </IonPage>
  );
};

export default NTRIPAccounts;
