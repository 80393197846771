import { gql, useMutation } from '@apollo/client';
import { IonAccordion, IonAccordionGroup, IonButton, IonButtons, IonCard, IonCardHeader, IonIcon, IonItem, IonLabel, IonList, IonSegment, IonSegmentButton, } from '@ionic/react';
import React, { useState } from 'react';
import getMaxRole from '../lib/getMaxRole';


export const getFlatKeys = (obj : any, prefix : String = "") : String[] => {
  // Recursive method to get 'flat' keys of object. E.g {a: {b: 3}} => ["/a/b"]
  let rv : String[] = [];
  for (let key in obj) {
    const newPrefix = prefix + "/" + key
    if (obj[key] instanceof Object) {
      rv = [...rv, ...getFlatKeys(obj[key], newPrefix)]
    } else {
      rv.push(newPrefix);
    }
  }
  return rv.map(e=>e.replace("//","/")) // use of slashes is inconsistent in database
}


export const flattenDict = (obj : any, prefix : String = "") : String[] => {
  // Recursive method to get 'flat' keys of object. E.g {a: {b: 3}} => {"/a/b": 3}
  let rv : any = {};
  for (let key in obj) {
    const newPrefix = (prefix + "/" + key).replace("//", "/")  // use of slashes is inconsistent in database
    if (obj[key] instanceof Object) {
      rv = {...rv, ...flattenDict(obj[key], newPrefix)}
    } else {
      rv[newPrefix] = obj[key]
    }
  }
  const sortedDict: any = {}
  Object.keys(rv).sort().map((key: string) => {
    sortedDict[key] = rv[key]
  })
  return sortedDict
}


export const mergeDicts = (objects : any[]) : String[] => {
  // Merge dicts as such : {"a": 3, "b": 1} and {"a": 0} => {"a": [3, 0], "b": 1}
  let rv : any = {};
  objects?.forEach((obj: any) => {
    for (let key in obj) {
      if (rv[key]) {
        rv[key].add(obj[key])
      }
      else {
        rv[key] = new Set([obj[key]])
      }
    }
  })
  return rv
}


export const getMissingKeys = (flatDict: any, paramOptions: any) => {
  const keys = new Set(Object.keys(flatDict))
  return [...Object.keys(paramOptions)].filter((e: any) => !keys.has(e))
}


export const ParamConfigErrors: React.FC<any> = ({param_config, paramOptions, editConfig, ...props}: any) => {
  // Find keys not present in this param config
  const missingKeys = getMissingKeys(flattenDict(param_config.data), paramOptions)
  const [overrideValues, setOverrideValues] = useState<any>({})
  const save = () => {
    // Create new config and replace key / value in the data of the new config
    editConfig(param_config, {...param_config.data, ...overrideValues})
  }
 
  if (missingKeys.length < 1) {
    // No error
    return null
  }
  
  return <IonAccordionGroup expand="inset">
    <IonAccordion value={param_config?.id}>
      <IonItem slot="header" color="danger">
        Missing params
      </IonItem>
      <div slot="content">
        <IonList style={{maxHeight: 400,  overflowY: "scroll"}}>
          {missingKeys.map((key : string) => <IonItem key={key}>
            {key}
            <IonButtons slot="end">
              <IonSegment value={overrideValues[key]}>
                {Array.from(paramOptions[key].values()).map((v: any) => <IonSegmentButton
                  color="secondary" onClick={() => setOverrideValues((d: any) => ({...d, [key]: v}))} value={v}>{JSON.stringify(v)}</IonSegmentButton>)}
              </IonSegment>
            </IonButtons>
          </IonItem>)}
        </IonList>
        <IonButton disabled={Object.keys(overrideValues).length < missingKeys.length} expand="full" onClick={save}>Set values</IonButton>
      </div>
    </IonAccordion>
  </IonAccordionGroup>
}

export default ParamConfigErrors