import { IonButton, IonModal, IonItem, IonContent, IonTitle, IonToolbar, IonChip, IonBadge, IonLabel, } from '@ionic/react';
import React, { useState } from 'react';
import { gql, } from '@apollo/client';
import { useSubscriptionWithElevatedRole } from '../hooks/hasuraHooks';
import { useTranslation } from 'react-i18next';


export const useIngredients = (where: any = {}) => useSubscriptionWithElevatedRole(gql`subscription GetIngredients($where: ingredients_bool_exp!) {
      ingredients (order_by: {id: desc, type: asc, group: asc}, where: $where) {
        id
        created_at
        name_DE name_PL name_EN
        active_substance_DE active_substance_PL active_substance_EN
        group type unit default_amount_per_ha
      }
  }`, {variables: {where}});


export const IngredientPicker: React.FC<any> = ({children, title, onSelect, ...props }) => {
  const [show, setShow] = useState(false)
  const {t} = useTranslation()
  
  return <>
    <IonModal isOpen={show} onDidDismiss={() => setShow(false)}>
      {title && <IonToolbar>
        <IonTitle>
          {title}
        </IonTitle>
      </IonToolbar>}
      <IngredientPickerContent onSelect={(ingredient: any) => {onSelect(ingredient); setShow(false)}} />
      <IonButton onClick={() => setShow(false)}>{t("Cancel")}</IonButton>
    </IonModal>
    <IonButton {...props} onClick={() => setShow(true)}>
      {children}
    </IonButton>
  </>
}


export const IngredientPickerContent: React.FC<any> = ({onSelect}) => {
  const { data } = useIngredients()
  const {t} = useTranslation()
  return <IonContent>
    {data?.ingredients?.map((ingredient: any) => <IonItem detail onClick={() => onSelect(ingredient)} button>
      {ingredient.name_DE}
      <IonLabel slot="end" style={{maxWidth: "50%", fontStyle: "italic"}}>
        {ingredient.active_substance_DE}
      </IonLabel>
      {ingredient.group && <IonBadge slot="end">{t(ingredient.group)}</IonBadge>}
      {ingredient.type && <IonBadge slot="end">{t(ingredient.type)}</IonBadge>}
    </IonItem>)}
  </IonContent>
}