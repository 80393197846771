import { IonButtons, IonContent, IonLoading, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import Toolbar from './Toolbar'
import { useTranslation } from 'react-i18next';

const Loading: React.FC<any> = ({loading = true}) => {
  const { t } = useTranslation();
  if (!loading) return <></>
  return (
      <IonLoading
        isOpen={true}
        message={`${t("Please wait")}...`}
      />
  );
};

export default Loading;
