import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonLabel,
  IonItem,
  IonIcon,
  IonButtons,
  IonPopover,
  IonToggle,
} from '@ionic/react';
import {Stage, Layer, Line, Arrow, Text, Circle, Label, Tag, Group, Rect} from 'react-konva';
import {Html} from 'react-konva-utils';

import { useKonvaStageProps } from '../hooks/useKonvaStageProps';
import { codeWorking, compass, gitPullRequest, layers, list, locate, mapOutline, navigate, shield, shuffle, swapHorizontal } from 'ionicons/icons';


export function RainBowColor(length: number, maxLength: number, opacity: number = 1.)
{
    var i = (length * 255 / maxLength);
    var r = Math.round(Math.sin(0.024 * i + 0) * 127 + 128);
    var g = Math.round(Math.sin(0.024 * i + 2) * 127 + 128);
    var b = Math.round(Math.sin(0.024 * i + 4) * 127 + 128);
    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
}


export const OriginFrame: React.FC<any> = ({size = 1}) => {
  return <>
    <Arrow points={[0, 0, size, 0.]} stroke="black" strokeWidth={0.05} pointerLength={0.1} pointerWidth={0.1} />
    <Text x={1.1 * size} y={0.1 * size} scaleY={-1} text={"x"} fontSize={0.2 * size } />
    <Arrow points={[0, 0, 0, size]} stroke="black" strokeWidth={0.05} pointerLength={0.1} pointerWidth={0.1} />
    <Text x={0} y={1.3 * size} text={"y"} scaleY={-1} fontSize={0.2 * size} />
  </>
}


export const KonvaStage: React.FC<any> = ({settings, buttons, children}) => {  // memo: memoized (not update from parent field section because has no parameters)
  const [followRobot, setFollowRobot] = useState(false)
  const [scale, stageProps, center] = useKonvaStageProps(() => setFollowRobot(false) /* when user moves / change map: deactivte follow mode */)
  //const rosHandle = useContext(RosHandleContext)
  //const devContext = useDeveloper()
  return <>
    <IonButtons style={{zIndex: 1, flexDirection: "column", alignItems: "flex-start", width: 0, top: "200px", position: "absolute"}}>
      {buttons}
    </IonButtons>
    <Stage
      style={{width: stageProps.width+"px", height: stageProps.height+"px", "top":0, "left": 0, position: "absolute"}}
      {...stageProps}
    >
      {/*<DeveloperModeContext.Provider value={devContext}>*/}
      {/* <RosHandleContext.Provider value={rosHandle}>  FIXME: find generalizing solution for this */}
        <Layer scaleY={-1 /* important: y goes down in konva */}>
          {children}
        </Layer>
      {/*</RosHandleContext.Provider>*/}
      {/*</DeveloperModeContext.Provider>*/}
    </Stage>
  </>
}


export const Path: React.FC<any> = ({path, color, strokeWidth = 0.1}: any) => {
  //const {developer} = useDeveloper()
  const developer = false
  const points = path?.points
  if (!points || points?.length < 3) {
    return null
  }
  const button = <IonButton fill="clear" color="success" size="small" onClick={() => console.log(path)}>
    {path.area_id && <>A{path.area_id}:</>}
    {path.type}
    {path.number > 0 && <>{path.number}</>}
  </IonButton>
  return <>
    {/*
    <CenterHtml x={points[0].x} y={points[0].y}>{button}</CenterHtml>
    <CenterHtml x={points[points.length - 1].x} y={points[points.length - 1].y}>{button}</CenterHtml>
    */}
    {developer && <StandardArrow p1={points[0]} p2={points[1]} fill={color}/>}
    {developer && <StandardArrow p1={points[points.length - 2]} p2={points[points.length - 1]} fill={color}/>}
    <Line points={points.flatMap((p: any) => [p.x, p.y])} stroke={color} strokeWidth={strokeWidth}  onMouseOver={() => console.log(path)}/>
  </>
}


export const KonvaMap: React.FC<any> = ({initialSettings, children, ...props}) => {
  const [showSettings, setShowSettings] = useState(false)
  const [settings, setSettings] = useState(initialSettings || {
    showGeofence: true,
    showField: true,
    showRowFollowing: true,
    showNavigation: true,
    showSideShift: false,
    showTrackCache: false,
    showLimitsCache: false,
  })
  //const {developer} = useDeveloper()
  const developer = false
  const buttons = <>
    <IonPopover isOpen={showSettings} onDidDismiss={() => setShowSettings(false)} className="bottom-popover">
      <IonItem onClick={() => setSettings((prev: any) => ({...prev, showGeofence: !prev.showGeofence}))} style={{cursor: "pointer"}}>
        <IonIcon icon={shield} slot="start"/>
        <IonLabel>Geofence</IonLabel>
        <IonToggle checked={settings.showGeofence}/>
      </IonItem>
      <IonItem onClick={() => setSettings((prev: any) => ({...prev, showField: !prev.showField}))} style={{cursor: "pointer"}}>
        <IonIcon icon={mapOutline} slot="start"/>
        <IonLabel>Field</IonLabel>
        <IonToggle checked={settings.showField}/>
      </IonItem>
      <IonItem onClick={() => setSettings((prev: any) => ({...prev, showNavigation: !prev.showNavigation}))} style={{cursor: "pointer"}}>
        <IonIcon icon={gitPullRequest} slot="start"/>
        <IonLabel>Navigation</IonLabel>
        <IonToggle checked={settings.showNavigation}/>
      </IonItem>
      <IonItem onClick={() => setSettings((prev: any) => ({...prev, showRowFollowing: !prev.showRowFollowing}))} style={{cursor: "pointer"}}>
        <IonIcon icon={shuffle} slot="start"/>
        <IonLabel>Row following</IonLabel>
        <IonToggle checked={settings.showRowFollowing}/>
      </IonItem>
      <IonItem onClick={() => setSettings((prev: any) => ({...prev, showSideShift: !prev.showSideShift}))} style={{cursor: "pointer"}}>
        <IonIcon icon={swapHorizontal} slot="start"/>
        <IonLabel>Side-shift</IonLabel>
        <IonToggle checked={settings.showSideShift}/>
      </IonItem>
      {developer && <IonItem onClick={() => setSettings((prev: any) => ({...prev, showTrackCache: !prev.showTrackCache}))} style={{cursor: "pointer"}}>
        <IonIcon icon={list} slot="start"/>
        <IonLabel>Track cache</IonLabel>
        <IonToggle checked={settings.showTrackCache}/>
      </IonItem>}
      {developer && <IonItem onClick={() => setSettings((prev: any) => ({...prev, showLimitsCache: !prev.showLimitsCache}))} style={{cursor: "pointer"}}>
        <IonIcon icon={codeWorking} slot="start"/>
        <IonLabel>Limits cache</IonLabel>
        <IonToggle checked={settings.showLimitsCache}/>
      </IonItem>}
    </IonPopover>
    <IonButton onClick={() => setShowSettings(true)}><IonIcon icon={layers} slot="icon-only"/></IonButton>
  </>
  return <KonvaStage buttons={buttons} settings={settings} {...props}>{children}</KonvaStage>
}


const StandardArrow: React.FC<any> = ({p1, p2, size=1.5, ...props}: any) => <Arrow
  pointerLength={1.5} pointerWidth={1.5} points={[p1.x, p1.y, p2.x, p2.y]} strokeWidth={0.1} {...props}
/>


export const CenterHtml: React.FC<any> = ({x, y, children, width = 200}: any) => {
  //const rosHandle = useContext(RosHandleContext)
  //const devContext = useDeveloper()
  return <Group x={x} y={y}>
    <Html
      divProps={{
        style: {
          position: 'absolute',
        },
      }}
      transformFunc={(a: any) => {/* no need to scale and rotate: we only need the position */ a.rotation = 0; a.scaleX /= 100; a.scaleY /= -100; return a}}
      >
      {/*<DeveloperModeContext.Provider value={devContext}><RosHandleContext.Provider value={rosHandle}>*/}
        <div style={{height: width, width: width, marginLeft: -width / 2, marginTop: -width / 2, display: "flex", justifyContent: "center", alignItems: "center"}}>
          {children}
        </div>
      {/*</RosHandleContext.Provider></DeveloperModeContext.Provider>*/}
    </Html>
  </Group>
}